<template>
  <div class="node-detail-metrics full-width" >
    <v-col class="pa-0" >
      <v-row class="ma-2">
        <div class="token-text-medium my-auto">
          Metrics
        </div>
        <div class="ml-auto">
        <CustomButton ref="menuButton" :level="$enums.ButtonLevels.Tertiary" @click="dropdownOpened = true" class="px-1">
          <span class="ml-n3">{{ userSelectedPeriod.text }}</span>
          <Icon  class="ml-1 mr-n4" color="#3219B2" icon="caret-down"/>
        </CustomButton>
        <DropdownMenu 
          :items="itemsDropdown"
          v-model:show="dropdownOpened"
          :modelValue="userSelectedPeriod"
          :maxContent="true"
          @update:modelValue="onUserSelecPeriod($event)"
          :target="$refs.menuButton"
        />
        </div>
      </v-row>
      <v-row class="ma-2 mb-4" v-if="userSelectedPeriod.id === 'custom'">
        <CustomTextInput v-model="startPicker" type="datetime-local" style="width: 200px;" @update:modelValue="onDatePickerChange"/>
        <Icon icon="arrow-right"/>
        <CustomTextInput v-model="endPicker" type="datetime-local" style="width: 200px;" @update:modelValue="onDatePickerChange"/>
      </v-row>
      <v-row class="ma-0 border-bot">
      </v-row>
    </v-col>
    <v-col class="pa-0 metrics-container" :class="{'custom-show' : userSelectedPeriod.id === 'custom'}" >
      <template v-for="(metric, index) of metrics" :key="'metric-' + index" >
        <v-row class="ma-2 mt-4 mb-4 token-text-medium">
          {{  metric.attributes.name }}
        </v-row>
        <v-row class="ma-0 pa-2 full-width" :id="'chart-' + index"></v-row>
      </template>
    </v-col>      
  </div>
</template>

<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component'
import { APIBlock, APIMetricMatrice } from '@/typesAPI'
import API from '@/api/wrapper'
import * as Highcharts from 'highcharts';
import { nextTick } from 'vue';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import Icon from '@/components/UIElements/Icon.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import { ListItem } from '@/components/UIElements/List.vue';

class Props {
  blockAPI: APIBlock = prop({
    required: true,
  });
}

@Options({
  components: {
    CustomTextInput,
    Icon,
    DropdownMenu,
    CustomButton,
  },
})
export default class NodeDetailMetrics extends Vue.with(Props) {

  metrics:APIMetricMatrice[] = []
  interval:any = null
  end = new Date()
  start = new Date()
  endPicker = ""
  startPicker = ""
  userSelectedPeriod:ListItem = {}
  charts:{[key:string]:Highcharts.Chart} = {};
  step = 0
  itemsDropdown:ListItem[] = [
    {
      text: 'Last 5 minutes',
      id: '5m'
    },
    {
      text: 'Last 15 minutes',
      id: '15m'
    },
    {
      text: 'Last hour',
      id: '1h'
    },
    {
      text: 'Last 24 hours',
      id: '1d'
    },
    {
      text: 'Last 7 days',
      id: '7d'
    },
    {
      text: 'Last 30 days',
      id: '30d'
    },
    {
      text: 'Last year',
      id: '1y'
    },
    {
      text: 'Custom dates',
      id: 'custom'
    },
  ]
  dropdownOpened = false

  mounted(): void {
    this.userSelectedPeriod = this.itemsDropdown[0]
    Highcharts.setOptions({
      colors: [
          '#FF8600', "#5386E4", "#00BFB2", "#F44174", "#9792E3", "#F7B801", "#00E5E8", "#0C8346", "#D991BA", "#907F9F"
      ]
    });

    this.start = new Date(new Date().setTime(new Date().getTime() - (5 * 60 * 1000))) // Last 5 min
    this.end = new Date()
    this.majPickers()



    if(this.blockAPI) {
      API.monitoring.metrics(this.blockAPI.id, this.start, this.end)
      .then(({metrics, step}) => {
        this.metrics = metrics
        this.step = step * 1000

        this.formatMetrics()

        nextTick(() => {
          this.metrics.forEach((metric:APIMetricMatrice, index:number) => {
            this.mountChart(metric, index)
          })
        })
       
        this.interval = setInterval(this.poll,5000)
      })
    }
  }

  majPickers() {
    this.endPicker = this.end.toISOString().split('.')[0]
    this.endPicker = this.endPicker.slice(0,this.endPicker.length - 3)
    this.startPicker = this.start.toISOString().split('.')[0]
    this.startPicker = this.startPicker.slice(0,this.startPicker.length - 3)
  }

  formatMetrics() {
    this.metrics.forEach((metric:APIMetricMatrice) => {
      metric.attributes.series.forEach((serie) => {
        serie.data = serie.data.map((value:any) => {
          return [value[0] * 1000, parseFloat(value[1])]
        })
        let lastTimestamp = this.start.getTime()
        serie.data.forEach((data, index) => {
          if(data[1] !== null) {
            if((data[0] as number) - lastTimestamp > this.step) {
              serie.data.splice(index,0,[lastTimestamp + this.step, null])
            }
            lastTimestamp = data[0] as number
          }
        })
        if(this.end.getTime() - lastTimestamp > this.step) {
          serie.data.push([this.end.getTime(), null])
        }
      })

    })
  }

  beforeUnmount(): void {
    clearInterval(this.interval)
  }

  unmounted(): void {
    clearInterval(this.interval)
  }

  onDatePickerChange() {
    this.start = new Date(this.startPicker)
    this.end = new Date(this.endPicker)
    
    this.getMetricsWithNewDates()
  }

  getMetricsWithNewDates() {
    if(this.blockAPI) {
      API.monitoring.metrics(this.blockAPI.id, this.start, this.end)
      .then(({metrics, step}) => {
        this.metrics = metrics
        this.step = step * 1000
        this.formatMetrics()

          this.metrics.forEach((metric:APIMetricMatrice) => {
            if(this.charts && this.charts[metric.id]) {
              this.charts[metric.id]?.update({series : metric.attributes.series as Highcharts.SeriesOptionsType[]}, true, true, true)
            }
          })   
      })
    }
  }

  mountChart(metric:APIMetricMatrice, index:number) {
    this.charts[metric.id] = Highcharts.chart({
      chart: {
          type: 'area',
          renderTo: 'chart-' + index, 
      },
      title: {
          text: undefined,
      },
      yAxis: {
        type: "linear",
        title: {
            useHTML: true,
            text: metric.attributes.ordinate
        }
      },
      xAxis: {
        type: "datetime",
        showFirstLabel: true,
        showLastLabel: true,
      },
      tooltip: {
          shared: true,
          headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span>' +
              '<br>'
      },
      plotOptions: {
          area: {
              stacking: 'normal',
              lineColor: '#666666',
              lineWidth: 1,
              marker: {
                  lineWidth: 1,
                  lineColor: '#666666'
              }
          }
      },
      series: metric.attributes.series as Highcharts.SeriesOptionsType[]
    });
  }

  onUserSelecPeriod(newPeriod:ListItem) {
    this.dropdownOpened = false
    this.userSelectedPeriod = newPeriod

    this.setDatesFromSelectedPeriod(newPeriod)
    
    this.majPickers()
    if(newPeriod.id !== 'custom') {
      this.getMetricsWithNewDates()
    }
  }

  setDatesFromSelectedPeriod(period:ListItem) {
    switch(period.id) {
      case "5m": // Last 5 min
        this.start = new Date(new Date().setTime(new Date().getTime() - (5 * 60 * 1000))) 
        this.end = new Date()
        break;

      case "15m": // Last 15 min
        this.start = new Date(new Date().setTime(new Date().getTime() - (15 * 60 * 1000))) 
        this.end = new Date()
        break;

      case "1h": // Last 60min
        this.start = new Date(new Date().setTime(new Date().getTime() - (60 * 60 * 1000))) 
        this.end = new Date()
        break;

      case "1d": // Last 24h
        this.start = new Date(new Date().setTime(new Date().getTime() - (24 * 60 * 60 * 1000))) 
        this.end = new Date()
        break;

      case "7d": // Last 7 days
        this.start = new Date(new Date().setTime(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))) 
        this.end = new Date()
        break;

      case "30d": // Last 30 days
        this.start = new Date(new Date().setTime(new Date().getTime() - (30 * 24 * 60 * 60 * 1000))) 
        this.end = new Date()
        break;

      case "1y": // Last 365 days
        this.start = new Date(new Date().setTime(new Date().getTime() - (365 * 24 * 60 * 60 * 1000))) 
        this.end = new Date()
        break;

      default:
        break;
    }
    clearInterval(this.interval)
    this.interval = setInterval(this.poll,5000)
  }
  
  poll(): void {
    if(this.userSelectedPeriod.id !== 'custom') {
      this.setDatesFromSelectedPeriod(this.userSelectedPeriod)
      this.getMetricsWithNewDates()
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.node-detail-metrics {
  .border-bot {
    border-bottom: 1px solid $color-neutral-grey-12 !important;
  }
  .metrics-container {
    height: calc(90vh - 183px);
    overflow: auto;

    &.custom-show {
      height: calc(90vh - 245px);
    }
  }
}

</style>