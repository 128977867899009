

import router from "@/router"
import { APIDeployment, APIProject } from "@/typesAPI"
import store from ".."
import API from "@/api/wrapper"
import enums from "@/enums"
import helpers from "@/utils/helpers"

type State = {
  currentProjectId: string,
  projectList:APIProject[] | null
}

const state: State = {
  currentProjectId: "",
  projectList: null,
}

const getters = {
  getCurrentProjectId: (state:State) => {
    return router.currentRoute.value.params.projectId
  },
  getProjectList: (state:State) => {
    if(state.projectList === null) {
      API.projects.getList()
      .then((res:APIProject[]) => {
        state.projectList = res
      })
      return []
    }
    return state.projectList
  },
  getCurrentProject: (state:State) => {
    const projectList:APIProject[] = store.getters['projects/getProjectList']
    return projectList ? projectList.find((obj:APIProject) => obj.id === router.currentRoute.value.params.projectId) : null
  },
}

const mutations = {
  UPDATE_PROJECT: (state:State, project:APIProject) => {
    if(state.projectList) {
      helpers.ressources.addMultipleRessourceToArray(state.projectList, [project])
    } else {
      state.projectList = [project]
    }
  },
  DELETE_PROJECT: (state:State, project:APIProject) => {
    if(state.projectList) {
      state.projectList = state.projectList.filter((p) => p.id !== project.id)
    }
  },
}

const actions = {
  clearStore: (context:any) => {
    context.dispatch('errorsValidation/setList', [], {root:true})
    context.dispatch('blocksAPI/setBlockList', [], {root:true})
    context.dispatch('elements/setNodesFromAPIBlocks',  [], {root:true})
    context.dispatch('elements/setLinksFromAPIConnections',  [], {root:true})
    context.dispatch('fields/setFieldsValue',  [], {root:true})
    context.dispatch('fields/setFieldTabs',  [], {root:true})
    context.dispatch('fields/setFieldBoxes',  [], {root:true})
    context.dispatch('fields/cleanTabLoaded',  [], {root:true})
    context.dispatch('errorsValidation/closeModal',  [], {root:true})
    context.dispatch('errorsValidation/closeWarningPopup',  [], {root:true})
    context.dispatch('errorsValidation/setList',  [], {root:true})
    context.dispatch('deployment/clear',  null, {root:true})

    context.dispatch('deployment/setModalIsDraftView', false, {root:true})
    context.dispatch('deployment/setModalIsOpen', false, {root:true})
    context.dispatch('deployment/setForceDeployCompletedState', false, {root:true})
    context.dispatch('deployment/setModalIsSelectClusterView', false, {root:true})
    context.dispatch('modals/setClusterSelected', null, {root:true})


    // context.dispatch('modals/hideAll',  null, {root:true})
  },
  updateProject: (context:any, project:APIProject) => {
    context.commit('UPDATE_PROJECT', project)
  },
  deleteProject: (context:any, project:APIProject) => {
    context.commit('DELETE_PROJECT', project)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}