<template>
  <div class="subscription-expired-modal">
    <Popin>
      <template #header>
        <v-img src="@/assets/illustrations/trial_over.png" height="56px" width="56px"/>
      </template>
      <template #content>
        <v-col class="pa-0 token-text-h5">
          <v-row class="ma-0">
            <div class="ma-auto">
              No subscription active
            </div>
          </v-row>
          <v-row class="ma-0 mb-1">
            <div class="ma-auto">
              Manage subscription to continue deploying
            </div>
          </v-row>
        </v-col>
      </template>
      <template #footer>
        <v-col class="pa-0">
          <v-row class="ma-0 mb-2">
            <CustomButton class="mx-auto" :level="$enums.ButtonLevels.Primary" @click="checkout" :loading="loadingCheckout">
              Manage subscription
            </CustomButton>
          </v-row>
        </v-col>
      </template>
    </Popin>
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Popin from '@/components/UIElements/Popin.vue';
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
    CustomButton,
    Popin
  },
})
export default class SubscriptionExpiredModal extends Vue {
  loadingCheckout = false
  
  mounted(): void {
    this.loadingCheckout = false
  }

  checkout() {
    this.loadingCheckout = true
    this.$helpers.subscription.checkout()
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

</style>
