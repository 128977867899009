<template>
  <div class="projectsView full-view">
    <v-col class="pa-0 ">
      <v-row class="ma-0">
        <v-col class="pa-0 token-text-h4">
          Account
        </v-col>
        <v-col class="pa-0 ml-auto fit-content">
          <CustomButton icon="send-out" text="Logout" :level="$enums.ButtonLevels.Tertiary" @click="logout"/>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-8">
        <v-col class="pa-0 token-text-h5">
          Account details
        </v-col>
        <v-col class="pa-0 ml-auto fit-content">
          <CustomButton text="Save changes" :level="$enums.ButtonLevels.Secondary" :disabled="disableSaveButton" @click="saveChange" :loading="saveLoading"/>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-4">
        <v-col class="pa-0 ">
          <Divider/>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-2 token-text-regular">
        <v-col class="pa-0 mr-8">
          Email
        </v-col>
        <v-col class="pa-0">
          Full name
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-4">
        <v-col class="pa-0 mr-8">
          <CustomTextInput v-model="email" placeholder="email@placeholder.com" :disabled="true"/>
        </v-col>
        <v-col class="pa-0">
          <CustomTextInput v-model="name" placeholder="Jon Smith"/>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-8">
        <v-col class="pa-0 token-text-h5">
          Connected services
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-4">
        <v-col class="pa-0">
          <Divider/>
        </v-col>
      </v-row>
      <v-row class="ma-0" v-for="account of accountList" :key="account.id">
          <DetailFieldSocialAccount :forcedSocialAccount="account" class="full-width"/>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/UIElements/CustomButton.vue';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import Divider from '@/components/UIElements/Divider.vue';

import DetailFieldSocialAccount from '@/components/Details/Fields/DetailFieldSocialAccount.vue';
import { Vue, Options } from 'vue-class-component';
import API from '@/api/wrapper';
import { APISocialAccount, APIUser } from '@/typesAPI';
import { Watch } from 'vue-property-decorator';

@Options({
  components: {
    CustomButton,
    CustomTextInput,
    DetailFieldSocialAccount,
    Divider
  },
})
export default class AccountView extends Vue {

  email = ""
  name = ""
  saveLoading = false

  get accountList():APISocialAccount[] {
    return Object.values(this.$store.getters['user/getSocialAccountsByProvider'])
  }

  get user():APIUser {
    return this.$store.getters['user/getUser']
  }

  get disableSaveButton():boolean {
    return !this.user || (this.email === this.user.attributes.email && this.name === this.user.attributes.full_name)
  }

  saveChange() {
    this.saveLoading = true
    API.user.edit(this.user.id, {email : this.email, full_name: this.name})
    .finally(() => {
      this.saveLoading = false
    })
  }

  logout() {
    API.session.logout()
    .then(() => {
      this.$store.dispatch('user/setUser', null)
      this.$router.push({name: 'login'})
    })
  }

  @Watch('user', {immediate: true})
  onUserChange() {
    if(this.user) {
      this.email = this.user?.attributes?.email
      this.name = this.user?.attributes?.full_name
    }
  }
    

}
</script>

<style lang="scss" scoped>
</style>
