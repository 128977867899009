<template>
  <div class="spinner-container" :class="{fullScreen: fullScreen, dark: dark}">
    <Icon icon="logo" v-if="fullScreen" class="spinner-logo"/>
    <Icon icon="spinner" class="spinner-icon" />
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'

class Props {
  dark?: boolean = prop({
    required: false,
  });
  fullScreen?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon,
  },
})
export default class Spinner extends Vue.with(Props) {
}
</script>

<style lang="scss">
.spinner-container {
  display: flex;
  &.dark {
    .icon {
      background: black;
    }
  }
  &.fullScreen {
    position:fixed;
    width: 100%;
    height: 100%;
    z-index: 100000000;
    background: #F7F8FD;
    .spinner-logo {
      position: absolute;
      top: 37px;
      left: 40px;
    }
    .spinner-icon {
      .icon {
        background: black;
      }
    }
  }
}
.spinner-icon {
  margin: auto;
  .icon {
    background: black;
  }
}

</style>
