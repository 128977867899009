import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-number-input-container" }
const _hoisted_2 = ["min", "max", "placeholder", "value"]
const _hoisted_3 = {
  key: 1,
  class: "custom-number-input-select-unit"
}
const _hoisted_4 = { class: "token-text-micro" }
const _hoisted_5 = {
  key: 2,
  class: "custom-number-input-buttons"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          class: "custom-number-input-icon",
          icon: _ctx.icon
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: _normalizeClass(["custom-number-input", {'has-error': _ctx.error, 'has-error-fixed': false, 'has-icon': _ctx.icon, disabled: _ctx.disabled}]),
      type: "number",
      min: _ctx.min,
      max: _ctx.max,
      placeholder: _ctx.placeholder,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
      onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleDelete && _ctx.handleDelete(...args)), ["delete"]))
    }, null, 42, _hoisted_2),
    (_ctx.units)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_CustomButton, {
            level: _ctx.$enums.ButtonLevels.UnitSwitcher,
            ref: "unitSwitcher",
            onClick: _ctx.openBurgerUnits
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.selectedUnit?.label), 1),
              _createVNode(_component_Icon, { icon: "caret-down" })
            ]),
            _: 1
          }, 8, ["level", "onClick"]),
          _createVNode(_component_DropdownMenu, {
            items: _ctx.unitBurgerList,
            show: _ctx.showBurgerUnits,
            "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showBurgerUnits) = $event)),
            maxContent: true,
            "onUpdate:modelValue": _ctx.onUserUnitChange,
            target: _ctx.$refs.unitSwitcher,
            classInternal: "my-4"
          }, null, 8, ["items", "show", "onUpdate:modelValue", "target"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "up",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.numberUp && _ctx.numberUp(...args))),
            disabled: _ctx.disabled ? true : (_ctx.modelValue && _ctx.max ? parseInt(_ctx.modelValue) >= _ctx.max : false)
          }, [
            _createVNode(_component_Icon, { icon: "caret-up-small" })
          ], 8, _hoisted_6),
          _createElementVNode("button", {
            class: "down",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.numberDown && _ctx.numberDown(...args))),
            disabled: _ctx.disabled ? true : (_ctx.modelValue && _ctx.min ? parseInt(_ctx.modelValue) <= _ctx.min : false)
          }, [
            _createVNode(_component_Icon, { icon: "caret-down-small" })
          ], 8, _hoisted_7)
        ]))
      : _createCommentVNode("", true)
  ]))
}