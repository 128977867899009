import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "environments-bar bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_list = _resolveComponent("list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_list, {
      items: _ctx.listItems,
      title: "ENVIRONMENTS",
      deployable: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_v_col, { class: "add-new-environment pa-0" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" + New environment ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["items"])
  ]))
}