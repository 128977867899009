import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "panel-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactUsBar = _resolveComponent("ContactUsBar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ValidationErrorsBar = _resolveComponent("ValidationErrorsBar")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_col, { class: "pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-0 no-flex-wrap" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              class: "pa-0 pr-4 fit-content ml-auto",
              align: "right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ContactUsBar)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "pa-0 fit-content",
              align: "right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ValidationErrorsBar)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}