<template>
  <div class="block-item" 
    :class="{'block-item-dragged' : isDragging, 'block-item-click-down' : isClickDown}"
    ref="htmlElement"
    @click="addElementNode" 
    draggable="true" 
    @dragstart="onDragStart"
    @dragend="onDragEnd"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
  >
  <div class="block-item-icon">
    <Icon :icon="block.icon" v-if="block.icon"/>
    <Logo :logo="block.logo" v-if="block.logo" />
  </div>
  <div class="block-item-label">
    {{block.name}}
  </div>
    <BlockItemShadow :block="block" v-if="isDragging" :style="{left: shadowLeft + 'px', top:shadowTop + 'px'}"/>
  </div>
</template>

<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component';
import { Block, Platform, PositionXY } from '@/types'
import BlockItemShadow from '@/components/Blocks/BlockItemShadow.vue'
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'
import { Ref } from 'vue-property-decorator';

class Props {
  block: Block | Platform = prop({
    required: true,
  });
  type: string = prop({
    required: true,
  });
  positionXY?: PositionXY = prop({
    required: false,
  });
  linkSrc?: string = prop({
    required: false,
  });
}

@Options({
  components: {
    BlockItemShadow,
    Icon,
    Logo
  },
})
export default class BlockItem extends Vue.with(Props) {
  isDragging = false
  isClickDown = false
  shadowLeft = 0
  shadowTop = 0
  offset = {left: 0, top: 0}
  @Ref('htmlElement') readonly htmlElement!: HTMLElement

  get isAddLocked () {
    return this.$store.getters['elements/isLocked']
  }

  addElementNode () {
    if(!this.isAddLocked) {
      this.$store.dispatch('elements/addNode', {block:this.block, type:this.type, positionXY: this.positionXY, linkSrc: this.linkSrc})
      this.$emitter.emit('addNodeFromClick')
      this.$emit('addNode')
    }
  }

  onMouseDown() {
    this.isClickDown = true
  }
  onMouseUp() {
    this.isClickDown = false
  }

  onDragStart(event:DragEvent) {
    document.addEventListener('dragover', this.updateShadow)

    this.isDragging = true
    this.isClickDown = false
    if(this.type === 'block') {
      this.$store.dispatch('blocks/setDraggingBlock', this.block)
    } else {
      this.$store.dispatch('platforms/setDraggingPlatform', this.block)
    }
    const img = new Image();
    if(event.dataTransfer) {
      event.dataTransfer.setDragImage(img, 0, 0);
    }
    const rect = this.htmlElement.getBoundingClientRect();
    this.offset = {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }
  onDragEnd() {
    document.removeEventListener('dragover', this.updateShadow)

    this.isDragging = false
    this.$store.dispatch('blocks/setDraggingBlock')
  }

  updateShadow(event:DragEvent) {
    const rect = this.htmlElement.getBoundingClientRect();

    const offset = {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    }
    this.shadowLeft = event.x - this.offset.left - 68
    this.shadowTop = event.y - this.offset.top - 20
  }
    
}
</script>

<style lang="scss">
@import '@/css/variables';
.block-item {
  position: relative;
  width: 216px;
  display: flex;
  margin-left:8px;
  box-shadow: $shadow-z1;
  background:white ;
  border: 1px solid $color-neutral-grey-12;
  height: 40px;
  border-radius: 8px;
  text-transform: none;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  cursor: grab;

  &:hover {
    border: 1px solid $color-neutral-grey-30;
    box-shadow: $shadow-z2;
    .block-item-icon {
      margin-left:8px;
    }
  }

  &.block-item-click-down {
    border: 2px solid $color-brand;
    box-shadow: $shadow-dragged;
    .block-item-icon {
      margin-left:7px;
    }
  }

  &.block-item-dragged {
    background: $color-neutral-grey-4 !important;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.16), inset 0px 1px 1px rgba(0, 0, 0, 0.06), inset 0px -1px 0px rgba(255, 255, 255, 0.6) !important;
    border: none !important;
    >.block-item-label {
      visibility: hidden;
    }
    >.block-item-icon {
      visibility: hidden;
    }
  }
  .block-item-label {
    margin-left:8px;
    margin-top:auto;
    margin-bottom: auto;
  }
  .block-item-icon {
    margin-left:8px;
    margin-top:auto;
    margin-bottom: auto;
  }
}
</style>
