<template>
  <div class="logo-bar bar d-flex" :class="{collapsed:collapsed, hover:hover}" @mouseover="hover = true" @mouseleave="hover = false">
    <template v-if="hover">
      <CustomButton :level="$enums.ButtonLevels.Tertiary" class="back-button my-auto" @click="onClick">
        <Icon icon="caret-left"/>
      </CustomButton>
    </template>
    <template v-else>
      <v-col class="ma-auto pa-0 py-2 ">
        <v-row class="pa-0 ma-0 d-flex">
          <v-col class="logo-container pa-0 ma-auto" >
            <Logo logo="fransys" />
          </v-col>
        </v-row>
      </v-col>
    </template>

  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import Logo from '@/components/UIElements/Logo.vue'
import Badge from '@/components/UIElements/Badge.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Icon from '@/components/UIElements/Icon.vue'

@Options({
  components: {
    Logo,
    Badge,
    CustomButton,
    Icon
  },
})
export default class LogoBar extends Vue {
  hover = false
  // FIRST UNBOARDING
  get collapsed () {
    return !this.$store.getters['app/getFirstUnboardingDone']
  }
  // END FIRST UNBOARDING

  onClick() {
    this.$router.push({name:'projects'})
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.logo-bar {
  width: fit-content;
  padding-left: 18px;
  padding-right: 18px;
  height: 56px;
  font-size: 16px;
  font-weight: bolder;
  transition: width 0.2s ease-in-out 1.8s;
  &.hover {
    padding-left: 3px;
    padding-right: 3px;
  }
  .logo-container {
    max-width: 54px;
    .icon {
      background: #00003A;
      width: 18px;
      height: 30px;
    }
  }
  .back-button {
  }
  .title-container {
    opacity: 1;
    transition: opacity 0.2s ease-in-out 2s;
  }
  &.collapsed {
    width: 72px;
    transition: width 0.2s ease-in-out 2s;
    .title-container {
      transition: opacity 0.2s ease-in-out 1.8s;
      opacity: 0;
    }
  }
}
</style>