import enums from '@/enums'
import { APICluster } from '@/typesAPI'

type State = {
  isVisible: {[key:string]:boolean},
  clusterSelected:APICluster | null
}

const state: State = {
  isVisible: {},
  clusterSelected: null
}


const getters = {
  getVisibilityCluster: (state:State) => {
    return !!state.isVisible[enums.ModalsName.Cluster] 
  },
  getClusterSelected: (state:State) => {
    return state.clusterSelected
  },
}

const mutations = {
  SET_VISIBILITY: (state:State, payload:{modal:any, newState:boolean}) => {
    state.isVisible[payload.modal] = payload.newState
  },
  SET_CLUSTER_SELECTED: (state:State,newCluster:APICluster | null) => {
    state.clusterSelected = newCluster
  },
  HIDE_ALL: (state:State) => {
    state.isVisible = {}
  },

}

const actions = {
  setVisibility: (context:any,payload:{modal:any, newState:boolean}) => {
    context.commit('SET_VISIBILITY', payload)
  },
  setClusterSelected: (context:any, newCluster:APICluster | null) => {
    context.commit('SET_CLUSTER_SELECTED', newCluster)
  },
  hideAll: (context:any) => {
    context.commit('HIDE_ALL')
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}