<template>
  <div class="panels-layout">
    <v-col class="pa-0">
      <v-row class="ma-0 pa-4">
        <v-col class="fit-content ma-0 pa-0">
          <PanelLeft/>
        </v-col>
        <v-col class="ma-0 pa-0">
          <PanelTop/>
        </v-col>
        <v-col class="fit-content ma-0 pa-0">
          <PanelRight/>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import PanelLeft from '@/components/Panels/PanelLeft.vue'; 
import PanelTop from '@/components/Panels/PanelTop.vue'; 
import PanelRight from '@/components/Panels/PanelRight.vue'; 

@Options({
  components: {
    PanelLeft,
    PanelTop,
    PanelRight
  },
})
export default class PanelsLayout extends Vue {}
</script>

<style lang="scss" scoped>

.panels-layout {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    z-index: 1;
    pointer-events: none;
}
.panel-side {
  display: flex;
  flex-direction: column;
}
</style>