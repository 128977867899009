<template>
  <div class="projectSneakPeek" >
    <ModalRenameRessource :target="project" v-model:visible="showModalRenameProject"/>
    <ModalProjectPutOffline :project="project" v-model:visible="showModalProjectPutOffline"/>
    <ModalProjectDelete :project="project" v-model:visible="showModalProjectDelete"/>
    
    <v-col class="pa-0">
      <v-row class="content ma-0" @click="onClick" :class="{loading : loading}">
        <template v-if="loading">
          <Spinner class="ma-auto"/>
        </template>
        <template v-else>
          <VueFlow 
            class="viewFlowSneakPeek"
            v-model="elements"
            :min-zoom="0.2"
            :select-nodes-on-drag="false"
            :delete-key-code="null"
            :fit-view-on-init="true"
            :prevent-scrolling="true"
            :zoom-on-scroll="false"
            :zoom-on-pinch="false"
            :zoom-on-double-click="false"
            :pan-on-drag="false"
            @dragover.prevent
            @dragenter.prevent
            ref="vueFlowRef"
            :connection-line-style="{stroke: 'rgba(50, 25, 178, 1)', 'stroke-width' : '2px'}"
          >
            <template #node-block="props">
              <BlockNode :props="props" :forcedBlockAPIList="blocks" :forcedNodesList="elements" :noStatus="true"/>
            </template>
            <template #edge-link="props">
              <CustomEdge :props="props" />
            </template>
            <Background :variant="BackgroundVariant.Dots"  :gap="12" />
            <div class="grid-snap-hightlight" ref="gridSnapHightlightRef" ></div>
          </VueFlow>
        </template>
      </v-row>
      <v-row class="ma-0 mt-2">
        <div class="token-text-medium my-auto">
          {{ project.attributes.name }}
        </div>
        <Badge class="ml-2 my-auto" :label="$helpers.states.translateDeploymentStatusToLabel(project?.attributes?.status)" :severity="$helpers.states.translateDeploymentStatusToSeverity(project?.attributes?.status)" :icon="project?.attributes?.status === $enums.DeploymentStatus.DEPLOYING || project?.attributes?.status === $enums.DeploymentStatus.SHUTTING_DOWN ? 'spinner' : ''" iconColor="rgba(0, 0, 58, 1)"/>

        <div class="ml-auto mr-0">
          <CustomButton ref="menuButton" class="px-3 my-auto button-burger" :level="$enums.ButtonLevels.Tertiary" @click="burgerOpened[project.id] = !burgerOpened[project.id]" icon="burger" />
          <DropdownMenu 
            :items="burgerItems"
            v-model:show="burgerOpened[project.id]"
            :maxContent="true"
            @update:modelValue="onBurgerMenuItemClick"
            :target="$refs.menuButton"
          />
        </div>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import API from '@/api/wrapper'
import { APIBlock, APIConnection, APIProject } from '@/typesAPI'
import { Vue, Options, prop } from 'vue-class-component'
import { VueFlow, useVueFlow } from '@vue-flow/core'
import { Background, BackgroundVariant } from '@vue-flow/additional-components'
import BlockNode from '@/components/Nodes/BlockNode.vue'
import CustomEdge from '@/components/Edges/CustomEdge.vue'
import { Element, ElementNode } from '@/types'
import Badge from '@/components/UIElements/Badge.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import { ListItem } from '@/components/UIElements/List.vue'
import CustomButton  from '@/components/UIElements/CustomButton.vue'
import DropdownMenu  from '@/components/UIElements/DropdownMenu.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import ModalRenameRessource from '@/components/Modals/ModalRenameRessource.vue'
import ModalProjectPutOffline from '@/components/Modals/ModalProjectPutOffline.vue'
import ModalProjectDelete from '@/components/Modals/ModalProjectDelete.vue'
import { Watch } from 'vue-property-decorator'

class Props {
  project: APIProject = prop({
    required: true,
  })
}

@Options({
  components: {
    VueFlow,
    Background,
    CustomEdge,
    BlockNode,
    Badge,
    Spinner,
    CustomButton,
    DropdownMenu,
    ModalRenameRessource,
    ModalProjectPutOffline,
    ModalProjectDelete,
    CustomTextInput
  },
})
export default class LogoBar extends Vue.with(Props) {
  vueFlowState = useVueFlow()
  BackgroundVariant:typeof BackgroundVariant = BackgroundVariant

  blocks:APIBlock[] = []
  connections:APIConnection[] = []
  elements:Element[] = []
  burgerOpened:{[key:string]:boolean} = {}
  burgerItems: ListItem[] = [
    {
      id: 'rename',
      text: 'Rename',
      icon: 'edit'
    },
    {
      id: 'offline',
      text: 'Undeploy',
      icon: 'cloud-off'
    },
    
    {
      id: 'separator',
      separator: true,
    },
    {
      id: 'delete',
      text: 'Delete',
      icon: 'trash',
      class: 'token-text-color-orange'
    },
  ]  
  loading = false
  showModalRenameProject = false
  showModalProjectPutOffline = false
  showModalProjectDelete = false

  mounted(): void {
    if(this.project.id) {
      this.loadDatas()
    }
  }

  @Watch('project', {immediate: true, deep: true})
  onProjectChange() {
    if( this.project ) {
      this.burgerItems.forEach((item) => {
        if(item.id === 'offline') {
          item.disabled = this.project?.attributes?.status === this.$enums.DeploymentStatus.OFFLINE || this.project?.attributes?.status === this.$enums.DeploymentStatus.SHUTTING_DOWN
        } else if(item.id === 'delete') {
          item.disabled = this.project?.attributes?.status !== this.$enums.DeploymentStatus.OFFLINE
        }
      })
    }
  }

  onBurgerMenuItemClick(item:ListItem) {

    switch(item.id) {
      case 'rename':
        this.showModalRenameProject = true
      break;
      case 'offline':
        this.showModalProjectPutOffline = true
      break;
      case 'delete':
        this.showModalProjectDelete = true
      break;
    }

    item.selected = false
    this.burgerOpened[this.project.id] = false
  }

  loadDatas () {
    this.loading = true
    API.blocks.getIndex(this.project.id, "current_connections")
    .then((result) => {
      this.blocks = result.blocks
      this.connections = result.connections

      this.blocks.forEach((blockAPI:APIBlock) => {
        const block = this.$store.getters['blocks/getByID'](blockAPI.relationships?.category?.data?.id)
        const newElementNode:Element = {
          id: blockAPI.id,
          label: blockAPI.attributes.name,
          position:{ x: blockAPI.attributes.longitude, y: blockAPI.attributes.latitude },
          type: 'block',
          extra: {
            block: block,
            blockAPIID: blockAPI.id
          }
        }
        this.elements.push(newElementNode)
      })

      const storeNodes:ElementNode[] = this.elements.filter((elem:any) => {
        if(elem.type === 'block') return elem
      }) as ElementNode[]
      this.connections.forEach((connectionAPI:APIConnection) => {
        const leftNode:ElementNode | undefined = storeNodes.find((node:ElementNode) => {
          return node.extra.blockAPIID === connectionAPI.relationships.left.data.id
        })
        const rightNode:ElementNode | undefined = storeNodes.find((node:ElementNode) => {
          return node.extra.blockAPIID === connectionAPI.relationships.right.data.id
        })
        if(leftNode && rightNode) {
          this.elements.push({
            id: connectionAPI.id,
            source: leftNode.id,
            target: rightNode.id,
            sourceHandle: leftNode.id + '__handle-'+ this.getClosestHandleDirection(leftNode.id, rightNode.id),
            targetHandle: rightNode.id + '__handle-'+ this.getClosestHandleDirection(rightNode.id, leftNode.id),
            type: 'link'
          })
        }
      })
      

      // this.$store.dispatch('blocksAPI/setBlockList', result.blocks)
      // this.$store.dispatch('elements/setNodesFromAPIBlocks', result.blocks)
      // this.$store.dispatch('elements/setLinksFromAPIConnections', result.connections)
    })
    .finally(() => {
      this.loading = false
    })
  }


  getClosestHandleDirection(nodeSrcId: string, nodeTargetId: string): string {
    const nodes = this.elements
    const nodeSrc = nodes.find((node) => node.id === nodeSrcId) as ElementNode
    const nodeTarget = nodes.find((node) => node.id === nodeTargetId) as ElementNode
    let closestDirection = ''
    const nodeWidth = 116
    const nodeHeight = 40
    if(nodeSrc && nodeTarget) {
      const srcTop = {x : nodeSrc.position.x + nodeWidth/2, y: nodeSrc.position.y}
      const srcBottom = {x : nodeSrc.position.x + nodeWidth/2, y: nodeSrc.position.y + nodeHeight}
      const srcRight = {x : nodeSrc.position.x  + nodeWidth, y: nodeSrc.position.y + nodeHeight/2}
      const srcLeft = {x : nodeSrc.position.x, y: nodeSrc.position.y + nodeHeight/2}
    
      const distTop = Math.hypot(nodeTarget.position.x - srcTop.x, nodeTarget.position.y - srcTop.y)
      const distRight = Math.hypot(nodeTarget.position.x - srcRight.x, nodeTarget.position.y - srcRight.y)
      const distBottom = Math.hypot(nodeTarget.position.x - srcBottom.x, nodeTarget.position.y - srcBottom.y)
      const distLeft = Math.hypot(nodeTarget.position.x - srcLeft.x, nodeTarget.position.y - srcLeft.y)
    
      const minDist = Math.min(distTop, distRight, distBottom, distLeft)
    
      if(minDist === distTop) {
        closestDirection = 'top'
      } else if(minDist === distRight) {
        closestDirection = 'right'
      } else if(minDist === distBottom) {
        closestDirection = 'bottom'
      } else if(minDist === distLeft) {
        closestDirection = 'left'
      } 
    }
  
    return closestDirection
  }

  onClick() {
    this.$router.push(
      {
        name:'flow',
        params: {
          ...this.$route.params,
          projectId: this.project.id
        }
      }
    )  
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.projectSneakPeek {
  border-radius: 8px;
  padding: 8px;
  .button-burger {
    // visibility: hidden;
  }
  &:hover {
    border: 1px solid $color-neutral-grey-30;
    box-shadow: $shadow-z2;
    padding: 7px;
    .button-burger {
      visibility: visible;
    }
  }
}

.content {
  cursor: pointer;
  height: 200px;
  width: 100%;
  min-width: 150px;
  border-radius: 8px;
  overflow: hidden;
  &.loading {
    background: $color-neutral-grey-4;
  }
}
.viewFlowSneakPeek {
  min-height: 100%;
  min-width: 100%;
  user-select: none;
  :deep(*) {
    pointer-events: none !important;
  }
}
</style>