<template>
    <v-row 
      class="list-element" 
      :class="{'list-element-selected' : selected, 'no-hover': noHover || selected || separator || disabled, 'dense' : dense, skeleton:skeleton, 'separator': separator, 'disabled': disabled, 'disabled-but-colored': disabledButColored}"
      @click="onClick"
    >
      <template v-if="skeleton">
        <v-col class="list-element-icon-col pa-0">
          <v-row class="ma-0 list-element-icon skeleton-bg">
          </v-row>
          <v-row  class="ma-0 mt-1 token-text-micro">
          </v-row>
        </v-col> 
        <v-col class="pa-0" >
          <v-row class="ma-0 text skeleton-bg">
              &nbsp;
          </v-row>
          <v-row  class="ma-0 mt-1 subtext ">
            <v-col class="pa-0 subtext1 skeleton-bg mr-4" >
              &nbsp;
            </v-col>
            <v-col class="pa-0 subtext2 skeleton-bg" >
              &nbsp;
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-else-if="separator">
 
      </template>
      <template v-else>
        <v-col v-if="warningLevel" class="list-element-warning-col pa-0">
          <Badge :severity="warningLevel"/>
        </v-col> 
        <v-col v-if="withIconOrLogo" class="list-element-icon-col pa-0" :class="(flag ? 'flag' : '')">
          <v-row class="ma-0 list-element-icon token-text-regular" >
            <Logo v-if="logo" :logo="logo" :color="selected ? 'white' : ''" class="my-auto"/>
            <Icon v-else-if="icon" :icon="icon" class="my-auto" :color="selected ? 'white' : ''" />
            <Flag v-else-if="flag" :code="flag" class="my-auto" />
          </v-row>
          <v-row v-if="subtext" class="ma-0 mt-1 token-text-micro">
          </v-row>
        </v-col> 
        <v-col class="pa-0" >
          <v-row class="ma-0 token-text-regular text" :class="{isURL : isURL}" @click="onTextClick">
            <template v-if="text">
              <div class="text" :class="alignRight ? 'ml-auto' : ''">{{ text }}</div>
            </template>
            <template v-else>
              <slot></slot>
              <slot name="content"></slot>
            </template>
            <Badge v-if="badgeLabel && !$slots['badge']" class="ml-auto mr-0" :severity="badgeState ? badgeState : $enums.Severity.NEUTRAL" :label="badgeLabel" :outlined="badgeOutlined" :icon="badgeIcon"/>
            <span class="ml-auto mr-0" v-if="$slots['badge']">
              <slot name="badge"></slot>
            </span>
            <div v-if="iconRigth" :class="'my-auto ' + (badgeLabel || isURL ? 'ml-3' : 'ml-auto') + (isURL ? ' url-share' : '')">
              <Icon :icon="iconRigth"/>
            </div>
          </v-row>
          <v-row v-if="subtext" class="ma-0 mt-1 subtext token-text-micro">
            <div class="d-flex">
              <Icon class="list-element-icon-col ml-0" :icon="iconSubtext" v-if="iconSubtext"/>
              <span v-html="subtext"></span>
            </div>
          </v-row>
          <v-row v-else-if="$slots['subtext']" class="ma-0 mt-1 subtext token-text-micro">
            <div class="d-flex">
              <Icon class="list-element-icon-col ml-0" :icon="iconSubtext" v-if="iconSubtext"/>
              <slot name="subtext"></slot>
            </div>
          </v-row>
        </v-col>
      </template>
    </v-row>
</template>


<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'
import Badge from '@/components/UIElements/Badge.vue'
import Flag from '@/components/UIElements/Flag.vue'

export class Props {
  logo?: string = prop({
    required: false,
  });
  icon?: string = prop({
    required: false,
  });
  flag?: string = prop({
    required: false,
  });
  badgeLabel?: string = prop({
    required: false,
  });
  badgeState?: string = prop({
    required: false,
  });
  badgeIcon?: string = prop({
    required: false,
  });
  badgeOutlined?: boolean = prop({
    required: false,
  });
  text?: string = prop({
    required: false,
  });
  alignRight?: boolean = prop({
    required: false,
  });
  subtext?: string = prop({
    required: false,
  });
  selected?: boolean = prop({
    required: false,
  });
  iconRigth?: string = prop({
    required: false,
  });
  warningLevel?: string = prop({
    required: false,
  });
  noHover?: boolean = prop({
    required: false,
  });
  dense?: boolean = prop({
    required: false,
  });
  iconSubtext?: string = prop({
    required: false,
  });
  skeleton?: boolean = prop({
    required: false,
  });
  isURL?: boolean = prop({
    required: false,
  });
  separator?: boolean = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  disabledButColored?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon,
    Logo,
    Badge,
    Flag
  },
})
export default class ListElement extends Vue.with(Props) {
  onClick() {
    if(!this.separator && !this.disabled && !this.disabledButColored) {
      this.$emit('update:selected', true)
    }
  }

  onTextClick(event:any) {
    if(this.isURL) {
      event.stopPropagation()
      window.open('https://'+ this.text, "_blank");
    }
  }

  get withIconOrLogo () {
    return !!(this.logo || this.icon || this.flag)
  }
}



</script>

<style lang="scss" scoped>
@import '@/css/variables';
.list-element {
  color: $color-neutral-black;
  transition: margin 0.0s ease-in-out 0.2s, padding 0.0s ease-in-out 0.2s, height 0.0s ease-in-out 0.2s, color 0.2s ease-in-out 0s, background 0.2s ease-in-out 0s;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-left:8px;
  margin-right:8px;
  padding: 8px;
  margin-bottom:8px;
  margin-top:0px;
  
  &.separator {
    cursor: default;
    border-top: 1px solid $color-neutral-grey-12 !important;
    padding: 0 !important;
  }
  &.dense {
    height: fit-content;
    padding: 0;
    margin: 0;
  }

  &:hover:not(.no-hover) {
    background: $color-neutral-grey-4;
  }
  &.list-element-selected {
    background-color: $color-brand;
  }
  .list-element-warning-col {
    max-width: 5px;
    margin-right: 16px !important;
  }
  .list-element-icon-col {
    max-width: 16px;
    margin-right: 8px !important;
    &.flag {
      max-width: 20px;
    }
  }
  .list-element-icon {
    display: flex;
    height: 24px;
  }

  
}
</style>

<style lang="scss" scoped>
@import '@/css/variables';
.list-element {
  .list-element-warning-col {
    .badge-container {
      height: 100%;
    }
  }
  &.disabled {
    cursor: default;
    color: $color-neutral-grey-30 !important;
    :deep(.icon)  {
      background-color: $color-neutral-grey-30 !important;
    }
  }

  &.disabled-but-colored {
    cursor: default;
  }

  .subtext {
    transition: color 0.2s ease-in-out !important;
    color: $color-neutral-grey-60;
    :deep(.icon)  {
      background-color: $color-neutral-grey-60 !important;
    }
  }
  .text {
    transition: color 0.2s ease-in-out !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .icon  {
      background: black ;
    }

    &.isURL {
      color: $color-brand;
    }

    .url-share {
      pointer-events: all;
      cursor: pointer !important;
    }
  }

  &.list-element-selected {
    .subtext {
      color: white;
      :deep(.icon)  {
        background-color: white !important;
      }
    }
    .text {
      color: white;
      :deep(.icon)  {
        background-color: $color-brand !important;
      }
    }
  }
  
  &.skeleton {
    .list-element-icon-col {
      padding-right: 8px;
      max-width: 24px;
      width: 24px;
    }
    &:hover {
      background: none !important;
      cursor: default;
    }
  }
  .skeleton-bg {
    background: #eee;
    background-image: linear-gradient(90deg, $color-neutral-grey-4 0px, #f5f5f5 40px, $color-neutral-grey-4 80px); 
    border-radius: 4px;
    background-size: 200% 100%;
    animation: 1.6s shine0 linear infinite;
    border-radius: 4px;
    &.subtext1 {
      animation: 1.6s shine1 linear 0.25s infinite;
    }
    &.subtext2 {
      animation: 1.6s shine2 linear infinite;
    }
    &.list-element-icon {
      animation: 1.6s shine3 linear infinite;
    }
  }
  @keyframes shine0 {
    0% {
      background-position-x: 50%;
    }
    100% {
      background-position-x: -100%;
    }
  }
  @keyframes shine1 {
    0% {
      background-position-x: 50%;
    }
    50% {
      background-position: -100%;
    }
    100% {
      background-position: -100%;
    }
  }
  @keyframes shine2 {
    0% {
      background-position-x: 50%;
    }
    50% {
      background-position: 50%;
    }
    100% {
      background-position: -100%;
    }
  }
  @keyframes shine3 {
    0% {
      background-position-x: 60%;
    }
    10% {
      background-position: -100%;
    }
    100% {
      background-position: -200%;
    }
  }
 
}
</style>