<template>
  <div class="checkbox" >
    <v-checkbox 
      color="rgba(50, 25, 178, 1)"
      density="compact"
      hide-details
      v-model="innerValue"
      :disabled="disabled"
      @change="handleChange"
    ></v-checkbox>
  </div>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

class Props {
  modelValue: boolean = prop({
    required: true,
  });
  disabled?: boolean = prop({
    required: false,
  });
}

export default class Checkbox extends Vue.with(Props) {
  innerValue = false

  mounted() {
    this.innerValue = this.modelValue
  }

  handleChange () {
    this.$emit('update:modelValue', !this.modelValue)
  }

  @Watch('modelValue')
  onModelValueChange(newVal:boolean, oldVal:boolean) {
    if(newVal !== this.innerValue) {
      this.innerValue = this.modelValue
    }
  }
}
</script>

<style lang="scss">
@import '@/css/variables';
.checkbox {

}
</style>
