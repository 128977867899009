<template>
  <KCDetector @KCActivation="onKCActivation" :activated="KCActive"/>
  <KCGame v-if="KCActive" @quit="onQuit"/>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import KCDetector from './KCDetector.vue'
  import KCGame from './KCGame.vue'
  
  let KCActive = ref<boolean>(false)
  function onKCActivation () {
    KCActive.value = true
  }

  function onQuit () {
    KCActive.value = false
  }

  
</script>

<style lang="scss" scoped>
</style>