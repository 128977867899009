import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/illustrations/too_many_request.png'
import _imports_1 from '@/assets/illustrations/mail.svg'


const _hoisted_1 = { class: "createMagicLinkBar bar" }
const _hoisted_2 = { class: "token-text-h5 mx-auto" }
const _hoisted_3 = { class: "full-width pt-6" }
const _hoisted_4 = { class: "token-text-h5 mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 0,
          class: "ma-auto"
        }))
      : (_ctx.tooManyRequest)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_v_img, {
              src: _imports_0,
              height: "64px",
              width: "96px",
              class: "mx-auto mb-6"
            }),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "token-text-h4 mb-2 mx-auto" }, " Too many log in requests ", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "token-text-regular token-text-color-secondary mx-auto" }, " You can only make 1 log in request per minute. ", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "token-text-regular token-text-color-secondary mx-auto" }, " Please wait to make another one for ", -1)),
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.email), 1),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_CustomButton, {
                level: _ctx.$enums.ButtonLevels.Primary,
                class: "full-width",
                onClick: _ctx.generateLink,
                loading: _ctx.tooManyRequestButtonLoading
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Generate Link ")
                ])),
                _: 1
              }, 8, ["level", "onClick", "loading"])
            ])
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createVNode(_component_v_img, {
              src: _imports_1,
              height: "64px",
              width: "96px",
              class: "mx-auto mb-6"
            }),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "token-text-h4 mb-2 mx-auto" }, " Check your email to log in ", -1)),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "token-text-regular token-text-color-secondary mx-auto" }, " We sent you a login link on the following address ", -1)),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.email), 1),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "token-text-regular token-text-color-secondary mx-auto" }, " Click on the link to log in ", -1)),
            (_ctx.provider)
              ? (_openBlock(), _createBlock(_component_CustomButton, {
                  key: 0,
                  level: _ctx.$enums.ButtonLevels.Primary,
                  onClick: _ctx.openInbox,
                  class: "open-inbox-btn mx-auto mt-6"
                }, {
                  default: _withCtx(() => [
                    _cache[4] || (_cache[4] = _createElementVNode("span", null, " Open inbox ", -1)),
                    _createVNode(_component_Icon, {
                      icon: "share",
                      class: "ml-2",
                      color: "white"
                    })
                  ]),
                  _: 1
                }, 8, ["level", "onClick"]))
              : _createCommentVNode("", true)
          ], 64))
  ]))
}