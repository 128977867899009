<template>
  <span class="flag ma-auto fp fp-rounded fp-sm " :class="computedClass"></span>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component';

class Props {
  code: string = prop({
    required: true,
  });
}

export default class Flag extends Vue.with(Props) {
  get computedClass() {
    return this.code.toLowerCase()
  }
}
</script>

<style lang="scss">
@import '@/css/variables';
.flag {
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.2));
}
</style>
