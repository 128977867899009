<template>
  <div class="validation-errors-bar bar pa-2 d-flex" :class="{hidden:hidden}" >
    <CustomButton :level="$enums.ButtonLevels.Tertiary" class='validation-error-bar-button' @click="openModal">
      <Icon :icon="icon" :color="iconColor" :class="list.length ? 'mr-4' : 'mx-1'"/>
      <Badge v-if="errors.length" :severity="$enums.Severity.DANGER" :label="errors.length" :class="{'mr-2' : warnings.length || infos.length}"/>
      <Badge v-if="warnings.length" :severity="$enums.Severity.WARNING" :label="warnings.length" :class="{'mr-2' : infos.length}"/>
      <Badge v-if="infos.length" :severity="$enums.Severity.INFO" :label="infos.length" />
    </CustomButton>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Badge from '@/components/UIElements/Badge.vue'
import Icon from '@/components/UIElements/Icon.vue'
import ValidationErrorsModal from '@/components/ValidationErrors/ValidationErrorsModal.vue'
import { APIErrorValidation } from '@/typesAPI';

@Options({
  components: {
    CustomButton,
    Icon,
    Badge,
    ValidationErrorsModal
  },
})
export default class ValidationErrorsBar extends Vue {

  openModal () {
    this.$store.dispatch('errorsValidation/toggleModal')
    this.$store.dispatch('deployment/setModalIsReduced', true)
  }

  get list ():APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getList']
  }

  get errors (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getErrors']
  }

  get warnings (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getWarnings']
  }

  get infos (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getInfos']
  }

  get isLoading ():boolean {
    return this.$store.getters['errorsValidation/isLoading']
  }

  get hidden ():boolean {
    return this.$store.getters['elements/getList'].length === 0 && this.list.length === 0
  }

  get icon ():string {
    if(this.isLoading) {
      return 'spinner'
    } else if (this.list.length) {
      return 'warning'
    } else {
      return 'check-circle'
    }
  }
  get iconColor ():string {
    if(this.isLoading) {
      return 'rgba(0, 0, 58, 1)'
    } else if (this.list.length) {
      return 'rgba(0, 0, 58, 1)'
    } else {
      return '#49DFA5'
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.validation-errors-bar {
  width: fit-content;
  margin-left: auto;
  transition: margin-right 0.2s ease-in-out, width 0.2s ease-in-out ;
  opacity: 1;
  margin-right: 16px !important;
  .validation-error-bar-button {
    border-radius:8px !important;
  }

  &.hidden {
    transition: margin-right 0.2s ease-in-out, opacity 0s ease-in-out 1s;
    opacity: 0;
    margin-right: -75px !important;
  }
}
</style>
<style lang="scss">

.validation-errors-bar {
  .validation-error-bar-button {
    .custom-button-text {
      padding-right: 8px !important;
      padding-left: 8px !important;
    }
  }
  height: 56px;

}
</style>