<template>
  <v-col class="segmented-control-container" :class="{disabled:disabled}" >
    <v-row class="ma-0 pa-0 control-container">
      <v-col 
        v-for="(text, key) of tabs"
        :value="key"
        :key="'tabs-' + key"
        class="d-flex ma-0 pa-0 control"
        :class="{'control-selected': modelValue === key}"
        @click="handleClick(key as string)"
      >
        <div class="ma-auto token-text-regular">
          <template v-if="!$slots.content" >
            {{ text }}
          </template>
          <slot v-else name="content" :key="key" :value="text"></slot>
        </div>
      </v-col>
    </v-row>

  </v-col>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component';

class Props {
  modelValue: string = prop({
    required: true,
  });
  disabled?: boolean = prop({
    required: false,
  });
  tabs: {[key:string]: string} = prop({
    required: true,
  });
}

export default class Switch extends Vue.with(Props) {
  currentTab = null

  handleClick (keyClicked: string) {
    this.$emit('update:modelValue', keyClicked)
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.segmented-control-container {
  padding: 0px;
  margin: 0px;
  width: 100%;

  &.disabled {
    .control-container {
      .control {
        pointer-events: none;
        user-select: none !important;
        cursor: default !important;
      }

    }

  }

  .control-container {
    padding: 2px !important;
    border-radius: 8px;
    background-color: $color-neutral-grey-8;
    .control {
      height: 40px;
      border-radius: 6px;
      cursor: pointer;
      color: $color-neutral-grey-60;
      transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
      user-select: none;
      &:not(:last-of-type) {
        margin-right: 2px !important
      }
      &:hover {
        background: rgba(255, 255, 255, 0.6);
      }
      &.control-selected {
        background-color: white;
        color: black;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      }
    }
  }
}
</style>

<style lang="scss">
.segmented-control-container {
  .control {
    .logo {
      transition: opacity 0.2s ease-in-out;
    }
    &:not(.control-selected) {
      .logo {
        opacity: 0.6;
      }
    }
  }
}
</style>

