<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class App extends Vue {
}

</script>
<style lang="scss">
@import "main";
</style>