<template>
    <span class="icon-container" :class="{'full-size' : fullSize}">
      <i class="icon" ref="dom" :class="'icon-'+icon" :style="'background: '+color+' !important;'"></i>
    </span>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component';

class Props {
  icon: string = prop({
    required: true,
  });
  color?: string = prop({
    required: false,
  });
  fullSize?: boolean = prop({
    required: false,
  });
}

export default class Icon extends Vue.with(Props) {
}
</script>

<style lang="scss">
@import '@/css/variables';

.icon-container {
  display:flex;
  margin: auto;
  &.full-size {
    width: 100%;
    height: 100%;
  }
}
.icon {
  width: 16px;
  height: 16px;
  mask-size: 16px;
  -webkit-mask-size: 16px;
  display: flex;
  margin: auto;
  transition: 0.2s background ease-in-out;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  background-color: $color-neutral-black;
}

.icon-add {
  mask-image: url(@/assets/icons/add.svg);
}
.icon-save {
  mask-image: url(@/assets/icons/save.svg);
}
.icon-publish {
  mask-image: url(@/assets/icons/publish.svg);
}
.icon-google {
  mask-image: url(@/assets/icons/google.svg);
}
.icon-previous {
  mask-image: url(@/assets/icons/previous.svg);
}
.icon-next {
  mask-image: url(@/assets/icons/next.svg);
}
.icon-search {
  mask-image: url(@/assets/icons/search.svg);
  background: $color-neutral-grey-60 !important;
}

.icon-kubernetes {
  mask-image: url(@/assets/icons/kubernetes.svg);
  background: #326CE5 !important;
}
.icon-aws {
  mask-image: url(@/assets/icons/aws.svg);
  background: #DF9544 !important;
}
.icon-code {
  mask-image: url(@/assets/icons/code.svg);
  background: #2DC7FF !important;
}
.icon-database {
  mask-image: url(@/assets/icons/database.svg);
  background: #B4ADEA !important;
}
.icon-storage {
  mask-image: url(@/assets/icons/storage.svg);
  background: #51CB20 !important;
}
.icon-worker {
  mask-image: url(@/assets/icons/worker.svg);
  background: #FF7D00 !important;
}
.icon-socket {
  mask-image: url(@/assets/icons/socket.svg);
  background: #D90368 !important;
}
.icon-caret-down-small {
  mask-image: url(@/assets/icons/caret-down-small.svg);
  background: #00003A !important;
  width: 12px;
  height: 12px;
  -webkit-mask-size: 12px;
}
.icon-caret-up-small {
  mask-image: url(@/assets/icons/caret-up-small.svg);
  background: #00003A !important;
  width: 12px;
  height: 12px;
  -webkit-mask-size: 12px;
}
.icon-caret-down {
  mask-image: url(@/assets/icons/caret-down.svg);
  background: #00003A !important;
}
.icon-caret-up {
  mask-image: url(@/assets/icons/caret-up.svg);
  background: #00003A !important;
}
.icon-caret-left {
  mask-image: url(@/assets/icons/caret-left.svg);
  background: #00003A !important;
}
.icon-caret-right {
  mask-image: url(@/assets/icons/caret-right.svg);
  background: #00003A !important;
}
.icon-x {
  mask-image: url(@/assets/icons/x.svg);
  background: #00003A !important;
}
.icon-spinner {
  mask-image: url(@/assets/icons/spinner.svg);
  background: #00003A !important;
}
.icon-trash {
  mask-image: url(@/assets/icons/trash.svg);
  background: #E15F19 !important;
}
.icon-check-circle {
  mask-image: url(@/assets/icons/check-circle.svg);
  background: $color-green !important;
}
.icon-check-square {
  mask-image: url(@/assets/icons/check-square.svg);
  background: $color-green !important;
}
.icon-cancel-circle {
  mask-image: url(@/assets/icons/cancel-circle.svg);
}
.icon-cancel-square {
  mask-image: url(@/assets/icons/cancel-square.svg);
}
.icon-question-circle {
  mask-image: url(@/assets/icons/question-circle.svg);
}
.icon-block {
  mask-image: url(@/assets/icons/block.svg);
}
.icon-share {
  mask-image: url(@/assets/icons/share.svg);
  background: $color-brand !important;
}
.icon-info {
  mask-image: url(@/assets/icons/info.svg);
  background: $color-blue !important;
}
.icon-warning {
  mask-image: url(@/assets/icons/warning.svg);
  background: $color-yellow !important;
}
.icon-arrow-right {
  mask-image: url(@/assets/icons/arrow-right.svg);
}
.icon-arrow-left {
  mask-image: url(@/assets/icons/arrow-left.svg);
}
.icon-stopsign-alert {
  mask-image: url(@/assets/icons/stopsign-alert.svg);
  background: $color-orange !important;
}
.icon-clock {
  mask-image: url(@/assets/icons/clock.svg);
  background: #00003A !important;
}
.icon-special-check {
  mask-image: url(@/assets/icons/special-check.svg);
  background: $color-green !important;
  width: 24px;
  height: 24px;
  mask-size: 24px;
  -webkit-mask-size: 24px;
}
.icon-user {
  mask-image: url(@/assets/icons/user.svg);
}
.icon-calendar {
  mask-image: url(@/assets/icons/calendar.svg);
}
.icon-hashtag {
  mask-image: url(@/assets/icons/hashtag.svg);
}
.icon-chat {
  mask-image: url(@/assets/icons/chat.svg);
}
.icon-bug {
  mask-image: url(@/assets/icons/bug.svg);
}
.icon-lightbulb {
  mask-image: url(@/assets/icons/lightbulb.svg);
}
.icon-account {
  mask-image: url(@/assets/icons/account.svg);
}
.icon-subscription-dollar {
  mask-image: url(@/assets/icons/subscription-dollar.svg);
}
.icon-send-out {
  mask-image: url(@/assets/icons/send-out.svg);
}
.icon-arrow-up {
  mask-image: url(@/assets/icons/arrow-up.svg);
}
.icon-copy {
  mask-image: url(@/assets/icons/copy.svg);
}
.icon-eye {
  mask-image: url(@/assets/icons/eye.svg);
}
.icon-eye-off {
  mask-image: url(@/assets/icons/eye-off.svg);
}
.icon-burger {
  mask-image: url(@/assets/icons/burger.svg);
}

.icon-gitlab {
  mask-image: url(@/assets/logos/gitlab.svg);
}
.icon-github {
  mask-image: url(@/assets/logos/github.svg);
}

.icon-receipt {
  mask-image: url(@/assets/icons/receipt.svg);
}
.icon-chip {
  mask-image: url(@/assets/icons/chip.svg);
}
.icon-projects {
  mask-image: url(@/assets/icons/projects.svg);
}
.icon-cluster {
  mask-image: url(@/assets/icons/cluster.svg);
}
.icon-edit {
  mask-image: url(@/assets/icons/edit.svg);
}
.icon-arrow-up-right {
  mask-image: url(@/assets/icons/arrow-up-right.svg);
}
.icon-cloud-off {
  mask-image: url(@/assets/icons/cloud-off.svg);
}
.icon-star {
  mask-image: url(@/assets/icons/star.svg);
}

</style>
