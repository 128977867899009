<template>
  <v-col class="flat-gauge pa-0">
    <v-row class="gauge-area ma-0" >
      <template v-for="(value, index) of values" :key="'gauge-segment-'+index">
        <v-col class="pa-0"  v-if="index !== 0" :style="'max-width:' + sizes[index] + '%; min-width:' + sizes[index] + '%;' + 'background-color:' + (colors ? colors[index - 1] : 'black')">
        </v-col>
      </template>
    </v-row>
  </v-col>

</template>


<script lang="ts">
import { Vue, prop } from 'vue-class-component';

class Props {
  values: number[]  = prop({
    required: true,
  });
  colors?: string[]  = prop({
    required: false,
  });
}

export default class FlatGauge extends Vue.with(Props) {

  //{0-100}[]
  get sizes():number[] {
    const min = this.values[0]
    const max = Math.max(...this.values)
    const range = max - min
    const ret:number[] = []
    let lastVal = min
    this.values.forEach((value:number, index:number) => {
      if(value > max) {
        value = max
      }
      const diff = value - lastVal
      ret.push((diff/range) * 100)
      lastVal = value
    })
    return ret
  }

}
</script>


<style lang="scss" scoped>
@import '@/css/variables';
.flat-gauge {
  .gauge-area {
    border-radius: 8px;
    overflow: hidden;
    height: 8px;
  }
}

</style>
