<template>
  <div class="panel-left">
    <v-col class="pa-0">
      <v-row class="ma-0 mb-4">
        <v-col class="pa-0 pr-4 mr-0 fit-content">
          <LogoBar/>
        </v-col>
        <v-col class="pa-0 fit-content">
          <ProjectBar/>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="pa-0  mr-0 fit-content">
          <BlockBar/>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BlockBar from '@/components/Blocks/BlockBarMenu.vue';
import EnvironmentsBar from '@/components/Environments/EnvironmentsBar.vue';
import ProjectBar from '@/components/Projects/ProjectBar.vue';
import LogoBar from '@/components/Projects/LogoBar.vue';

@Options({
  components: {
    BlockBar,
    EnvironmentsBar,
    ProjectBar,
    LogoBar
  },
})
export default class PanelLeft extends Vue {
 
}
</script>

<style lang="scss" scoped>
.panel-left {
  pointer-events: all;
  // max-width: 234px;
}
</style>