import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "my-auto token-text-medium mr-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Flag = _resolveComponent("Flag")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_component_v_col, {
    class: _normalizeClass(["pa-0 bar cluster-display pl-4 pr-2 pt-2 pb-4", {selected : _ctx.selected}]),
    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('clickDisplay', _ctx.cluster)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "ma-0 mb-2" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.cluster.attributes.name), 1),
          _createVNode(_component_Badge, {
            class: "my-auto",
            label: _ctx.$helpers.states.translateDeploymentStatusToLabel(_ctx.clustersStatus),
            severity: _ctx.$helpers.states.translateDeploymentStatusToSeverity(_ctx.clustersStatus),
            icon: _ctx.clustersStatus === _ctx.$enums.DeploymentStatus.DEPLOYING || _ctx.clustersStatus === _ctx.$enums.DeploymentStatus.SHUTTING_DOWN ? 'spinner' : '',
            iconColor: "rgba(0, 0, 58, 1)"
          }, null, 8, ["label", "severity", "icon"]),
          _createElementVNode("div", {
            class: "ml-2",
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createVNode(_component_CustomButton, {
              ref: "menuButton",
              class: "px-3",
              icon: "burger",
              level: _ctx.$enums.ButtonLevels.Tertiary,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.burgerOpened = !_ctx.burgerOpened))
            }, null, 8, ["level"])
          ]),
          _createVNode(_component_DropdownMenu, {
            items: _ctx.burgerItems,
            show: _ctx.burgerOpened,
            "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.burgerOpened) = $event)),
            maxContent: true,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onBurgerMenuItemClick($event, _ctx.cluster))),
            target: _ctx.$refs.menuButton
          }, null, 8, ["items", "show", "target"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "ma-0 token-text-regular token-text-color-secondary" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { class: "pa-0 mr-2 fit-content" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "ma-0 fit-content" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Logo, {
                    logo: _ctx.cluster?.attributes?.provider,
                    class: "mr-2"
                  }, null, 8, ["logo"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$helpers.providers.providerToLabel(_ctx.cluster?.attributes?.provider)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { class: "pa-0 fit-content mr-2" }, {
            default: _withCtx(() => [
              (_ctx.currentLocation)
                ? (_openBlock(), _createBlock(_component_v_row, {
                    key: 0,
                    class: "ma-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Flag, {
                        code: _ctx.currentLocation.attributes.country,
                        class: "mr-2"
                      }, null, 8, ["code"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.clusterDisplayName), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "ma-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Icon, {
                    class: "mr-2",
                    icon: "chip",
                    color: "rgba(0, 0, 58, 0.6)"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.cluster.attributes.requested_config.node_number_min) + " ", 1),
                  _createVNode(_component_Icon, {
                    class: "mx-1",
                    icon: "arrow-right",
                    color: "rgba(0, 0, 58, 0.6)"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.cluster.attributes.requested_config.node_number_max) + "  nodes ", 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.projects && _ctx.projects.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_v_row, { class: "ma-0 my-4 separator-border-bottom" }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
              return (_openBlock(), _createBlock(_component_v_row, {
                class: "ma-0 mt-4 token-text-regular",
                key: 'cluster-'+_ctx.cluster.id +'-project-'+project.id
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(project.attributes.name) + " ", 1),
                  _createVNode(_component_Badge, {
                    class: "ml-2 my-auto",
                    label: _ctx.$helpers.states.translateDeploymentStatusToLabel(project?.attributes?.status),
                    severity: _ctx.$helpers.states.translateDeploymentStatusToSeverity(project?.attributes?.status),
                    icon: project?.attributes?.status === _ctx.$enums.DeploymentStatus.DEPLOYING || project?.attributes?.status === _ctx.$enums.DeploymentStatus.SHUTTING_DOWN ? 'spinner' : '',
                    iconColor: "rgba(0, 0, 58, 1)"
                  }, null, 8, ["label", "severity", "icon"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}