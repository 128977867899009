<template>
  <div :class="'badge-container badge-severity-' + severity + ' ' + (outlined ? 'badge-outlined' : '')">
    <Icon v-if="icon" :icon="icon" class="badge-icon" :class="{'mr-2': !label}" :color="iconColor ? iconColor : ''"/>
    <Icon v-else-if="showIcon" :icon="computedIcon" class="badge-icon" :class="{'mr-2': !label}"/>
    <div v-if="label" class="badge-label my-auto token-text-micro">
      {{ label }}
    </div>
    <template v-else-if="$slots.default">
      <div class="badge-label my-auto token-text-micro">
        <slot></slot>
      </div>
    </template>
    <template v-else>
      <div class="badge-label thin token-text-micro">
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'

class Props {
  showIcon?: boolean = prop({
    required: false,
  });
  outlined?: boolean = prop({
    required: false,
  });
  label?: string = prop({
    required: false,
  });
  icon?: string = prop({
    required: false,
  });
  iconColor?: string = prop({
    required: false,
  });
  severity: string = prop({
    required: true,
  });
}

@Options({
  components: {
    Icon,
  },
})
export default class Badge extends Vue.with(Props) {

  get computedIcon ():string {
    switch (this.severity) {
      case this.$enums.Severity.DANGER :
        return 'cancel-circle';
      case this.$enums.Severity.INFO :
        return 'cross';
      case this.$enums.Severity.SUCCESS :
        return 'check-circle';
      case this.$enums.Severity.WARNING :
        return 'warning';
      default :
        return '';
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/variables'; 

.badge-container {
  height: 24px;
  border-radius: 4px;
  width: fit-content;
  color: $color-neutral-grey-60;
  display: flex;
  padding-left:4px;
  transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  .badge-label {
    padding: 0px 8px 0px 4px;
    &.thin {
      padding: 0;
    }
  }
  .badge-icon {
    margin-left: 4px;
  }
  &.badge-severity-danger {
    background-color: $color-orange;
    color:$color-neutral-black;
    :deep(.icon) {
      background-color: $color-neutral-black !important;
    }
  }
  &.badge-severity-info {
    background-color: $color-blue;
    color:$color-neutral-black;
    :deep(.icon) {
      background-color: $color-neutral-black !important;
    }
  }
  &.badge-severity-neutral {
    background-color: $color-neutral-grey-8;
    color:$color-neutral-black;
    :deep(.icon) {
      background-color: $color-neutral-grey-8 !important;
    }
  }
  &.badge-severity-success {
    background-color: $color-green;
    color:$color-neutral-black;
    :deep(.icon) {
      background-color: $color-neutral-black !important;
    }
  }
  &.badge-severity-warning {
    background-color: $color-yellow;
    :deep(.icon) {
      background-color: $color-neutral-black !important;
    }
  }

  &:not(.badge-outlined) {
    margin-left: 1px;
    margin-right: 1px;
  }

  &.badge-outlined {
    background-color: transparent;
    &.badge-severity-danger {
      color: $text-error;
      border: 1px solid $color-orange;
      :deep(.icon) {
        background-color: $text-error !important;
      }
    }
    &.badge-severity-info {
      color: $color-blue;
      border: 1px solid $color-blue;
      :deep(.icon) {
        background-color: $color-blue !important;
      }
    }
    &.badge-severity-neutral {
      color: $color-neutral-grey-60;
      border: 1px solid $color-neutral-grey-30;
      :deep(.icon) {
        background-color: $color-neutral-grey-60 !important;
      }
    }
    &.badge-severity-success {
      color : $text-success;
      border: 1px solid $color-green;
      :deep(.icon) {
        background-color: $text-success !important;
      }
    }
    &.badge-severity-warning {
      color: $color-yellow;
      border: 1px solid $color-yellow;
      :deep(.icon) {
        background-color: $color-yellow !important;
      }
    }
  }
}
</style>
<style lang="scss">
@import '@/css/variables'; 
.badge-container {
  .icon {
    background: $color-neutral-grey-60 !important;
    width: 12px !important;
    height: 12px !important;
    mask-size: 12px !important;  
  }
}
</style>