<template>
  <v-card class="block-bar bar pa-0" >
    <v-row class="block-title pa-0 pt-2 pl-4 ma-0 pb-2">
      <!-- BLOCKS -->
    </v-row>
    <v-row class="ma-0 pb-2 pr-2" v-for="block in listBlocks" :key="'block-'+block.id">
      <BlockCategory 
        :block="block"
        type="block"
        :positionXY="positionXY"
        :linkSrc="linkSrc"
        @addNode="$emit('addNode')"
      />
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';
import BlockCategory from '@/components/Blocks/BlockCategory.vue'
import { Block, PositionXY } from '@/types'

class Props {
  positionXY?: PositionXY = prop({
    required: false,
  });
  linkSrc?: string = prop({
    required: false,
  });
}


@Options({
  components: {
    BlockCategory,
  },
})
export default class BlockBarContext extends Vue.with(Props) {
  get listBlocks () : Block[] {
    return this.$store.getters['blocks/getList']
  }
}
</script>

<style lang="scss" scoped>
.block-bar {
  width: 147px;
  transform: translateY(-25%);
  .block-title {
    font-weight: 450;
    font-size: 12px;
    line-height: 20px;
  }
}
</style>
