import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message", 'severity-' + _ctx.severity])
  }, [
    _createVNode(_component_v_row, { class: "ma-3" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
          default: _withCtx(() => [
            (_ctx.severity === _ctx.$enums.Severity.DANGER)
              ? (_openBlock(), _createBlock(_component_Icon, {
                  key: 0,
                  icon: "cancel-square"
                }))
              : (_ctx.severity === _ctx.$enums.Severity.WARNING)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 1,
                    icon: "warning"
                  }))
                : (_ctx.severity === _ctx.$enums.Severity.SUCCESS)
                  ? (_openBlock(), _createBlock(_component_Icon, {
                      key: 2,
                      icon: "check-square"
                    }))
                  : (_ctx.severity === _ctx.$enums.Severity.INFO)
                    ? (_openBlock(), _createBlock(_component_Icon, {
                        key: 3,
                        icon: "info"
                      }))
                    : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          class: _normalizeClass(["pa-0 mx-3", {'mt-n1' : (_ctx.title || _ctx.$slots['title']) && (_ctx.text || _ctx.$slots['text'])}])
        }, {
          default: _withCtx(() => [
            (_ctx.title)
              ? (_openBlock(), _createBlock(_component_v_row, {
                  key: 0,
                  class: "ma-0 token-text-medium mb-1"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$helpers.text.capitalize(_ctx.title)), 1)
                  ]),
                  _: 1
                }))
              : (_ctx.$slots['title'])
                ? (_openBlock(), _createBlock(_component_v_row, {
                    key: 1,
                    class: "ma-0 token-text-medium mb-1"
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "title", {}, undefined, true)
                    ]),
                    _: 3
                  }))
                : _createCommentVNode("", true),
            (_ctx.text)
              ? (_openBlock(), _createBlock(_component_v_row, {
                  key: 2,
                  class: "ma-0 token-text-regular"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.text), 1)
                  ]),
                  _: 1
                }))
              : (_ctx.$slots['text'])
                ? (_openBlock(), _createBlock(_component_v_row, {
                    key: 3,
                    class: "ma-0 token-text-regular"
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "text", {}, undefined, true)
                    ]),
                    _: 3
                  }))
                : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["class"])
      ]),
      _: 3
    })
  ], 2))
}