<template>
  <div class="link-detail-container" v-if="link" >
    <Popover :visible="isOpened" :target="target" :transparent="isModalTransparent" @clickExternal="onClose">
      <template #header>
        <v-col class="pa-0" v-if="variablesOverrides.length">
          Connection variable
        </v-col>
        <v-col class="pa-0">
          <CustomButton class="ml-auto" :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="onClose"/>
        </v-col>
      </template>
      <template #content v-if="variablesOverrides.length">
        <v-col class="pa-0">
          <v-row class="link-detail-variable">
            Original variable name 
          </v-row>
          <v-row 
            v-for="variableOverride in variablesOverrides"
            class="link-detail-variable"
            :key="variableOverride.id+'-original_name'"
          >
          <CustomTextInput v-model="variableOverride.attributes.original_name" :disabled="true" :disabledBox="true" />
          </v-row>
        </v-col>
        <v-col class="pa-0 pl-4">
          <v-row class="link-detail-variable">
            Override variable name
          </v-row>
          <v-row 
            v-for="variableOverride in variablesOverrides"
            class="link-detail-variable"
            :key="variableOverride.id+'-name'"
          >
            <CustomTextInput v-model="variableOverride.attributes.name" @blur="editOverride($event, variableOverride.id)" :error='isInError(variableOverride.id)'/>
          </v-row>
        </v-col>
      </template>
      <template #footer>
        <div class="btn-container">
          <CustomButton :level="$enums.ButtonLevels.Tertiary" icon="trash" text="Delete link" class="delete-btn footer-btn" @click="deleteLink"/>
        </div>
      </template>
    </Popover>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Element } from '@/types'
import Icon from '@/components/UIElements/Icon.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import { Watch } from 'vue-property-decorator';
import {APIBlockEnvironmentVariableOverrides, APIErrorValidation } from '@/typesAPI';
import Popover from '@/components/UIElements/Popover.vue';

import API from '@/api/wrapper'
import { nextTick } from 'vue'


enum POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

@Options({
  components: {
    Icon,
    CustomButton,
    CustomTextInput,
    Spinner,
    Popover
  },
})
export default class LinkDetailV2 extends Vue {
  loading = false
  variablesOverrides:APIBlockEnvironmentVariableOverrides[] = []
  POSITION = POSITION
  top = 0;
  left = 0;
  width = 464;
  arrowTop = 0;
  arrowLeft = 0;
  arrowWidth = 10;
  isPlaced = false
  position = POSITION.BOTTOM

  onClose() {
    this.$store.dispatch('elements/setSelectedLink')
    this.$store.dispatch('elements/setDetailOpenLink')
  }

  get target() {
    return document.querySelector('[data-id="'+this.link.id + '"] .edge-button')
  }

  deleteLink() {
    this.$store.dispatch('elements/removeLink', this.link.id)
    this.$store.dispatch('elements/setSelectedLink')
    this.$store.dispatch('elements/setDetailOpenLink')
  }

  loadVariablesOverrides() {
    // this.loading = true

    // API.environmentVariables.getOverride(this.link.id)
    // .then((datas:APIBlockEnvironmentVariableOverrides[]) => {
    //   this.variablesOverrides = datas
    // })
    // .finally(() => {
    //   this.loading = false
    // })
  }

  editOverride(value:string, idOverride:string) {
    API.environmentVariables.editOverride(idOverride, value)
  }

  get link ():Element {
    return this.$store.getters['elements/getDetailOpenLink']
  }

  get isOpened () {
    return !!this.link
  }

  @Watch('link')
  onLinkChange() {
    if(this.link) {
      this.loadVariablesOverrides()
    }
  }

  isInError(id:string):boolean {
    return !!this.errorsValidation.find((error: APIErrorValidation) => error.meta.additionalContext?.overrides?.includes(id))
  }

  get errorsValidation (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getErrors'].filter((error:APIErrorValidation) => error.relationships.source.data.id === this.link.id)
  }

  get warningsValidation (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getWarnings'].filter((error:APIErrorValidation) => error.relationships.source.data.id === this.link.id)
  }

  get isModalTransparent ():boolean {
    return this.$store.getters['errorsValidation/getModalTransparency']
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.link-detail-variable {
  margin: 0px;
  padding-bottom:8px;
}
.btn-container {
  display: flex;
  margin: auto;
  .delete-btn {
    color: #E15F19;
    width: auto;
    margin: auto;
    .icon {
      mask-size: 16px 16px;
    }
  }
  .footer-btn {
    margin-right: 16px;
  }
}

:deep(.custom-text-input-container) {
  width: 100%;
}

</style>
