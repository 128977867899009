<template>
    <div class="background-gradiant">
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';


export default class BackgroundGradiant extends Vue {
}
</script>

<style lang="scss" scoped>
  .background-gradiant {
    background: url(@/assets/gradiants/background-gradiant.jpg) 50% / cover no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0.3;
    z-index: 0;
  }
</style>
