import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "usage-alert-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_HeightDeployer = _resolveComponent("HeightDeployer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["usage-alert full-width", {disabled: _ctx.disabled, deployed : _ctx.deployed}])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["usage-alert-btn-deploy", {disabled: _ctx.disabled}]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deploy && _ctx.deploy(...args)))
    }, [
      (!_ctx.deployed)
        ? (_openBlock(), _createBlock(_component_v_col, {
            key: 0,
            class: "pa-4 usage-alert-summary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "ma-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    class: "pa-0 ma-0 usage-alert-btn-deploy-text token-text-medium fit-content",
                    ref: "rowTitle",
                    cols: "11"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.selectedVal.value + '% of ' + _ctx.selectedLimit.text), 1)
                    ]),
                    _: 1
                  }, 512),
                  _createVNode(_component_v_col, {
                    class: "pa-0 ma-0 ml-auto usage-alert-btn-deploy-item-selected fit-content",
                    ref: "rowValue"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _renderSlot(_ctx.$slots, "value", {}, undefined, true)
                      ])
                    ]),
                    _: 3
                  }, 512),
                  (!_ctx.disabled)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        class: "pa-0 ma-0 usage-alert-btn-deploy-icon pl-auto fit-content"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Icon, { icon: "caret-down" })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 3
              })
            ]),
            _: 3
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_v_col, {
              class: "pa-0 usage-alert-btn-deploy-text token-text-medium fit-content",
              cols: "11"
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Edit alert ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_col, {
              align: "right",
              class: "pa-0 usage-alert-btn-deploy-item-selected fit-content"
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createElementVNode("div", { class: "usage-alert-value" }, "   ", -1)
              ])),
              _: 1
            }),
            (!_ctx.disabled)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  align: "right",
                  class: "pa-0 usage-alert-btn-deploy-icon pl-auto fit-content"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Icon, { icon: "caret-down" })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 64))
    ], 2),
    _createVNode(_component_HeightDeployer, {
      modelValue: _ctx.deployed,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.deployed) = $event)),
      class: "deployed-container",
      onExpand: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:forceDeployed', undefined))),
      onCollapse: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('update:forceDeployed', undefined)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { class: "pa-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => [
                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "my-auto" }, " When reaching ", -1)),
                _createVNode(_component_CustomSelect, {
                  items: _ctx.itemsVal,
                  modelValue: _ctx.selectedVal,
                  "onUpdate:modelValue": [
                    _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedVal) = $event)),
                    _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectedVal = $event))
                  ],
                  class: "select-val mx-2"
                }, null, 8, ["items", "modelValue"]),
                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "my-auto" }, " % of ", -1)),
                _createVNode(_component_CustomSelect, {
                  items: _ctx.itemsLimit,
                  modelValue: _ctx.selectedLimit,
                  "onUpdate:modelValue": [
                    _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedLimit) = $event)),
                    _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectedLimit = $event))
                  ],
                  class: "select-limit mx-2"
                }, null, 8, ["items", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "ma-0 my-4 mx-n8" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "pa-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Divider)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomButton, {
                  level: _ctx.$enums.ButtonLevels.Tertiary,
                  class: "delete-btn",
                  text: "Delete",
                  icon: "trash",
                  onClick: _ctx.deleteAlert
                }, null, 8, ["level", "onClick"]),
                _createVNode(_component_CustomButton, {
                  level: _ctx.$enums.ButtonLevels.Tertiary,
                  class: "ml-auto",
                  text: "Cancel",
                  onClick: _ctx.collapse
                }, null, 8, ["level", "onClick"]),
                _createVNode(_component_CustomButton, {
                  level: _ctx.$enums.ButtonLevels.Secondary,
                  class: "",
                  text: "Save alert",
                  onClick: _ctx.saveAlert
                }, null, 8, ["level", "onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 2))
}