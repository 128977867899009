import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/illustrations/trash_bin.svg'


const _hoisted_1 = { class: "ml-1 token-text-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    visible: _ctx.visible,
    closeOnExternalClick: true,
    "onUpdate:visible": _ctx.closeModal
  }, {
    content: _withCtx(() => [
      _createVNode(_component_CustomButton, {
        class: "ml-auto mt-2 mr-2",
        level: _ctx.$enums.ButtonLevels.SmallIcon,
        icon: "x",
        onClick: _ctx.closeModal
      }, null, 8, ["level", "onClick"]),
      _createVNode(_component_v_img, {
        src: _imports_0,
        height: "80px",
        width: "98px",
        class: "mx-auto"
      }),
      _createVNode(_component_v_col, { class: "pa-10 d-flex-col" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "ma-0 token-text-h5 mx-auto" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Delete cluster ")
            ])),
            _: 1
          }),
          _createVNode(_component_Divider, { class: "my-4" }),
          _createVNode(_component_v_row, { class: "ma-0" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "token-text-regular" }, " To confirm, type in the name of the cluster you will delete: ", -1)),
                _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.cluster?.attributes?.name), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "ma-0 mt-2 mb-10" }, {
            default: _withCtx(() => [
              _createVNode(_component_CustomTextInput, {
                modelValue: _ctx.confirmText,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.confirmText) = $event)),
                class: "full-width"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "pa-0 ma-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { class: "pa-0 mr-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_CustomButton, {
                    level: _ctx.$enums.ButtonLevels.Secondary,
                    class: "ml-auto mr-2 full-width",
                    onClick: _ctx.closeModal
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" Cancel ")
                    ])),
                    _: 1
                  }, 8, ["level", "onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "pa-0 ml-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_CustomButton, {
                    level: _ctx.$enums.ButtonLevels.Primary,
                    class: "full-width",
                    onClick: _ctx.doDelete,
                    loading: _ctx.loading,
                    disabled: _ctx.disabled
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" Delete cluster ")
                    ])),
                    _: 1
                  }, 8, ["level", "onClick", "loading", "disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "onUpdate:visible"]))
}