import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "placeholder", "value", "maxlength"]
const _hoisted_2 = {
  key: 0,
  class: "error-display"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["custom-text-input-container d-flex", {'has-error': _ctx.error, 'has-error-fixed': false, 'has-icon': _ctx.icon, disabled:_ctx.disabled, disabledBox: _ctx.disabledBox, searchStyle: _ctx.searchStyle, plain: _ctx.plain}])
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            class: "custom-text-input-icon my-auto mr-3",
            icon: _ctx.icon
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        class: _normalizeClass(["custom-text-input", (_ctx.shareButton ? ' with-share ' :'') + (_ctx.ellipsis ? ' ellipsis ' : '') + (_ctx.token ? _ctx.token : '')]),
        type: _ctx.computedType,
        placeholder: _ctx.placeholder,
        value: _ctx.modelValue,
        autocomplete: "false",
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur', _ctx.modelValue))),
        onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('change'))),
        onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$emit('blur', _ctx.modelValue)), ["enter"])),
        maxlength: _ctx.max ? _ctx.max : -1
      }, null, 42, _hoisted_1),
      (_ctx.shareButton)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            icon: "share",
            class: "custom-text-input-share",
            onClick: _ctx.onClickShare
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'password')
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 2,
            icon: 'eye' + (_ctx.isRevealed ? '-off' : ''),
            class: "custom-text-input-reveal",
            onClick: _ctx.onClickReveal
          }, null, 8, ["icon", "onClick"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.error && !_ctx.hideError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}