<template>
  <Popover :visible="show" @update:visible="$emit('update:show', $event)" :target="target" :closeOnExternalClick="true" :positionerOnly="true" :forcedPosition="forcedPosition"  v-if="target">
    <template #content>
      <div
        ref="dropdownMenu"
        :class="'dropdown-menu ma-auto my-2 ' + (maxContent ? ' max-content ': ' ') + (classInternal ? classInternal: ' ')"
        :style="'min-width:' + width+ '; ' + 'max-width:' + width+ '; ' + (marginTop ? 'margin-top:'+marginTop + 'px !important;' : '')"
      >
        <v-card 
          v-if="show"
          v-click-outside="onOutsideClick"
          elevation="0"
        >
          <div v-if="displaySearch" class="search-container">
            <CustomTextInput
              class="ma-4"
              placeholder="Search"
              icon="search"
              :searchStyle="true"
              :modelValue="query"
              @update:modelValue="$emit('update:query', $event)"
            />
          </div>
          <div
            class="expanded-select-items-container"
            :style="'max-height:' + maxHeight+'px;'"
          >
            <List :items="items" @select="onSelect" :loading="loading"/>
          </div>
          <div class="dropdown-footer" v-if="$slots['footer']" >
            <slot name="footer"></slot>
          </div>
        </v-card>
      </div>
    </template>
  </Popover>
</template>


<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'
import List, { ListItem } from '@/components/UIElements/List.vue';
import Popover, { POSITION } from '@/components/UIElements/Popover.vue';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';

import { Watch } from 'vue-property-decorator';
import { nextTick } from 'vue';

export class Props {
  items?: ListItem[] = prop({
    required: false,
  });
  modelValue?: ListItem = prop({
    required: false,
  });
  displaySearch?: boolean = prop({
    required: false,
  });
  loading?: boolean = prop({
    required: false,
  });
  width?: string = prop({
    required: false,
  });
  show: boolean = prop({
    required: true,
  });
  query?: string = prop({
    required: false,
  });
  maxContent?: boolean = prop({
    required: false,
  });
  target: HTMLElement = prop({
    required: true,
  });
  classInternal?: string = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon,
    List,
    CustomTextInput,
    Popover
  },
})
export default class DropdownMenu extends Vue.with(Props) {
  opened = false;
  maxHeight = 0;
  marginTop = 0;
  minMargin = 8
  heightDisplaySearch = 75

  forcedPosition?:POSITION = POSITION.BOTTOM;

  @Watch('show', {immediate: true})
  onShowChange() {
    this.forcedPosition = POSITION.BOTTOM
    this.calculateMaxHeight()
  }

  forcePosition(newPosition:POSITION) {
    if(this.forcedPosition !== newPosition) {
      this.forcedPosition = newPosition
      this.calculateMaxHeight()
    }
   
  }

  calculateMaxHeight() {
    this.maxHeight = 9999999
    this.marginTop = 0

    nextTick(() => {
      setTimeout(() => {
        const DOMelem = this.$refs.dropdownMenu as HTMLElement
        if(DOMelem) {
          const DOMRect = DOMelem.getBoundingClientRect()
          const maxBottom = window.innerHeight - this.minMargin
          if(this.forcedPosition === POSITION.BOTTOM) {
            this.maxHeight =  window.innerHeight - this.minMargin - DOMRect.y - (this.displaySearch ? this.heightDisplaySearch : 0)
            // Put on top if too long and there is more space on top
            if(DOMRect.bottom > maxBottom && DOMRect.top > window.innerHeight / 2) {
              this.forcePosition(POSITION.TOP)
            }
          } else if (this.forcedPosition === POSITION.TOP) {
            if(DOMRect.y < 0 ) {
              this.maxHeight = DOMRect.height + DOMRect.y - this.minMargin - (this.displaySearch ? this.heightDisplaySearch  : 0)
              this.marginTop = -DOMRect.y + this.minMargin*2
            }
          }
        }
      })
    })
    
  }

  onOutsideClick() {
    this.$emit('update:show',false)
  }

  onSelect(item:ListItem) {
    this.opened = false
    this.$emit('update:modelValue', item)
  }
}



</script>

<style lang="scss" scoped>
@import '@/css/variables';
.dropdown-menu {
  cursor: default;
  width: 100%;
  user-select: none;
  background: white;
  opacity: 1;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12), 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  border: 1px solid $color-neutral-grey-8;
  z-index:100000000000;

  &.max-content {
    width: max-content !important;
  }
  &.hidden {
    opacity: 0;
  }
  .search-container {
    border-bottom: 1px solid $color-neutral-grey-8;
  }
  .expanded-select-items-container {
    overflow-y: auto;
  }
  .dropdown-footer {
    padding: 16px;
    border-top: 1px solid $color-neutral-grey-8;
  }
}
</style>