import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/illustrations/shut_down.svg'


const _hoisted_1 = { class: "token-text-regular my-auto ml-2" }
const _hoisted_2 = { class: "token-text-regular" }
const _hoisted_3 = { class: "ml-1 token-text-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    visible: _ctx.visible,
    closeOnExternalClick: true,
    "onUpdate:visible": _ctx.closeModal
  }, {
    content: _withCtx(() => [
      _createVNode(_component_CustomButton, {
        class: "ml-auto mt-2 mr-2",
        level: _ctx.$enums.ButtonLevels.SmallIcon,
        icon: "x",
        onClick: _ctx.closeModal
      }, null, 8, ["level", "onClick"]),
      _createVNode(_component_v_img, {
        src: _imports_0,
        height: "80px",
        width: "144px",
        class: "mx-auto"
      }),
      _createVNode(_component_v_col, { class: "pa-10 d-flex-col" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "ma-0 token-text-h5 mb-2 mx-auto" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Undeploy cluster ")
            ])),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "ma-0 token-text-regular token-text-color-secondary mb-4 text-align-center mx-auto" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Careful, all data MUST be backed-up manually before doing this action, as all stateful applications like databases will lose all data permanently. ")
            ])),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "ma-0 token-text-regular token-text-color-secondary text-align-center mx-auto" }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Undeploying this cluster will also undeploy all projects currently on it. ")
            ])),
            _: 1
          }),
          _createVNode(_component_Divider, { class: "my-4" }),
          (_ctx.projects?.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
                  return (_openBlock(), _createBlock(_component_v_row, {
                    class: "ma-0 mt-2",
                    key: 'project-'+project.id
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Checkbox, {
                        modelValue: _ctx.checks[project.id],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.checks[project.id]) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                      _createElementVNode("div", _hoisted_1, _toDisplayString(project.attributes.name), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128)),
                _createVNode(_component_v_row, { class: "ma-0 mb-4" })
              ], 64))
            : _createCommentVNode("", true),
          _createVNode(_component_v_row, { class: "ma-0" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_2, [
                  _cache[4] || (_cache[4] = _createTextVNode(" To confirm, ")),
                  (_ctx.projects?.length)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(" check all the projects you will undeploy and ")
                      ], 64))
                    : _createCommentVNode("", true),
                  _cache[5] || (_cache[5] = _createTextVNode(" type in the name of the cluster you will undeploy: "))
                ]),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.cluster?.attributes?.name), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "ma-0 mt-2 mb-10" }, {
            default: _withCtx(() => [
              _createVNode(_component_CustomTextInput, {
                modelValue: _ctx.confirmText,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.confirmText) = $event)),
                class: "full-width"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "pa-0 ma-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { class: "pa-0 mr-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_CustomButton, {
                    level: _ctx.$enums.ButtonLevels.Secondary,
                    class: "ml-auto mr-2 full-width",
                    onClick: _ctx.closeModal
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" Cancel ")
                    ])),
                    _: 1
                  }, 8, ["level", "onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "pa-0 ml-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_CustomButton, {
                    level: _ctx.$enums.ButtonLevels.Primary,
                    class: "full-width",
                    onClick: _ctx.doPutOffline,
                    loading: _ctx.loading,
                    disabled: _ctx.disabled
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" Undeploy ")
                    ])),
                    _: 1
                  }, 8, ["level", "onClick", "loading", "disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "onUpdate:visible"]))
}