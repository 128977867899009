<template>
  <div class="stripe-widget-payment-methods full-width" >
    <!-- Display a payment form -->
      <div id="payment-element-payment-method">
        <!--Stripe.js injects the Payment Element-->
      </div>
      <template v-if="initialLoad">
        <Spinner/>
      </template>
      <template v-else>
        <v-row class="ma-0 mt-2">
          <template v-if="showCancel">
            <CustomButton :level="$enums.ButtonLevels.Secondary" text="Cancel" class="ml-auto mr-2" @click="$emit('cancel')"/>
          </template>
          <CustomButton text="Save payment method" :level="$enums.ButtonLevels.Primary" class="" :class="{'full-width' : !showCancel}" :disabled="submitDisabled || isInError" :status="isInError ? $enums.ButtonStates.Danger : ''" :loading="loading" @click="handleSubmit" />
        </v-row>
        <v-row class="ma-0 mt-2 token-background-color-error rounded-border"  v-if="messageContent">
          <div class="token-text-color-error ma-2">
            {{ messageContent }}
          </div>
        </v-row>
      </template>
     
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component';
import { loadStripe } from '@stripe/stripe-js/pure';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Switch from '@/components/UIElements/Switch.vue';
import API from '@/api/wrapper';
import { APIPaymentMethodSetupIntent } from '@/typesAPI';
import { Watch } from 'vue-property-decorator';
import Spinner from '@/components/UIElements/Spinner.vue';

class Props {
  customer: APIPaymentMethodSetupIntent = prop({
    required: true,
  });
  showCancel?: string = prop({
    required: false,
  });
}

@Options({
  components: {
    CustomButton,
    Switch,
    Spinner
  },
})
export default class StripeWidgetPaymentMethods extends Vue.with(Props) {

  submitDisabled = false
  initialLoad = false
  loading = false
  textHidden = false
  messageContent = ""
  isInError = false

  stripe:any;
  elements:any;

  @Watch('customer', {immediate : true})
  onCustomerChange() {
    if(this.customer && !this.stripe && !this.loading) {
      this.initialLoad = true
      this.setLoading(true)
      API.billing.addPaymentMethods(this.customer.id)
      .then(({setupIntent, stripePublicKey}) => {
        loadStripe(stripePublicKey)
        .then((newStripeObj) => {
          this.stripe = newStripeObj
          this.initialize(setupIntent.attributes.client_secret);
        })
      })
    }
  }

  initialize(clientSecret:string) {

    if(this.stripe) {
      //@ts-ignore
      this.elements = this.stripe.elements({ appearance: this.$helpers.static.stripeAppearance, clientSecret :clientSecret });
    }

    const paymentElementOptions = {
      layout: "tabs",
    };
    const paymentElement = this.elements.create("payment", paymentElementOptions);
    paymentElement.mount("#payment-element-payment-method");

    this.elements.getElement('payment').on('ready',() => {
      this.setLoading(false);
      this.initialLoad = false
    })
   
  }

  handleSubmit(e:any) {
    if(this.stripe && this.elements) {
      e.preventDefault();
      this.setLoading(true);

      this.stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: 'if_required'
      })
      .then((err:any) => {
        const { error } = err
        if(error) {
          if (error?.type === "card_error" || error?.type === "validation_error" ) {
            this.showMessage("");//error.message);
          } else {
            this.showMessage("An unexpected error occurred.");
          }
        }
        
      })
      .finally(() => {
        this.setLoading(false);
        if(!this.isInError) {
          this.$store.dispatch('billing/invalidatePaymentMethods')
          this.$emit('success')
        }

      })
    }
    
  }

  // ------- UI helpers -------

  showMessage(messageText:any) {
    this.messageContent = messageText
    this.isInError = true
    setTimeout(() => {
      this.isInError = false
      this.messageContent = ""
    }, 4000);
  }


  // Show a spinner on payment submission
  setLoading(isLoading:any) {
    if (isLoading) {
      // Disable the button and show a spinner
      this.submitDisabled = true;
      this.loading = true;
      this.textHidden = true
    } else {
      this.submitDisabled = false;
      this.loading = false;
      this.textHidden = false
    }
  }
}
</script>

<style lang="scss" scoped>
#submit {
  z-index: 1;
  position: relative;
}
</style>
