import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "node-detail-logs full-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_LogLine = _resolveComponent("LogLine")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_col, { class: "pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-2" }, {
          default: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "token-text-medium my-auto" }, " Logs ", -1)),
            _createVNode(_component_CustomTextInput, {
              class: "ml-auto",
              searchStyle: true,
              placeholder: "Search logs",
              modelValue: _ctx.query,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
                _ctx.onQueryUpdate
              ]
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 border-bot" }),
        _createVNode(_component_v_row, {
          class: "ma-0 log-container",
          ref: "logContainer"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 ma-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_Icon, {
                  class: _normalizeClass(["lazy-load-up-anchor mb-2", {invisible: _ctx.topLine === 1 || _ctx.query}]),
                  icon: "spinner",
                  ref: "lazyLoadSpinner"
                }, null, 8, ["class"]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logs, (log) => {
                  return (_openBlock(), _createBlock(_component_LogLine, {
                    key: log.id,
                    log: log
                  }, null, 8, ["log"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    })
  ]))
}