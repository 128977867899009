<template>
  <div class="validation-errors-modal bar" :class="{open: open, transparent: isModalTransparent, isBlockDetailOpen: isBlockDetailOpen}" >
    <template v-if="isLoading">
      <Spinner class="ma-auto"/>
    </template>
    <template v-else>
      <v-col class="pa-0">
        <!-- Header -->
        <v-row class="ma-0 pa-4 ">
          <div class="d-flex">
            <span class="token-text-medium">Errors</span>
          </div>
          <CustomButton class="ml-auto" :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="onClose"/>
        </v-row>

        <!-- Errors -->
        <template v-if="errors.length">
          <v-row class="ma-0 pa-4 categorie-title" >
            <div class="d-flex">
              <Badge  :severity="$enums.Severity.DANGER" :label="errors.length" class="mr-2"/>
              <span class="token-text-medium">Blocking errors</span>
            </div>
          </v-row>
          <v-row class="ma-0 ma-2 categorie-content" v-for="(error, index) in errors" :key="'error-'+index">
            <ListElement
              @click="onClickError(error)"
              @mouseenter="onErrorHover(error)"
              @mouseleave="onErrorHover(null)"
              :text="error.attributes.title"
              :subtext="error.attributes.detail"
              :warningLevel="$enums.Severity.DANGER"
              :selected="selectedError === error"
              class="ma-0 px-4"
            />
          </v-row>
        </template>
      
        <!-- Warnings -->
        <template v-if="warnings.length">
          <v-row class="ma-0 pa-4 categorie-title" >
            <div class="d-flex">
              <Badge :severity="$enums.Severity.WARNING" :label="warnings.length" class="mr-2"/>
              <span class="token-text-medium">Warnings</span>
            </div>
          </v-row>
          <v-row class="ma-2 categorie-content" v-for="(warning, index) in warnings" :key="'warning-'+index">
            <ListElement
              @click="onClickError(warning)"
              @mouseenter="onErrorHover(warning)"
              @mouseleave="onErrorHover(null)"
              :text="warning.attributes.title"
              :subtext="warning.attributes.detail"
              :warningLevel="$enums.Severity.WARNING"
              :selected="selectedError === warning"
              class="ma-0 px-4"
            />
          </v-row>
        </template>
        
        <!-- Infos -->
        <template v-if="infos.length">
          <v-row class="ma-0 pa-4 categorie-title" >
            <div class="d-flex">
              <Badge :severity="$enums.Severity.INFO" :label="infos.length" class="mr-2"/>
              <span class="token-text-medium">Advices</span>
            </div>
          </v-row>
          <v-row class="ma-0 ma-2 categorie-content" v-for="(info, index) in infos" :key="'info-'+index">
            <ListElement
              @click="onClickError(info)"
              @mouseenter="onErrorHover(info)"
              @mouseleave="onErrorHover(null)"
              :text="info.attributes.title"
              :subtext="info.attributes.detail"
              :warningLevel="$enums.Severity.INFO"
              :selected="selectedError === info"
              class="ma-0 px-4"
            />
          </v-row>
        </template>

        <!-- All Clear -->
        <template v-if="!errors.length && !warnings.length && !infos.length">
          <v-row class="ma-0 separator" >
          </v-row>
          <v-row class="ma-0 pt-4" >
            <div class="special-check-background d-flex mx-auto">
              <Icon class="ma-auto" icon="special-check"/>
            </div>
          </v-row>
          <v-row class="ma-0 pt-2" >
            <span class="mx-auto token-text-medium">All clear !</span>
          </v-row>
          <v-row class="ma-0 d-flex token-text-regular pt-2 subtext" >
            <span class="mx-auto">You are ready for deployment</span>
          </v-row>
        </template>
      </v-col>
      <template v-if="showWarningPopup">
        <Popin>
          <template #header>
            <v-img src="@/assets/illustrations/triangle-error.svg" height="56px"/>
          </template>
          <template #content>
            <v-col class="pa-0">
              <v-row class="token-text-h5 ma-0 mb-1 ">
                <div class="ma-auto">
                  Your project still has unresolved warnings
                </div>
              </v-row>
              <v-row class="ma-0 mb-1 ">
                <div class="token-text-regular token-text-color-secondary ma-auto">
                  Warnings do not block deployment but ignoring them can result higher ressource use or unexpected bugs.
                </div>
              </v-row>
            </v-col>
          </template>
          <template #footer>
            <v-col class="pa-0">
              <v-row class="ma-0 mb-2">
                <CustomButton :level="$enums.ButtonLevels.Primary" class="ma-auto" @click="closeWarningPopup">Show warning</CustomButton>
              </v-row>
              <v-row class="ma-0">
                <CustomButton :level="$enums.ButtonLevels.Tertiary" class="ma-auto" @click="forceDeploy">Ignore warnings and deploy</CustomButton>
              </v-row>
            </v-col>
          </template>
        </Popin>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Badge from '@/components/UIElements/Badge.vue'
import Icon from '@/components/UIElements/Icon.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import Popin from '@/components/UIElements/Popin.vue'
import { APIErrorValidation } from '@/typesAPI';
import { ElementNode } from '@/types';
import { Watch } from 'vue-property-decorator';
import ListElement from '@/components/UIElements/ListElement.vue';

@Options({
  components: {
    CustomButton,
    Icon,
    Popin,
    Spinner,
    Badge,
    ListElement
  },
})
export default class ValidationErrorsModal extends Vue {
  
  onClose () {
    this.$store.dispatch('errorsValidation/closeModal')
  }

  closeWarningPopup () {
    this.$store.dispatch('errorsValidation/closeWarningPopup')
  }

  forceDeploy () {
    this.$store.dispatch('errorsValidation/closeWarningPopup')
    this.$store.dispatch('errorsValidation/closeModal')
    this.$emitter.emit('forceDeploy')
  }

  onClickError (error:APIErrorValidation) {
    this.$store.dispatch('errorsValidation/setSelectedError', error)
    if(error.relationships.source.data.type === 'blocks') {
      // Open Block
      const tree = this.$store.getters['fields/getTree']
      const block = tree.blocks[error.relationships.source.data.id]

      this.$router.push({
        name: 'flowBlock',
        params: {
          blockId: error.relationships.source.data.id,
          tabId: Object.keys(block.childs)[0],
        }
      });

    } else if(error.relationships.source.data.type === 'blockConnections') {
      // Open Connection
      this.$store.dispatch('elements/setDetailOpenLink', error.relationships.source.data.id)
    } else if(error.relationships.source.data.type === 'blockEnvironmentVariableOverrides') {
      // Open Connection and hightlight override
    } else if(error.relationships.source.data.type === 'fields') {
      // Open Block + Open Box

      const tree = this.$store.getters['fields/getTree']
      const fv = tree.fieldValues[error.relationships.source.data.id]
      this.$router.push({
        name: 'flowBlockAndBox',
        params: {
          blockId: fv?.relationships?.block?.data?.id,
          tabId: fv?.parent?.parent?.parent.id,
          boxId: fv?.parent?.parent.id
        }
      });

    }
    this.$store.dispatch('errorsValidation/setHoveredError', null)
    this.$store.dispatch('errorsValidation/setModalTransparency', false)

  }

  onErrorHover(error: APIErrorValidation | null) {
    if(!this.selectedError || this.selectedError !== error) {
      this.$store.dispatch('errorsValidation/setHoveredError', error)

      if(error) {
        const nodes: ElementNode[] = this.$store.getters['elements/getNodes']
        let blockIdToSearch = ''
        const tree = this.$store.getters['fields/getTree']
        switch(error.relationships.source.data.type) {
          case 'block':
            blockIdToSearch = error.relationships.source.data.id
            break;
          case 'fields':
            blockIdToSearch = tree.fieldValues[error.relationships.source.data.id]?.relationships?.block.data.id
            break;
          default:
            blockIdToSearch = error.relationships.source.data.id
            break;
        }

        const node = nodes.find((n:ElementNode) => {
          return n.extra.blockAPIID === blockIdToSearch
        })
        const htmlDiv:HTMLDivElement |null = document.querySelector("[data-id=" + node?.id +"]")
        if(this.isBlockDetailOpen || this.isLinkDetailOpen || (htmlDiv && (htmlDiv.getBoundingClientRect().right > (document.body.getBoundingClientRect().width - 416)))) {
          this.$store.dispatch('errorsValidation/setModalTransparency', true)
        }
      } else {
        this.$store.dispatch('errorsValidation/setModalTransparency', false)
      }
    }
    
  }

  get isModalTransparent ():boolean {
    return this.$store.getters['errorsValidation/getModalTransparency']
  }

  get isLoading ():boolean {
    return this.$store.getters['errorsValidation/isLoading']
  }

  get selectedError ():APIErrorValidation | null {
    return this.$store.getters['errorsValidation/getSelectedError']
  }

  get showWarningPopup ():APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getWarningPopupIsOpen']
  }

  get open ():boolean {
    return this.$store.getters['errorsValidation/getModalIsOpen']
  }

  get list ():APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getList']
  }

  get errors (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getErrors']
  }

  get warnings (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getWarnings']
  }

  get infos (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getInfos']
  }

  get isBlockDetailOpen (): boolean {
    return !!this.$store.getters['elements/getDetailOpenElement']
  }

  get isLinkDetailOpen (): boolean {
    return !!this.$store.getters['elements/getDetailOpenLink']
  }

  @Watch('isBlockDetailOpen')
  onBlockDetailToggle(newVal: boolean, oldVal:boolean) {
    if(oldVal && !newVal) {
      this.$store.dispatch('errorsValidation/setSelectedError', null)
    }
  }

  @Watch('isLinkDetailOpen')
  onLinkDetailToggle(newVal: boolean, oldVal:boolean) {
    if(oldVal && !newVal) {
      this.$store.dispatch('errorsValidation/setSelectedError', null)
    }
  }

  

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.validation-errors-modal {
  display: flex;
  z-index: 100001 !important;
  width: 416px;
  height: calc(100vh - 106px);
  position: absolute;
  right: -500px;
  top: 90px;
  transition: right 0.2s ease-in-out, background 0.1s ease-in-out;
  overflow-y: auto;
  backdrop-filter: blur(1px) !important;
  &.isBlockDetailOpen {
    background: white !important;
  }
  &.open {
    right: 16px;
  }
  &.transparent {
    background: rgba(255, 255, 255, 0.24) !important;
  }

  .subtext {
    color: $color-neutral-grey-60;
  }
  .special-check-background {
    border-radius: 24px;
    width: 40px;
    height: 40px;
    background: rgba(73, 223, 165, 0.16);
  }
  .separator {
    border-bottom: 1px solid $color-neutral-grey-12;
  }

  .warning-popup-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.88);
    .warning-popup {
      position: absolute;
      width: 94%;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: fit-content;
      .text {
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
        color: $color-neutral-black;
      }
      .subtext {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: $color-neutral-grey-60;
      }
    }
  }
  

  .categorie-title {
    border-top: 1px solid $color-neutral-grey-12;
    border-bottom: 1px solid $color-neutral-grey-12;
  }
  .categorie-content {
    .categorie-content-button {
      border-radius:8px !important;
      height: auto;
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;

      &.selected {
        background-color: $color-brand;
        .categorie-content-title {
          color: white !important;
        }
        .categorie-content-detail {
          color: white !important;
        }
      }
      .col-badge {
        max-width: 5px;
      }
      .categorie-content-title {
        color: $color-neutral-black;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
      .categorie-content-detail {
        color: $color-neutral-grey-60;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
      }
    }

  }
}
</style>

<style lang="scss">

.validation-errors-modal {
  .categorie-content {
    .categorie-content-button {
      .custom-button-text {
        padding-right: 0px !important;
        padding-left: 0px !important;
        margin-left: 18px !important;
        margin-right: 0px !important;
        width: 100%;
      }
      .col-badge {
        .badge-container {
          height: 100%;
        }
      }
    }
  }
}
</style>