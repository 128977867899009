<template>
  <div>
    <Modal v-model:visible="visible" :loading="loading">
      <div class="token-text-h4 pa-8">
        Fransys Terms and conditions
      </div>
      <div class="token-text-regular pa-8 pt-0">
        <div class="token-text-medium">1. Acceptance of Terms</div>
        By using the Fransys Cloud Hosting Service ("Service"), you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, please refrain from using the Service.
        <br/><br/>
        <div class="token-text-medium">2. Service Description</div>
        Fransys provides cloud hosting services that allow users to store, manage, and access data and applications over the internet. The Service may be subject to updates, modifications, or enhancements at any time.
        <br/><br/>
        <div class="token-text-medium">3. User Responsibilities</div>
        a. You are responsible for all activities that occur under your account, including maintaining the security and confidentiality of your login credentials.
        <br/>
        b. You agree not to use the Service for any unlawful, unauthorized, or unethical purposes, including but not limited to distributing malware, infringing on intellectual property rights, or engaging in fraudulent activities.
        <br/><br/>     
        <div class="token-text-medium">4. Data Security and Privacy</div>
        Fransys takes data security and privacy seriously. We will take reasonable steps to protect your data, but we are not responsible for data loss, unauthorized access, or breaches that may occur. Please review our Privacy Policy for more information on how we handle your data.
      </div>
      <template #footer>
        <v-row class="pa-4 ma-0">
          <CustomButton :level="$enums.ButtonLevels.Tertiary" class="ml-auto mr-2" @click="reject">
            Refuse terms and conditions
          </CustomButton>
          <CustomButton :level="$enums.ButtonLevels.Primary" @click="accept" :loading="loading">
            Accept terms and conditions
          </CustomButton>
        </v-row>
      </template>
    </Modal>
    <Modal v-model:visible="visibleFallback">
      <v-col class="pa-10">
        <v-row class="ma-0">
          <v-img src="@/assets/illustrations/forbidden.png" height="64px" width="96px" class="mx-auto mb-6"/>
        </v-row>
        <v-row class="token-text-h4 pb-2 ma-0">
          <span class="mx-auto">
            Accept the terms and conditions to use Fransys
          </span>
        </v-row>
        <v-row class="token-text-h5 pb-6 ma-0">
          <span class="mx-auto">
            You can't use fransys if you don't accept the terms and conditions.
          </span>
        </v-row>
        <v-row class="ma-0">
          <CustomButton :level="$enums.ButtonLevels.Primary" class="mx-auto" @click="reopen">
            Show terms and conditions
          </CustomButton>
        </v-row>
      </v-col>
    </Modal>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import API from '@/api/wrapper'
import APIUser from '@/typesAPI/APISocialAccount'

@Options({
  components: {
    Modal,
    CustomButton
  },
})
export default class ModalCGU extends Vue {
  loading = false
  visible = true
  visibleFallback = false

  reject () {
    this.visible = false
    this.visibleFallback = true
  }

  accept () {
    this.loading = true
    API.user.acceptCGU(this.$store.getters['user/getUser'].id)
    .then((newMe:APIUser) => {
      this.$store.dispatch('user/setUser', newMe)
      this.visible = false
      // this.$router.go(0)
    })
    .catch()
    .finally(() => {
      this.loading = false
    })
  }

  reopen () {
    this.visible = true
    this.visibleFallback = false
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
</style>
