<template>
  <div class="panel-right">
    <v-col class="pa-0">
      <v-row class="ma-0">
        <v-col class="pa-0 fit-content" align="right">
          <SaveBar/>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import SaveBar from '@/components/Actions/SaveBar.vue';

@Options({
  components: {
    SaveBar
  },
})
export default class PanelRight extends Vue {
 
}
</script>

<style lang="scss" scoped>
.panel-right {
  pointer-events: all;
  margin-left: auto;
}
</style>