<template>
  <div class="homeView full-view">
    <BackgroundGradiant/>
    <v-col class="pa-0 my-auto">
      <v-row class="ma-0 pb-12 mx-auto fit-content">
        <FransysSignature/>
      </v-row>
      <v-row class="ma-0">
        <LoginBar class="ma-auto" :withPassword="true"/>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import LoginBar from '@/components/Actions/LoginBar.vue';
import BackgroundGradiant from '@/components/UIElements/BackgroundGradiant.vue';
import FransysSignature from '@/components/UIElements/FransysSignature.vue';
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
    LoginBar,
    FransysSignature,
    BackgroundGradiant
  },
})
export default class AdminView extends Vue {

}
</script>

<style lang="scss" scoped>
</style>
