import { APIFieldDefinition, APITabField, APIFieldValue, APIFieldBox, APIBlock, APIBaseAttributes, APIFieldGroup } from "@/typesAPI"
import helpers from '@/utils/helpers'
import store from "@/store"

type State = {
  listFieldValue: APIFieldValue[],
  listFieldDefinitions: APIFieldDefinition[],
  listTabs: APITabField[],
  listBoxes: APIFieldBox[],
  listFieldGroups: APIFieldGroup[],
  openedBoxId: string,
  tree: any,
  tabLoaded:string[],
}

const state: State = {
  listFieldValue: [],
  listFieldDefinitions : [],
  listTabs : [],
  listBoxes : [],
  listFieldGroups : [],
  openedBoxId: "",
  tree: { tabs : {}, boxes: {}, fieldDefinitions: {}, fieldValues :{}},
  tabLoaded: []
}


const buildTree = (state:State) => {
  const tree:any = { blocks: {}, tabs : {}, boxes: {}, fieldDefinitions: {}, fieldValues :{}}
  // Add Blocks
  store.getters['blocksAPI/getList'].forEach((value:APIBlock) => {
    tree.blocks[value.id] = value
  })
  for (const blockIndex in tree.blocks) {
    tree.blocks[blockIndex].tabs = {}
    tree.blocks[blockIndex].childs = tree.blocks[blockIndex].tabs
  }

  // Add Tabs
  state.listTabs.forEach((value:APITabField) => {
    tree.tabs[value.id] = value
  })
  for (const tabIndex in tree.tabs) {
    tree.tabs[tabIndex].parent = null
    tree.tabs[tabIndex].boxes = {}
    for (const blockIndex in tree.blocks) {
      if(tree.blocks[blockIndex]?.relationships?.tabs?.data?.find((b:APIBaseAttributes) => b.id === tree.tabs[tabIndex].id)) {
        tree.blocks[blockIndex].tabs[tree.tabs[tabIndex].id] = tree.tabs[tabIndex]
      }
    }
    tree.tabs[tabIndex].childs = tree.tabs[tabIndex].boxes
    // Add Boxes
    state.listBoxes.forEach((value:APIFieldBox) => {
      if(value.relationships.tab && value.relationships.tab.data.id === tree.tabs[tabIndex].id) {
        tree.tabs[tabIndex].boxes[value.id] = value
      }
    })
    tree.tabs[tabIndex].childsByPriority = Object.values(tree.tabs[tabIndex].childs).sort((a:any, b:any) => a?.attributes?.priority - b?.attributes?.priority)

    for (const boxIndex in tree.tabs[tabIndex].boxes) {
      tree.tabs[tabIndex].boxes[boxIndex].parent = tree.tabs[tabIndex]
      tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions = {}
      tree.tabs[tabIndex].boxes[boxIndex].childs = tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions


      // Add FieldDefinitions
      state.listFieldDefinitions.forEach((value:APIFieldDefinition) => {
        if(value.relationships?.box && value.relationships?.box?.data.id === tree.tabs[tabIndex].boxes[boxIndex].id) {
          tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions[value.id] = value
        }
      })
      tree.tabs[tabIndex].boxes[boxIndex].childsByPriority = Object.values(tree.tabs[tabIndex].boxes[boxIndex].childs).sort((a:any, b:any) => a?.attributes?.priority - b?.attributes?.priority)
      for (const fieldDefinitionsIndex in tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions) {
        tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions[fieldDefinitionsIndex].parent = tree.tabs[tabIndex].boxes[boxIndex]
        tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions[fieldDefinitionsIndex].fieldValues = {}
        tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions[fieldDefinitionsIndex].childs = tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions[fieldDefinitionsIndex].fieldValues
        tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions[fieldDefinitionsIndex].childsByPriority = Object.values(tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions[fieldDefinitionsIndex].childs)
        // Add FieldValues
        state.listFieldValue.forEach((value:APIFieldValue) => {
          if(value.relationships?.definition && value.relationships?.definition?.data.id === tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions[fieldDefinitionsIndex].id) {
            if(!value.attributes.isTrashed) {
              tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions[fieldDefinitionsIndex].fieldValues[value.id] = value
              tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions[fieldDefinitionsIndex].fieldValues[value.id].parent = tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions[fieldDefinitionsIndex]
            }
          }
        })
      }
    }
  }


  // Shake tree first
  for (const tabIndex in tree.tabs) {
    tree.boxes = {...tree.tabs[tabIndex].boxes, ...tree.boxes }
    for (const boxIndex in tree.tabs[tabIndex].childs) {
      tree.fieldDefinitions = {...tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions, ...tree.fieldDefinitions }
      for (const fieldDefinitionsIndex in tree.tabs[tabIndex].boxes[boxIndex].childs) {
        tree.fieldValues = {...tree.tabs[tabIndex].boxes[boxIndex].fieldDefinitions[fieldDefinitionsIndex].fieldValues, ...tree.fieldValues }
      }
    }
  }

  for (const blockIndex in tree.blocks) {
    const listTabs:any[] = []
    tree.blocks[blockIndex].fieldValues = {}
    Object.values(tree.fieldValues).forEach((fieldValue:any) => {
      const tab = fieldValue?.parent?.parent?.parent
      if(tab && fieldValue.relationships.block.data.id === tree.blocks[blockIndex].id) {
        if(!listTabs.find((t) => t.id === tab.id)) {
          listTabs.push(tab)
        }
        tree.blocks[blockIndex].fieldValues[fieldValue.id] = fieldValue
      }
    })
    listTabs.forEach((tab) => {
      tree.blocks[blockIndex].tabs[tab.id] = tab
    })
    Object.values(tree.blocks[blockIndex].tabs).forEach((tab:any) => {
      tab.parent = tree.blocks[blockIndex]
    })
    tree.blocks[blockIndex].childs = tree.blocks[blockIndex].tabs
    tree.blocks[blockIndex].childsByPriority = Object.values(tree.blocks[blockIndex].childs).sort((a:any, b:any) => a?.attributes?.priority - b?.attributes?.priority)

  }

  // Shake tree second
  // for (const blockIndex in tree.blocks) {
  //   tree.tabs = {...tree.blocks[blockIndex].tabs, ...tree.tabs }
  //   for (const tabIndex in tree.blocks[blockIndex].childs) {
  //     tree.boxes = {...tree.blocks[blockIndex].tabs[tabIndex].boxes, ...tree.boxes }
  //     for (const boxIndex in tree.blocks[blockIndex].tabs[tabIndex].childs) {
  //       tree.fieldDefinitions = {...tree.blocks[blockIndex].tabs[tabIndex].boxes[boxIndex].fieldDefinitions, ...tree.fieldDefinitions }
  //       for (const fieldDefinitionsIndex in tree.blocks[blockIndex].tabs[tabIndex].boxes[boxIndex].childs) {
  //         tree.fieldValues = {...tree.blocks[blockIndex].tabs[tabIndex].boxes[boxIndex].fieldDefinitions[fieldDefinitionsIndex].fieldValues, ...tree.fieldValues }
  //       }
  //     }
  //   }
  // }

  state.tree = tree

  // console.log('TREE : ', tree)
  return tree
}


const getters = {
  getTree: (state:State) => {
    return state.tree
  },
  getValueByID: (state:State) => {
    return (id:string) => {
      return state.listFieldValue.filter(value => !value.attributes.isTrashed).find(value => value?.id === id)
    }
  },
  getDefinitionsByIDValue: (state:State) => {
    return (id:string) => {
      const value = state.listFieldValue.filter(value => !value.attributes.isTrashed).find(val => val.id === id)
      return state.listFieldDefinitions.find(definition => definition.id === value?.relationships?.definition.data.id)
    }
  },
  getValuesByIDDefinition: (state:State) => {
    return (id:string) => {
      const definition = state.listFieldDefinitions.find(definition => definition.id === id)
      if(definition) {
        const fvs:APIFieldValue[] = Object.values(state.tree.fieldDefinitions[definition.id].fieldValues)
        return fvs.length ? fvs[0] : []
        // return state.listFieldValue.filter(value => definition.id === value?.relationships?.definition.data.id)
      }
      return null
    }
  },     
  getDefinitionsByID: (state:State) => {
    return (id:string) => {
      return state.listFieldDefinitions.find(definition => definition.id === id)
    }
  },  
  getTabsByBlockID: (state:State) => {
    return (id:string) => {
      const block = store.getters['blocksAPI/getList'].find((blockAPI:APIBlock) => blockAPI.id === id)
      const ret:APITabField[] = []
      if(block) {
        block?.relationships?.tabs?.data.forEach((baseAttribute:APIBaseAttributes) => {
          const tab = state.listTabs.find(tab => tab.id === baseAttribute.id)
          if(tab) {
            ret.push(tab)
          }
        })
      }
      return ret
    }
  },   
  getTabByID: (state:State) => {
    return (id:string) => {
      return state.listTabs.find(tab => tab.id === id)
    }
  },    
  getBoxByID: (state:State) => {
    return (id:string) => {
      return state.listBoxes.find(box => box.id === id)
    }
  },  
  getListDefinitions: (state:State) => {
    return state.listFieldDefinitions
  },
  getListValues: (state:State) => {
    return state.listFieldValue.filter(value => !value.attributes.isTrashed)
  },
  getListTabs: (state:State) => {
    return state.listTabs
  },
  getListBoxes: (state:State) => {
    return state.listBoxes
  },
  getListGroups: (state:State) => {
    return state.listFieldGroups
  },
  getOpenedBoxId: (state:State) => {
    return state.openedBoxId
  },
  getIsTabLoaded: (state:State) => {
    return (id:string) => {
      return state.tabLoaded.includes(id)
    }
  },
}

const mutations = {
  ADD_FIELD_VALUE: (state:State, fieldValue:APIFieldValue) => {
    helpers.ressources.addMultipleRessourceToArray(state.listFieldValue, [fieldValue])
  },
  ADD_FIELD_VALUES: (state:State, fieldValues:APIFieldValue[]) => {
    helpers.ressources.addMultipleRessourceToArray(state.listFieldValue, fieldValues)
  },
  ADD_FIELD_GROUPS: (state:State, fieldGroups:APIFieldGroup[]) => {
    helpers.ressources.addMultipleRessourceToArray(state.listFieldGroups, fieldGroups)
  },
  SET_FIELDS_VALUE: (state:State, fieldValues:APIFieldValue[]) => {
    state.listFieldValue = fieldValues.filter((fv:APIFieldValue) => fv.attributes.isTrashed === false)
  },

  DELETE_FIELD_VALUE: (state:State, fieldValues:APIFieldValue) => {
    state.listFieldValue.forEach((fv, index) => {
      if(fv.id === fieldValues.id && helpers.ressources.isNewerThan(fieldValues, fv)) {
        state.listFieldValue.splice(index,1)
      }
    })
  },
  SET_LIST_DEFINITION: (state:State, fieldsDefinitions:APIFieldDefinition[]) => {
    state.listFieldDefinitions = fieldsDefinitions
  },
  ADD_DEFINITION: (state:State, fieldDefinition:APIFieldDefinition) => {
    helpers.ressources.addMultipleRessourceToArray(state.listFieldDefinitions, [fieldDefinition])
  },
  ADD_DEFINITIONS: (state:State, fieldDefinitions:APIFieldDefinition[]) => {
    helpers.ressources.addMultipleRessourceToArray(state.listFieldDefinitions, fieldDefinitions)
  },
  ADD_BOXES: (state:State, boxes:APIFieldBox[]) => {
    helpers.ressources.addMultipleRessourceToArray(state.listBoxes, boxes)
  },
  SET_LIST_TABS: (state:State, tabsField:APITabField[]) => {
    state.listTabs = tabsField
  },
  SET_LIST_BOXES: (state:State, tabsBoxes:APIFieldBox[]) => {
    state.listBoxes = tabsBoxes
  },
  ADD_TAB: (state:State, tabField:APITabField) => {
    helpers.ressources.addMultipleRessourceToArray(state.listTabs, [tabField])
  },
  ADD_TABS: (state:State, tabFields:APITabField[]) => {
    helpers.ressources.addMultipleRessourceToArray(state.listTabs, tabFields)
  },
  ADD_BOX: (state:State, box:APIFieldBox) => {
    helpers.ressources.addMultipleRessourceToArray(state.listBoxes, [box])
  },
  SET_OPENED_BOX: (state:State, boxId:string) => {
    state.openedBoxId = boxId
  },
  ADD_TAB_LOADED: (state:State, id:string) => {
    state.tabLoaded.push(id)
  },
  CLEAN_TAB_LOADED: (state:State) => {
    state.tabLoaded = []
  },
  BUILD_TREE: (state:State) => {
    buildTree(state)
  },
  
}

const actions = {
  addNodeFieldValue: (context:any, fieldValue:APIFieldValue) => {
    context.commit('ADD_FIELD_VALUE', fieldValue)
    // console.warn('From addNodeFieldValue')
    context.commit('BUILD_TREE')
  },
  addFieldValues: (context:any, fieldValues:APIFieldValue[]) => {
    context.commit('ADD_FIELD_VALUES', fieldValues)
    // console.warn('From addNodeFieldValue')
    // context.commit('BUILD_TREE')
  },
  addFieldGroups: (context:any, fieldGroups:APIFieldGroup[]) => {
    context.commit('ADD_FIELD_GROUPS', fieldGroups)
    // console.warn('From addFieldGroups')
  },
  setFieldsValue: (context:any, fieldValues:APIFieldValue[]) => {
    context.commit('SET_FIELDS_VALUE', fieldValues)
    // console.warn('From setFieldsValue')
    context.commit('BUILD_TREE')
  },
  deleteFieldValue: (context:any, fieldValue:APIFieldValue) => {
    context.commit('DELETE_FIELD_VALUE', fieldValue)
    // console.warn('From deleteFieldValue')
    context.commit('BUILD_TREE')
  },
  setFieldsDefinition: (context:any, fieldsDefinitions:APIFieldDefinition[]) => {
    context.commit('SET_LIST_DEFINITION', fieldsDefinitions)
    // console.warn('From setFieldsDefinition')
    context.commit('BUILD_TREE')
  },
  addNodeFieldDefinition: (context:any, fieldDefinition:APIFieldDefinition) => {
    context.commit('ADD_DEFINITION', fieldDefinition)
    // console.warn('From addNodeFieldDefinition')
    context.commit('BUILD_TREE')
  },
  addNodeFieldDefinitions: (context:any, fieldDefinitions:APIFieldDefinition[]) => {
    context.commit('ADD_DEFINITIONS', fieldDefinitions)
    // console.warn('From addNodeFieldDefinitions')
    // context.commit('BUILD_TREE')
  },
  setFieldTabs: (context:any, tabsField:APITabField[]) => {
    context.commit('SET_LIST_TABS', tabsField)
    // console.warn('From setFieldTabs')
    context.commit('BUILD_TREE')
  },
  addNodeFieldTab: (context:any, tabField:APITabField) => {
    context.commit('ADD_TAB', tabField)
    // console.warn('From addNodeFieldTab')
    context.commit('BUILD_TREE')
  },
  addFieldTabs: (context:any, tabFields:APITabField[]) => {
    context.commit('ADD_TABS', tabFields)
    // console.warn('From addFieldTabs')
    context.commit('BUILD_TREE')
  },
  setFieldBoxes: (context:any, tabsBoxes:APIFieldBox[]) => {
    context.commit('SET_LIST_BOXES', tabsBoxes)
    // console.warn('From setFieldBoxes')
    context.commit('BUILD_TREE')
  },
  addFieldBoxes: (context:any, boxes:APIFieldBox[]) => {
    context.commit('ADD_BOXES', boxes)
    // console.warn('From addNodeFieldBox')
    // context.commit('BUILD_TREE')
  },
  addNodeFieldBox: (context:any, box:APIFieldBox) => {
    context.commit('ADD_BOX', box)
    // console.warn('From addNodeFieldBox')
    context.commit('BUILD_TREE')
  },
  setOpenedBoxId: (context:any, boxId:string) => {
    context.commit('SET_OPENED_BOX', boxId)
  },
  addTabLoaded: (context:any, id:string) => {
    context.commit('ADD_TAB_LOADED', id)
  },
  cleanTabLoaded: (context:any) => {
    context.commit('CLEAN_TAB_LOADED')
  },
  buildTree: (context:any) => {
    context.commit('BUILD_TREE')
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}