<template>
    <div class="indicator ma-2" :style="'background-color:'+ color+';'" :class="'state-'+(state ? state : 'neutral')">
    </div>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component';

class Props {
  color?: string  = prop({
    required: false,
  });
  state?: string  = prop({
    required: false,
  });
}
export default class Indicator extends Vue.with(Props) {
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
.indicator {
  overflow: hidden;
  border-radius: 8px;
  min-width: 8px;
  max-width: 8px;
  min-height: 8px;
  max-height: 8px;
  background-color: black;
  &.state-danger {
    background-color: $color-orange;
  }
  &.state-info {
    background-color: $color-blue;
  }
  &.state-neutral {
    background-color: $color-neutral-grey-8;
  }
  &.state-success {
    background-color: $color-green;
  }
  &.state-warning {
    background-color: $color-yellow;
  }
}
</style>
