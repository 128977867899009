<template>
    <Modal :visible="visible" :closeOnExternalClick="true" @update:visible="closeModal">
      <template #content>
        <CustomButton class="ml-auto mt-2 mr-2" :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="closeModal"/>
        <v-img src="@/assets/illustrations/shut_down.svg" height="80px" width="144px" class="mx-auto"/>

        <v-col class="pa-10 d-flex-col">
          <v-row class="ma-0 token-text-h5 mb-2 mx-auto">
            Undeploy cluster
          </v-row>
          <v-row class="ma-0 token-text-regular token-text-color-secondary mb-4 text-align-center mx-auto">
            Careful, all data MUST be backed-up manually before doing this action, as all stateful applications like databases will lose all data permanently.
          </v-row>
          <v-row class="ma-0 token-text-regular token-text-color-secondary text-align-center mx-auto">
            Undeploying this cluster will also undeploy all projects currently on it.
          </v-row>
          <Divider class="my-4"/> 
          <template v-if="projects?.length">
            <v-row class="ma-0 mt-2" v-for="project of projects" :key="'project-'+project.id">
              <Checkbox  v-model="checks[project.id]"/>
              <div class="token-text-regular my-auto ml-2">{{ project.attributes.name }}</div>
            </v-row>
            <v-row class="ma-0 mb-4"></v-row>
          </template>
          <v-row class="ma-0">
            <div>
              <span class="token-text-regular">
                To confirm, 
                <template v-if="projects?.length">
                  check all the projects you will undeploy and 
                </template>
                type in the name of the cluster you will undeploy:
              </span>
              <span class="ml-1 token-text-medium">{{ cluster?.attributes?.name }}</span>
            </div>
          </v-row>
          <v-row class="ma-0 mt-2 mb-10">
            <CustomTextInput v-model="confirmText" class="full-width"/>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col class="pa-0 mr-2">
              <CustomButton :level="$enums.ButtonLevels.Secondary" class="ml-auto mr-2 full-width" @click="closeModal">
                Cancel
              </CustomButton>
            </v-col>
            <v-col class="pa-0 ml-2">
              <CustomButton :level="$enums.ButtonLevels.Primary" class="full-width" @click="doPutOffline" :loading="loading" :disabled="disabled">
                Undeploy
              </CustomButton>
            </v-col>
          </v-row>
        </v-col>
        
      </template>
    </Modal>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import API from '@/api/wrapper';
import { Watch } from 'vue-property-decorator';
import { APIProject } from '@/typesAPI';
import Checkbox from '@/components/UIElements/Checkbox.vue';
import Divider from '@/components/UIElements/Divider.vue';

class Props {
  visible: boolean = prop({
    required: true,
  });
  cluster: APIProject = prop({
    required: true,
  });
  projects: APIProject[] = prop({
    required: true,
  });
}


@Options({
  components: {
    Modal,
    CustomButton,
    CustomTextInput,
    Checkbox,
    Divider
  },
})
export default class ModalClusterPutOffline extends Vue.with(Props) {
  loading = false
  confirmText = ""
  checks:{[key:string]:boolean} = {}

  get disabled():boolean {
    return this.confirmText !== this.cluster?.attributes?.name || !this.isAllCheck
  }

  get isAllCheck():boolean {
    if(!this.projects?.length) {
      return true
    }
    let ret = true
    Object.values(this.checks).forEach((val) => {
      if(!val) {
        ret = false
      }
    })

    return Object.keys(this.checks).length === this.projects?.length && ret
  }

  closeModal() {
    this.$emit('update:visible', false)
  }

  doPutOffline() {
    this.loading = true

    API.clusters.putOffline(this.cluster.id, Object.keys(this.checks))
    .then(() => {
      this.loading = false
      this.$emit('update:visible', false)
    }) 

  }

  @Watch('visible', {immediate: true})
  onVisibleChange() {
    if(this.visible) {
      this.confirmText = ""
      this.checks = {}
      this.projects?.forEach((project) => {
        this.checks[project.id] = false
      })
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
</style>
