<template>
  <div class="trash-bar bar  d-flex" :class="{deployed: isDraggingNode, hovered: isHovered}">
    <object v-if="trashOpen" tabindex="-1" type="image/svg+xml" data="/animations/trash-opening.svg" class="trash-img">
      Trash Open
    </object>

    <object v-else tabindex="-1" type="image/svg+xml" data="/animations/trash-closing.svg" class="trash-img">
      Trash Closed
    </object>

  </div>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component';

class Props {
  isHovered?: boolean = prop({
    required: false,
  });
}

export default class TrashBar extends Vue.with(Props) {
  get isDraggingNode () {
    return !!this.$store.getters['elements/getDraggingElement']
  }

  get trashOpen () :boolean {
    if ( this.isDraggingNode ) {
      if (this.isHovered) {
        return true
      } else {
        return false
      }
    } else {
      if (this.isHovered) {
        return false
      }
    }
    return true
  }
}
</script>

<style lang="scss">
.trash-bar {
  margin: -1px;
  position: absolute;
  height: 80px;
  width: 80px;
  top: 0px;
  left: 0px;
  transition: top 0.2s ease-in-out, left 0.2s ease-in-out, background 0.2s ease-in-out;

  &.deployed {
    top: -96px;
    left: -96px;
  }
  &.hovered {
    padding-top :2px !important;
    padding-left :2px !important;
    top: -96px;
    left: -96px;
    border: 4px solid #E15F19 !important;
    margin: -4px;
    height: 88px;
    width: 88px;
    background: linear-gradient(0deg, rgba(225, 95, 25, 0.08), rgba(225, 95, 25, 0.08)), #FFFFFF !important;
    .trash-img {
      margin-left: -2px;
      margin-top: -2px;
    }
  }
}
</style>