<template>
  <div class="save-bar d-flex" :class="{hideLeft: hideLeft, firstUnboardingOngoing: firstUnboardingOngoing, deployementModalOpen: isDeployementModalOpen, deployementModalReduced: isDeployementModalReduced, deployed:deployed, deploying:deploying}">
    <v-col class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <DeploymentModal/>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import DeploymentModal from '@/components/Deployment/DeploymentModal.vue'

@Options({
  components: {
    DeploymentModal
  },
})
export default class SaveBar extends Vue {
  

  // DEPLOY SIZE
  get isDeployementModalOpen ():boolean {
    return this.$store.getters['deployment/getModalIsOpen']
  }
  get isDeployementModalReduced ():boolean {
    return this.$store.getters['deployment/getModalIsReduced']
  }
  // END DEPLOY SIZE



  // FIRST UNBOARDING
  waitingForUnboarding = false
  get hideLeft () {
    return !this.$store.getters['app/getFirstUnboardingDone']
  }

  get firstUnboardingOngoing () {
    return this.$store.getters['app/getFirstUnboardingDone'] && this.waitingForUnboarding
  }

  get deploying (): boolean {
    return this.$store.getters['deployment/getDeployingState'] === this.$enums.DeploymentState.Running
  }

  get deployed (): boolean {
    return this.$store.getters['deployment/getDeployingState'] === this.$enums.DeploymentState.Completed
  }

  @Watch('hideLeft')
  onChangeHideLeft(newVal:boolean) {
    if(!newVal) {
      this.waitingForUnboarding = true
      setTimeout(() => {
        this.waitingForUnboarding = false
      },200)
    }
  }
  // END FIRST UNBOARDING
}
</script>

<style lang="scss" scoped>
.save-bar {
  width: fit-content;
  transition: left 0.2s ease-in-out 2s, opacity 0.1s ease-in-out 2s, width 0.2s ease-in-out ;

  &.firstUnboardingOngoing {
    position: absolute !important;
    left: calc(100vw - 168px) !important;
    z-index: 0 !important;
    opacity: 1;
  }
  &.hideLeft {
    z-index: 0 !important;
    position: absolute;
    opacity: 0;
    left: 18px;
  }
  // height: 56px;
}
</style>