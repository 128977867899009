import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "invisible-edge" }
const _hoisted_2 = { class: "under-edge" }
const _hoisted_3 = { class: "upper-edge" }
const _hoisted_4 = ["x", "y", "width", "height", "rx"]
const _hoisted_5 = {
  key: 0,
  class: "broken-link-icon"
}
const _hoisted_6 = ["x", "y", "width", "height"]
const _hoisted_7 = ["transform"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["transform"]
const _hoisted_10 = ["x", "y"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BezierEdge = _resolveComponent("BezierEdge")!

  return (_openBlock(), _createElementBlock("g", {
    class: _normalizeClass(["custom-edge", {selected:  _ctx.isValidationErrorSelected, hovered: _ctx.isSelected || _ctx.isValidationErrorHovered, 'in-error': _ctx.isInError}]),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onEdgeClick && _ctx.onEdgeClick(...args)))
  }, [
    _createElementVNode("g", _hoisted_1, [
      _createVNode(_component_BezierEdge, _normalizeProps(_guardReactiveProps(_ctx.computedProps)), null, 16)
    ]),
    _createElementVNode("g", _hoisted_2, [
      _createVNode(_component_BezierEdge, _normalizeProps(_guardReactiveProps(_ctx.computedProps)), null, 16)
    ]),
    _createElementVNode("g", _hoisted_3, [
      _createVNode(_component_BezierEdge, _normalizeProps(_guardReactiveProps(_ctx.computedProps)), null, 16)
    ]),
    _createElementVNode("g", {
      class: "edge-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onButtonClick && _ctx.onButtonClick(...args)))
    }, [
      _createElementVNode("rect", {
        class: "border-button",
        x: _ctx.buttonX - (_ctx.isInError ? 2 : 0),
        y: _ctx.buttonY - (_ctx.isInError ? 2 : 0),
        width: _ctx.buttonSize + (_ctx.isInError ? 4 : 0),
        height: _ctx.buttonSize + (_ctx.isInError ? 4 : 0),
        rx: _ctx.isInError ? 16 : 14
      }, null, 8, _hoisted_4),
      (_ctx.isInError)
        ? (_openBlock(), _createElementBlock("g", _hoisted_5, [
            _createElementVNode("rect", {
              x: _ctx.buttonX + 2,
              y: _ctx.buttonY + 2,
              width: _ctx.buttonSize - 4,
              height: _ctx.buttonSize - 4,
              rx: "13",
              stroke: "#E15F19",
              fill: "white"
            }, null, 8, _hoisted_6),
            _createElementVNode("g", {
              transform: 'matrix(1 0 0 1 ' + (_ctx.buttonX + _ctx.buttonSize/4 + 1)  + ' ' + (_ctx.buttonY + _ctx.buttonSize/4 + 1) +')'
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("path", {
                d: "M10 5.99992L10.6667 5.33325",
                stroke: "#E15F19",
                "stroke-width": "1.33333",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1),
              _createElementVNode("path", {
                d: "M5.33301 10.6667L5.99967 10",
                stroke: "#E15F19",
                "stroke-width": "1.33333",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1),
              _createElementVNode("path", {
                d: "M13.3947 8.68063L14.0753 7.99996C15.7527 6.32263 15.7527 3.60263 14.0753 1.92463C12.398 0.247292 9.678 0.247292 8 1.92463L7.31934 2.60529",
                stroke: "#E15F19",
                "stroke-width": "1.33333",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1),
              _createElementVNode("path", {
                d: "M2.60517 7.31934L1.9245 8C0.24717 9.67734 0.24717 12.3973 1.9245 14.0753C3.60184 15.7527 6.32184 15.7527 7.99984 14.0753L8.68051 13.3947",
                stroke: "#E15F19",
                "stroke-width": "1.33333",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1),
              _createElementVNode("path", {
                d: "M0.666504 0.666626L15.3332 15.3333",
                stroke: "#E15F19",
                "stroke-width": "1.33333",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1)
            ]), 8, _hoisted_7)
          ]))
        : (_openBlock(), _createElementBlock("g", _hoisted_8, [
            _createElementVNode("g", {
              transform: 'matrix(1 0 0 1 ' + (_ctx.buttonX - 2)  + ' ' + (_ctx.buttonY - 2) +')'
            }, [
              _cache[3] || (_cache[3] = _createElementVNode("path", {
                d: "M25.23 18.9594C25.2967 19.296 25.3333 19.644 25.3333 20C25.3333 20.356 25.2967 20.704 25.23 21.0407L26.434 22.0334C26.7687 22.3094 26.8553 22.788 26.6387 23.164L26.0593 24.1694C25.8427 24.5454 25.3853 24.71 24.9793 24.5574L23.514 24.0074C22.9947 24.4634 22.388 24.8194 21.72 25.0467L21.4633 26.5907C21.3913 27.0194 21.0213 27.3334 20.5873 27.3334H19.4287C18.9947 27.3334 18.6247 27.0194 18.5533 26.5907L18.296 25.0467C17.628 24.8194 17.0213 24.4634 16.502 24.0074L15.0367 24.5574C14.63 24.7094 14.1733 24.5454 13.9567 24.1694L13.3767 23.164C13.16 22.788 13.2467 22.3094 13.5813 22.0334L14.7853 21.0407C14.7187 20.704 14.682 20.3567 14.682 20C14.682 19.6434 14.7187 19.296 14.7853 18.9594L13.5813 17.9667C13.2467 17.6907 13.16 17.212 13.3767 16.836L13.956 15.8307C14.1727 15.4547 14.63 15.29 15.036 15.4427L16.5013 15.9927C17.0207 15.5367 17.6273 15.1807 18.2953 14.9534L18.552 13.4094C18.6247 12.9807 18.9947 12.6667 19.4287 12.6667H20.5873C21.0213 12.6667 21.3913 12.9807 21.4627 13.4094L21.7193 14.9534C22.3873 15.1807 22.9947 15.5367 23.5133 15.9927L24.9787 15.4427C25.3847 15.29 25.842 15.454 26.0587 15.8307L26.638 16.836C26.8547 17.212 26.7687 17.6907 26.4333 17.9667L25.23 18.9594Z",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1)),
              _createElementVNode("path", {
                x: _ctx.buttonX,
                y: _ctx.buttonY,
                d: "M20.0079 18C21.1125 18 22.0079 18.8954 22.0079 20C22.0079 21.1046 21.1125 22 20.0079 22C18.9034 22 18.0079 21.1046 18.0079 20C18.0079 18.8954 18.9034 18 20.0079 18Z",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, 8, _hoisted_10)
            ], 8, _hoisted_9)
          ]))
    ])
  ], 2))
}