<template>
  <div>
    <div class="custom-text-input-container d-flex"
      :class="{'has-error': error, 'has-error-fixed': false, 'has-icon': icon, disabled:disabled, disabledBox: disabledBox, searchStyle: searchStyle, plain: plain}"
    >
      <Icon v-if="icon" class="custom-text-input-icon my-auto mr-3" :icon="icon"/>
      <input 
        class="custom-text-input" 
        :class="(shareButton ? ' with-share ' :'') + (ellipsis ? ' ellipsis ' : '') + (token ? token : '')"
        :type="computedType" 
        :placeholder="placeholder"
        :value="modelValue"
        autocomplete="false"
        @input="handleInput"
        @blur="$emit('blur', modelValue)"
        @change="$emit('change')"
        @keyup.enter="$emit('blur', modelValue)"
        :maxlength="max ? max : -1"
      />
      <Icon v-if="shareButton" icon="share" class="custom-text-input-share" @click="onClickShare"/>
      <Icon v-if="type === 'password'" :icon="'eye' + (isRevealed ? '-off' : '')" class="custom-text-input-reveal" @click="onClickReveal"/>
    </div>
    <div v-if="error && !hideError" class="error-display">
      {{ error }}
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop, WithDefault } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'

class Props {
  placeholder?: string = prop({
    required: false,
  });
  type?: string = prop({
    required: false,
  });
  error?: string = prop({
    required: false,
  });
  hideError?: boolean = prop({
    required: false,
  });
  icon?: string = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  disabledBox?: boolean = prop({
    required: false,
  });
  shareButton?: boolean = prop({
    required: false,
  });
  modelValue?: string = prop({
    required: false,
  });
  searchStyle?: boolean = prop({
    required: false,
  });
  plain?: boolean = prop({
    required: false,
  });
  max?: number = prop({
    required: false,
  });
  ellipsis: WithDefault<boolean> = prop({
    required: false,
    default: true
  });
  token?: string = prop({
    required: false,
  });
}


@Options({
  components: {
    Icon,
  },
})
export default class CustomTextInput extends Vue.with(Props) {
  isRevealed = false

  handleInput (event:Event) {
    const target = event.target as HTMLInputElement
    this.$emit('update:modelValue', target?.value)
  }
  onClickShare() {
    if (this.modelValue && navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(this.modelValue).then(() => {
        this.$emit('clickShare', this.modelValue)
      })
    } else {
      this.$emit('clickShare', this.modelValue)
    }
  }

  onClickReveal() {
    this.isRevealed = !this.isRevealed
  }

  get computedType() {
    if(this.type) {
      if(this.type === 'password' && this.isRevealed) {
        return 'text'
      }
      return this.type
    }
    return 'text'
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.error-display {
  color: $color-orange;
}
.custom-text-input-container {
  position: relative;
  box-shadow: 0px 1px 4px rgba(0, 0, 58, 0.08), 0px 1px 1px -1px rgba(0, 0, 58, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding-left:12px;
  padding-right:12px;
  padding-top: 8px;
  padding-bottom: 8px;
  color:#00003A;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out, background 0.2s ease-in-out;
  border: 1px solid $color-neutral-grey-12;
  background: white;
  height: 42px;
  input {
    outline: none !important;
  }

  &.plain {
    background: none;
    border:1px transparent solid;
    box-shadow: none;
    &:hover {
      box-shadow: 0px 1px 4px rgba(0, 0, 58, 0.08), 0px 1px 1px -1px rgba(0, 0, 58, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.08);
    }
    &:focus-within {
      box-shadow: 0px 0px 0px 2px rgba(50, 25, 178, 0.24);
      padding-left:11px;
    }
  }

  &:hover {
    border: 1px solid $color-neutral-grey-30;
  }
  &:focus-within {
    transition: box-shadow 0.2s ease-in-out, background 0.2s ease-in-out;
    outline: none;
    border: 2px solid $color-brand;
    box-shadow: 0px 0px 0px 2px rgba(50, 25, 178, 0.24);
    padding-top: 8px;
    padding-left:11px;
    .custom-text-input-share {
      cursor: pointer;
      position: absolute;
      right: 13px;
      bottom: 13px;
    }
    .custom-text-input-reveal {
      cursor: pointer;
    }
  }
  &::placeholder { 
    font-weight: 400;
    font-size: 14px; 
    color: $color-neutral-grey-60;
  }

  &.has-error {
    border: 1px solid $color-orange;
    &:focus-within {
      outline: none;
      line-height: 25px;
      padding-left:12px;
      box-shadow: 0px 0px 0px 2px rgba(225, 95, 25, 0.24);
    }
  }

  &.has-error-fixed {
    border: 1px solid $color-green;
    &:focus {
      outline: none;
      line-height: 25px;
      padding-left:12px;
      box-shadow: 0px 0px 0px 2px rgba(225, 95, 25, 0.24);
    }
  }
  &.disabledBox {
    background: $color-neutral-grey-8;
    border: 1px solid $color-neutral-grey-12;
    border-radius: 8px;
    .custom-text-input {
      color: $color-neutral-grey-60;
      pointer-events: none;
    }
  }
  &.disabled {
    border: none;
    background: none;
    box-shadow: none;
    .custom-text-input {
      color: $color-neutral-grey-60;
      pointer-events: none;
    }
  }
  .custom-text-input-share {
    cursor: pointer;
    position: absolute;
    right: 14px;
    bottom: 14px;
    pointer-events: all;
  }

  .custom-text-input-reveal {
      cursor: pointer;
      pointer-events: all;
    }
  .custom-text-input-icon {
    // position: absolute;
    // top: 21px;
    // left: 22px;
  }
  .custom-text-input {
    width: 100%;
    &.with-share {
      width: calc(100% - 24px);
    }
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    &:focus {
      caret-color: $color-brand;
    }
  }

  &.searchStyle {
    background-color: $color-neutral-grey-4;
    border: 1px solid $color-neutral-grey-12;
    box-shadow: none;

    &:hover {
      border: 1px solid $color-neutral-grey-24;
      box-shadow: none;
    }
    &:focus-within {
      background-color: #FFF;
      border: 2px solid $color-brand;
      box-shadow: 0px 0px 0px 2px rgba(50, 25, 178, 0.24);
    }
  }
}

</style>
