<template>
    <div class="invisible-node">
    </div>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component';
import { NodeProps } from '@vue-flow/core'

class Props {
  props: NodeProps = prop({
    required: true,
  });
}
export default class InvisibleNode extends Vue.with(Props) {
}
</script>

<style lang="scss">
.invisible-node {
  pointer-events: none;
  height: 80px;
  width: 80px;
  border: 2px solid #326ce5;
}
</style>
