<template>
  <div class="field-box"
    :class="{disabled: disabled, deployed : computedDeployed, invisible: isInvisible}"
  >
    <div 
      class="field-box-btn-deploy"
      :class="{disabled: disabled, isCollapsible: isCollapsible}"
      @click="deploy"
    >
    <template v-if="!computedDeployed">
      <template v-if="vertical">
        <v-col class="pa-4">
          <v-row class="ma-0" :class="{'pb-4' : !isInvisible}">
            <v-col class="pa-0 ma-0 field-box-btn-deploy-text token-text-medium" :class="isInvisible ? 'token-text-micro token-text-transform-uppercase' : ''" cols="11">
              {{title}}
            </v-col>
            <v-col align="right" v-if="!disabled && isCollapsible" cols="1" class="pa-0 ma-0 pl-2 field-box-btn-deploy-icon" >
              <Icon icon="caret-down"/>
            </v-col>
          </v-row>
          <v-row class="ma-0" v-if="!isInvisible">
            <v-col class="pa-0">
              <div class="field-box-value" >
                <slot name="value" ></slot>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-else>
        <v-col class="pa-4 field-box-summary">
          <v-row class="ma-0">
            <v-col class="pa-0 ma-0 field-box-btn-deploy-text token-text-medium fit-content" ref="rowTitle" :class="isInvisible ? 'token-text-micro token-text-transform-uppercase' : ''" cols="11">
              {{title}}
            </v-col>
            <v-col  class="pa-0 ma-0 ml-auto field-box-btn-deploy-item-selected fit-content" ref="rowValue">
              <div class="field-box-value" v-if="!isLongValue">
                <slot name="value" ></slot>
              </div>
            </v-col>
            <v-col  v-if="!disabled && isCollapsible" cols="1" class="pa-0 ma-0 field-box-btn-deploy-icon pl-2">
              <Icon icon="caret-down"/>
            </v-col>
          </v-row>
          <v-row class="ma-0 pt-4" v-if="isLongValue && !isInvisible">
            <v-col class="pa-0">
              <div class="field-box-value">
                <slot name="value" ></slot>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </template>
    <template v-else>
      <v-col class="pa-0 field-box-btn-deploy-text token-text-medium fit-content" :class="isInvisible ? 'token-text-micro token-text-transform-uppercase token-text-color-secondary ' : ''" cols="11">
        {{title}}
      </v-col>
      <v-col align="right" class="pa-0 field-box-btn-deploy-item-selected fit-content">
        <div class="field-box-value">
          &nbsp;
        </div>
      </v-col>
      <v-col align="right" v-if="!disabled && isCollapsible" cols="1" class="pa-0 field-box-btn-deploy-icon" >
        <Icon icon="caret-down"/>
      </v-col>
    </template>

    </div>
    <HeightDeployer v-model="deployed" v-model:forceDeployed="forceDeployed" class="deployed-container" @expand="$emit('update:forceDeployed', undefined)" @collapse="$emit('update:forceDeployed', undefined)">
      <slot name="deployed"></slot>
    </HeightDeployer>
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop, WithDefault } from 'vue-class-component'
import HeightDeployer from '@/components/UIElements/HeightDeployer.vue'
import Icon from '@/components/UIElements/Icon.vue'
import { APIFieldBox, APIFieldValue } from '@/typesAPI'
import { Watch } from 'vue-property-decorator';
import { nextTick } from 'vue';
import { TreePart } from '@/types';

class Props {
  title: string = prop({
    required: true,
  });
  forceDeployed?: boolean = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  deployed: boolean = prop({
    required: true,
  });
  vertical?: boolean = prop({
    required: false,
  });
  box?: APIFieldBox&TreePart = prop({
    required: false,
  });
}

@Options({
  components: {
    HeightDeployer,
    Icon
  },
})
export default class FieldBox extends Vue.with(Props) {

  maxWidthSoloValue = 370
  isLongValue = false

  get isCollapsible(): boolean {
    return !!this.box?.attributes.isCollapsible
  }

  get computedDeployed():boolean {
    if(this.box) {
      if(!this.isInvisible) {
        return this.openedBoxId === this.box.id
      } else {
        return this.isCollapsible ? !!this.deployed : true
      }
    }
    return !!this.deployed
  }

  get openedBoxId():string {
    return this.$route.params.boxId as string
    // return this.$store.getters['fields/getOpenedBoxId']
  }

  @Watch('openedBoxId')
  onOpenedBoxIdChange () {
    this.$emit('update:deployed', this.computedDeployed)
  }

  mounted() {
    this.$emit('update:deployed', false)
    setTimeout(() => {
      this.$emit('update:deployed', this.computedDeployed)
      this.checkLongValue()
    },100)
  }

  @Watch('computedDeployed')
  onRefRowValueChange(newVal:boolean, oldVal:boolean) {
    if(!newVal && oldVal) {
      nextTick(() => {
        this.checkLongValue()
      })
    }
  }

  @Watch('fieldsValues', {deep:true})
  onFieldsValuesChange(newVal:boolean, oldVal:boolean) {
    if(!this.deployed) {
      setTimeout(() => {
        this.checkLongValue()
      },50)
    }
  }

  get fieldsValues ():APIFieldValue[] {
    return this.$store.getters['fields/getListValues']
  }

  get isInvisible(): boolean {
    return this.box?.attributes ? this.box?.attributes?.isInvisible : false
  }

  checkLongValue() {
    if(this.box?.childsByPriority && this.box?.childsByPriority[0]?.attributes?.key === 'url') {
      this.isLongValue = true;
      return;
    }

    if(!this.vertical) {
      this.isLongValue = false
      if(this.$refs.rowValue && this.$refs.rowTitle) {
        // @ts-ignore
        if(this.$refs.rowValue.$el.getBoundingClientRect().width + this.$refs.rowTitle.$el.getBoundingClientRect().width > this.maxWidthSoloValue) {
          this.isLongValue = true
        } else {
          this.isLongValue = false
        }
      }
    }
  }

  deploy() {
    if(!this.disabled) {
      if(this.computedDeployed) {
        this.collapse()
      } else {
        this.expand()
      }
    }
  }

  collapse() {
    if(this.isCollapsible) {
      if(this.box && !this.isInvisible) {
        this.$router.push({
          name: 'flowBlock',
          params: {
            ...this.$route.params,
          }
        });
      }

      this.$emit('update:deployed', false)
    }
  }

  expand() {
    if(this.box && !this.isInvisible) {
      this.$router.push({
        name: 'flowBlockAndBox',
        params: {
          ...this.$route.params,
          boxId: this.box.id
        }
      });
    }
    this.$emit('update:deployed', true)
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
.field-box {
  margin: 8px;
  background: #FFFFFF;
  border: 1px solid $color-neutral-grey-12;
  border-radius: 8px;
  min-height: 40px;
  transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  overflow: hidden;

  .field-box-summary {
    transition: opacity 0.2s ease-in-out;
  }
  .deployed-container {
    margin: 16px;
    &.deployed {
      margin: 8px !important;
      padding: 8px !important;
    }
  }
  .field-box-btn-deploy {
    width: 100%;
    overflow: hidden;
    padding: 0;
    justify-content: flex-start;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;

    
    &.disabled {
      pointer-events: none;
      cursor: inherit;
      background: $color-neutral-grey-8 !important;
      color: $color-neutral-grey-60 !important;
      .field-box-btn-deploy-item-selected {
        color:black;
      }
    }
    .field-box-btn-deploy-text {
      margin-top:auto;
      margin-bottom:auto;
      margin-left: 16px;
      margin-right: auto;
      color: $color-neutral-grey-60;
      transition: color 0.2s ease-in-out;
    }
    .field-box-btn-deploy-item-selected {
      margin-top:16px;
      margin-bottom:16px;
      margin-right: 16px;
      margin-left: auto;
      color: $color-neutral-black;
      .field-box-value {
        width: 100%;
      }
    }
    .field-box-btn-deploy-icon {
      display: flex;
      border-radius: 8px;
      width: 24px;
      height: 24px;
      margin-top:auto;
      margin-bottom:auto;
      margin-left: auto;
      margin-right:12px;
      opacity: 0.6;
      transition: opacity 0.2s ease-in-out;
    }
  }
  &:hover {
    &:not(.disabled) {
      &:not(.deployed) {
        &.isCollapsible {
          border: 1px solid $color-neutral-grey-30;
          box-shadow: $shadow-z2;
        }
        &:not(.isCollapsible) {
          cursor: auto;
        }
      }
    }
  }
  &.deployed {
    border: 1px solid $color-brand;
    box-shadow: $shadow-z1;

    .field-box-btn-deploy {
      transition: background 0.2s ease-in-out;
      &:hover {
        &.isCollapsible {
          background: $color-background-button-text-tertiary-hover;
        }
        &:not(.isCollapsible) {
          cursor: auto;
        }
      }
      &:active {
        background: $color-background-button-text-tertiary-pressed;
      }
    }

    .field-box-btn-deploy-icon {
      opacity: 1;
    }
    .field-box-btn-deploy-text {
      color: $color-neutral-black;
    }
  }

  &.invisible {
    border: none !important;
    box-shadow: none !important;
    .field-box-btn-deploy {
      transition: background 0.2s ease-in-out;
      &:hover {
        &.isCollapsible {
          background: $color-background-button-text-tertiary-hover;
        }
        &:not(.isCollapsible) {
          cursor: auto;
        }
      }
      &:active {
        background: $color-background-button-text-tertiary-pressed;
      }
    }
    .deployed-container {
      &.deployed {
        margin: 0px !important;
        padding: 8px !important;
      }
  }
  }
}
</style>
<style lang="scss">
@import '@/css/variables';

.field-box { 
  .field-box-btn-deploy {
    .field-box-btn-deploy-icon {
      .icon-container {
        transition: transform 0.2s ease-in-out;
        transform: rotate(-90deg);
      }
      .icon {
        background: $color-neutral-grey-60 !important;
      }
    }
  }
  &.deployed {
    .field-box-btn-deploy-icon {
      .icon-container {
        transform: rotate(0deg);
      }
    }
  }
}
</style>