<template>
    <Modal :visible="visible" :closeOnExternalClick="true" @update:visible="closeModal" :fullScreen="fullScreen">
      <template #headerFullScreen v-if="fullScreen">
        <v-row class="ma-0 my-auto mx-12">
          <v-col class="pa-0 fit-content my-auto" style="position: absolute; top: 60px;">
            <CustomButton text="Back" icon="caret-left" :level="$enums.ButtonLevels.Secondary" @click="closeModal"/>
          </v-col>
          <v-col class="pa-0">
            <FransysSignature :vertical="true" class="ma-auto"/>
          </v-col>
        </v-row> 
      </template>
      <template #header v-if="fullScreen">
        <v-row class="ma-0 token-text-h4">
          <div>
            Add payment method
          </div>
        </v-row>      
      </template>
      <template #content>
        <v-col class="pa-4">
          <template v-if="showEditBillingDetails">
            <WidgetBillingDetails :customer="customer" @success="onEditBillingDetails"/>
          </template>
          <template v-else-if="showAddOtherPaymentMethod">
            <StripeWidgetPaymentMethods :customer="customer" :showCancel="true" @success="onAddPaymentMethod" @cancel="showAddOtherPaymentMethod = false"/>
          </template>
          <template v-else>
            
            <!-- PAYEMENT METHODS -->
            <v-row class="ma-0 token-text-medium mb-2">
              Payment methods
            </v-row>
            <v-row class="ma-0">
              <PaymentMethodsDisplay :paymentMethods="paymentMethods" :selectable="true" v-model:selected="paymentMethodSelected"/>
            </v-row>
            <v-row class="ma-0 mb-4">
              <!-- ADD PAYEMENT METHODS -->
              <CustomButton text="Add payment method" :level="$enums.ButtonLevels.Secondary" @click="onClickAddOtherPaymentMethod" />
            </v-row>
            
            <!-- CTA -->
            <v-row class="ma-0 mt-4">
              <CustomButton :text="loadingModalCTA ? '' : 'Validate payment methods'" :level="$enums.ButtonLevels.Primary" class="full-width" @click="onValidate" :loading="loadingModalCTA" :disabled="!paymentMethodSelected"/>
            </v-row>      
          </template>
        </v-col>
      </template>
    </Modal>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import PaymentMethodsDisplay from '@/components/UIElements/PaymentMethodsDisplay.vue'
import { APICustomer, APIPaymentMethod } from '@/typesAPI';
import { Watch } from 'vue-property-decorator'
import StripeWidgetPaymentMethods from '@/components/UIElements/Widget/StripeWidgetPaymentMethods.vue'
import WidgetBillingDetails from '@/components/UIElements/Widget/WidgetBillingDetails.vue';
import FransysSignature from '@/components/UIElements/FransysSignature.vue'

class Props {
  visible: boolean = prop({
    required: true,
  });
  fullScreen?: boolean = prop({
    required: false,
  });
}


@Options({
  components: {
    Modal,
    CustomButton,
    PaymentMethodsDisplay,
    StripeWidgetPaymentMethods,
    WidgetBillingDetails,
    FransysSignature,
  },
})
export default class ModalValidatePaymentMethodList extends Vue.with(Props) {
    loadingModalCTA = false
    paymentMethodSelected:APIPaymentMethod | null = null
    showAddOtherPaymentMethod = false
    showEditBillingDetails = false

    get customer():APICustomer {
      return this.$store.getters['user/getCustomer']
    }

    get paymentMethods():APIPaymentMethod[] {
      return this.$store.getters['billing/getPaymentMethods']
    }

    onAddPaymentMethod() {
      this.showAddOtherPaymentMethod = false
    }

    onEditBillingDetails() {
      this.showEditBillingDetails = false
    }

    @Watch('visible', {immediate: true})
    onVisibleChange() {
      if(this.visible) {
        this.loadingModalCTA = false
        this.showAddOtherPaymentMethod = false
        this.showEditBillingDetails = false
        
        if(!this.customer?.attributes?.country) {
          this.showEditBillingDetails = true
        }
      }
    }

    onValidate() {
      this.loadingModalCTA = true
      this.$emit('success')
    }

    onClickAddOtherPaymentMethod() {
      this.showAddOtherPaymentMethod = true
    }

    closeModal() {
      this.$emit('update:visible',false)
      this.loadingModalCTA = false
      this.showAddOtherPaymentMethod = false
      this.showEditBillingDetails = false
    }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
</style>
