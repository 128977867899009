import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contact-us-bar bar pa-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CustomButton, {
      level: _ctx.$enums.ButtonLevels.Tertiary,
      onClick: _ctx.toggleDropDown,
      ref: "menuButton"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "mx-n2" }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, {
              icon: "chat",
              class: "mr-2"
            }),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "my-auto" }, " Contact us ", -1)),
            _createVNode(_component_Icon, {
              icon: "caret-down",
              class: _normalizeClass(["ml-2 caret", {invert : _ctx.opened}])
            }, null, 8, ["class"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["level", "onClick"]),
    _createVNode(_component_DropdownMenu, {
      items: _ctx.items,
      show: _ctx.opened,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.opened) = $event)),
      modelValue: _ctx.modelValue,
      maxContent: true,
      "onUpdate:modelValue": _ctx.onModelValueUpdate,
      target: _ctx.$refs.menuButton,
      classInternal: "my-4"
    }, null, 8, ["items", "show", "modelValue", "onUpdate:modelValue", "target"])
  ]))
}