import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    visible: _ctx.visible,
    closeOnExternalClick: true,
    "onUpdate:visible": _ctx.closeModal,
    width: "400px"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_v_row, { class: "ma-0" }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("div", null, " Rename ", -1)),
          _createVNode(_component_CustomButton, {
            class: "ml-auto",
            level: _ctx.$enums.ButtonLevels.SmallIcon,
            icon: "x",
            onClick: _ctx.closeModal
          }, null, 8, ["level", "onClick"])
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_CustomTextInput, {
        modelValue: _ctx.newName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newName) = $event)),
        class: "ma-6 mb-4",
        placeholder: 'New Name',
        max: "30"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_v_row, { class: "pa-4 ma-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { class: "pa-0 mx-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_CustomButton, {
                level: _ctx.$enums.ButtonLevels.Secondary,
                class: "ml-auto mr-2 full-width",
                onClick: _ctx.closeModal
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" Cancel ")
                ])),
                _: 1
              }, 8, ["level", "onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { class: "pa-0 mx-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_CustomButton, {
                level: _ctx.$enums.ButtonLevels.Primary,
                class: "full-width",
                onClick: _ctx.doRename,
                loading: _ctx.loadingRename
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Save ")
                ])),
                _: 1
              }, 8, ["level", "onClick", "loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "onUpdate:visible"]))
}