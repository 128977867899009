<template>
  <span></span>
</template>

<script setup lang="ts">
  import { defineProps, toRefs, defineEmits} from 'vue'

  const props = defineProps({
    activated: Boolean
  })
  let { activated } = toRefs(props);
  const $emit = defineEmits(['KCActivation'])


  let currentCode: string[] = []
  const successCode: string[] = ["ArrowUp", "ArrowUp", "ArrowDown", "ArrowDown", "ArrowLeft", "ArrowRight", "ArrowLeft", "ArrowRight", "b", "a", "Enter"]
  let lastInputDate: Date = new Date()
  const maxDelayBetweenInputs = 1000 //ms

  function watchInputs () {
    const html:HTMLHtmlElement = document.getElementsByTagName('html')[0]
    html.addEventListener('keydown', (e:KeyboardEvent) => {
      if(!activated.value) {
        const newInputDate = new Date()
        if(newInputDate.getTime() - lastInputDate.getTime() > maxDelayBetweenInputs) {
          currentCode = []
        }
        lastInputDate = newInputDate
        currentCode.push(e.key)

        if(currentCode.join('').includes(successCode.join(''))) {
          $emit('KCActivation')
        }
      }
    })
  }
  watchInputs()
</script>

