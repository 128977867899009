<template>
  <v-col class="custom-table full-view pa-0 flex-wrap" >
    <v-row class="ma-0 full-width token-text-medium" v-if="headers">
      <v-col :cols="12/headers.length" class="pa-0" v-for="(header, index) of headers" :key="'header-'+index">
        <slot name="header" :header="header" :index="index"  v-if="$slots['header']" /> 
        <template v-else>
          {{ header }}
        </template>
      </v-col>
    </v-row>
    <v-row class="ma-0 mb-2 mt-2 full-width" v-if="headers">
        <v-col class="pa-0">
          <Divider />
        </v-col>
      </v-row>
    <v-row class="ma-0 full-width token-text-medium mb-2" v-for="(row, indexR) of values" :key="'row-'+indexR">
      <v-col class="pa-0 d-flex" v-for="(val, indexC) of row" :class="(colClasses && colClasses[indexC]) ? colClasses[indexC] : ''" :key="'cell-'+indexR+'-'+indexC" >
        <slot name="value" :value="val" :row="indexR" :col="indexC" v-if="$slots['value']"></slot> 
        <template v-else>
          {{ val }}
        </template>
      </v-col>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component';
import Divider from '@/components/UIElements/Divider.vue';

class Props {
  headers?: any[] = prop({
    required: false,
  });
  values: any[][] = prop({
    required: true,
  });
  colClasses?: string[][] = prop({
    required: false,
  });
}

@Options({
  components: {
    Divider,
  },
})
export default class Table extends Vue.with(Props) {
 
}
</script>

<style lang="scss">
@import '@/css/variables';

</style>
