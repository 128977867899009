<template>
  <div class="custom-select" ref="customSelect">
     <!-- Opened Part -->
    <DropdownMenu 
      :items="items"
      v-model:show="opened"
      :modelValue="modelValue"
      :displaySearch="displaySearch"
      :query="query"
      @update:modelValue="onModelValueUpdate"
      @update:query="$emit('update:query',$event)"
      :width="dropdownMenuWidth + 'px'"
      :loading="loading"
      :target="$refs.inputMenu"
    >
      <template #footer v-if="$slots['footer']">
        <slot name="footer"></slot>
      </template>
    </DropdownMenu>
    <div class="custom-select-input" @click="onOpenClick" :class="{disabled:disabled}" ref="inputMenu">
      <!-- Closed Part -->
      <v-row v-if="modelValue && modelValue.text" class="value my-auto ml-3">
        <div v-if="modelValue?.icon || modelValue?.logo || modelValue?.flag" class="fit-content my-auto mr-2">
          <Logo v-if="modelValue?.logo" :logo="modelValue?.logo" />
          <Icon v-else-if="modelValue?.icon" :icon="modelValue?.icon"/>
          <Flag v-else-if="modelValue?.flag" :code="modelValue?.flag"/>
        </div>
        <div>
          {{ modelValue.text }}
        </div>
      </v-row>
      <span v-else class="placeholder my-auto ml-3">
        {{ placeholder }}
      </span>
      <span class="carret my-auto mr-2 ml-auto">
        <Icon icon="caret-down"/>
      </span>
    </div>
  </div>
</template>


<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component';
import { ListItem } from '@/components/UIElements/List.vue';
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'
import Flag from '@/components/UIElements/Flag.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import { Watch } from 'vue-property-decorator';

export class Props {
  placeholder?: string = prop({
    required: false,
  });
  items?: ListItem[] = prop({
    required: false,
  });
  query?: string = prop({
    required: false,
  });
  loading?: boolean = prop({
    required: false,
  });
  modelValue?: ListItem = prop({
    required: false,
  });
  displaySearch?: boolean = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon,
    Logo,
    Flag,
    CustomTextInput,
    DropdownMenu
  },
})
export default class CustomSelect extends Vue.with(Props) {
  opened = false;
  dropdownMenuWidth = '150'
  dropdownMenuTop = '0'
  onModelValueUpdate(newValue:ListItem) {
    this.opened = false
    this.$emit('update:modelValue', newValue)
  }

  mounted(): void {
    // TODO: Find a way to remove the timeout and base on the update of the HTML
    setTimeout(() => {
     this.setWidth()
     this.setTop()
    },100)
  }

  onOpenClick(): void {
    if(!this.disabled) {
      this.opened = true
    }
  }

  setWidth() {
    const DOMelem = this.$refs.customSelect as HTMLElement
    if(DOMelem) {
      this.dropdownMenuWidth = DOMelem.getBoundingClientRect().width.toString()
    }
  }
  setTop() {
    const DOMelem = this.$refs.customSelect as HTMLElement
    if(DOMelem) {
      const offsetParent = DOMelem.offsetParent as HTMLElement
      this.dropdownMenuTop = (DOMelem.getBoundingClientRect().top - offsetParent.getBoundingClientRect().top).toString()
    }
  }

  @Watch('items', {immediate: true})
  onItemsChange() {
    this.setWidth()
  }

  @Watch('opened', {immediate: true})
  onOpenChange() {
    this.setWidth()
    this.setTop()
  }

}



</script>

<style lang="scss" scoped>
@import '@/css/variables';
.custom-select {
  width: 100%;
  cursor: pointer;
  .custom-select-input {
    width: 100%;
    height: 42px;
    border: 1px solid $color-neutral-grey-8;
    border-radius: 8px;
    display: flex;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 2px 0px 0px rgba(255, 255, 255, 0.34) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
    .placeholder {
      color: $color-neutral-grey-60;
      user-select: none;
    }
    .value {
      color: black;
      user-select: none;
    }
    .carret {

    }

    &.disabled {
      cursor: default;
      background-color:$color-neutral-grey-4;
      box-shadow: none;
    }
  }
  .expanded-select {
    position: absolute;
    background: white;
    width: 100%;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12), 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(8px);
    border-radius: 8px;
    border: 1px solid $color-neutral-grey-8;
    z-index:100000000000;
    .search-container {
      border-bottom: 1px solid $color-neutral-grey-8;
    }
    .expanded-select-item {

    }
  }
}
</style>