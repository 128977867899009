import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "projectSneakPeek" }
const _hoisted_2 = {
  class: "grid-snap-hightlight",
  ref: "gridSnapHightlightRef"
}
const _hoisted_3 = { class: "token-text-medium my-auto" }
const _hoisted_4 = { class: "ml-auto mr-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalRenameRessource = _resolveComponent("ModalRenameRessource")!
  const _component_ModalProjectPutOffline = _resolveComponent("ModalProjectPutOffline")!
  const _component_ModalProjectDelete = _resolveComponent("ModalProjectDelete")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_BlockNode = _resolveComponent("BlockNode")!
  const _component_CustomEdge = _resolveComponent("CustomEdge")!
  const _component_Background = _resolveComponent("Background")!
  const _component_VueFlow = _resolveComponent("VueFlow")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ModalRenameRessource, {
      target: _ctx.project,
      visible: _ctx.showModalRenameProject,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showModalRenameProject) = $event))
    }, null, 8, ["target", "visible"]),
    _createVNode(_component_ModalProjectPutOffline, {
      project: _ctx.project,
      visible: _ctx.showModalProjectPutOffline,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModalProjectPutOffline) = $event))
    }, null, 8, ["project", "visible"]),
    _createVNode(_component_ModalProjectDelete, {
      project: _ctx.project,
      visible: _ctx.showModalProjectDelete,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showModalProjectDelete) = $event))
    }, null, 8, ["project", "visible"]),
    _createVNode(_component_v_col, { class: "pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, {
          class: _normalizeClass(["content ma-0", {loading : _ctx.loading}]),
          onClick: _ctx.onClick
        }, {
          default: _withCtx(() => [
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_Spinner, {
                  key: 0,
                  class: "ma-auto"
                }))
              : (_openBlock(), _createBlock(_component_VueFlow, {
                  key: 1,
                  class: "viewFlowSneakPeek",
                  modelValue: _ctx.elements,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.elements) = $event)),
                  "min-zoom": 0.2,
                  "select-nodes-on-drag": false,
                  "delete-key-code": null,
                  "fit-view-on-init": true,
                  "prevent-scrolling": true,
                  "zoom-on-scroll": false,
                  "zoom-on-pinch": false,
                  "zoom-on-double-click": false,
                  "pan-on-drag": false,
                  onDragover: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
                  onDragenter: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"])),
                  ref: "vueFlowRef",
                  "connection-line-style": {stroke: 'rgba(50, 25, 178, 1)', 'stroke-width' : '2px'}
                }, {
                  "node-block": _withCtx((props) => [
                    _createVNode(_component_BlockNode, {
                      props: props,
                      forcedBlockAPIList: _ctx.blocks,
                      forcedNodesList: _ctx.elements,
                      noStatus: true
                    }, null, 8, ["props", "forcedBlockAPIList", "forcedNodesList"])
                  ]),
                  "edge-link": _withCtx((props) => [
                    _createVNode(_component_CustomEdge, { props: props }, null, 8, ["props"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_Background, {
                      variant: _ctx.BackgroundVariant.Dots,
                      gap: 12
                    }, null, 8, ["variant"]),
                    _createElementVNode("div", _hoisted_2, null, 512)
                  ]),
                  _: 1
                }, 8, ["modelValue"]))
          ]),
          _: 1
        }, 8, ["onClick", "class"]),
        _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.project.attributes.name), 1),
            _createVNode(_component_Badge, {
              class: "ml-2 my-auto",
              label: _ctx.$helpers.states.translateDeploymentStatusToLabel(_ctx.project?.attributes?.status),
              severity: _ctx.$helpers.states.translateDeploymentStatusToSeverity(_ctx.project?.attributes?.status),
              icon: _ctx.project?.attributes?.status === _ctx.$enums.DeploymentStatus.DEPLOYING || _ctx.project?.attributes?.status === _ctx.$enums.DeploymentStatus.SHUTTING_DOWN ? 'spinner' : '',
              iconColor: "rgba(0, 0, 58, 1)"
            }, null, 8, ["label", "severity", "icon"]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_CustomButton, {
                ref: "menuButton",
                class: "px-3 my-auto button-burger",
                level: _ctx.$enums.ButtonLevels.Tertiary,
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.burgerOpened[_ctx.project.id] = !_ctx.burgerOpened[_ctx.project.id])),
                icon: "burger"
              }, null, 8, ["level"]),
              _createVNode(_component_DropdownMenu, {
                items: _ctx.burgerItems,
                show: _ctx.burgerOpened[_ctx.project.id],
                "onUpdate:show": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.burgerOpened[_ctx.project.id]) = $event)),
                maxContent: true,
                "onUpdate:modelValue": _ctx.onBurgerMenuItemClick,
                target: _ctx.$refs.menuButton
              }, null, 8, ["items", "show", "onUpdate:modelValue", "target"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}