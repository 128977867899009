import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "list" }
const _hoisted_2 = {
  key: 0,
  class: "list-btn-deploy-text"
}
const _hoisted_3 = {
  key: 0,
  class: "no-result ma-auto token-text-medium"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ListElement = _resolveComponent("ListElement")!
  const _component_HeightDeployer = _resolveComponent("HeightDeployer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["list-btn-deploy", {deployable: _ctx.deployable, noTitle: !_ctx.title, disabled:_ctx.disabled}]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deploy && _ctx.deploy(...args)))
    }, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      (_ctx.deployable)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["list-btn-deploy-icon", {deployed : _ctx.deployed}])
          }, [
            _createVNode(_component_Icon, { icon: "caret-down" })
          ], 2))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_HeightDeployer, {
      modelValue: _ctx.deployed,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deployed) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["list-items", {deployed : _ctx.deployed}]),
          ref: "listItems"
        }, [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(3, (i) => {
                return _createVNode(_component_ListElement, {
                  skeleton: true,
                  key: 'ListElementSkeleton-'+i
                })
              }), 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (!_ctx.internalItems.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, " No result found "))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.internalItems, (item, index) => {
                      return (_openBlock(), _createBlock(_component_ListElement, {
                        key: ('list-item-' + _ctx.title + '-' + index),
                        selected: item.selected,
                        "onUpdate:selected": ($event: any) => ((item.selected) = $event),
                        text: item.text,
                        onClick: ($event: any) => (_ctx.selectItem(item)),
                        icon: item.icon,
                        logo: item.logo,
                        flag: item.flag,
                        subtext: item.subtext,
                        iconSubtext: item.iconSubtext,
                        badgeLabel: item.label,
                        iconRigth: item.showArrow ? 'arrow-right' : '',
                        class: _normalizeClass(item.class),
                        separator: item.separator,
                        disabled: _ctx.disabled || item.disabled
                      }, null, 8, ["selected", "onUpdate:selected", "text", "onClick", "icon", "logo", "flag", "subtext", "iconSubtext", "badgeLabel", "iconRigth", "class", "separator", "disabled"]))
                    }), 128))
              ], 64))
        ], 2)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}