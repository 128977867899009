<template>
    <v-col class="country-select full-width pa-0">
      <v-row class="ma-0 token-text-regular">
        Country
      </v-row>
      <v-row class="ma-0 mt-2 token-text-regular">
        <CountrySelect v-model="privateCountry" :country="privateCountry" topCountry="US" :usei18n="false" @update:modelValue="$emit('update:country', $event)"/>
      </v-row>
      <template v-if="useRegion">
        <v-row class="ma-0 token-text-regular mt-4">
          Region
        </v-row>
        <v-row class="ma-0 mt-2 token-text-regular">
          <RegionSelect v-model="privateRegion" :country="privateCountry" :region="privateRegion" :usei18n="false" @update:modelValue="$emit('update:region', $event)"/>
        </v-row>
      </template>
    </v-col>
</template>

<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
//@ts-ignore
import { CountrySelect, RegionSelect }  from 'vue3-country-region-select'

class Props {
  country: string = prop({
    required: true,
  });
  region?: string = prop({
    required: false,
  });
  useRegion?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    CountrySelect,
    RegionSelect,
  },
})
export default class CustomCountrySelect extends Vue.with(Props) {
  privateCountry = ''
  privateRegion = ''

  @Watch('country', {immediate:true})
  onCountryChange() {
    if(this.country) {
      this.privateCountry = this.country
    }
  }

  @Watch('region', {immediate:true})
  onRegionChange() {
    if(this.region) {
      this.privateRegion = this.region
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

:deep(select) {
  width: 100%;
  height: 42px;
  border: 1px solid $color-neutral-grey-8;
  border-radius: 8px;
  padding-left: 12px;
  display: flex;
  box-shadow: 0px 1px 4px rgba(0, 0, 58, 0.08), 0px 1px 1px -1px rgba(0, 0, 58, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.2s ease-in-out, background 0.2s ease-in-out;

  &:hover {
    border: 1px solid $color-neutral-grey-30;
  }

  &:focus-within {
    transition: box-shadow 0.2s ease-in-out, background 0.2s ease-in-out;
    outline: none;
    padding-left: 11px;
    border: 2px solid $color-brand;
    box-shadow: 0px 0px 0px 2px rgba(50, 25, 178, 0.24);
  }
}
</style>
