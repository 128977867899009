import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 3,
  class: "ml-auto mr-0"
}
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Flag = _resolveComponent("Flag")!

  return (_openBlock(), _createBlock(_component_v_row, {
    class: _normalizeClass(["list-element", {'list-element-selected' : _ctx.selected, 'no-hover': _ctx.noHover || _ctx.selected || _ctx.separator || _ctx.disabled, 'dense' : _ctx.dense, skeleton:_ctx.skeleton, 'separator': _ctx.separator, 'disabled': _ctx.disabled, 'disabled-but-colored': _ctx.disabledButColored}]),
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      (_ctx.skeleton)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_v_col, { class: "list-element-icon-col pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "ma-0 list-element-icon skeleton-bg" }),
                _createVNode(_component_v_row, { class: "ma-0 mt-1 token-text-micro" })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "ma-0 text skeleton-bg" }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("   ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_row, { class: "ma-0 mt-1 subtext" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { class: "pa-0 subtext1 skeleton-bg mr-4" }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("   ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { class: "pa-0 subtext2 skeleton-bg" }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("   ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 64))
        : (_ctx.separator)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (_ctx.warningLevel)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    class: "list-element-warning-col pa-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Badge, { severity: _ctx.warningLevel }, null, 8, ["severity"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.withIconOrLogo)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 1,
                    class: _normalizeClass(["list-element-icon-col pa-0", (_ctx.flag ? 'flag' : '')])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "ma-0 list-element-icon token-text-regular" }, {
                        default: _withCtx(() => [
                          (_ctx.logo)
                            ? (_openBlock(), _createBlock(_component_Logo, {
                                key: 0,
                                logo: _ctx.logo,
                                color: _ctx.selected ? 'white' : '',
                                class: "my-auto"
                              }, null, 8, ["logo", "color"]))
                            : (_ctx.icon)
                              ? (_openBlock(), _createBlock(_component_Icon, {
                                  key: 1,
                                  icon: _ctx.icon,
                                  class: "my-auto",
                                  color: _ctx.selected ? 'white' : ''
                                }, null, 8, ["icon", "color"]))
                              : (_ctx.flag)
                                ? (_openBlock(), _createBlock(_component_Flag, {
                                    key: 2,
                                    code: _ctx.flag,
                                    class: "my-auto"
                                  }, null, 8, ["code"]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      (_ctx.subtext)
                        ? (_openBlock(), _createBlock(_component_v_row, {
                            key: 0,
                            class: "ma-0 mt-1 token-text-micro"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["class"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_col, { class: "pa-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, {
                    class: _normalizeClass(["ma-0 token-text-regular text", {isURL : _ctx.isURL}]),
                    onClick: _ctx.onTextClick
                  }, {
                    default: _withCtx(() => [
                      (_ctx.text)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["text", _ctx.alignRight ? 'ml-auto' : ''])
                          }, _toDisplayString(_ctx.text), 3))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
                          ], 64)),
                      (_ctx.badgeLabel && !_ctx.$slots['badge'])
                        ? (_openBlock(), _createBlock(_component_Badge, {
                            key: 2,
                            class: "ml-auto mr-0",
                            severity: _ctx.badgeState ? _ctx.badgeState : _ctx.$enums.Severity.NEUTRAL,
                            label: _ctx.badgeLabel,
                            outlined: _ctx.badgeOutlined,
                            icon: _ctx.badgeIcon
                          }, null, 8, ["severity", "label", "outlined", "icon"]))
                        : _createCommentVNode("", true),
                      (_ctx.$slots['badge'])
                        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                            _renderSlot(_ctx.$slots, "badge", {}, undefined, true)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.iconRigth)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 4,
                            class: _normalizeClass('my-auto ' + (_ctx.badgeLabel || _ctx.isURL ? 'ml-3' : 'ml-auto') + (_ctx.isURL ? ' url-share' : ''))
                          }, [
                            _createVNode(_component_Icon, { icon: _ctx.iconRigth }, null, 8, ["icon"])
                          ], 2))
                        : _createCommentVNode("", true)
                    ]),
                    _: 3
                  }, 8, ["class", "onClick"]),
                  (_ctx.subtext)
                    ? (_openBlock(), _createBlock(_component_v_row, {
                        key: 0,
                        class: "ma-0 mt-1 subtext token-text-micro"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            (_ctx.iconSubtext)
                              ? (_openBlock(), _createBlock(_component_Icon, {
                                  key: 0,
                                  class: "list-element-icon-col ml-0",
                                  icon: _ctx.iconSubtext
                                }, null, 8, ["icon"]))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", { innerHTML: _ctx.subtext }, null, 8, _hoisted_3)
                          ])
                        ]),
                        _: 1
                      }))
                    : (_ctx.$slots['subtext'])
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 1,
                          class: "ma-0 mt-1 subtext token-text-micro"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_4, [
                              (_ctx.iconSubtext)
                                ? (_openBlock(), _createBlock(_component_Icon, {
                                    key: 0,
                                    class: "list-element-icon-col ml-0",
                                    icon: _ctx.iconSubtext
                                  }, null, 8, ["icon"]))
                                : _createCommentVNode("", true),
                              _renderSlot(_ctx.$slots, "subtext", {}, undefined, true)
                            ])
                          ]),
                          _: 3
                        }))
                      : _createCommentVNode("", true)
                ]),
                _: 3
              })
            ], 64))
    ]),
    _: 3
  }, 8, ["class", "onClick"]))
}