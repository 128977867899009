import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "node-detail-metrics full-width" }
const _hoisted_2 = { class: "ml-auto" }
const _hoisted_3 = { class: "ml-n3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_col, { class: "pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-2" }, {
          default: _withCtx(() => [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "token-text-medium my-auto" }, " Metrics ", -1)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_CustomButton, {
                ref: "menuButton",
                level: _ctx.$enums.ButtonLevels.Tertiary,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dropdownOpened = true)),
                class: "px-1"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.userSelectedPeriod.text), 1),
                  _createVNode(_component_Icon, {
                    class: "ml-1 mr-n4",
                    color: "#3219B2",
                    icon: "caret-down"
                  })
                ]),
                _: 1
              }, 8, ["level"]),
              _createVNode(_component_DropdownMenu, {
                items: _ctx.itemsDropdown,
                show: _ctx.dropdownOpened,
                "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dropdownOpened) = $event)),
                modelValue: _ctx.userSelectedPeriod,
                maxContent: true,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.onUserSelecPeriod($event))),
                target: _ctx.$refs.menuButton
              }, null, 8, ["items", "show", "modelValue", "target"])
            ])
          ]),
          _: 1
        }),
        (_ctx.userSelectedPeriod.id === 'custom')
          ? (_openBlock(), _createBlock(_component_v_row, {
              key: 0,
              class: "ma-2 mb-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomTextInput, {
                  modelValue: _ctx.startPicker,
                  "onUpdate:modelValue": [
                    _cache[3] || (_cache[3] = ($event: any) => ((_ctx.startPicker) = $event)),
                    _ctx.onDatePickerChange
                  ],
                  type: "datetime-local",
                  style: {"width":"200px"}
                }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_Icon, { icon: "arrow-right" }),
                _createVNode(_component_CustomTextInput, {
                  modelValue: _ctx.endPicker,
                  "onUpdate:modelValue": [
                    _cache[4] || (_cache[4] = ($event: any) => ((_ctx.endPicker) = $event)),
                    _ctx.onDatePickerChange
                  ],
                  type: "datetime-local",
                  style: {"width":"200px"}
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_row, { class: "ma-0 border-bot" })
      ]),
      _: 1
    }),
    _createVNode(_component_v_col, {
      class: _normalizeClass(["pa-0 metrics-container", {'custom-show' : _ctx.userSelectedPeriod.id === 'custom'}])
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metrics, (metric, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: 'metric-' + index
          }, [
            _createVNode(_component_v_row, { class: "ma-2 mt-4 mb-4 token-text-medium" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(metric.attributes.name), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_v_row, {
              class: "ma-0 pa-2 full-width",
              id: 'chart-' + index
            }, null, 8, ["id"])
          ], 64))
        }), 128))
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}