import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue'
  import KCDetector from './KCDetector.vue'
  import KCGame from './KCGame.vue'
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'KC',
  setup(__props) {

  let KCActive = ref<boolean>(false)
  function onKCActivation () {
    KCActive.value = true
  }

  function onQuit () {
    KCActive.value = false
  }

  

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(KCDetector, {
      onKCActivation: onKCActivation,
      activated: _unref(KCActive)
    }, null, 8, ["activated"]),
    (_unref(KCActive))
      ? (_openBlock(), _createBlock(KCGame, {
          key: 0,
          onQuit: onQuit
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})