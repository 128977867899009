<template>
  <div class="block-detail-container" :class="{'opened': isOpened, 'error-validation-modal-is-open': errorsValidationModalIsOpen, transparent: isModalTransparent}" @click="onClose">
    <div class="block-detail bar" v-if="block" @click.stop="" :class="{compact: isOnlyOneTab}">
      <div class="block-detail-top">
        <v-row  class="ma-0 pa-0 block-detail-header">
          <v-col class="ma-0 pa-0 d-flex">
            <v-row class="ma-0 pa-0 my-auto">
              <div class="my-auto ml-4 mr-2 block-detail-header-icon">
                <Logo v-if="blockAPI && $enums.EnginesLogos[blockAPI.attributes.default_name]" :logo="$enums.EnginesLogos[blockAPI.attributes.default_name]" />
                <Icon v-else :icon="block.icon" />
              </div>
              <div class="block-detail-header-text">
                <v-text-field 
                  variant="plain"
                  density="compact"
                  single-line
                  hide-details
                  v-model="editedName"
                  class="token-text-medium block-detail-title"
                  @blur="onNameEdit"
                  @keypress.enter="onNameEdit"
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
          <v-col class="ma-0 pa-0 my-auto mr-4 block-detail-header-x" cols="1" align="right">
            <CustomButton :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="onClose"/>
          </v-col>
        </v-row>
        <v-row  class="ma-0 pa-0 " >
          <v-col class="ma-0 pa-0 block-detail-left" :class="{closed:listConfigTab.length <= 1}">
            <list
              :items="listConfigTab"
              @select="onSelectConfigTab"
            />
          </v-col>
          <v-col class="ma-0 pa-0 block-detail-right" v-if="loading">
            <v-row class="block-detail-content" :class="{compact: listConfigTab.length === 1}">
              <Spinner class="ma-auto"/>
            </v-row>
          </v-col>
          <v-col class="ma-0 pa-0 block-detail-right" v-else>
            <v-row class="block-detail-content" :class="{compact: listConfigTab.length === 1}">
              <template v-if="currentConfigTab && currentConfigTab.id==='metrics'">
                <NodeDetailMetrics :blockAPI="blockAPI" />
              </template>
              <template v-else-if="currentConfigTab && currentConfigTab.id==='logs'">
                <NodeDetailLogs :blockAPI="blockAPI" />
              </template>
              <template v-else>
                <NodeDetailContent :block="block" :node="node" :currentTab="currentConfigTab" :isOnlyOneTab="isOnlyOneTab" v-if="currentConfigTab" @save:start="onSave(false)" @save:success="onSaveSuccess"/>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="block-detail-bottom">
        <!-- <div class="price-container">
          <span class="price-value">$999999999,9</span><span class="price-period">/month</span>
        </div> -->
        <div class="btn-container">
          <CustomButton :level="$enums.ButtonLevels.Tertiary" icon="trash" text="Delete" class="delete-btn footer-btn" @click="deleteBlock"/>
          <CustomButton :level="$enums.ButtonLevels.Primary" :text="saveBtnTxt" @click="onSave(true)" :status="saveBtnStatus" :loading="saving" class="footer-btn mr-4 ml-auto my-auto"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Element, Block } from '@/types'
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'
import List, {ListItem} from '@/components/UIElements/List.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import NodeDetailContent from '@/components/Details/NodeDetailContent.vue'
import NodeDetailMetrics from '@/components/Details/NodeDetailMetrics.vue'
import NodeDetailLogs from '@/components/Details/NodeDetailLogs.vue'
import { APITabField, APIBlock, APIFieldDefinition, APIFieldValue, APIFieldBox } from '@/typesAPI'
import { Watch } from 'vue-property-decorator'
import API from '@/api/wrapper'
import Spinner from '@/components/UIElements/Spinner.vue'

@Options({
  components: {
    Icon,
    Logo,
    CustomButton,
    List,
    NodeDetailContent,
    NodeDetailMetrics,
    NodeDetailLogs,
    Spinner
  },
})
export default class NodeDetail extends Vue {
  currentConfigTab: ListItem | null = null 
  editedName = ""
  listConfigTab:ListItem[] = []
  saving = false
  saveBtnStatus = ''
  saveBtnTxt = 'Save'
  loading = false

  @Watch('isOpened', {immediate: true})
  onIsOpenedChange() {
    if(this.isOpened) {
      this.init()
    }
  }

  init() {
    this.initTabSelected()
    if(this.blockAPIName) {
      this.editedName = this.blockAPIName
    }
  }

  @Watch('currentConfigTab', {immediate: true})
  onTabSelectedChange() {
    // TODO : check correctly for Live Tabs
    if(!this.loading && this.currentConfigTab?.id && !this.isTabLoaded && this.currentConfigTab?.id !== "metrics" && this.currentConfigTab?.id !== "logs" ) {
      this.loading = true
      API.blocks.getBlockFields(this.blockAPI?.id as string, this.currentConfigTab?.id as string)
      .then((result) => {
        this.$store.dispatch('fields/addTabLoaded', this.blockAPI?.id +'-'+ this.currentConfigTab?.id )

        this.$store.dispatch('fields/addFieldValues', result.values)
        this.$store.dispatch('fields/addFieldBoxes', result.boxes)
        this.$store.dispatch('fields/addNodeFieldDefinitions', result.definitions)
        this.$store.dispatch('fields/addFieldGroups', result.groups)

        this.$store.dispatch('fields/buildTree')

        this.loading = false
      })
    }
  }

  get isTabLoaded():boolean {
    return this.$store.getters['fields/getIsTabLoaded'](this.blockAPI?.id +'-'+ this.currentConfigTab?.id)
  }

  get blockAPI():APIBlock | undefined {
    return this.node && this.node.extra && this.tree.blocks && this.tree.blocks[this.node?.extra?.blockAPIID] ? this.tree.blocks[this.node?.extra?.blockAPIID] : undefined
  }

  get node ():Element {
    return this.$store.getters['elements/getDetailOpenElement']
  }

  get block ():Block {
    return this.node?.extra?.block
  }

  get isOpened () {
    return !!this.node
  }

  get errorsValidationModalIsOpen ():boolean {
    return this.$store.getters['errorsValidation/getModalIsOpen']
  }

  get isModalTransparent ():boolean {
    return this.$store.getters['errorsValidation/getModalTransparency']
  }

  get isOnlyOneTab (): boolean {
    return this.listConfigTab.length === 1
  }

  get tree():any {
    return this.$store.getters['fields/getTree']
  }

  get blockAPIName() {
    let ret = ''
    if(this.blockAPI) {
      ret = this.blockAPI?.attributes?.name ? this.blockAPI.attributes.name : this.blockAPI.attributes.default_name 
    }
    return ret
  }

  get listConfigTabGetter ():ListItem[] {

    let ret:ListItem[] = []
    let listTab:APITabField[] = []
    // if(this.blockAPI && this.tree.blocks[this.blockAPI.id]) {
    //   listTab = Object.values(this.tree.blocks[this.blockAPI.id].tabs) as APITabField[]
    // }
    if(this.blockAPI) {
      listTab = this.$store.getters['fields/getTabsByBlockID'](this.blockAPI.id)
    }

    listTab.sort((tab1:APITabField, tab2:APITabField) => {
      return tab1.attributes.priority - tab2.attributes.priority
    })


    ret = listTab.map((tab:APITabField) => {
      return {
        text: tab.attributes.name,
        id: tab.id
      }
    })

    const monitoringPart:ListItem[] = [
      {
        text: '-',
        id:'-',
        separator: true
      },
      {
        text: 'Metrics',
        id:'metrics'
      },
      {
        text: 'Logs',
        id:'logs'
      }
    ]

    if(this.blockStatus !== this.$enums.DeploymentStatus.OFFLINE) {
      ret = ret.concat(...monitoringPart)
    }
    
    return ret
  }

  get blockStatus() {
    return this.$store.getters["blocksAPI/getDeployementStatusByBlockID"](this.blockAPI?.id)
  }

  initTabSelected() {
    this.listConfigTab = this.listConfigTabGetter

    if(this.listConfigTab && this.listConfigTab[0]) {
      if(this.$route.params.tabId) {
        // Chose Tab
        this.listConfigTab.forEach((tabItem:ListItem) => {
          if(tabItem.id === this.$route.params.tabId) {
            tabItem.selected = true
            this.currentConfigTab = tabItem
          } else {
            tabItem.selected = false
          }
        })
      } else {
        this.listConfigTab[0].selected = true
        this.currentConfigTab = this.listConfigTab[0]
      }

      if(this.currentConfigTab && this.$route.params.tabId !== this.currentConfigTab?.id && this.blockAPI) {
        this.$router.push({
          name: 'flowBlock',
          params: {
            ...this.$route.params,
            blockId: this.blockAPI ? this.blockAPI.id : '',
            tabId: this.currentConfigTab.id
          }
        });
      }
    }
  }

  @Watch('$route.params.tabId')
  onQueryChange() {
    if(this.$route.name === 'flow') {
      this.$store.dispatch('elements/setDetailOpenElement')
    } else {
      this.initTabSelected()
    }
  }

  @Watch('listConfigTabGetter')
  onListTabChange() {
    this.initTabSelected()
  }

  onSave(shouldClose:boolean) {
    this.saving = true
    this.saveBtnTxt = 'Saving'
    setTimeout(() => {
      this.onSaveSuccess(shouldClose)
    },250)
  }

  onSaveSuccess(shouldClose:boolean) {
    if(this.saving) {
      this.saveBtnTxt = 'Saved'
      this.saving = false
      this.saveBtnStatus = this.$enums.ButtonStates.Success
      setTimeout(() => {
        this.saveBtnStatus = ''
        this.saveBtnTxt = 'Save'
        if(shouldClose) {
          this.onClose()
        }
      },1000)
    }
    
  }

  onClose() {
    this.$store.dispatch('elements/setDetailOpenElement')
  }

  onSelectConfigTab(configTab:ListItem ) {
    this.currentConfigTab = configTab
    this.$router.push({
      name: 'flowBlock',
      params: {
        ...this.$route.params,
        blockId: this.blockAPI ? this.blockAPI.id : '',
        tabId: configTab.id
      }
    });
  }

  deleteBlock() {
    this.$emitter.emit('deleteBlock', this.node)
  }

  @Watch('isOpened')
  onOpenChange() {
    this.saveBtnStatus = ''
    if(!this.listConfigTab[0]) {
      this.listConfigTab = this.listConfigTabGetter
    }

    if(this.isOpened && this.listConfigTab[0]) {
      this.listConfigTab[0].selected = true
      this.currentConfigTab = this.listConfigTab[0]
      if(this.$route.params.boxId) {
        this.$router.push({
          name: 'flowBlockAndBox',
          params: {
            ...this.$route.params,
            blockId: this.blockAPI ? this.blockAPI.id : '',
            tabId: this.currentConfigTab ? this.currentConfigTab.id : '',
            boxId: this.$route.params.boxId
          }
        })
      } else {
        this.$router.push({
          name: 'flowBlock',
          params: {
            ...this.$route.params,
            blockId: this.blockAPI ? this.blockAPI.id : '',
            tabId: this.currentConfigTab ? this.currentConfigTab.id : '',
          }
        })
      }
     
    } else {
      this.$router.push({
        ...this.$route.params,
        name: 'flow'
      });
    }
  }

  @Watch('listConfigTab')
  onListConfigTabChange() {
    if(this.currentConfigTab) {
      this.listConfigTab.forEach((tab:ListItem) => {
        tab.selected = tab.id === this.currentConfigTab?.id
      })
    }
  }

  @Watch('blockAPI')
  onBlockAPIChange() {
    this.editedName = this.blockAPIName
  }

  onNameEdit() {
    if(this.blockAPI && this.editedName !== this.blockAPIName) {
      API.blocks.editBlockName(this.blockAPI.id, this.editedName).then((data) => {
        this.$store.dispatch('blocksAPI/editBlock', data)
        this.$store.dispatch('deployment/editDeploymentSubject', data)
      })
    }
  }



  getTabByFieldID(id:string): APITabField {
    const field: APIFieldDefinition = this.$store.getters['fields/getDefinitionsByID'](id) as APIFieldDefinition
    const box : APIFieldBox = this.$store.getters['fields/getBoxByID'](field?.relationships?.box.data.id) as APIFieldBox
    const tab : APITabField = this.$store.getters['fields/getTabByID'](box?.relationships?.tab.data.id) as APITabField
    return tab
  }

  getFieldsDefinitionsByIDValue(id:string): APIFieldDefinition {
    return this.$store.getters['fields/getDefinitionsByIDValue'](id) as APIFieldDefinition
  }

  

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
@import '@/css/tokens';

.block-detail-container {
  display: flex;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  position: fixed;
  z-index: 100000 !important;
  background: $color-neutral-background-modal;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, padding 0.2s ease-in-out, background 0.2s ease-in-out;
  pointer-events: none;

  &.opened {
    opacity: 1;
    pointer-events: all;
    &.transparent {
      background: transparent !important;
      .block-detail {
        background: rgba(255, 255, 255, 0.24) !important;
        div {
          opacity: 0;
        }
      }
    }
  }
  &.error-validation-modal-is-open {
    padding-right: 436px;
  }

  .block-detail {
    transition: opacity 0.2s ease-in-out, background 0.2s ease-in-out, height 0.2s ease-in-out;
    div {
      transition: opacity 0.2s ease-in-out, background 0.2s ease-in-out, height 0.2s ease-in-out ;
    }
    z-index: 100001 !important;
    backdrop-filter: blur(1px) !important;
    height: auto;
    &:not(.compact) {
      height: 90vh;
    }
    width: auto;
    max-width: 676px;
    margin: auto;
    display: flex;
    background: white !important;
    flex-direction: column;
    display: flex;

    .block-detail-top {
      width: 100%;
      height: 100%;
      max-height: calc(90vh - 72px);
      flex-direction: column;
      display: flex;

      .block-detail-header {
        margin: 0px;
        background: $color-neutral-grey-4;
        height: 56px;
        border-bottom: 1px solid $color-neutral-grey-12;
        backdrop-filter: blur(4px);
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        &::v-deep .v-input.token-text-medium {
          .v-field__input {
            @extend .token-text-medium
          }
        }
        .block-detail-title {
          border-radius: 8px;
          padding-left: 8px;
          border: 1px solid transparent;
          &:hover {
            border: 1px solid $color-neutral-grey-12;
          }
          &:focus-within {
            margin-left: -1px;
            border: 2px solid $color-brand;
            background:white;
            box-shadow: 0px 0px 0px 2px rgba(50, 25, 178, 0.24);
          }
        }
      }
      .block-detail-left {
        transition: width 0.2s ease-in-out;
        padding: 0px;
        backdrop-filter: blur(4px);
        border-right: 1px solid $color-neutral-grey-12;
        width: 212px;
        &.closed {
          width: 0px;
        }
      }
      .block-detail-right {
        padding: 0px;
        min-width: 462px;
        width: 100%;
        
        .block-detail-content {
          max-width: 461px;
          max-height: calc(90vh - 128px);
          &:not(.compact) {
            height: calc(90vh - 128px);
          }
          margin: 0px;
          overflow-y: auto;
          margin-right: 1px;
        }
      }
    }
    .block-detail-bottom {
      flex-direction: row;
      display: flex;
      border-top: 1px solid $color-neutral-grey-12;
      height: 72px;
      .price-container {
        padding-left:16px;
        margin-top: auto;
        margin-bottom: auto;
        .price-value {
          color: $color-brand;
          font-weight: 500;
          font-size: 24px;
          line-height: 40px;
        }
        .price-period {
          padding-left:4px;
          color: $color-neutral-grey-60;
          font-weight: 500;
          font-size: 16px;
          line-height: 32px;
        }
      }
      .btn-container {
        width: 100%;
        display: flex;
        .delete-btn {
          margin: auto;
          margin-left: 16px;
          color: #E15F19;
          .icon {
            mask-size: 16px 16px;
          }
        }
        // .footer-btn {
        //   margin-right: 16px;
        // }
      }
    }
  }
}

</style>
<style lang="scss">
.block-detail-header-text {
  width: calc(100% - 42px);
  .v-field__input {
    padding: 0 !important;
  }
}
</style>