import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import FlowView from '@/views/FlowView.vue'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import RegisterView from '@/views/RegisterView.vue'
import ProjectsView from '@/views/ProjectsView.vue'
import ClustersView from '@/views/ClustersView.vue'
import AccountView from '@/views/AccountView.vue'
import BillingView from '@/views/BillingView.vue'
import RessourcesUsageView from '@/views/RessourcesUsageView.vue'
import TokenLoginView from '@/views/TokenLoginView.vue'
import SocialAccountCallbackView from '@/views/SocialAccountCallbackView.vue'
import LoginSocialCallbackView from '@/views/LoginSocialCallbackView.vue'
import AdminView from '@/views/AdminView.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect:{name:'login'},
    component: HomeView,
    props: { needAuth: true },
    children: [
      {
        // ProjectsView will be rendered inside HomeView's <router-view>
        // when /projects is matched
        path: 'projects',
        name: 'projects',
        component: ProjectsView,
      },
      {
        path: 'clusters',
        name: 'clusters',
        component: ClustersView,
      },
      {
        path: 'account',
        name: 'account',
        component: AccountView,
      },
      {
        path: 'billing',
        name: 'billing',
        component: BillingView,
      },
      {
        path: 'usage',
        name: 'usage',
        component: RessourcesUsageView,
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    props: { needAuth: false }
  },
  {
    path: '/login/:socialProvider/callback',
    name: 'loginSocialCallback',
    component: LoginSocialCallbackView,
    props: { needAuth: false }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    props: { needAuth: false }
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    props: { needAuth: false }
  },
  {
    path: '/token',
    name: 'token',
    component: TokenLoginView,
    props: { needAuth: false }
  },
  {
    path: '/projects/:projectId/flow',
    name: 'flow',
    component: FlowView,
    props: { needAuth: true }
  },
  {
    path: '/projects/:projectId/flow/block/:blockId/tab/:tabId',
    name: 'flowBlock',
    component: FlowView,
    props: { needAuth: true }
  },
  {
    path: '/projects/:projectId/flow/block/:blockId/tab/:tabId/box/:boxId',
    name: 'flowBlockAndBox',
    component: FlowView,
    props: { needAuth: true }
  },
  {
    path: '/socialAccounts/:socialProvider/callback',
    name: 'socialAccountCallback',
    component: SocialAccountCallbackView,
    props: { needAuth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
