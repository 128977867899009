<template>
  <div class="popin-cover">
    <div class="popin bar">
      <v-col class="pa-4">
        <v-row class="ma-0 mb-6">
          <slot name="header"></slot>
        </v-row>
        <v-row class="ma-0 mb-4 ">
          <div class="ma-auto">
            <slot name="content"></slot>
          </div>
        </v-row>
        <v-row class="ma-0">
          <slot name="footer"></slot>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
export default class Popin extends Vue {

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
.popin-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.88);
    .popin {
      position: absolute;
      width: 94%;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: fit-content;
    }
  }
</style>
