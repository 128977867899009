import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "custom-select",
  ref: "customSelect"
}
const _hoisted_2 = {
  key: 0,
  class: "fit-content my-auto mr-2"
}
const _hoisted_3 = {
  key: 1,
  class: "placeholder my-auto ml-3"
}
const _hoisted_4 = { class: "carret my-auto mr-2 ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Flag = _resolveComponent("Flag")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DropdownMenu, {
      items: _ctx.items,
      show: _ctx.opened,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.opened) = $event)),
      modelValue: _ctx.modelValue,
      displaySearch: _ctx.displaySearch,
      query: _ctx.query,
      "onUpdate:modelValue": _ctx.onModelValueUpdate,
      "onUpdate:query": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:query',$event))),
      width: _ctx.dropdownMenuWidth + 'px',
      loading: _ctx.loading,
      target: _ctx.$refs.inputMenu
    }, _createSlots({ _: 2 }, [
      (_ctx.$slots['footer'])
        ? {
            name: "footer",
            fn: _withCtx(() => [
              _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["items", "show", "modelValue", "displaySearch", "query", "onUpdate:modelValue", "width", "loading", "target"]),
    _createElementVNode("div", {
      class: _normalizeClass(["custom-select-input", {disabled:_ctx.disabled}]),
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onOpenClick && _ctx.onOpenClick(...args))),
      ref: "inputMenu"
    }, [
      (_ctx.modelValue && _ctx.modelValue.text)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 0,
            class: "value my-auto ml-3"
          }, {
            default: _withCtx(() => [
              (_ctx.modelValue?.icon || _ctx.modelValue?.logo || _ctx.modelValue?.flag)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_ctx.modelValue?.logo)
                      ? (_openBlock(), _createBlock(_component_Logo, {
                          key: 0,
                          logo: _ctx.modelValue?.logo
                        }, null, 8, ["logo"]))
                      : (_ctx.modelValue?.icon)
                        ? (_openBlock(), _createBlock(_component_Icon, {
                            key: 1,
                            icon: _ctx.modelValue?.icon
                          }, null, 8, ["icon"]))
                        : (_ctx.modelValue?.flag)
                          ? (_openBlock(), _createBlock(_component_Flag, {
                              key: 2,
                              code: _ctx.modelValue?.flag
                            }, null, 8, ["code"]))
                          : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, _toDisplayString(_ctx.modelValue.text), 1)
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.placeholder), 1)),
      _createElementVNode("span", _hoisted_4, [
        _createVNode(_component_Icon, { icon: "caret-down" })
      ])
    ], 2)
  ], 512))
}