<template>
  <div class="tooltip fit-content">
    <div class="iconContainer" ref="iconContainer">
      <CustomButton class="" :level="$enums.ButtonLevels.SmallIcon" icon="question-circle" @click="onClickShow" @mouseenter="onStartHoverButton" @mouseleave="onEndHoverButton"/>
    </div>
    <Popover maxWidth='512' :visible="show" @update:visible="$event ? '' : onClickHide()" :target="target" :closeOnExternalClick="clicked" v-model:isHovered="hoverPopover"  v-if="target">
      <template #header>
        <div>
          <slot name="header"></slot>
        </div>
        <CustomButton class="ml-auto mr-2 my-auto" :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="onClickHide"/>
      </template>
      <template #content v-if="$slots['content'] || docLink">
        <v-col class="pa-0">
          <v-row class="ma-0" v-if="$slots['content']">
            <slot name="content"></slot>

          </v-row>
          <v-row class="ma-0" v-if="docLink">
            <CustomButton class="ml-auto mt-4" :level="$enums.ButtonLevels.Tertiary" @click="onDocLinkClick">
              Learn more
              <Icon icon="share" class="ml-2" />
            </CustomButton>
          </v-row>
        </v-col>
      </template>
      <template #footer v-if="$slots['footer']">
        <div>
          <slot name="footer"></slot>
        </div>
      </template>
    </Popover>
  </div>

</template>

<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Popover from '@/components/UIElements/Popover.vue'
import Icon from '@/components/UIElements/Icon.vue'

class Props {
  docLink?:string = prop({
    required: false,
  });
}

@Options({
  components: {
    CustomButton,
    Popover,
    Icon
  },
})
export default class Tooltip extends Vue.with(Props) {
  hoverButton = false
  hoverPopover = false
  clicked = false
  target:HTMLElement | null = null
  endOverTimeout = 0

  onDocLinkClick() {
    window.open('https://docs.fransys.cloud/' + this.docLink, "_blank");
  }

  onStartHoverButton() {
    clearInterval(this.endOverTimeout)
    this.hoverButton = true
  }

  onEndHoverButton() {
    this.endOverTimeout = setTimeout(() => {
      this.hoverButton = false
    },300)
  }

  onStartHoverPopover() {
    this.hoverPopover = true
  }

  onEndHoverPopover() {
    this.hoverPopover = false
  }

  onClickShow() {
    this.clicked = true
    this.hoverButton = false
    this.hoverPopover = false
  }

  onClickHide() {
    this.clicked = false
    this.hoverButton = false
    this.hoverPopover = false
  }

  get show():boolean {
    return this.clicked || this.hoverButton || this.hoverPopover
  }

  mounted() {
    this.target = this.$refs.iconContainer as HTMLElement
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

</style>
