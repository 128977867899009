import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/illustrations/triangle-error.svg'


const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "special-check-background d-flex mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_ListElement = _resolveComponent("ListElement")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_Popin = _resolveComponent("Popin")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["validation-errors-modal bar", {open: _ctx.open, transparent: _ctx.isModalTransparent, isBlockDetailOpen: _ctx.isBlockDetailOpen}])
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 0,
          class: "ma-auto"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_v_col, { class: "pa-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "ma-0 pa-4" }, {
                default: _withCtx(() => [
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "d-flex" }, [
                    _createElementVNode("span", { class: "token-text-medium" }, "Errors")
                  ], -1)),
                  _createVNode(_component_CustomButton, {
                    class: "ml-auto",
                    level: _ctx.$enums.ButtonLevels.SmallIcon,
                    icon: "x",
                    onClick: _ctx.onClose
                  }, null, 8, ["level", "onClick"])
                ]),
                _: 1
              }),
              (_ctx.errors.length)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_v_row, { class: "ma-0 pa-4 categorie-title" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_Badge, {
                            severity: _ctx.$enums.Severity.DANGER,
                            label: _ctx.errors.length,
                            class: "mr-2"
                          }, null, 8, ["severity", "label"]),
                          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "token-text-medium" }, "Blocking errors", -1))
                        ])
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, index) => {
                      return (_openBlock(), _createBlock(_component_v_row, {
                        class: "ma-0 ma-2 categorie-content",
                        key: 'error-'+index
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ListElement, {
                            onClick: ($event: any) => (_ctx.onClickError(error)),
                            onMouseenter: ($event: any) => (_ctx.onErrorHover(error)),
                            onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onErrorHover(null))),
                            text: error.attributes.title,
                            subtext: error.attributes.detail,
                            warningLevel: _ctx.$enums.Severity.DANGER,
                            selected: _ctx.selectedError === error,
                            class: "ma-0 px-4"
                          }, null, 8, ["onClick", "onMouseenter", "text", "subtext", "warningLevel", "selected"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.warnings.length)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_v_row, { class: "ma-0 pa-4 categorie-title" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_Badge, {
                            severity: _ctx.$enums.Severity.WARNING,
                            label: _ctx.warnings.length,
                            class: "mr-2"
                          }, null, 8, ["severity", "label"]),
                          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "token-text-medium" }, "Warnings", -1))
                        ])
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.warnings, (warning, index) => {
                      return (_openBlock(), _createBlock(_component_v_row, {
                        class: "ma-2 categorie-content",
                        key: 'warning-'+index
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ListElement, {
                            onClick: ($event: any) => (_ctx.onClickError(warning)),
                            onMouseenter: ($event: any) => (_ctx.onErrorHover(warning)),
                            onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onErrorHover(null))),
                            text: warning.attributes.title,
                            subtext: warning.attributes.detail,
                            warningLevel: _ctx.$enums.Severity.WARNING,
                            selected: _ctx.selectedError === warning,
                            class: "ma-0 px-4"
                          }, null, 8, ["onClick", "onMouseenter", "text", "subtext", "warningLevel", "selected"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.infos.length)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createVNode(_component_v_row, { class: "ma-0 pa-4 categorie-title" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_Badge, {
                            severity: _ctx.$enums.Severity.INFO,
                            label: _ctx.infos.length,
                            class: "mr-2"
                          }, null, 8, ["severity", "label"]),
                          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "token-text-medium" }, "Advices", -1))
                        ])
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infos, (info, index) => {
                      return (_openBlock(), _createBlock(_component_v_row, {
                        class: "ma-0 ma-2 categorie-content",
                        key: 'info-'+index
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ListElement, {
                            onClick: ($event: any) => (_ctx.onClickError(info)),
                            onMouseenter: ($event: any) => (_ctx.onErrorHover(info)),
                            onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onErrorHover(null))),
                            text: info.attributes.title,
                            subtext: info.attributes.detail,
                            warningLevel: _ctx.$enums.Severity.INFO,
                            selected: _ctx.selectedError === info,
                            class: "ma-0 px-4"
                          }, null, 8, ["onClick", "onMouseenter", "text", "subtext", "warningLevel", "selected"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ], 64))
                : _createCommentVNode("", true),
              (!_ctx.errors.length && !_ctx.warnings.length && !_ctx.infos.length)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _createVNode(_component_v_row, { class: "ma-0 separator" }),
                    _createVNode(_component_v_row, { class: "ma-0 pt-4" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_Icon, {
                            class: "ma-auto",
                            icon: "special-check"
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 pt-2" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createElementVNode("span", { class: "mx-auto token-text-medium" }, "All clear !", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 d-flex token-text-regular pt-2 subtext" }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createElementVNode("span", { class: "mx-auto" }, "You are ready for deployment", -1)
                      ])),
                      _: 1
                    })
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (_ctx.showWarningPopup)
            ? (_openBlock(), _createBlock(_component_Popin, { key: 0 }, {
                header: _withCtx(() => [
                  _createVNode(_component_v_img, {
                    src: _imports_0,
                    height: "56px"
                  })
                ]),
                content: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "token-text-h5 ma-0 mb-1" }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createElementVNode("div", { class: "ma-auto" }, " Your project still has unresolved warnings ", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0 mb-1" }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createElementVNode("div", { class: "token-text-regular token-text-color-secondary ma-auto" }, " Warnings do not block deployment but ignoring them can result higher ressource use or unexpected bugs. ", -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                footer: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "ma-0 mb-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CustomButton, {
                            level: _ctx.$enums.ButtonLevels.Primary,
                            class: "ma-auto",
                            onClick: _ctx.closeWarningPopup
                          }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode("Show warning")
                            ])),
                            _: 1
                          }, 8, ["level", "onClick"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CustomButton, {
                            level: _ctx.$enums.ButtonLevels.Tertiary,
                            class: "ma-auto",
                            onClick: _ctx.forceDeploy
                          }, {
                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                              _createTextVNode("Ignore warnings and deploy")
                            ])),
                            _: 1
                          }, 8, ["level", "onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 64))
  ], 2))
}