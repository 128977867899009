<template>
   <div class="slider" :class="{disabled:disabled, hideThumb:hideThumb, 'mt-4' : !hideThumb}" >
    <v-row class="ma-2">
      <template v-if="range">
        <v-range-slider
          v-model="insideValues"
          :hide-details="true"
          :step="step || 1"
          :min="min"
          :max="max"
          @update:modelValue="updateValues"
          :show-ticks="'always'"
          tick-size="4"
          track-fill-color="rgba(50, 25, 178, 1)"
          track-color="rgba(0, 0, 58, 0.3)"
          :thumb-label="hideThumbLabels ? false : 'always'"
          :disabled="disabled"
        />
      </template>
      <template v-else>
        <v-slider
          v-model="insideValue"
          :hide-details="true"
          :step="step || 1"
          :min="min"
          :max="max"
          @update:modelValue="updateValue"
          @end="end"
          :show-ticks="'always'"
          tick-size="4"
          track-fill-color="rgba(50, 25, 178, 1)"
          track-color="rgba(0, 0, 58, 0.3)"
          :thumb-label="hideThumbLabels ? false : 'always'"
          :disabled="disabled"
        />
      </template>
     
    </v-row>
    </div>
</template>


<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import { Watch } from 'vue-property-decorator';

class Props {
  values?: number[] = prop({
    required: false,
  });
  value?: number = prop({
    required: false,
  });
  step: number | undefined  = prop({
    required: false,
  });
  min: number | undefined = prop({
    required: false,
  });
  max: number | undefined = prop({
    required: false,
  });
  hideThumbLabels: boolean | undefined = prop({
    required: false,
  });
  hideThumb: boolean | undefined = prop({
    required: false,
  });
  range: boolean | undefined = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    CustomButton
  },
})
export default class Slider extends Vue.with(Props) {

  insideValues:number[] | undefined = [0,0]
  insideValue:number | undefined = 0

  updateValues(values:number[]) {
    this.$emit('updateValue:values', values)
  }
  updateValue(value:number) {
    this.$emit('updateValue:value', value)
  }

  end(value:number) {
    this.$emit('end', value)
  }

  @Watch('values', {immediate : true})
  onValuesChange() {
    if(this.range && this.values !== undefined) {
      this.insideValues = this.values
    }
  }

  @Watch('value', {immediate : true})
  onValueChange() {
    if(!this.range && this.value !== undefined) {
      this.insideValue = this.value
    }
  }


}
</script>


<style lang="scss" scoped>
@import '@/css/variables';
.slider {
  width: 100%;
  :deep(.v-slider-thumb__surface) {
    color:white !important;
    border:1px solid $color-neutral-grey-12;
  }

  :deep(.v-slider-track__tick) {
    background-color:$color-neutral-grey-60 !important;
  }

  &.hideThumb {
    :deep(.v-slider-thumb) {
      display: none !important;
    }
  }
}
</style>
