<template>
    <span class="pmlogo-container">
      <span class="pmlogo" ref="dom" :class="'pmlogo-' +pmlogo"></span>
    </span>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component';

class Props {
  pmlogo: string = prop({
    required: true,
  });
}

export default class PaymentMethodLogo extends Vue.with(Props) {
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.pmlogo-container {
  display:flex;
  margin: auto;
}
.pmlogo {
  width: 70px;
  height: 48px;
  background-size: 100% 100%;
  border-radius: 4px;
  display: flex;
  margin: auto;
  transition: 0.2s background ease-in-out;
  background-color: $color-neutral-grey-12;
}

// Payment Methods
// Paypal
.pmlogo-paypal {
  background-image: url(@/assets/paymentMethods/paypal.svg);
}
// Cards
.pmlogo-visa {
  background-image: url(@/assets/paymentMethods/visa.svg);
}
.pmlogo-mastercard {
  background-image: url(@/assets/paymentMethods/mastercard.svg);
}
.pmlogo-amex {
  background-image: url(@/assets/paymentMethods/amex.svg);
}
.pmlogo-diners {
  background-image: url(@/assets/paymentMethods/diners.svg);
}
.pmlogo-discover {
  background-image: url(@/assets/paymentMethods/discover.svg);
}
.pmlogo-jcb {
  background-image: url(@/assets/paymentMethods/jcb.svg);
}
.pmlogo-unionpay {
  background-image: url(@/assets/paymentMethods/unionpay.svg);
}


</style>
