import { ToastrDescriptor } from "@/types"

type State = {
  descriptors: ToastrDescriptor[]
}

const state: State = {
  descriptors: []
}


const getters = {
  getDescriptors: (state:State) => {
    return state.descriptors
  },
}

const mutations = {
  ADD_DESCRIPTOR: (state:State, descriptor:ToastrDescriptor) => {
    state.descriptors.push(descriptor)
  },
  REMOVE_DESCRIPTOR: (state:State, descriptor:ToastrDescriptor) => {
    const index = state.descriptors.findIndex((d) => d.id === descriptor.id)
    if(index >= 0) {
      state.descriptors.splice(index, 1)
    }
  },
}

const actions = {
  addDescriptor: (context:any, descriptor:ToastrDescriptor) => {
    context.commit('ADD_DESCRIPTOR', descriptor)
  },
  removeDescriptor: (context:any, descriptor:ToastrDescriptor) => {
    context.commit('REMOVE_DESCRIPTOR', descriptor)
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}