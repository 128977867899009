<template>
    <div class="avatar-container">
      <img :src="src" />
    </div>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component';

class Props {
  src?:string = prop({
    required: false,
  });
}

export default class Avatar extends Vue.with(Props) {
}
</script>

<style lang="scss" scoped>
.avatar-container {
  overflow: hidden;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  img {
    height: 100%;
    width: 100%;
  }
}
</style>
