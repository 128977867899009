<template>
  <div class="loginSocialButton">
    <CustomButton class="full-width" :level="$enums.ButtonLevels.Secondary" :icon="provider" @click="onClick">
      Log in with {{ providerName }}
    </CustomButton>
  </div>
</template>

<script lang="ts">
import API from '@/api/wrapper';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Logo from '@/components/UIElements/Logo.vue';
import { APIUrlRedirection } from '@/typesAPI';
import { Vue, Options, prop } from 'vue-class-component';


class Props {
  provider: string = prop({
    required: true,
  });
  providerName: string = prop({
    required: true,
  });
}


@Options({
  components: {
    CustomButton,
    Logo,
  },
})
export default class LoginSocialButton extends Vue.with(Props) {
  onClick() {
    API.session.loginViaSocial(this.provider)
    .then((urlRedirection:APIUrlRedirection) => {
      this.$helpers.links.redirect(urlRedirection.attributes.url)
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.loginSocialButton {
 width: 100%;
}
</style>
