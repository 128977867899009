import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "stripe-widget-payment-methods full-width" }
const _hoisted_2 = { class: "token-text-color-error ma-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { id: "payment-element-payment-method" }, null, -1)),
    (_ctx.initialLoad)
      ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
            default: _withCtx(() => [
              (_ctx.showCancel)
                ? (_openBlock(), _createBlock(_component_CustomButton, {
                    key: 0,
                    level: _ctx.$enums.ButtonLevels.Secondary,
                    text: "Cancel",
                    class: "ml-auto mr-2",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
                  }, null, 8, ["level"]))
                : _createCommentVNode("", true),
              _createVNode(_component_CustomButton, {
                text: "Save payment method",
                level: _ctx.$enums.ButtonLevels.Primary,
                class: _normalizeClass(["", {'full-width' : !_ctx.showCancel}]),
                disabled: _ctx.submitDisabled || _ctx.isInError,
                status: _ctx.isInError ? _ctx.$enums.ButtonStates.Danger : '',
                loading: _ctx.loading,
                onClick: _ctx.handleSubmit
              }, null, 8, ["level", "class", "disabled", "status", "loading", "onClick"])
            ]),
            _: 1
          }),
          (_ctx.messageContent)
            ? (_openBlock(), _createBlock(_component_v_row, {
                key: 0,
                class: "ma-0 mt-2 token-background-color-error rounded-border"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.messageContent), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}