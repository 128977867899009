import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "token-text-regular subtext my-auto ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalValidatePaymentMethodList = _resolveComponent("ModalValidatePaymentMethodList")!
  const _component_ModalEditLimits = _resolveComponent("ModalEditLimits")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["subscription-status-message pa-0", {hidden: _ctx.hidden, transitioning: _ctx.isTransitioning}])
  }, [
    _createVNode(_component_ModalValidatePaymentMethodList, {
      visible: _ctx.showValidatePaymentMethodList,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showValidatePaymentMethodList) = $event)),
      fullScreen: true,
      onSuccess: _ctx.onSuccessValidatePaymentMethodList
    }, null, 8, ["visible", "onSuccess"]),
    _createVNode(_component_ModalEditLimits, {
      visible: _ctx.showEditLimits,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showEditLimits) = $event)),
      fullScreen: true
    }, null, 8, ["visible"]),
    (_ctx.pendingState === _ctx.PENDING_STATE.WAITING)
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 0,
          class: "subscription-status-message-background pending ma-0 pa-2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Spinner, { class: "mr-2" }),
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "token-text-medium text my-auto" }, " Waiting for payment confirmation ", -1))
          ]),
          _: 1
        }))
      : (_ctx.pendingState === _ctx.PENDING_STATE.SUCESS)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 1,
            class: "subscription-status-message-background pending-sucess ma-0 pa-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Icon, {
                icon: "check-circle",
                class: "mr-2"
              }),
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "token-text-medium text my-auto" }, " Subscription active ", -1))
            ]),
            _: 1
          }))
        : (_ctx.pendingState === _ctx.PENDING_STATE.FAIL)
          ? (_openBlock(), _createBlock(_component_v_row, {
              key: 2,
              class: "subscription-status-message-background pending-fail ma-0 pa-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Icon, {
                  icon: "cancel-circle",
                  class: "mr-2"
                }),
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "token-text-medium text my-auto" }, " Unable to verify subscription ", -1))
              ]),
              _: 1
            }))
          : (_ctx.subscriptionState === _ctx.$enums.SubscriptionState.TRIALING)
            ? (_openBlock(), _createBlock(_component_v_row, {
                key: 3,
                class: "subscription-status-message-background trialing ma-0 pa-2"
              }, {
                default: _withCtx(() => [
                  _cache[6] || (_cache[6] = _createElementVNode("span", { class: "token-text-medium text my-auto" }, " You are on free trial ", -1)),
                  _createElementVNode("span", _hoisted_1, " Your projects will be destroyed in " + _toDisplayString(_ctx.timer) + ". Upgrade to keep them online ", 1),
                  _createVNode(_component_CustomButton, {
                    class: "ml-4",
                    level: _ctx.$enums.ButtonLevels.Secondary,
                    onClick: _ctx.checkout,
                    loading: _ctx.loadingCheckout,
                    status: _ctx.btnStatus
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Upgrade now ")
                    ])),
                    _: 1
                  }, 8, ["level", "onClick", "loading", "status"])
                ]),
                _: 1
              }))
            : (_ctx.subscriptionState === _ctx.$enums.SubscriptionState.TRIAL_EXPIRED)
              ? (_openBlock(), _createBlock(_component_v_row, {
                  key: 4,
                  class: "subscription-status-message-background trial-expired ma-0 pa-2"
                }, {
                  default: _withCtx(() => [
                    _cache[8] || (_cache[8] = _createElementVNode("span", { class: "token-text-medium text my-auto" }, " Free trial is over ", -1)),
                    _cache[9] || (_cache[9] = _createElementVNode("span", { class: "token-text-regular subtext my-auto ml-2" }, " Deploys are locked and your previous workloads are now offline ", -1)),
                    _createVNode(_component_CustomButton, {
                      class: "ml-4",
                      level: _ctx.$enums.ButtonLevels.Secondary,
                      onClick: _ctx.checkout,
                      loading: _ctx.loadingCheckout,
                      status: _ctx.btnStatus
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" Upgrade now ")
                      ])),
                      _: 1
                    }, 8, ["level", "onClick", "loading", "status"])
                  ]),
                  _: 1
                }))
              : (_ctx.subscriptionState === _ctx.$enums.SubscriptionState.EXPIRED)
                ? (_openBlock(), _createBlock(_component_v_row, {
                    key: 5,
                    class: "subscription-status-message-background trial-expired ma-0 pa-2"
                  }, {
                    default: _withCtx(() => [
                      _cache[11] || (_cache[11] = _createElementVNode("span", { class: "token-text-medium text my-auto" }, " No subscription active ", -1)),
                      _cache[12] || (_cache[12] = _createElementVNode("span", { class: "token-text-regular subtext my-auto ml-2" }, " Deploys are locked and your previous workloads are now offline ", -1)),
                      _createVNode(_component_CustomButton, {
                        class: "ml-4",
                        level: _ctx.$enums.ButtonLevels.Secondary,
                        onClick: _ctx.checkout,
                        loading: _ctx.loadingCheckout,
                        status: _ctx.btnStatus
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode(" Manage subscription ")
                        ])),
                        _: 1
                      }, 8, ["level", "onClick", "loading", "status"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
  ], 2))
}