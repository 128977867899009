<template>
  <div class="account-bar bar pa-2">
    <CustomButton :level="$enums.ButtonLevels.Tertiary" @click="toggleDropDown" class="px-1">
      <v-row >
        <Icon icon="account" />
      </v-row>
    </CustomButton>
    <DropdownMenu 
      :items="items"
      v-model:show="opened"
      :modelValue="modelValue"
      :maxContent="true"
      @update:modelValue="onModelValueUpdate"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Icon from '@/components/UIElements/Icon.vue'
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue'
import { ListItem } from '@/components/UIElements/List.vue';
import API from '@/api/wrapper';
import { APIUrlRedirection } from '@/typesAPI';

@Options({
  components: {
    CustomButton,
    Icon,
    DropdownMenu
  },
})
export default class AccountBar extends Vue {

  opened = false
  modelValue: ListItem = {}

  toggleDropDown () {
    this.opened = !this.opened
  }

  onModelValueUpdate (newVal:ListItem) {
    newVal.selected = false
    this.opened = false

    switch(newVal.id) {
      case 'subscription' : 
        API.subscription.manage()
        .then((urlRedirection:APIUrlRedirection) => {
          window.location.href = urlRedirection.attributes.url
        })
        .catch()
      break;
      case 'logout' : 
        API.session.logout()
        .then(() => {
          this.$store.dispatch('user/setUser', null)
          this.$router.push({name: 'login'})
        })
      break;
      default:
      break;
    }
  }

  get subscriptionState ():string {
    return this.$store.getters['user/getSubscriptionState']
  }

  get items():ListItem[]  {
     return [
      {
        id: 'subscription',
        icon: this.subscriptionState === this.$enums.SubscriptionState.ACTIVE ? 'subscription-dollar' : 'arrow-up',
        text: this.subscriptionState === this.$enums.SubscriptionState.ACTIVE ? 'Manage subscription' : 'Upgrade',
      },
      {
        id: 'separator',
        separator: true,
      },
      {
        id: 'logout',
        icon: 'send-out',
        text: 'Logout',
        class: 'logout'
      }
    ]
  }
  

}
</script>

<style lang="scss" scoped >
@import '@/css/variables';

.account-bar {
  position: relative;
  :deep(.logout) {
    color: $text-error;
    .icon {
      background-color: $text-error !important;
    }
  }
  :deep(.custom-button-text) {
    color: $color-neutral-black;
    .icon {
      background-color: $color-neutral-black !important;
    }
  }
}
</style>
