<template>
  <div class="projectsView full-view">
    <template v-if="projectsList?.length === 0">
      <v-col class="pa-0 ">
        <v-row class="ma-0">
          <v-col class="pa-0 token-text-h4">
            Projects
          </v-col>
        </v-row>
        <v-row class="ma-0 pt-4">
          <v-col class="pa-4 empty-box">
            <v-row class="ma-0 d-flex-row">
              <v-col class="pa-0 ma-auto fit-content fit-content-height d-flex-col">
                <v-row class="ma-0 mx-auto fit-content-height">
                  <v-img src="@/assets/illustrations/empty_box.svg" height="77px" width="98px"/>
                </v-row>
                <v-row class="ma-0 token-text-h5 my-4 mx-auto fit-content-height">
                  No project
                </v-row>
                <v-row class="ma-0 mx-auto fit-content-height">
                  <CustomButton text="Create project" @click="createProject"/>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>
    <template v-else>
      <v-col class="pa-0 ">
        <v-row class="ma-0">
          <v-col class="pa-0 token-text-h4">
            Projects
          </v-col>
          <v-col class="pa-0 ml-auto fit-content">
            <CustomButton icon="add" text="Create project" @click="createProject"/>
          </v-col>
        </v-row>
        <v-row class="ma-0 mx-n6 pt-4">
          <v-col class="pa-4" cols="6" v-for="(project, index) of projectsList" :key="project.id">
            <ProjectsSneakPeek v-model:project="projectsList[index]"/>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/UIElements/CustomButton.vue';
import ProjectsSneakPeek from '@/components/Projects/ProjectSneakPeek.vue';
import { Vue, Options } from 'vue-class-component';
import API from '@/api/wrapper';
import { Block } from '@/types';
import { APIBlockCategory, APIProject } from '@/typesAPI';

@Options({
  components: {
    CustomButton,
    ProjectsSneakPeek
  },
})
export default class ProjectsView extends Vue {

  get projectsList():APIProject[] {
    return this.$store.getters['projects/getProjectList']
  }

  mounted(): void {
    API.blocks.getCategories()
    .then(({blockCategories, definitions}) => {
      const blocks:Block[] = []
      blockCategories.forEach((blockCategory:APIBlockCategory) => {
        const block:Block = {
          ...blockCategory.attributes,
          id: blockCategory.id,
          preselectedFieldDefinitionId: blockCategory?.relationships.preselect_field_definition.data.id
        }
        blocks.push(block)
      })
      this.$store.dispatch('blocks/setBlockList', blocks)
      this.$store.dispatch('fields/addNodeFieldDefinitions', definitions)
    })
  }

  createProject() {
    API.projects.create()
    .then((newProject:APIProject) => {
      this.projectsList.push(newProject)
    })
  }

}
</script>

<style lang="scss" scoped>
</style>
