import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toastr = _resolveComponent("Toastr")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_component_v_col, { class: "toastr-manager pa-0" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.descriptors, (descriptor, index) => {
          return (_openBlock(), _createBlock(_component_Toastr, {
            key: 'toastr-' + descriptor.id,
            position: index,
            severity: descriptor.severity,
            title: descriptor.title,
            text: descriptor.text,
            onClose: ($event: any) => (_ctx.close(descriptor)),
            class: "mb-3"
          }, null, 8, ["position", "severity", "title", "text", "onClose"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}