import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { toRefs} from 'vue'

  const maxDelayBetweenInputs = 1000 //ms

  
export default /*@__PURE__*/_defineComponent({
  __name: 'KCDetector',
  props: {
    activated: Boolean
  },
  emits: ['KCActivation'],
  setup(__props, { emit: __emit }) {

  const props = __props
  let { activated } = toRefs(props);
  const $emit = __emit


  let currentCode: string[] = []
  const successCode: string[] = ["ArrowUp", "ArrowUp", "ArrowDown", "ArrowDown", "ArrowLeft", "ArrowRight", "ArrowLeft", "ArrowRight", "b", "a", "Enter"]
  let lastInputDate: Date = new Date()
  function watchInputs () {
    const html:HTMLHtmlElement = document.getElementsByTagName('html')[0]
    html.addEventListener('keydown', (e:KeyboardEvent) => {
      if(!activated.value) {
        const newInputDate = new Date()
        if(newInputDate.getTime() - lastInputDate.getTime() > maxDelayBetweenInputs) {
          currentCode = []
        }
        lastInputDate = newInputDate
        currentCode.push(e.key)

        if(currentCode.join('').includes(successCode.join(''))) {
          $emit('KCActivation')
        }
      }
    })
  }
  watchInputs()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span"))
}
}

})