import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Handle = _resolveComponent("Handle")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_ListElement = _resolveComponent("ListElement")!
  const _component_Popover = _resolveComponent("Popover")!
  const _component_BlockBarContext = _resolveComponent("BlockBarContext")!

  return (_openBlock(), _createElementBlock("div", {
    class: "block-node__hoverable",
    onMouseenter: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnter && _ctx.onMouseEnter(...args))),
    onMouseleave: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["block-node _node", {
        'suggest-connection' : _ctx.isConnectionSuggested,
        'started-connection' : _ctx.isStartOfConnection,
        'is-dragged' : _ctx.isDragged,
        'is-disappearing': _ctx.isDisappearing,
        needToAppear: _ctx.needToAppear,
        'in-error' : _ctx.isValidationErrorHovered ? _ctx.$store.getters['errorsValidation/getHoveredError'].meta.severity === 'error' : false,
        'in-warning' : _ctx.isValidationErrorHovered ? _ctx.$store.getters['errorsValidation/getHoveredError'].meta.severity === 'warning' : false,
        'in-info' : _ctx.isValidationErrorHovered ? _ctx.$store.getters['errorsValidation/getHoveredError'].meta.severity === 'info' : false,
      }]),
      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, [
      _createVNode(_component_v_row, { class: "ma-0 title" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            (_ctx.blockAPI && _ctx.$enums.EnginesLogos[_ctx.blockAPI.attributes.default_name])
              ? (_openBlock(), _createBlock(_component_Logo, {
                  key: 0,
                  logo: _ctx.$enums.EnginesLogos[_ctx.blockAPI.attributes.default_name]
                }, null, 8, ["logo"]))
              : (_ctx.block.icon)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 1,
                    icon: _ctx.block.icon
                  }, null, 8, ["icon"]))
                : (_ctx.block.logo)
                  ? (_openBlock(), _createBlock(_component_Logo, {
                      key: 2,
                      logo: _ctx.block.logo
                    }, null, 8, ["logo"]))
                  : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, _toDisplayString(_ctx.blockName), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Handle, {
        class: "handle handle-left",
        type: "source",
        position: _ctx.Position.Left
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createElementVNode("div", { class: "handle-display" }, [
            _createElementVNode("div", { class: "handle-continuum" })
          ], -1)
        ])),
        _: 1
      }, 8, ["position"]),
      _createVNode(_component_Handle, {
        class: "handle handle-right",
        type: "source",
        position: _ctx.Position.Right
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [
          _createElementVNode("div", { class: "handle-display" }, [
            _createElementVNode("div", { class: "handle-continuum" })
          ], -1)
        ])),
        _: 1
      }, 8, ["position"]),
      _createVNode(_component_Handle, {
        class: "handle handle-top",
        type: "source",
        position: _ctx.Position.Top
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [
          _createElementVNode("div", { class: "handle-display" }, [
            _createElementVNode("div", { class: "handle-continuum" })
          ], -1)
        ])),
        _: 1
      }, 8, ["position"]),
      _createVNode(_component_Handle, {
        class: "handle handle-bottom",
        type: "source",
        position: _ctx.Position.Bottom
      }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [
          _createElementVNode("div", { class: "handle-display" }, [
            _createElementVNode("div", { class: "handle-continuum" })
          ], -1)
        ])),
        _: 1
      }, 8, ["position"]),
      _createVNode(_component_Handle, {
        class: _normalizeClass(["handle handle-middle", {'is-connecting' : _ctx.isStartOfConnection, 'is-link-selected' : _ctx.isLinkRightSelected || _ctx.isLinkRightHovered}]),
        type: "source",
        position: _ctx.Position.Right
      }, {
        default: _withCtx(() => [
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "handle-inside-circle" }, null, -1)),
          _createElementVNode("div", {
            class: "handle-interceptor",
            draggable: "true",
            onDrag: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dragLink($event, 'target'))),
            onDragend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragLinkEnd && _ctx.dragLinkEnd(...args))),
            onDragstart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dragLinkStart($event, 'target')))
          }, null, 32)
        ]),
        _: 1
      }, 8, ["class", "position"]),
      (!_ctx.noStatus && _ctx.blockStatusIcon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["indicator-status", {'no-pointer': !_ctx.authoriseHealthCheck}]),
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onStatusIndicatorClick && _ctx.onStatusIndicatorClick(...args))),
            ref: "indicatorRef"
          }, [
            _createVNode(_component_Icon, { icon: _ctx.blockStatusIcon }, null, 8, ["icon"]),
            _createVNode(_component_Popover, {
              visible: _ctx.showHealthCheck,
              "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showHealthCheck) = $event)),
              target: _ctx.$refs.indicatorRef,
              closeOnExternalClick: true
            }, {
              header: _withCtx(() => [
                _createVNode(_component_v_col, { class: "pa-0" }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode(" Health checks ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_col, { class: "pa-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CustomButton, {
                      class: "ml-auto",
                      level: _ctx.$enums.ButtonLevels.SmallIcon,
                      icon: "x",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showHealthCheck = false))
                    }, null, 8, ["level"])
                  ]),
                  _: 1
                })
              ]),
              content: _withCtx(() => [
                _createVNode(_component_v_col, { class: "pa-0" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.healthChecks, (healthCheck) => {
                      return (_openBlock(), _createBlock(_component_v_row, {
                        class: "ma-0",
                        key: 'healthCheck-' + healthCheck.id
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ListElement, {
                            icon: _ctx.translateHealthCheckStatusToIcon(healthCheck.attributes.status),
                            noHover: true,
                            disabledButColored: true,
                            class: "ml-0"
                          }, {
                            content: _withCtx(() => [
                              _createTextVNode(_toDisplayString(healthCheck.attributes.message), 1)
                            ]),
                            _: 2
                          }, 1032, ["icon"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["visible", "target"])
          ], 2))
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.isDisappearing)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["btn-plus round-icon-btn", {'btn-deployed': _ctx.IsButtonDeployed, needToAppear: _ctx.needToAppear}]),
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showBlockBar = true))
        }, [
          _createVNode(_component_Icon, {
            icon: "add",
            fullSize: true
          })
        ], 2))
      : _createCommentVNode("", true),
    ((_ctx.showBlockBar && _ctx.isHovered && !_ctx.isDisappearing))
      ? (_openBlock(), _createBlock(_component_BlockBarContext, {
          key: 1,
          class: "block-bar-context",
          positionXY: _ctx.positionXYNewBlock,
          linkSrc: _ctx.props.id,
          onAddNode: _ctx.onAddNode
        }, null, 8, ["positionXY", "linkSrc", "onAddNode"]))
      : _createCommentVNode("", true)
  ], 32))
}