import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/illustrations/link_expired.png'
import _imports_1 from '@/assets/illustrations/link_not_exist.png'
import _imports_2 from '@/assets/illustrations/link_already_used.png'


const _hoisted_1 = { class: "tokenLoginView full-view" }
const _hoisted_2 = { class: "ma-auto error bar" }
const _hoisted_3 = { class: "ma-auto token-text-regular token-text-color-secondary" }
const _hoisted_4 = { class: "ma-auto token-text-regular token-text-color-secondary" }
const _hoisted_5 = {
  key: 3,
  class: "ma-auto token-text-h4 color-error"
}
const _hoisted_6 = { class: "full-width mb-2 mt-6" }
const _hoisted_7 = { class: "full-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_BackgroundGradiant = _resolveComponent("BackgroundGradiant")!
  const _component_FransysSignature = _resolveComponent("FransysSignature")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 0,
          class: "ma-auto"
        }))
      : (_ctx.error.title)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_BackgroundGradiant),
            _createVNode(_component_v_col, { class: "pa-0 my-auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "ma-0 pb-12 mx-auto fit-content" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FransysSignature)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, { class: "ma-0" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      (_ctx.error.title === _ctx.ERROR_TYPES.TOKEN_EXPIRED)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_component_v_img, {
                              src: _imports_0,
                              height: "56px",
                              width: "56px",
                              class: "mx-auto mb-6"
                            }),
                            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "ma-auto token-text-h4 mb-4" }, " This link expired ", -1)),
                            _createElementVNode("div", _hoisted_3, " Every link is only usable for " + _toDisplayString(_ctx.meta[_ctx.ERROR_TYPES.TOKEN_EXPIRED].expiration), 1),
                            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "ma-auto token-text-regular token-text-color-secondary" }, " To log in, generate a new log in link ", -1))
                          ], 64))
                        : (_ctx.error.title === _ctx.ERROR_TYPES.RESOURCE_NOT_FOUND)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createVNode(_component_v_img, {
                                src: _imports_1,
                                height: "56px",
                                width: "56px",
                                class: "mx-auto mb-6"
                              }),
                              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "ma-auto token-text-h4 mb-4" }, " This link does not exist ", -1)),
                              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "ma-auto token-text-regular token-text-color-secondary" }, " It seems the link you used does not exist ", -1)),
                              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "ma-auto token-text-regular token-text-color-secondary" }, " To log in, generate a new log in link ", -1))
                            ], 64))
                          : (_ctx.error.title === _ctx.ERROR_TYPES.TOKEN_MAX_USED)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                _createVNode(_component_v_img, {
                                  src: _imports_2,
                                  height: "56px",
                                  width: "56px",
                                  class: "mx-auto mb-6"
                                }),
                                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "ma-auto token-text-h4 mb-4" }, " This link has already been used ", -1)),
                                _createElementVNode("div", _hoisted_4, " Every link is only usable " + _toDisplayString(_ctx.meta[_ctx.ERROR_TYPES.TOKEN_MAX_USED].max_uses), 1),
                                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "ma-auto token-text-regular token-text-color-secondary" }, " To log in, generate a new log in link ", -1))
                              ], 64))
                            : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.error.details), 1)),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_CustomTextInput, {
                          modelValue: _ctx.email,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                          placeholder: "hello@email.com"
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_CustomButton, {
                          level: _ctx.$enums.ButtonLevels.Primary,
                          class: "full-width",
                          onClick: _ctx.generateNewLink
                        }, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode(" Generate Link ")
                          ])),
                          _: 1
                        }, 8, ["level", "onClick"])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
  ]))
}