<template>
  <div class="fransys-signature" :class="vertical ? 'v-col pa-0' : 'v-row ma-0'">
    <template v-if="vertical">
      <Logo logo="fransys" class="mx-0 my-auto"/>
      <Logo logo="fransys-txt" class="mx-0 mt-3 my-auto"/>
    </template>
    <template v-else>
      <Logo logo="fransys" class="mx-0 my-auto"/>
      <Logo logo="fransys-txt" class="mx-0 ml-3 my-auto"/>
    </template>
  </div>

</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component';
import Logo from '@/components/UIElements/Logo.vue';

class Props {
  vertical?: boolean = prop({
    required: false,
  });
}


@Options({
  components: {
    Logo,
  },
})
export default class FransysSignature extends Vue.with(Props) {
}
</script>

<style lang="scss" scoped>
  .fransys-signature {
    :deep(.logo-fransys) {
      mask-size: 36px !important;
      width: 36px;
      height: 60px;
    }
  }
</style>
