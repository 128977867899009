import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tooltip fit-content" }
const _hoisted_2 = {
  class: "iconContainer",
  ref: "iconContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CustomButton, {
        class: "",
        level: _ctx.$enums.ButtonLevels.SmallIcon,
        icon: "question-circle",
        onClick: _ctx.onClickShow,
        onMouseenter: _ctx.onStartHoverButton,
        onMouseleave: _ctx.onEndHoverButton
      }, null, 8, ["level", "onClick", "onMouseenter", "onMouseleave"])
    ], 512),
    (_ctx.target)
      ? (_openBlock(), _createBlock(_component_Popover, {
          key: 0,
          maxWidth: "512",
          visible: _ctx.show,
          "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ($event ? '' : _ctx.onClickHide())),
          target: _ctx.target,
          closeOnExternalClick: _ctx.clicked,
          isHovered: _ctx.hoverPopover,
          "onUpdate:isHovered": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hoverPopover) = $event))
        }, _createSlots({
          header: _withCtx(() => [
            _createElementVNode("div", null, [
              _renderSlot(_ctx.$slots, "header", {}, undefined, true)
            ]),
            _createVNode(_component_CustomButton, {
              class: "ml-auto mr-2 my-auto",
              level: _ctx.$enums.ButtonLevels.SmallIcon,
              icon: "x",
              onClick: _ctx.onClickHide
            }, null, 8, ["level", "onClick"])
          ]),
          _: 2
        }, [
          (_ctx.$slots['content'] || _ctx.docLink)
            ? {
                name: "content",
                fn: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      (_ctx.$slots['content'])
                        ? (_openBlock(), _createBlock(_component_v_row, {
                            key: 0,
                            class: "ma-0"
                          }, {
                            default: _withCtx(() => [
                              _renderSlot(_ctx.$slots, "content", {}, undefined, true)
                            ]),
                            _: 3
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.docLink)
                        ? (_openBlock(), _createBlock(_component_v_row, {
                            key: 1,
                            class: "ma-0"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_CustomButton, {
                                class: "ml-auto mt-4",
                                level: _ctx.$enums.ButtonLevels.Tertiary,
                                onClick: _ctx.onDocLinkClick
                              }, {
                                default: _withCtx(() => [
                                  _cache[2] || (_cache[2] = _createTextVNode(" Learn more ")),
                                  _createVNode(_component_Icon, {
                                    icon: "share",
                                    class: "ml-2"
                                  })
                                ]),
                                _: 1
                              }, 8, ["level", "onClick"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 3
                  })
                ]),
                key: "0"
              }
            : undefined,
          (_ctx.$slots['footer'])
            ? {
                name: "footer",
                fn: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                  ])
                ]),
                key: "1"
              }
            : undefined
        ]), 1032, ["visible", "target", "closeOnExternalClick", "isHovered"]))
      : _createCommentVNode("", true)
  ]))
}