<template>
  <div class="usage-alert full-width"
    :class="{disabled: disabled, deployed : deployed}"
  >
    <div 
      class="usage-alert-btn-deploy"
      :class="{disabled: disabled}"
      @click="deploy"
    >
    <template v-if="!deployed">
      <v-col class="pa-4 usage-alert-summary">
        <v-row class="ma-0">
          <v-col class="pa-0 ma-0 usage-alert-btn-deploy-text token-text-medium fit-content" ref="rowTitle" cols="11">
            {{selectedVal.value + '% of ' + selectedLimit.text}}
          </v-col>
          <v-col  class="pa-0 ma-0 ml-auto usage-alert-btn-deploy-item-selected fit-content" ref="rowValue">
            <div class="usage-alert-value">
              <slot name="value" ></slot>
            </div>
          </v-col>
          <v-col  v-if="!disabled"  class="pa-0 ma-0 usage-alert-btn-deploy-icon pl-auto fit-content">
            <Icon icon="caret-down"/>
          </v-col>
        </v-row>
      </v-col>
    </template>
    <template v-else>
      <v-col class="pa-0 usage-alert-btn-deploy-text token-text-medium fit-content" cols="11">
        Edit alert
      </v-col>
      <v-col align="right" class="pa-0 usage-alert-btn-deploy-item-selected fit-content">
        <div class="usage-alert-value">
          &nbsp;
        </div>
      </v-col>
      <v-col align="right" v-if="!disabled"  class="pa-0 usage-alert-btn-deploy-icon pl-auto fit-content" >
        <Icon icon="caret-down"/>
      </v-col>
    </template>

    </div>
    <HeightDeployer v-model="deployed" class="deployed-container" @expand="$emit('update:forceDeployed', undefined)" @collapse="$emit('update:forceDeployed', undefined)">
      <v-col class="pa-0">
        <v-row class="ma-0">
          <span class="my-auto">
            When reaching
          </span> 
          <CustomSelect :items="itemsVal" v-model="selectedVal" @update:modelValue="selectedVal = $event" class="select-val mx-2"/>
          <span class="my-auto">
            % of
          </span>
          <CustomSelect :items="itemsLimit" v-model="selectedLimit" @update:modelValue="selectedLimit = $event" class="select-limit mx-2"/>
        </v-row>
        <v-row class="ma-0 my-4 mx-n8">
          <v-col class="pa-0">
            <Divider/>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <CustomButton :level="$enums.ButtonLevels.Tertiary"   class="delete-btn"  text="Delete"  icon="trash"  @click="deleteAlert"/>
          <CustomButton :level="$enums.ButtonLevels.Tertiary"   class="ml-auto"     text="Cancel" @click="collapse" />
          <CustomButton :level="$enums.ButtonLevels.Secondary"  class=""            text="Save alert" @click="saveAlert"/>
        </v-row>
      </v-col>
    </HeightDeployer>
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import HeightDeployer from '@/components/UIElements/HeightDeployer.vue'
import Icon from '@/components/UIElements/Icon.vue'
import Divider from '@/components/UIElements/Divider.vue'
import CustomSelect from '@/components/UIElements/CustomSelect.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import { ListItem } from '@/components/UIElements/List.vue';

class Props {
  usageAlert: any = prop({
    required: true,
  });

}

@Options({
  components: {
    HeightDeployer,
    Icon,
    Divider,
    CustomSelect,
    CustomButton
  },
})
export default class UsageAlert extends Vue.with(Props) {

  deployed = false

  itemsVal:ListItem[] = [
    {
      text:'35',
      value: '35'
    },
    {
      text:'50',
      value: '50'
    },
    {
      text:'80',
      value: '80'
    },
    {
      text:'100',
      value: '100'
    },
  ]
  selectedVal = this.itemsVal[0]

  itemsLimit:ListItem[]  = [
    {
      text:'burn rate limit',
      value: 'brl'
    },
    {
      text:'monthly spending hard limit',
      value: 'hl'
    },
  ]

  selectedLimit = this.itemsLimit[0]

  get disabled():boolean {
    return this.usageAlert?.readonly
  }

  mounted(): void {
    this.initValues()
  }

  initValues () {
    const foundLimit = this.itemsLimit.find((limit) => limit.value === this.usageAlert.limit)
    if(foundLimit) {
      this.selectedLimit = foundLimit
    }
    const foundVal = this.itemsVal.find((val) => val.value === this.usageAlert.value.toString())
    if(foundVal) {
      this.selectedVal = foundVal
    }
  }


  onSelectedValUpdate(newVal:any) {
    this.selectedVal = newVal
  }

  saveAlert () {
    console.log('SaveAlert', this.usageAlert)
  }
  
  deleteAlert () {
    console.log('delete', this.usageAlert)
  }

  deploy() {
    if(!this.disabled) {
      if(this.deployed) {
        this.collapse()
      } else {
        this.expand()
      }
    }
  }

  collapse() {
    this.initValues()
    this.deployed = false
  }

  expand() {
    this.deployed = true
  }


}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
.usage-alert {
  background: #FFFFFF;
  border: 1px solid $color-neutral-grey-12;
  border-radius: 8px;
  min-height: 40px;
  transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  overflow: hidden;

  .delete-btn {
    color: $color-orange;
  }

  .select-val {
    width: 70px;
  }
  .select-limit {
    width: 260px;
  }

  .usage-alert-summary {
    transition: opacity 0.2s ease-in-out;
  }
  .deployed-container {
    margin: 16px;
    &.deployed {
      margin: 8px !important;
      padding: 8px !important;
    }
  }
  .usage-alert-btn-deploy {
    width: 100%;
    overflow: hidden;
    padding: 0;
    justify-content: flex-start;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;

    
    &.disabled {
      pointer-events: none;
      cursor: inherit;
      background: $color-neutral-grey-8 !important;
      color: $color-neutral-grey-60 !important;
      .usage-alert-btn-deploy-item-selected {
        color:black;
      }
    }
    .usage-alert-btn-deploy-text {
      margin-top:auto;
      margin-bottom:auto;
      margin-left: 16px;
      margin-right: auto;
      color: $color-neutral-grey-60;
      transition: color 0.2s ease-in-out;
    }
    .usage-alert-btn-deploy-item-selected {
      margin-top:16px;
      margin-bottom:16px;
      margin-right: 16px;
      margin-left: auto;
      color: $color-neutral-black;
      .usage-alert-value {
        width: 100%;
      }
    }
    .usage-alert-btn-deploy-icon {
      display: flex;
      border-radius: 8px;
      width: 24px;
      height: 24px;
      margin-top:auto;
      margin-bottom:auto;
      margin-left: auto;
      margin-right:12px;
      opacity: 0.6;
      transition: opacity 0.2s ease-in-out;
    }
  }
  &:hover {
    &:not(.disabled) {
      &:not(.deployed) {
        &.isCollapsible {
          border: 1px solid $color-neutral-grey-30;
          box-shadow: $shadow-z2;
        }
        &:not(.isCollapsible) {
          cursor: auto;
        }
      }
    }
  }
  &.deployed {
    border: 1px solid $color-brand;
    box-shadow: $shadow-z1;

    .usage-alert-btn-deploy {
      transition: background 0.2s ease-in-out;
      &:hover {
        &.isCollapsible {
          background: $color-background-button-text-tertiary-hover;
        }
        &:not(.isCollapsible) {
          cursor: auto;
        }
      }
      &:active {
        background: $color-background-button-text-tertiary-pressed;
      }
    }

    .usage-alert-btn-deploy-icon {
      opacity: 1;
    }
    .usage-alert-btn-deploy-text {
      color: $color-neutral-black;
    }
  }

  &.invisible {
    border: none !important;
    box-shadow: none !important;
    .usage-alert-btn-deploy {
      transition: background 0.2s ease-in-out;
      &:hover {
        &.isCollapsible {
          background: $color-background-button-text-tertiary-hover;
        }
        &:not(.isCollapsible) {
          cursor: auto;
        }
      }
      &:active {
        background: $color-background-button-text-tertiary-pressed;
      }
    }
    .deployed-container {
      &.deployed {
        margin: 0px !important;
        padding: 8px !important;
      }
  }
  }
}
</style>
<style lang="scss">
@import '@/css/variables';

.usage-alert { 
  .usage-alert-btn-deploy {
    .usage-alert-btn-deploy-icon {
      .icon-container {
        transition: transform 0.2s ease-in-out;
        transform: rotate(-90deg);
      }
      .icon {
        background: $color-neutral-grey-60 !important;
      }
    }
  }
  &.deployed {
    .usage-alert-btn-deploy-icon {
      .icon-container {
        transform: rotate(0deg);
      }
    }
  }
}
</style>