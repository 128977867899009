<template>
  <div class="background">
    <div class="game-area" ref="gameArea" :class="{playBG: !paused}">

      <!-- START Animated background -->
      <div class='air air1'></div>
      <div class='air air2'></div>
      <div class='air air3'></div>
      <div class='air air4'></div>
      <div class="stars">
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
      </div>
      <!-- END Animated background -->

      <div class="obstacle" ref="obstacle1">
        <div class="top"></div>
        <div class="gap"></div>
        <div class="bottom"></div>
      </div>
      <div class="obstacle" ref="obstacle2">
        <div class="top"></div>
        <div class="gap"></div>
        <div class="bottom"></div>
      </div>
      <div class="obstacle" ref="obstacle3">
        <div class="top"></div>
        <div class="gap"></div>
        <div class="bottom"></div>
      </div>
      <div class="obstacle" ref="obstacle4">
        <div class="top"></div>
        <div class="gap"></div>
        <div class="bottom"></div>
      </div>
      <div class="obstacle" ref="obstacle5">
        <div class="top"></div>
        <div class="gap"></div>
        <div class="bottom"></div>
      </div>

      <div class="player" ref="player"></div>

      <div class="title" v-if="paused">
        Fransys Bird
      </div>

      <div class="pause-menu" v-if="paused">
        Paused <br/>
        <br/>
        Space to flap<br/>
        Enter to un/pause<br/>
        Escape to quit<br/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, defineEmits } from "vue";

  const $emit = defineEmits(['quit'])

  const gameArea = ref<HTMLDivElement>()
  const player = ref<HTMLDivElement>()
  const obstacle1 = ref<HTMLDivElement>()
  const obstacle2 = ref<HTMLDivElement>()
  const obstacle3 = ref<HTMLDivElement>()
  const obstacle4 = ref<HTMLDivElement>()
  const obstacle5 = ref<HTMLDivElement>()
  const obstacles = [
    {
      ref: obstacle1,
      offset: 0
    }, 
    {
      ref: obstacle2,
      offset: 0
    }, 
    {
      ref: obstacle3,
      offset: 0
    }, 
    {
      ref: obstacle4,
      offset: 0
    }, 
    {
      ref: obstacle5,
      offset: 0
    }
  ]
  const tops:HTMLDivElement[] = []
  const gaps:HTMLDivElement[] = []
  const bottoms:HTMLDivElement[] = []
  let gameAreaRect:DOMRect = new DOMRect()


  let playerY = 250
  let isFlapping = false
  let isFlappingNbFrame = 0
  let isFlappingNbMaxFrame = 6

  const gapBetweenObstacles = 700
  const gapBetweenTopBottomPx = 200
  const gapBetweenTopBottomVariation = 150
  const sizeTopPercent = 25
  const sizeTopPercentVariation = 25
  const obstacleSpeed = 50
  const playerSpeed = 30

  let paused = ref(true)


  function initKeyListen() {
    const html:HTMLHtmlElement = document.getElementsByTagName('html')[0]
    function listener(e:KeyboardEvent) {
      if(e.key === 'Escape') {
        html.removeEventListener('keydown', listener)
        $emit('quit')
      }

      if(e.key === 'Enter') {
        paused.value = !paused.value
      }
      
      if(!paused.value) {
        if(e.key === ' ') {
          isFlapping = true
          isFlappingNbFrame = 0
        }
      }
     
      e.preventDefault();
      e.stopPropagation();
    }
    html.addEventListener('keydown', listener)
      
  }

  function initGame() {

    paused.value = true

    gameAreaRect = gameArea.value?.getClientRects() ? gameArea.value?.getClientRects()[0] : new DOMRect()
    const baseOffset = gameAreaRect.width - (gameAreaRect.width / 4)

    for(const obstacle of obstacles) {
      if(obstacle && obstacle.ref.value) {
        tops.push(obstacle?.ref.value.getElementsByClassName('top')[0] as HTMLDivElement)
        gaps.push(obstacle?.ref.value.getElementsByClassName('gap')[0] as HTMLDivElement)
        bottoms.push(obstacle?.ref.value.getElementsByClassName('bottom')[0] as HTMLDivElement)
      }
    }

    for(const obstacleIndex in obstacles) {
      obstacles[obstacleIndex].offset = baseOffset + (parseInt(obstacleIndex) * gapBetweenObstacles) 
    }

    playerY = gameAreaRect.height / 2

    majObstaclePos()
    majPlayerPos()
    randomizeObstacleSize()
  }

  function majPlayerPos() {
    if(!paused.value) {
      if(isFlapping) {
        isFlappingNbFrame++
        if(isFlappingNbFrame >= isFlappingNbMaxFrame) {
          isFlapping = false
        }
        playerY -= playerSpeed
      } else {
        playerY += 1.5*playerSpeed
      }
    }

    player.value?.setAttribute('style', 'top:' +playerY + 'px;')
  }

  function majObstaclePos() {
    for(const obstacleIndex in obstacles) {
      if(!paused.value) {
        obstacles[obstacleIndex].offset -= obstacleSpeed
      }
      if(obstacles[obstacleIndex].offset < -110 ) {
        const lessOffseted = obstacles.reduce(function(prev, curr) {
            return prev.offset > curr.offset ? prev : curr;
        });
        obstacles[obstacleIndex].offset = lessOffseted.offset + gapBetweenObstacles
        randomizeObstacleSize(parseInt(obstacleIndex))
        obstacles[obstacleIndex].ref.value?.setAttribute('style', 'left: ' + obstacles[obstacleIndex].offset + 'px; transition: none;')
      } else {
        obstacles[obstacleIndex].ref.value?.setAttribute('style', 'left: ' + obstacles[obstacleIndex].offset + 'px')
      }
    }
  }

  function randomizeObstacleSize(index?:number) {
    if(index !== undefined) {
      let sizeTopPercentTemp = sizeTopPercent + (sizeTopPercentVariation * Math.random() )
      let gapBetweenTopBottomPxTemp = gapBetweenTopBottomPx + ( gapBetweenTopBottomVariation * Math.random())

      tops[index].setAttribute('style', 'height: ' + parseInt((gameAreaRect.height * (sizeTopPercentTemp/100)).toString()) + 'px')
      gaps[index].setAttribute('style', 'height: ' + parseInt((gapBetweenTopBottomPxTemp).toString()) + 'px')
      bottoms[index].setAttribute('style', 'height: ' + parseInt( ((gameAreaRect.height * (1 - (sizeTopPercentTemp/100))) - gapBetweenTopBottomPxTemp + 5).toString()) + 'px')
    } else {
      for(const obstacleIndex in obstacles) {
        let sizeTopPercentTemp = sizeTopPercent + (sizeTopPercentVariation * Math.random() )
        let gapBetweenTopBottomPxTemp = gapBetweenTopBottomPx + ( gapBetweenTopBottomVariation * Math.random())

        tops[obstacleIndex].setAttribute('style', 'height: ' + parseInt((gameAreaRect.height * (sizeTopPercentTemp/100)).toString()) + 'px')
        gaps[obstacleIndex].setAttribute('style', 'height: ' + parseInt((gapBetweenTopBottomPxTemp).toString()) + 'px')
        bottoms[obstacleIndex].setAttribute('style', 'height: ' + parseInt( ((gameAreaRect.height * (1 - (sizeTopPercentTemp/100))) - gapBetweenTopBottomPxTemp + 5).toString()) + 'px')
      }
    }

    
  }

  function calculateCollision() {
    const playerRect:DOMRect = player.value?.getClientRects() ? player.value?.getClientRects()[0] : new DOMRect()

    if(playerRect.bottom >= gameAreaRect.bottom || playerRect.top <= gameAreaRect.top) {
      initGame()
      return
    }

    for(const top of tops) {
      const topRect:DOMRect = top.getClientRects() && top.getClientRects()[0] ? top.getClientRects()[0] : new DOMRect()
      if(
        (playerRect.x > topRect.x && (playerRect.x < topRect.x + topRect.width) && playerRect.y > topRect.y && (playerRect.y < topRect.y + topRect.height)) || //Top left
        (playerRect.x + playerRect.width > topRect.x && (playerRect.x + playerRect.width < topRect.x + topRect.width) && playerRect.y > topRect.y && (playerRect.y < topRect.y + topRect.height)) //Top right
      ) {
        initGame()
        return
      }
    }

    for(const bottom of bottoms) {
      const bottomRect:DOMRect = bottom.getClientRects() && bottom.getClientRects()[0] ? bottom.getClientRects()[0] : new DOMRect()
      if(
        (playerRect.x > bottomRect.x && (playerRect.x < bottomRect.x + bottomRect.width) && playerRect.y + playerRect.width > bottomRect.y && (playerRect.y + playerRect.width < bottomRect.y + bottomRect.height)) || //Bottom left
        (playerRect.x + playerRect.height > bottomRect.x && (playerRect.x + playerRect.height < bottomRect.x + bottomRect.width) && playerRect.y + playerRect.width > bottomRect.y && (playerRect.y + playerRect.width < bottomRect.y + bottomRect.height)) //Bottom right
      ) {
        initGame()
        return
      }
    }
   
  }

  function gameplayLoop() {
    majObstaclePos()
    majPlayerPos()
    calculateCollision()
  }

  onMounted(() => {
    initKeyListen()
    initGame()
    setInterval(() => {
      if(!paused.value) {
        gameplayLoop()
      }
    },100)
  })


</script>

<style lang="scss" scoped>
.background {
  z-index: 100000000000000000;
  background: rgba(0,0,0,0.6);
  backdrop-filter: blur(4px) !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  .game-area {
    position: absolute;
    z-index: 100000000000000001;
    background: radial-gradient(ellipse at bottom, #0d1d31 0%, #0c0d13 100%);
    border-radius: 12px;
    top: 1vh;
    left: 1vw;
    width: 97vw;
    height: 97vh;
    overflow: hidden;
    display: flex;
    ////////

    @mixin sp-layout {
      @media screen and (max-width: 750px) {
        @content;
      }
    }

    @function random_range($min, $max) {
      $rand: random();
      $random_range: $min + floor($rand * (($max - $min) + 1));
      @return $random_range;
    }

    .stars {
      position: absolute;
      top: 0;
      left: 0;
      width: 97vw;
      height: 97vh;
      transform: rotate(-0deg);
    }

    .star {
      $star-count: 50;
      --primary-color: hsl(196, 78%, 61%);
      --star-color: var(--primary-color);
      --star-tail-length: 6em;
      --star-tail-height: 2px;
      --star-width: calc(var(--star-tail-length) / 6);
      --fall-duration: 9s;
      --tail-fade-duration: var(--fall-duration);

      position: absolute;
      top: var(--top-offset);
      left: 0;
      width: var(--star-tail-length);
      height: var(--star-tail-height);
      color: var(--star-color);
      background: linear-gradient(45deg, currentColor, transparent);
      border-radius: 50%;
      filter: drop-shadow(0 0 6px currentColor);
      transform: translate3d(100vw, 0, 0);
      
      @include sp-layout {
        // For mobile performance, tail-fade animation will be removed QAQ
        animation: fall var(--fall-duration) var(--fall-delay) linear infinite;
      }

      @for $i from 1 through $star-count {
        &:nth-child(#{$i}) {
          --star-tail-length: #{random_range(500em, 750em) / 100};
          --top-offset: #{random_range(0vh, 10000vh) / 100};
          --fall-duration: #{random_range(6000, 12000s) / 1000};
          --fall-delay: #{random_range(0, 10000s) / 1000};
        }
      }

      &::before, &::after {
        position: absolute;
        content: '';
        top: 0;
        left: calc(var(--star-width) / -2);
        width: var(--star-width);
        height: 100%;
        background: linear-gradient(45deg, transparent, currentColor, transparent);
        border-radius: inherit;
        animation: blink 2s linear infinite;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    @keyframes fall {
      to {
        transform: translate3d(-30em, 0, 0);
      }
    }

    @keyframes tail-fade {
      0%, 70% {
        width: var(--star-tail-length);
        opacity: 1;
      }

      80%, 90% {
        width: 0;
        opacity: 0.4;
      }

      100% {
        width: 0;
        opacity: 0;
      }
    }

    @keyframes blink {
      50% {
        opacity: 0.6;
      }
    }

    ///////////
    .air{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 200px;
      background: url(https://1.bp.blogspot.com/-xQUc-TovqDk/XdxogmMqIRI/AAAAAAAACvI/AizpnE509UMGBcTiLJ58BC6iViPYGYQfQCLcBGAsYHQ/s1600/wave.png);
      background-size: 1000px 200px
    }
    .air1{
      z-index: 1000;
      opacity: 1;
      animation-delay: 0s;
      bottom: 0;
    }
    .air2{
      z-index: 999;
      opacity: 0.5;
      animation-delay: -5s;
      bottom: 10px;
    }
    .air3{
      z-index: 998;
      opacity: 0.2;
      animation-delay: -2s;
      bottom: 15px;
    }
    .air4{
      z-index: 997;
      opacity: 0.7;
      animation-delay: -5s;
      bottom: 20px;
    }
    @keyframes wave{
      0%{
        background-position-x: 0px; 
      }
      100%{
        background-position-x: -1000px; 
      }
    }
    @keyframes wave2{
      0%{
        background-position-x: 0px; 
      }
      100%{
        background-position-x: -1000px; 
      }
    }

    &.playBG {
      .air1{
        animation: wave 10s linear infinite;
      }
      .air2{
        animation: wave2 5s linear infinite;
      }
      .air3{
        animation: wave 10s linear infinite;
      }
      .air4{
        animation: wave2 2s linear infinite;
      }
      .star {
        animation: fall var(--fall-duration) var(--fall-delay) linear infinite, tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;
      }
    }
  }
  .pause-menu {
    z-index: 100000000000000100;
    font-size: 50px;
    margin: auto;
    padding: 24px;
    text-align: center;
    border-radius: 12px;
    background: rgba(255,255,255,0.8);
    backdrop-filter: blur(2px) !important;
  }
  .title {
    z-index: 100000000000000100;
    font-size: 50px;
    width: 100%;
    position: absolute;
    padding: 24px;
    text-align: center;
    border-radius: 12px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: rgba(255,255,255,0.8);
    backdrop-filter: blur(2px) !important;
  }
  .player {
    z-index: 100000000000000020;
    transition: top 0.1s linear;
    background: url(https://assets-global.website-files.com/62d9b7ac8a9a4e78bcc1cc13/6380fa36992fecdac450a133_Logo-fransys-no-text.svg);
    background-color: yellow;
    border: 2px solid black;
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50% - 25px);
    left: 20vw;
    border-radius: 12px;
  }

  .obstacle {
    z-index: 100000000000000010;
    position: absolute;
    width: 100px;
    height: 100%;
    left: 0px;
    transition: left 0.1s linear;
    .top {
      background-color: green;
      border: 2px solid black;
      height: 30%;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      border-top: none;
    }
    .gap {
      height: 10%;
    }
    .bottom {
      background-color: green;
      border: 2px solid black;
      height: 60%;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      border-bottom: none;
    }
  }
}

</style>