<template>
  <v-row class="ma-0 mb-2">
    <v-col class="pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-secondary mr-2 fit-content">
      {{ $helpers.dates.formatTime(log.attributes.time) }}
    </v-col>
    <v-col class="pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-secondary mr-2 fit-content">
      {{ log.attributes.pod }}
    </v-col>
    <v-col class="pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-primary">
      {{ log.attributes.message }}
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component';
import { APILiveLog } from '@/typesAPI'

class Props {
  log:APILiveLog = prop({
    required: true,
  });
}
export default class LogLine extends Vue.with(Props) {

}
</script>

<style lang="scss">
@import '@/css/variables';

</style>
