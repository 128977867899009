<template>
  <div class="subscription-status-message pa-0" :class="{hidden: hidden, transitioning: isTransitioning}">
    <!-- MODALS -->
    <ModalValidatePaymentMethodList v-model:visible="showValidatePaymentMethodList" :fullScreen="true" @success="onSuccessValidatePaymentMethodList"/>
    <ModalEditLimits v-model:visible="showEditLimits" :fullScreen="true" />
    <!-- END MODALS -->

    <template v-if="pendingState === PENDING_STATE.WAITING">
      <v-row class="subscription-status-message-background pending ma-0 pa-2 ">
        <Spinner class="mr-2"/>
        <span class="token-text-medium text my-auto">
          Waiting for payment confirmation
        </span>
      </v-row>
    </template>
    <template v-else-if="pendingState === PENDING_STATE.SUCESS">
      <v-row class="subscription-status-message-background pending-sucess ma-0 pa-2 ">
        <Icon icon="check-circle" class="mr-2"/>
        <span class="token-text-medium text my-auto">
          Subscription active
        </span>
      </v-row>
    </template>
    <template v-else-if="pendingState === PENDING_STATE.FAIL">
      <v-row class="subscription-status-message-background pending-fail ma-0 pa-2 ">
        <Icon icon="cancel-circle" class="mr-2"/>
        <span class="token-text-medium text my-auto">
          Unable to verify subscription
        </span>
      </v-row>
    </template>
    <template v-else-if="subscriptionState === $enums.SubscriptionState.TRIALING">
      <v-row class="subscription-status-message-background trialing ma-0 pa-2 ">
        <span class="token-text-medium text my-auto">
          You are on free trial
        </span>
        <span class="token-text-regular subtext my-auto ml-2">
           Your projects will be destroyed in {{timer}}. Upgrade to keep them online
        </span>
        <CustomButton class="ml-4" :level="$enums.ButtonLevels.Secondary" @click="checkout" :loading="loadingCheckout" :status="btnStatus">
          Upgrade now
        </CustomButton>
      </v-row>
    </template>
    <template v-else-if="subscriptionState === $enums.SubscriptionState.TRIAL_EXPIRED">
      <v-row class="subscription-status-message-background trial-expired ma-0 pa-2 ">
        <span class="token-text-medium text my-auto">
          Free trial is over
        </span>
        <span class="token-text-regular subtext my-auto ml-2">
          Deploys are locked and your previous workloads are now offline
        </span>
        <CustomButton class="ml-4" :level="$enums.ButtonLevels.Secondary" @click="checkout" :loading="loadingCheckout" :status="btnStatus">
          Upgrade now
        </CustomButton>
      </v-row>
    </template>
    <template v-else-if="subscriptionState === $enums.SubscriptionState.EXPIRED">
      <v-row class="subscription-status-message-background trial-expired ma-0 pa-2 ">
        <span class="token-text-medium text my-auto">
          No subscription active
        </span>
        <span class="token-text-regular subtext my-auto ml-2">
          Deploys are locked and your previous workloads are now offline
        </span>
        <CustomButton class="ml-4" :level="$enums.ButtonLevels.Secondary" @click="checkout" :loading="loadingCheckout" :status="btnStatus">
          Manage subscription
        </CustomButton>
      </v-row>
    </template>
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Popin from '@/components/UIElements/Popin.vue';
import { Vue, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Spinner from '@/components/UIElements/Spinner.vue';
import Icon from '@/components/UIElements/Icon.vue';
import { DateTime, Interval, Duration } from "luxon";
import ModalValidatePaymentMethodList from '@/components/Modals/ModalValidatePaymentMethodList.vue';
import ModalEditLimits from '@/components/Modals/ModalEditLimits.vue';

enum PENDING_STATE {
  NONE,
  WAITING,
  SUCESS,
  FAIL
}

@Options({
  components: {
    CustomButton,
    Popin,
    Spinner,
    Icon,
    ModalValidatePaymentMethodList,
    ModalEditLimits
  },
})
export default class SubscriptionStatusMessage extends Vue {
  loadingCheckout = false
  btnStatus = ''
  pendingSuccess = true
  pendingFail = false
  PENDING_STATE = PENDING_STATE
  pendingState:PENDING_STATE = PENDING_STATE.NONE
  isTransitioning = false
  timer = ""

  showEditLimits = false
  showValidatePaymentMethodList = false

  mounted(): void {
    this.loadingCheckout = false

    if(localStorage.getItem("paymentPending") === "1") {
      this.pendingState = PENDING_STATE.WAITING
      this.checkInitPendingState()
    }

    this.calculateTimer()
    setInterval(() => {
      this.calculateTimer()
    },30000)
    
  }

  onSuccessValidatePaymentMethodList() {
    this.showValidatePaymentMethodList = false
    this.showEditLimits = true
  }

  calculateTimer () {
    const expireAt = this.$store.getters['user/getUser']?.meta?.subscription_expires_at
    const nowDate = DateTime.now()
    const expireAtDate = DateTime.fromISO(expireAt)
    const i = Interval.fromDateTimes(nowDate, expireAtDate);
    this.timer = i.toDuration().shiftTo('hours', 'minutes').toHuman({ unitDisplay: 'narrow', maximumFractionDigits: 0 }).split(',').join('')
  }

  checkout() {
    this.loadingCheckout = true
    this.$helpers.subscription.checkout()
    .catch(() => {
      this.btnStatus = this.$enums.ButtonStates.Danger
      setTimeout(() => {
        this.btnStatus = ''
      },5000)
    })
    .finally(() => {
      this.loadingCheckout = false
    })
  }

  transitionTo(newState:PENDING_STATE) {
    this.isTransitioning = true
    setTimeout(() => {
      this.pendingState = newState
      this.isTransitioning = false
    },350)
  }

  get subscriptionState ():string {
    return this.$store.getters['user/getSubscriptionState']
  }

  get hidden() {
    return this.subscriptionState !== this.$enums.SubscriptionState.TRIALING && this.subscriptionState !== this.$enums.SubscriptionState.TRIAL_EXPIRED && this.subscriptionState !== this.$enums.SubscriptionState.EXPIRED && this.pendingState === PENDING_STATE.NONE
  }

  get shouldDisplayPendingMessage ():boolean {
    return this.pendingState === PENDING_STATE.WAITING
  }

  checkInitPendingState () {
    if(this.subscriptionState && this.pendingState === PENDING_STATE.WAITING) {
      if(this.subscriptionState === this.$enums.SubscriptionState.ACTIVE) {
        this.transitionTo(PENDING_STATE.SUCESS)
      } else {
        this.transitionTo(PENDING_STATE.FAIL)
      }
      setTimeout(() => {
        this.transitionTo(PENDING_STATE.NONE)
      },5000)
      localStorage.setItem("paymentPending", "0")
    }
  }

  @Watch('subscriptionState', {deep:true})
  onSubscriptionStateChange() {
    this.checkInitPendingState()
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.subscription-status-message {
  position:absolute;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  transition: bottom 0.3s ease-in-out, width 0.3s ease-in-out;

  &.transitioning {
    bottom: -60px;
  }

  &.hidden {
    bottom: -60px;
  }

  .subscription-status-message-background {
    transition: background-color 0.2s ease-in-out;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 0px -1px 4px 0px $color-neutral-grey-12;

    :deep(.icon) {
      background-color: $color-neutral-black !important;
    }
    .text, .subtext {
      transition: color 0.2s ease-in-out;
    }
    &.pending {
      background-color: $color-grey;
      .text {
        color: $color-neutral-black
      }
      .subtext {
        color: $color-neutral-black
      }
    }
    &.pending-sucess {
      background-color: $color-green;
      .text {
        color: $color-neutral-black
      }
      .subtext {
        color: $color-neutral-black
      }
    }
    &.pending-fail {
      background-color: $color-orange;
      .text {
        color: $color-neutral-black
      }
      .subtext {
        color: $color-neutral-black
      }
    }
    &.trialing {
      background-color: $color-brand;
      .text {
        color: rgba(255, 255, 255, 1)
      }
      .subtext {
        color: rgba(255, 255, 255, 0.80)
      }
    }
    &.trial-expired {
      background-color: $color-orange;
      .text {
        color: $color-neutral-black
      }
      .subtext {
        color: $color-neutral-black
      }
    }
  }
}


</style>
