<template>
  <div class="switch-container" >
    <v-switch 
      inset
      v-model="innerValue"
      :disabled="disabled"
      @change="handleChange"
    ></v-switch>
  </div>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

class Props {
  modelValue: boolean = prop({
    required: true,
  });
  disabled?: boolean = prop({
    required: false,
  });
}

export default class Switch extends Vue.with(Props) {
  innerValue = false

  mounted() {
    this.innerValue = this.modelValue
  }

  handleChange () {
    this.$emit('update:modelValue', !this.modelValue)
  }

  @Watch('modelValue')
  onModelValueChange(newVal:boolean, oldVal:boolean) {
    if(newVal !== this.innerValue) {
      this.innerValue = this.modelValue
    }
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.switch-container {
  width: fit-content;
  .v-ripple__container {
    display: none;
  }
  .v-selection-control__input::before {
    background-color: transparent;
  }
  .v-selection-control {
    min-height: initial;
  }
  .v-input__details {
    display: none;
  }
  .v-switch__track {
    height: 24px;
    width: 40px;
    min-width: 40px;
    max-width: 40px;
  }
  .v-switch__thumb {
    height: 20px;
    width: 20px;
    transition: width 0.2s ease-in-out, margin 0.2s ease-in-out !important;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.08), inset 0px 2px 0px rgba(255, 255, 255, 0.34);
    border-radius: 18px;
    border: 1px solid $color-neutral-grey-8;
    color:white !important;
    transform: scale(1) !important;
  }
  .v-selection-control__wrapper {
    height: auto;
    .v-switch__track {
      opacity: 1;
      background: $color-neutral-grey-30;
    }
    .v-switch__thumb {
      margin-left: 4px;
      margin-right: 0px;
    }
    &:hover {
      .v-switch__track {
        background: $color-neutral-grey-20;
      }
    }
    &:active {
      .v-switch__thumb {
        width: 24px;
        margin-left: 8px;
        margin-right: 0px;
        color:white !important;
      }
    }
  }
  
  .v-selection-control--dirty {
    .v-selection-control__wrapper {
      .v-switch__track {
        opacity: 1;
        background-color:$color-brand;
      }
      .v-switch__thumb {
        margin-left: 0px;
        margin-right: 4px;
      }
      &:hover {
        .v-switch__track {
          background-color:rgba(50, 25, 178, 0.8);
        }
      }
      &:active {
        .v-switch__thumb {
          width: 24px;
          margin-left: 0px;
          margin-right: 8px;
          color:white !important;
        }
      }
    }
  }
}
</style>
