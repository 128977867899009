<template>
  <div class="field-group"
    v-if="!hideAll"
    :class="{disabled: disabled, deployed : computedDeployed}"
  >
    <div 
      class="field-group-btn-deploy"
      :class="{disabled: disabled, deployable: deployable}"
      @click="deploy"
    >
    <template v-if="!computedDeployed">
      <template v-if="vertical">
        <v-col class="pa-4">
          <v-row class="ma-0 pb-4">
            <v-col align="right" v-if="!disabled && deployable" cols="1" class="pa-0 ma-0 pl-2 field-group-btn-deploy-icon" >
              <Icon icon="caret-down"/>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col class="pa-0">
              <div class="field-group-value" >
                <slot name="value" ></slot>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-else>
        <v-col class="pa-4 field-group-summary">
          <v-row class="ma-0">
            <v-col class="pa-0 ma-0 field-group-btn-deploy-item-selected" ref="rowValue" cols="11">
              <div class="field-group-value" v-if="!isLongValue">
                <slot name="value" ></slot>
              </div>
            </v-col>
            <v-col v-if="!disabled && deployable" cols="1" class="pa-0 ma-0 ml-auto fit-content field-group-btn-deploy-icon pl-2" >
              <Icon icon="caret-down"/>
            </v-col>
          </v-row>
          <v-row class="ma-0 pt-4" v-if="isLongValue">
            <v-col class="pa-0">
              <div class="field-group-value">
                <slot name="value" ></slot>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </template>
    <template v-else>
      <v-col align="right" class="pa-0 field-group-btn-deploy-item-selected">
        <div class="field-group-value">
          &nbsp;
        </div>
      </v-col>
      <v-col align="right" v-if="!disabled && deployable" cols="1" class="pa-0 field-group-btn-deploy-icon" >
        <Icon icon="caret-down"/>
      </v-col>
    </template>

    </div>
    <HeightDeployer v-model="deployed" v-model:forceDeployed="forceDeployed" class="deployed-container" @expand="$emit('update:forceDeployed', undefined)" @collapse="$emit('update:forceDeployed', undefined)">
      <slot name="deployed"></slot>
      <Divider class="mx-n8"/>
      <v-row class="ma-0 mt-4" >
        <CustomButton :level="$enums.ButtonLevels.Tertiary"  icon="trash" class="delete-btn" @click="deleteGroup" :loading="deleteLoading" :disabled="disableDelete">
          Delete
        </CustomButton>
      </v-row>
    </HeightDeployer>
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop, WithDefault } from 'vue-class-component'
import HeightDeployer from '@/components/UIElements/HeightDeployer.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Divider from '@/components/UIElements/Divider.vue'
import Icon from '@/components/UIElements/Icon.vue'
import { APIFieldDefinition, APIFieldGroup, APIFieldValue } from '@/typesAPI'
import { Watch } from 'vue-property-decorator';
import { nextTick } from 'vue';
import API from '@/api/wrapper';

class Props {
  deployable: WithDefault<boolean> = prop({
    required: false,
    default: true
  });
  forceDeployed?: boolean = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  deployed: boolean = prop({
    required: true,
  });
  vertical?: boolean = prop({
    required: false,
  });
  groupId: string = prop({
    required: true,
  });
}

@Options({
  components: {
    HeightDeployer,
    Icon,
    CustomButton,
    Divider
  },
})
export default class FieldGroup extends Vue.with(Props) {

  maxWidthSoloValue = 370
  isLongValue = false
  hideAll = false

  deleteLoading = false

  get computedDeployed():boolean {
    return !!this.deployed
  }

  mounted() {
    setTimeout(() => {
      this.$emit('update:deployed', this.computedDeployed)
      this.checkLongValue()
    },100)
  }

  @Watch('computedDeployed')
  onRefRowValueChange(newVal:boolean, oldVal:boolean) {
    if(!newVal && oldVal) {
      nextTick(() => {
        this.checkLongValue()
      })
    }
  }

  @Watch('fieldsValues', {deep:true})
  onFieldsValuesChange(newVal:boolean, oldVal:boolean) {
    if(!this.deployed) {
      setTimeout(() => {
        this.checkLongValue()
      },50)
    }
  }

  get fieldsValues ():APIFieldValue[] {
    return this.$store.getters['fields/getListValues']
  }

  get fieldGroup ():APIFieldGroup | undefined {
    return this.$store.getters['fields/getListGroups'].find((group:APIFieldDefinition) => group.id === this.groupId)
  }

  get disableDelete():boolean {
    return this.fieldGroup ? this.fieldGroup.attributes.is_read_only : false
  }

  deleteGroup () {
    this.deleteLoading = true
    API.group.delete(this.groupId)
    .then(() => {
      this.hideAll = true
    })
  }

  checkLongValue() {
    if(!this.vertical) {
      this.isLongValue = false
      if(this.$refs.rowValue && this.$refs.rowTitle) {
        // @ts-ignore
        if(this.$refs.rowValue.$el.getBoundingClientRect().width + this.$refs.rowTitle.$el.getBoundingClientRect().width > this.maxWidthSoloValue) {
          this.isLongValue = true
        } else {
          this.isLongValue = false
        }
      }
    }
  }

  deploy() {
    if(this.deployable && !this.disabled) {
      if(this.computedDeployed) {
        this.collapse()
      } else {
        this.expand()
      }
    }
  }

  collapse() {
    this.$emit('update:deployed', false)
  }

  expand() {
    this.$emit('update:deployed', true)
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
.field-group {
  margin-bottom: 8px;
  background: #FFFFFF;
  border: 1px solid $color-neutral-grey-12;
  border-radius: 8px;
  min-height: 40px;
  transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  .field-group-summary {
    transition: opacity 0.2s ease-in-out;
  }
  .deployed-container {
    margin: 16px;
    &.deployed {
      margin: 0px !important;
      padding: 16px !important;
    }
  }
  .field-group-btn-deploy {
    width: 100%;
    overflow: hidden;
    padding: 0;
    justify-content: flex-start;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    &.deployable {
      cursor: pointer;
    }
    
    &.disabled {
      pointer-events: none;
      cursor: inherit;
      background: $color-neutral-grey-8 !important;
      color: $color-neutral-grey-60 !important;
      .field-group-btn-deploy-item-selected {
        color:black;
      }
    }
    .field-group-btn-deploy-text {
      margin-top:auto;
      margin-bottom:auto;
      margin-left: 16px;
      margin-right: auto;
      color: $color-neutral-grey-60;
      transition: color 0.2s ease-in-out;
    }
    .field-group-btn-deploy-item-selected {
      margin-top:16px;
      margin-bottom:16px;
      margin-right: 16px;
      margin-left: auto;
      color: $color-neutral-black;
      .field-group-value {
        width: 100%;
      }
    }
    .field-group-btn-deploy-icon {
      display: flex;
      border-radius: 8px;
      width: 24px;
      height: 24px;
      margin-top:auto;
      margin-bottom:auto;
      margin-left: auto;
      margin-right:12px;
      opacity: 0.6;
      transition: opacity 0.2s ease-in-out;
    }
  }
  &:hover {
    &:not(.disabled) {
      &:not(.deployed) {
        border: 1px solid $color-neutral-grey-30;
        box-shadow: $shadow-z2;
      }
    }
  }
  &.deployed {
    border: 1px solid $color-brand;
    box-shadow: $shadow-z1;

    .field-group-btn-deploy.deployable {
      transition: background 0.2s ease-in-out;
      &:hover {
        background: $color-background-button-text-tertiary-hover;
      }
      &:active {
        background: $color-background-button-text-tertiary-pressed;
      }
    }

    .field-group-btn-deploy-icon {
      opacity: 1;
    }
    .field-group-btn-deploy-text {
      color: $color-neutral-black;
    }
  }
  .delete-btn {
    color: $color-orange;
  }
}
</style>
<style lang="scss">
@import '@/css/variables';

.field-group { 
  .field-group-btn-deploy {
    .field-group-btn-deploy-icon {
      .icon-container {
        transition: transform 0.2s ease-in-out;
        transform: rotate(-90deg);
      }
      .icon {
        background: $color-neutral-grey-60 !important;
      }
    }
  }
  &.deployed {
    .field-group-btn-deploy-icon {
      .icon-container {
        transform: rotate(0deg);
      }
    }
  }
}
</style>