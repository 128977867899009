<template>
  <v-col class="pa-0">
    <v-radio-group v-model="selectedPaymentMethod" hide-details>
      <v-row class="ma-0 mt-2 mb-4" v-for="(paymentMethod, index) of paymentMethods" :key="paymentMethod.id">
        <template v-if="selectable">
          <v-col class="pa-0 fit-content d-flex mr-2">
            <v-radio :value="paymentMethod" class="my-auto" color="rgba(50, 25, 178, 1)"></v-radio>
          </v-col>
        </template>
        <!-- PAYEMENT METHOD HERE -->
        <PaymentMethodDisplay :paymentMethod="paymentMethod" :withBurger="!selectable" :isDefault="index === 0"/>
      </v-row>
    </v-radio-group>
  </v-col>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component';
import PaymentMethodDisplay from '@/components/UIElements/PaymentMethodDisplay.vue'
import { APIPaymentMethod } from '@/typesAPI';
import { Watch } from 'vue-property-decorator';

class Props {
  paymentMethods: APIPaymentMethod[] = prop({
    required: true,
  });
  selectable?: boolean = prop({
    required: false,
  });
  selected?: APIPaymentMethod = prop({
    required: false,
  });
}

@Options({
  components: {
    PaymentMethodDisplay
  },
})
export default class PaymentMethodsDisplay extends Vue.with(Props) {
  selectedPaymentMethod:APIPaymentMethod | null = null
  
  @Watch('paymentMethods', {immediate:true})
  onPaymentMethodsChange() {
    if(this.paymentMethods?.length) {
      this.selectedPaymentMethod = this.paymentMethods[0]
    }
  }

  @Watch('selectedPaymentMethod', {immediate:true})
  onSelectedPaymentMethodsChange() {
    if(this.selectedPaymentMethod) {
      this.$emit('update:selected', this.selectedPaymentMethod)
    }
  }
}
</script>

<style lang="scss" scoped>


</style>
