import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "badge-label my-auto token-text-micro"
}
const _hoisted_2 = {
  key: 3,
  class: "badge-label my-auto token-text-micro"
}
const _hoisted_3 = {
  key: 4,
  class: "badge-label thin token-text-micro"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('badge-container badge-severity-' + _ctx.severity + ' ' + (_ctx.outlined ? 'badge-outlined' : ''))
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          icon: _ctx.icon,
          class: _normalizeClass(["badge-icon", {'mr-2': !_ctx.label}]),
          color: _ctx.iconColor ? _ctx.iconColor : ''
        }, null, 8, ["icon", "class", "color"]))
      : (_ctx.showIcon)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            icon: _ctx.computedIcon,
            class: _normalizeClass(["badge-icon", {'mr-2': !_ctx.label}])
          }, null, 8, ["icon", "class"]))
        : _createCommentVNode("", true),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : (_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3))
  ], 2))
}