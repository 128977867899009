import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_FransysSignature = _resolveComponent("FransysSignature")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_WidgetBillingDetails = _resolveComponent("WidgetBillingDetails")!
  const _component_StripeWidgetPaymentMethods = _resolveComponent("StripeWidgetPaymentMethods")!
  const _component_PaymentMethodsDisplay = _resolveComponent("PaymentMethodsDisplay")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    visible: _ctx.visible,
    closeOnExternalClick: true,
    "onUpdate:visible": _ctx.closeModal,
    fullScreen: _ctx.fullScreen
  }, _createSlots({
    content: _withCtx(() => [
      _createVNode(_component_v_col, { class: "pa-4" }, {
        default: _withCtx(() => [
          (_ctx.showEditBillingDetails)
            ? (_openBlock(), _createBlock(_component_WidgetBillingDetails, {
                key: 0,
                customer: _ctx.customer,
                onSuccess: _ctx.onEditBillingDetails
              }, null, 8, ["customer", "onSuccess"]))
            : (_ctx.showAddOtherPaymentMethod)
              ? (_openBlock(), _createBlock(_component_StripeWidgetPaymentMethods, {
                  key: 1,
                  customer: _ctx.customer,
                  showCancel: true,
                  onSuccess: _ctx.onAddPaymentMethod,
                  onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAddOtherPaymentMethod = false))
                }, null, 8, ["customer", "onSuccess"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createVNode(_component_v_row, { class: "ma-0 token-text-medium mb-2" }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" Payment methods ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_row, { class: "ma-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PaymentMethodsDisplay, {
                        paymentMethods: _ctx.paymentMethods,
                        selectable: true,
                        selected: _ctx.paymentMethodSelected,
                        "onUpdate:selected": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.paymentMethodSelected) = $event))
                      }, null, 8, ["paymentMethods", "selected"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, { class: "ma-0 mb-4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CustomButton, {
                        text: "Add payment method",
                        level: _ctx.$enums.ButtonLevels.Secondary,
                        onClick: _ctx.onClickAddOtherPaymentMethod
                      }, null, 8, ["level", "onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CustomButton, {
                        text: _ctx.loadingModalCTA ? '' : 'Validate payment methods',
                        level: _ctx.$enums.ButtonLevels.Primary,
                        class: "full-width",
                        onClick: _ctx.onValidate,
                        loading: _ctx.loadingModalCTA,
                        disabled: !_ctx.paymentMethodSelected
                      }, null, 8, ["text", "level", "onClick", "loading", "disabled"])
                    ]),
                    _: 1
                  })
                ], 64))
        ]),
        _: 1
      })
    ]),
    _: 2
  }, [
    (_ctx.fullScreen)
      ? {
          name: "headerFullScreen",
          fn: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0 my-auto mx-12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  class: "pa-0 fit-content my-auto",
                  style: {"position":"absolute","top":"60px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CustomButton, {
                      text: "Back",
                      icon: "caret-left",
                      level: _ctx.$enums.ButtonLevels.Secondary,
                      onClick: _ctx.closeModal
                    }, null, 8, ["level", "onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { class: "pa-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FransysSignature, {
                      vertical: true,
                      class: "ma-auto"
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.fullScreen)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0 token-text-h4" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("div", null, " Add payment method ", -1)
              ])),
              _: 1
            })
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["visible", "onUpdate:visible", "fullScreen"]))
}