<template>
    <Modal :visible="visible" :closeOnExternalClick="true" @update:visible="closeModal" :loading="loadingDraft" :fullScreen="fullScreen">
      <template #headerFullScreen v-if="fullScreen">
        <v-row class="ma-0 my-auto mx-12">
          <v-col class="pa-0 fit-content my-auto" style="position: absolute; top: 60px;" v-if="!showSuccess">
            <CustomButton text="Back" icon="caret-left" :level="$enums.ButtonLevels.Secondary" @click="closeModal"/>
          </v-col>
          <v-col class="pa-0">
            <FransysSignature :vertical="true" class="ma-auto"/>
          </v-col>
        </v-row> 
      </template>
      <template #header v-if="fullScreen && !showSuccess">
        <v-row class="ma-0 token-text-h4">
          <div>
            Purchase credits
          </div>
        </v-row>      
      </template>
      <template #header v-else-if="!showSuccess">
        <v-row class="ma-0">
          <div>
            Purchase credits
          </div>
          <CustomButton class="ml-auto" :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="closeModal"/>
        </v-row>      
      </template>
      <template #content>
        <v-col class="pa-4">
          <template v-if="showEditBillingDetails">
            <WidgetBillingDetails :customer="customer" @success="onEditBillingDetails"/>
          </template>
          <template v-else-if="showAddOtherPaymentMethod">
            <StripeWidgetPaymentMethods :customer="customer" :showCancel="true" @success="onAddPaymentMethod" @cancel="showAddOtherPaymentMethod = false"/>
          </template>
          <template v-else-if="showSuccess">
            <v-row class="ma-0 mx-auto fit-content mt-12">
              <v-img src="@/assets/illustrations/coins-success.svg" height="64px" width="96px" class="mx-auto mb-6"/>
            </v-row>
            <v-row class="ma-0 token-text-h5 mx-auto fit-content">
              {{ creditAmountToAdd }} credits purchased
            </v-row>
            <v-row class="ma-0 mx-auto fit-content mt-6">
              <Spinner />
            </v-row>
          </template>
          <template v-else>
            <!-- CREDIT AMOUNT -->
            <v-row class="ma-0 token-text-medium">
              Credit amount
            </v-row> 
            <v-row class="ma-0 mt-2">
              <CustomNumberInput v-model="creditAmountToAdd" class="full-width" @delayedUpdate="editDraft" @update:modelValue="loadingModalCTA = true"/>
            </v-row>
            <template v-if="errs?.quantity">
              <v-row class="ma-0 mt-2 token-background-color-error rounded-border"  v-for="(err, index) of errs.quantity" :key="'err-quantity-' + index">
                <div class="token-text-color-error ma-2">
                  {{ err }}
                </div>
              </v-row>
            </template>

            <v-row class="ma-0 mt-2">
              <span class="token-text-regular token-text-color-secondary">After this purchase you will have {{ $helpers.prices.format(creditsLeft + (creditAmountToAdd * 100)) }} credits.</span> 
            </v-row>
            <v-row class="ma-0 my-4">
              <Divider class="full-width"/>
            </v-row>
            <!-- PAYEMENT METHODS -->
            <v-row class="ma-0 token-text-medium mb-2">
              Payment methods
            </v-row>
            <v-row class="ma-0">
              <PaymentMethodsDisplay :paymentMethods="paymentMethods" :selectable="true" v-model:selected="paymentMethodSelected"/>
            </v-row>
            <v-row class="ma-0 mb-4">
              <!-- ADD PAYEMENT METHODS -->
              <CustomButton text="Add payment method" :level="$enums.ButtonLevels.Secondary" @click="onClickAddOtherPaymentMethod" />
            </v-row>
            <v-row class="ma-0 my-4">
              <Divider class="full-width"/>
            </v-row>
            <!-- Summary -->
            <v-row class="ma-0">
              <span class="token-text-medium">Summary</span>
            </v-row>
            <v-row class="ma-0 mt-2" v-if="draftInvoiceCredit?.attributes.amount_subtotal">
              <div class="token-text-regular">Credits</div>
              <div class="token-text-medium ml-auto">{{ $helpers.prices.format(draftInvoiceCredit?.attributes.amount_subtotal, draftInvoiceCredit?.attributes.currency) }}</div>
            </v-row>
            <v-row class="ma-0 mt-2" v-if="draftInvoiceCredit?.attributes.amount_tax">
              <div class="token-text-regular">Tax</div>
              <div class="token-text-medium ml-auto">{{ $helpers.prices.format(draftInvoiceCredit?.attributes.amount_tax, draftInvoiceCredit?.attributes.currency) }}</div>
            </v-row>
            <v-row class="ma-0 mt-2" v-if="draftInvoiceCredit?.attributes.amount_total">
              <div class="token-text-regular">Final price</div>
              <div class="token-text-medium ml-auto">{{ $helpers.prices.format(draftInvoiceCredit?.attributes.amount_total, draftInvoiceCredit?.attributes.currency) }}</div>
            </v-row>  
            <v-row class="ma-0 my-4">
              <Divider class="full-width"/>
            </v-row>
            <!-- Promo code -->
            <v-row class="ma-0 mt-4 ">
              <Switch v-model="switchPromoValue"/>
              <div class="token-text-regular ml-2">Promo code</div>
            </v-row> 
            <v-row class="ma-0 mt-4" v-if="switchPromoValue">
              <CustomTextInput v-model="promotionCode" class="full-width" @blur="promotionCode ? editDraft() : ''" placeholder="Promo code"/>
            </v-row>
            
            <!-- CTA -->
            <v-row class="ma-0 mt-4">
              <CustomButton :text="loadingModalCTA ? '' : 'Purchase ' + creditAmountToAdd + ' credits'" :level="$enums.ButtonLevels.Primary" class="full-width" @click="onPurchaseCredit" :loading="loadingModalCTA" :disabled="disabledCTA"/>
            </v-row>      
          </template>
        </v-col>
      </template>
    </Modal>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import CustomNumberInput from '@/components/UIElements/CustomNumberInput.vue'
import PaymentMethodsDisplay from '@/components/UIElements/PaymentMethodsDisplay.vue'
import Icon from '@/components/UIElements/Icon.vue'
import { APICustomer, APIInvoice, APIPaymentMethod } from '@/typesAPI';
import API from '@/api/wrapper';
import { Watch } from 'vue-property-decorator'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import StripeWidgetPaymentMethods from '@/components/UIElements/Widget/StripeWidgetPaymentMethods.vue'
import WidgetBillingDetails from '@/components/UIElements/Widget/WidgetBillingDetails.vue';
import FransysSignature from '@/components/UIElements/FransysSignature.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import Divider from '@/components/UIElements/Divider.vue'
import Switch from '@/components/UIElements/Switch.vue'

class Props {
  visible: boolean = prop({
    required: true,
  });
  defaultQuantity?: number | null = prop({
    required: false,
  });
  fullScreen?: boolean = prop({
    required: false,
  });
}


@Options({
  components: {
    Modal,
    CustomButton,
    CustomNumberInput,
    CustomTextInput,
    Icon,
    PaymentMethodsDisplay,
    StripeWidgetPaymentMethods,
    WidgetBillingDetails,
    FransysSignature,
    Spinner,
    Divider,
    Switch
  },
})
export default class ModalPurchaseCredit extends Vue.with(Props) {
    creditAmountToAdd = 100
    loadingModalCTA = false
    loadingDraft = false
    stripePublicKey = ""
    promotionCode = ""
    paymentMethodSelected:APIPaymentMethod | null = null
    invoice:APIInvoice | null = null
    editing = false
    waitingToEdit = false
    showAddOtherPaymentMethod = false
    showEditBillingDetails = false
    showSuccess = false
    switchPromoValue = false
    errs:any = null

    get creditsLeft(): number {
    return this.$store.getters['billing/getCreditLeft']
  }

    get consumed():number {
      return this.$store.getters['billing/getRessourceUsed']
    }

    get customer():APICustomer {
      return this.$store.getters['user/getCustomer']
    }

    get paymentMethods():APIPaymentMethod[] {
      return this.$store.getters['billing/getPaymentMethods']
    }

    get draftInvoiceCredit():APIInvoice | null {
      return this.$store.getters['billing/getDraftInvoiceCredit']
    }

    get disabledCTA(): boolean {
      return this.errs || !this.paymentMethodSelected
    }

    onAddPaymentMethod() {
      this.showAddOtherPaymentMethod = false
      this.editDraft()
    }

    onEditBillingDetails() {
      this.showEditBillingDetails = false
    }

    @Watch('visible', {immediate: true})
    onVisibleChange() {
      if(this.visible) {
        this.onDefaultQuantityChange()
        if(!this.customer?.attributes?.country) {
          this.showEditBillingDetails = true
        }
        this.createDraft() 
      }
    }

    @Watch('draftInvoiceCredit', {immediate: true})
    onDraftInvoiceCreditChange() {
      if(!this.draftInvoiceCredit && this.visible && !this.showSuccess) {
        this.createDraft() 
      }
    }

    @Watch('paymentMethodSelected')
    onPaymentMethodSelectedChange() {
      this.editDraft()
    }

    @Watch('editing')
    onEditingChange() {
      if(this.waitingToEdit) {
        this.editDraft()
      }
    }
    

    createDraft() {
      if(!this.draftInvoiceCredit && this.visible && !this.loadingDraft) {
        this.loadingDraft = true
        API.billing.createDraftInvoiceCredits(this.customer.id, this.creditAmountToAdd)
        .then((res:APIInvoice) => {
          if(res.attributes.amount_subtotal === this.creditAmountToAdd * 100) {
            this.$store.dispatch('billing/setDraftInvoiceCredit', res)
            this.loadingDraft = false
          }
        })
      }
    }

    editDraft() {
      if(this.editing) {
        this.waitingToEdit = true
      } else if(this.draftInvoiceCredit && this.visible) {
        this.loadingModalCTA = true
        this.editing = true
        this.waitingToEdit = false
        API.billing.editDraftInvoiceCredits(this.draftInvoiceCredit?.id, this.creditAmountToAdd, this.paymentMethodSelected?.id, this.promotionCode && this.switchPromoValue ? [this.promotionCode] : undefined)
        .then((res:APIInvoice) => {
          this.$store.dispatch('billing/setDraftInvoiceCredit', res)
          this.errs = null
        })
        .catch((err:any) => {
          this.errs = err.response?.data?.meta
        })
        .finally(() => {
          this.loadingModalCTA = false
          this.editing = false
        })
      }
    }

    onPurchaseCredit() {
      this.loadingModalCTA = true
      if(this.draftInvoiceCredit && this.paymentMethodSelected) {
        API.billing.finaliseDraftInvoiceCredits(this.draftInvoiceCredit?.id, this.creditAmountToAdd, this.paymentMethodSelected?.id, this.promotionCode && this.switchPromoValue ? [this.promotionCode] : undefined)
        .then((res:APIInvoice) => {
          this.onSuccessfullPurchaseCredit()
          this.$store.dispatch('billing/invalidateInvoices')
          this.$store.dispatch('billing/setDraftInvoiceCredit', null)
          this.errs = null
        })
        .catch((err:any) => {
          this.errs = err.response.data.meta
        })
      }
    }

    onSuccessfullPurchaseCredit() {
      this.showSuccess = true
      setTimeout(this.closeModal, 3000)
    }

    onClickAddOtherPaymentMethod() {
      this.showAddOtherPaymentMethod = true
    }

    closeModal() {
      this.$emit('update:visible',false)
      this.loadingModalCTA = false
      this.editing = false
      this.waitingToEdit = false
      this.loadingDraft = false
      this.showAddOtherPaymentMethod = false
      this.showEditBillingDetails = false
      this.showSuccess = false
    }

    @Watch('defaultQuantity', {immediate: true})
    onDefaultQuantityChange() {
      if(this.defaultQuantity !== undefined && this.defaultQuantity !== null) {
        this.creditAmountToAdd = this.defaultQuantity
      }
    }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
</style>
