<template>
  <div class="contact-us-bar bar pa-2">
    <CustomButton :level="$enums.ButtonLevels.Tertiary" @click="toggleDropDown" ref="menuButton">
      <v-row class="mx-n2">
        <Icon icon="chat" class="mr-2"/>
        <span class="my-auto">
          Contact us
        </span>
        <Icon icon="caret-down" class="ml-2 caret" :class="{invert : opened}"/>
      </v-row>
    </CustomButton>
    <DropdownMenu 
      :items="items"
      v-model:show="opened"
      :modelValue="modelValue"
      :maxContent="true"
      @update:modelValue="onModelValueUpdate"
      :target="$refs.menuButton"
      classInternal="my-4"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Icon from '@/components/UIElements/Icon.vue'
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue'
import { ListItem } from '@/components/UIElements/List.vue';
import getEnv from '@/utils/env';

@Options({
  components: {
    CustomButton,
    Icon,
    DropdownMenu
  },
})
export default class ContactUsBar extends Vue {

  opened = false
  items: ListItem[] = [
    {
      id: 'bug',
      icon: 'bug',
      text: 'Report a bug',
    },
    {
      id: 'feature',
      icon: 'lightbulb',
      text: 'Suggest feature',
    },
    {
      id: 'discord',
      logo: 'discord',
      text: 'Join our discord community',
    }
  ]
  modelValue: ListItem = {}

  toggleDropDown () {
    this.opened = !this.opened
  }

  onModelValueUpdate (newVal:ListItem) {
    newVal.selected = false
    this.opened = false

    switch(newVal.id) {
      case 'discord' : 
        window.open(getEnv('VUE_APP_DISCORD_LINK'), '_blank')
      break;
      case 'bug' : 
        window.open('mailto:' + getEnv('VUE_APP_SUPPORT_EMAIL') + '?subject=Bug report', '_blank')
      break;
      case 'feature' : 
        window.open('mailto:' + getEnv('VUE_APP_SUPPORT_EMAIL') + '?subject=Feature request', '_blank')
      break;
      default:
      break;
    }
  }

}
</script>

<style lang="scss" scoped >
@import '@/css/variables';

.contact-us-bar {
  position: relative;
  .caret {
    transition: transform 0.2s ease-in-out;
    &.invert {
      transform: rotate(-180deg);
    }
  }
  :deep(.custom-button-text) {
    color: $color-neutral-black;
    .icon {
      background-color: $color-neutral-black !important;
    }
  }
}
</style>
