import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "field-box-value" }
const _hoisted_2 = {
  key: 0,
  class: "field-box-value"
}
const _hoisted_3 = { class: "field-box-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_HeightDeployer = _resolveComponent("HeightDeployer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field-box", {disabled: _ctx.disabled, deployed : _ctx.computedDeployed, invisible: _ctx.isInvisible}])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["field-box-btn-deploy", {disabled: _ctx.disabled, isCollapsible: _ctx.isCollapsible}]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deploy && _ctx.deploy(...args)))
    }, [
      (!_ctx.computedDeployed)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.vertical)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  class: "pa-4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, {
                      class: _normalizeClass(["ma-0", {'pb-4' : !_ctx.isInvisible}])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          class: _normalizeClass(["pa-0 ma-0 field-box-btn-deploy-text token-text-medium", _ctx.isInvisible ? 'token-text-micro token-text-transform-uppercase' : '']),
                          cols: "11"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.title), 1)
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        (!_ctx.disabled && _ctx.isCollapsible)
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              align: "right",
                              cols: "1",
                              class: "pa-0 ma-0 pl-2 field-box-btn-deploy-icon"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Icon, { icon: "caret-down" })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["class"]),
                    (!_ctx.isInvisible)
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 0,
                          class: "ma-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { class: "pa-0" }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_1, [
                                  _renderSlot(_ctx.$slots, "value", {}, undefined, true)
                                ])
                              ]),
                              _: 3
                            })
                          ]),
                          _: 3
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 3
                }))
              : (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  class: "pa-4 field-box-summary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          class: _normalizeClass(["pa-0 ma-0 field-box-btn-deploy-text token-text-medium fit-content", _ctx.isInvisible ? 'token-text-micro token-text-transform-uppercase' : '']),
                          ref: "rowTitle",
                          cols: "11"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.title), 1)
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_col, {
                          class: "pa-0 ma-0 ml-auto field-box-btn-deploy-item-selected fit-content",
                          ref: "rowValue"
                        }, {
                          default: _withCtx(() => [
                            (!_ctx.isLongValue)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                  _renderSlot(_ctx.$slots, "value", {}, undefined, true)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 3
                        }, 512),
                        (!_ctx.disabled && _ctx.isCollapsible)
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "1",
                              class: "pa-0 ma-0 field-box-btn-deploy-icon pl-2"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Icon, { icon: "caret-down" })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 3
                    }),
                    (_ctx.isLongValue && !_ctx.isInvisible)
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 0,
                          class: "ma-0 pt-4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { class: "pa-0" }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_3, [
                                  _renderSlot(_ctx.$slots, "value", {}, undefined, true)
                                ])
                              ]),
                              _: 3
                            })
                          ]),
                          _: 3
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 3
                }))
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_v_col, {
              class: _normalizeClass(["pa-0 field-box-btn-deploy-text token-text-medium fit-content", _ctx.isInvisible ? 'token-text-micro token-text-transform-uppercase token-text-color-secondary ' : '']),
              cols: "11"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_v_col, {
              align: "right",
              class: "pa-0 field-box-btn-deploy-item-selected fit-content"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("div", { class: "field-box-value" }, "   ", -1)
              ])),
              _: 1
            }),
            (!_ctx.disabled && _ctx.isCollapsible)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  align: "right",
                  cols: "1",
                  class: "pa-0 field-box-btn-deploy-icon"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Icon, { icon: "caret-down" })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 64))
    ], 2),
    _createVNode(_component_HeightDeployer, {
      modelValue: _ctx.deployed,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deployed) = $event)),
      forceDeployed: _ctx.forceDeployed,
      "onUpdate:forceDeployed": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.forceDeployed) = $event)),
      class: "deployed-container",
      onExpand: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:forceDeployed', undefined))),
      onCollapse: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:forceDeployed', undefined)))
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "deployed", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["modelValue", "forceDeployed"])
  ], 2))
}