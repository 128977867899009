import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mt-2"
}
const _hoisted_2 = { class: "token-text-regular" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_TextBetweenLines = _resolveComponent("TextBetweenLines")!
  const _component_LoginSocialButton = _resolveComponent("LoginSocialButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "loginBar bar",
    onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.doLogin && _ctx.doLogin(...args)), ["enter"]))
  }, [
    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "token-text-h4 mb-6" }, " Log in / Sign up ", -1)),
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "token-text-regular" }, " Email ", -1)),
    _createVNode(_component_CustomTextInput, {
      class: "mt-2",
      modelValue: _ctx.login,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.login) = $event)),
      error: _ctx.errors.login,
      placeholder: "hello@email.com"
    }, null, 8, ["modelValue", "error"]),
    (_ctx.withPassword)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "token-text-regular mt-2" }, " Password ", -1)),
          _createVNode(_component_CustomTextInput, {
            class: "mt-2",
            modelValue: _ctx.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
            type: "password",
            error: _ctx.errors.password
          }, null, 8, ["modelValue", "error"])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.displayGenericError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "token-text-button" }, " Wrong email or password. ", -1)),
          _createElementVNode("div", _hoisted_2, [
            _cache[5] || (_cache[5] = _createTextVNode(" During alpha, passwords are sent to you by a member of the Fransys team. If you have lost your password please ")),
            _createElementVNode("span", {
              class: "link",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.$helpers.contact.contactUs && _ctx.$helpers.contact.contactUs(...args)))
            }, "contact us"),
            _cache[6] || (_cache[6] = _createTextVNode(". "))
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_CustomButton, {
      level: _ctx.$enums.ButtonLevels.Primary,
      onClick: _ctx.doLogin,
      class: "login-btn mt-2",
      status: _ctx.buttonState,
      text: _ctx.btnText,
      disabled: _ctx.loading,
      loading: _ctx.loading
    }, null, 8, ["level", "onClick", "status", "text", "disabled", "loading"]),
    _createVNode(_component_TextBetweenLines, { class: "my-4" }, {
      default: _withCtx(() => _cache[8] || (_cache[8] = [
        _createElementVNode("span", { class: "token-text-color-secondary token-text-microbold token-text-transform-uppercase token-text-font-iAWriterDuoV" }, " or ", -1)
      ])),
      _: 1
    }),
    _createVNode(_component_LoginSocialButton, {
      provider: "gitlab",
      providerName: "GitLab",
      class: "mb-2"
    }),
    _createVNode(_component_LoginSocialButton, {
      provider: "github",
      providerName: "GitHub"
    })
  ], 32))
}