import API from "@/api/wrapper"
import store from ".."
import { APICustomer, APIInvoice, APIMoney, APIPaymentMethod, APIPrice } from "@/typesAPI"
import helpers from "@/utils/helpers"

type State = {
  burnRate: APIMoney | null,
  currentBillingCycle:APIPrice[] | null,
  invoices:APIInvoice[] | null,
  paymentMethods:APIPaymentMethod[] | null,
  draftInvoiceCredit:APIInvoice | null,
  loadingStates: {[key:string]:boolean}
}

const state: State = {
  burnRate: null,
  currentBillingCycle: null,
  invoices: null,
  paymentMethods: null,
  draftInvoiceCredit: null,
  loadingStates: {
    burnRate: true,
    currentBillingCycle: true,
    invoices: true,
    paymentMethods: true,
    draftInvoiceCredit: true,
  }
}


const getters = {
  getCustomer: ():APICustomer => {
    return store.getters['user/getCustomer']
  },
  getBurnRate: (state:State) => {
    if(state.burnRate === null && store.getters['user/getCustomer']?.id) {
      API.billing.getBurnRate(store.getters['user/getCustomer']?.id)
      .then((res:APIMoney) => {
        state.burnRate = res
        state.loadingStates.burnRate = false
      })
      return 0
    }
    return state.burnRate
  },
  getPaymentMethods: (state:State) => {
    if(state.paymentMethods === null && store.getters['user/getCustomer']?.id) {
      API.billing.getPaymentMethods(store.getters['user/getCustomer']?.id)
      .then((res:APIPaymentMethod[]) => {
        state.paymentMethods = res.sort((a, b) => a.attributes.priority - b.attributes.priority)
        state.loadingStates.paymentMethods = false
      })
      return []
    }
    return state.paymentMethods
  },
  getCurrentBillingPeriod: (state:State) => {
    let invoices:APIInvoice[] | null = store.getters['billing/getInvoices']
    if(invoices) {
      invoices = invoices.filter((invoice:APIInvoice) => invoice.attributes.type === 'tally')
    } 
    const customer:APICustomer = store.getters['user/getCustomer']
    const startDateString:string = invoices === null || !invoices.length ? customer?.attributes?.created_at : invoices[invoices.length - 1].attributes.end
    const startCycle:Date = new Date(startDateString)
    const endCycle:Date = new Date(startCycle.setMonth(startCycle.getMonth() + 1));
    return {start: new Date(startDateString), end : endCycle}

  },
  getProjectedUsage: (state:State) => {
    const customer:APICustomer = store.getters['user/getCustomer']
    return customer?.meta?.estimated_monthly_cost
  },
  getCreditLeft: (state:State) => {
    const customer:APICustomer = store.getters['user/getCustomer']
    const consumed:number = store.getters['billing/getRessourceUsed']
    if(state.loadingStates.currentBillingCycle) {
      return null
    }
    return customer?.attributes?.balance ? Math.max(parseInt(customer?.attributes?.balance) - consumed, 0) : 0
  },
  getAmountDue: (state:State) => {
    const customer:APICustomer = store.getters['user/getCustomer']
    const consumed:number = store.getters['billing/getRessourceUsed']
    if(state.loadingStates.currentBillingCycle) {
      return null
    }
    return customer?.attributes?.balance ? Math.max(consumed - parseInt(customer?.attributes?.balance), 0) : consumed
  },
  getRessourceUsed: (state:State) => {
    let ret = 0
    const currentBillingCycle = store.getters['billing/getCurrentBillingCycle']
    if(state.loadingStates.currentBillingCycle) {
      return null
    }
    currentBillingCycle?.forEach((priceAttributes:APIPrice) => {
      ret += parseInt(priceAttributes.attributes.amount)
    })
    return ret
  },
  getCurrentBillingCycle: (state:State) => {
    if(state.currentBillingCycle === null && store.getters['user/getCustomer']?.id) {
      API.billing.getCurrentBillingCycle(store.getters['user/getCustomer']?.id)
      .then((res:APIPrice[]) => {
        state.currentBillingCycle = res
        state.loadingStates.currentBillingCycle = false
      })
      return []
    }
    return state.currentBillingCycle
  },
  getInvoices: (state:State) => {
    if(state.invoices === null && store.getters['user/getCustomer']?.id) {
      API.billing.getInvoices(store.getters['user/getCustomer']?.id)
      .then((res:any) => {
        state.invoices = res
        state.loadingStates.invoices = false
      })
      return []
    }
    return state.invoices
  },
  getDraftInvoiceCredit: (state:State) => {
    return state.draftInvoiceCredit
  },
}

const mutations = {
  SET_DRAFT_INVOICE_CREDIT: (state:State, newVal:APIInvoice | null) => {
    if(newVal && state.draftInvoiceCredit) {
      if(helpers.ressources.isNewerThan(newVal, state.draftInvoiceCredit)) {
        state.draftInvoiceCredit = newVal
      }
    } else {
      state.draftInvoiceCredit = newVal
    }
  },
  UPDATE_INVOICE: (state:State, newVal:APIInvoice) => {
    if(!state.invoices)  {
      state.invoices = [newVal]
    } else {
      helpers.ressources.addMultipleRessourceToArray(state.invoices, [newVal])
    }
  },
  DELETE_INVOICE: (state:State, newVal:APIInvoice) => {
    if(state.invoices)  {
      state.invoices.forEach((fv, index) => {
        if(fv.id === newVal.id && helpers.ressources.isNewerThan(newVal, fv) && state.invoices) {
          state.invoices.splice(index,1)
        }
      })
    }
  },
  INVALIDATE_INVOICES: (state:State) => {
    state.invoices = null
  },
  UPDATE_PAYMENT_METHOD: (state:State, newVal:APIPaymentMethod) => {
    if(!state.paymentMethods)  {
      state.paymentMethods = [newVal]
    } else {
      helpers.ressources.addMultipleRessourceToArray(state.paymentMethods, [newVal])
    }
  },
  INVALIDATE_PAYMENT_METHODS: (state:State) => {
    state.paymentMethods = null
  },
}

const actions = {
  setDraftInvoiceCredit: (context:any, newVal:APIInvoice | null) => {
    context.commit('SET_DRAFT_INVOICE_CREDIT', newVal)
  },
  updateInvoice: (context:any, newVal:APIInvoice) => {
    context.commit('UPDATE_INVOICE', newVal)
  },
  deleteInvoice: (context:any, newVal:APIInvoice) => {
    context.commit('UPDATE_INVOICE', newVal)
  },
  invalidateInvoices: (context:any) => {
    context.commit('INVALIDATE_INVOICES')
  },
  updatePaymentMethod: (context:any, newVal:APIPaymentMethod) => {
    context.commit('UPDATE_PAYMENT_METHOD', newVal)
  },
  invalidatePaymentMethods: (context:any) => {
    context.commit('INVALIDATE_PAYMENT_METHODS')
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}