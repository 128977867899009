<template>
  <div class="registerView full-view">
    <BackgroundGradiant/>
    <v-col class="pa-0 my-auto">
      <v-row class="ma-0 pb-12 mx-auto fit-content">
        <FransysSignature />
      </v-row>
      <v-row class="ma-0" v-if="$route.query.email">
        <CreateMagicLinkBar class="ma-auto" :email="$route.query.email"/>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import CreateMagicLinkBar from '@/components/Actions/CreateMagicLinkBar.vue';
import BackgroundGradiant from '@/components/UIElements/BackgroundGradiant.vue';
import FransysSignature from '@/components/UIElements/FransysSignature.vue';
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
    CreateMagicLinkBar,
    BackgroundGradiant,
    FransysSignature
  },
})
export default class RegisterView extends Vue {}
</script>

<style lang="scss" scoped>
.registerView {

}
</style>
