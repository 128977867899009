<template>
  <div class="subscription-start-trial-modal">
    <Popin>
      <template #header>
        <v-img src="@/assets/illustrations/start_trial.png" height="56px" width="56px"/>
      </template>
      <template #content>
        <v-col class="pa-0">
          <v-row class="token-text-h5 ma-0 mb-1">
            <div class="ma-auto">
              Start free trial to deploy
            </div>
          </v-row>
          <v-row class="token-text-regular token-text-color-secondary ma-0">
            <div class="ma-auto">Fransys will host your project for 12 hours for you try out the service.</div>
            <div class="ma-auto">Your project will be removed after the trial period.</div>
            <div class="ma-auto">You will not be charged after the trial period.</div>
            <div class="ma-auto">You don't need a credit card to start a trial.</div>
          </v-row>
        </v-col>
      </template>
      <template #footer>
        <v-col class="pa-0">
          <v-row class="ma-0 mb-2">
            <CustomButton class="mx-auto" :level="$enums.ButtonLevels.Primary" @click="startTrial">
              Start free trial
            </CustomButton>
          </v-row>
          <v-row class="ma-0">
            <CustomButton class="mx-auto" :level="$enums.ButtonLevels.Tertiary" @click="checkout" :loading="loadingCheckout">
              Upgrade plan
            </CustomButton>
          </v-row>
        </v-col>
      </template>
    </Popin>
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Popin from '@/components/UIElements/Popin.vue';
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
    CustomButton,
    Popin
  },
})
export default class SubscriptionStartTrialModal extends Vue {
  loadingCheckout = false
  
  mounted(): void {
    this.loadingCheckout = false
  }

  checkout() {
    this.loadingCheckout = true
    this.$helpers.subscription.checkout()
  }

  startTrial () {
    this.$emitter.emit('startTrial')
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

</style>
