import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["toastr", 'severity-' + _ctx.severity + (_ctx.hidden ? ' hidden ' : '') + (_ctx.disapearing ? ' disapearing ' : '')]),
    style: _normalizeStyle(_ctx.disapearing ? ('margin-top:-' + _ctx.height + 'px') : ('z-index:' + _ctx.position)),
    ref: "DOMElement"
  }, [
    _createVNode(_component_v_row, { class: "ma-0 top-color-band" }),
    _createVNode(_component_v_row, { class: "ma-3" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          class: _normalizeClass(["pa-0 fit-content", {'my-auto': !(_ctx.title && _ctx.text)}])
        }, {
          default: _withCtx(() => [
            (_ctx.severity === _ctx.$enums.Severity.DANGER)
              ? (_openBlock(), _createBlock(_component_Icon, {
                  key: 0,
                  icon: "cancel-square"
                }))
              : (_ctx.severity === _ctx.$enums.Severity.WARNING)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 1,
                    icon: "warning"
                  }))
                : (_ctx.severity === _ctx.$enums.Severity.SUCCESS)
                  ? (_openBlock(), _createBlock(_component_Icon, {
                      key: 2,
                      icon: "check-square"
                    }))
                  : (_ctx.severity === _ctx.$enums.Severity.INFO)
                    ? (_openBlock(), _createBlock(_component_Icon, {
                        key: 3,
                        icon: "info"
                      }))
                    : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_v_col, {
          class: _normalizeClass(["pa-0 mx-3", {'mt-n1' : _ctx.title && _ctx.text}])
        }, {
          default: _withCtx(() => [
            (_ctx.title)
              ? (_openBlock(), _createBlock(_component_v_row, {
                  key: 0,
                  class: "ma-0 token-text-medium mb-1"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$helpers.text.capitalize(_ctx.title)), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.text)
              ? (_openBlock(), _createBlock(_component_v_row, {
                  key: 1,
                  class: "ma-0 token-text-regular"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.text), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_v_col, { class: "pa-0 fit-content ml-auto" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomButton, {
              level: _ctx.$enums.ButtonLevels.SmallIcon,
              icon: "x",
              onClick: _ctx.close
            }, null, 8, ["level", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 6))
}