<template><i></i></template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import Echo from "laravel-echo";
import API from '@/api/wrapper';
import getEnv from '@/utils/env';
import { APICluster, APICustomer, APIDeployment, APIDeploymentLog, APIDeploymentPlan, APIDeploymentSubject, APIDeploymentVersion, APIFieldBox, APIFieldDefinition, APIFieldValue, APIInvoice, APIProject, APISocialAccount, APITabField, APIUser } from '@/typesAPI';
import { Watch } from 'vue-property-decorator';

export default class SocketsManager extends Vue {
  echo?:Echo   

  mounted(): void {
    this.connectSocket()
  }


  connectSocket() {
    //@ts-ignore
    if(!window.Pusher) {
      //@ts-ignore
      window.Pusher = require('pusher-js');
    }
   
    this.echo =  new Echo({
      broadcaster: 'pusher',
      key: 'app-key',
      wsHost: getEnv('VUE_APP_SOCKET_URL'),
      wsPort: 80,
      wssPort: 443,
      forceTLS: false,
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      authorizer: (channel:any, options:any) => {
        return {
          authorize: (socketId:any, callback:any) => {
            API.sockets.auth(callback, {
              socket_id: socketId,
              channel_name: channel.name
            })
          }
        };
      },
    });

    this.connectChannels()

  }

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }
  get user():APIUser {
    return this.$store.getters['user/getUser']
  }
  get clusters():APICluster[] {
    return this.$store.getters['clusters/getClusters']
  }
  get projects():APIProject[] {
    return this.$store.getters['projects/getProjectList']
  }
  get currentProject():APIProject {
    return this.$store.getters['projects/getCurrentProject']
  }

  get routeProjectId():string {
    return this.$route?.params?.projectId as string
  }

  get currentProjectClusters():APICluster[] {
    const myClusters:APICluster[] = []

    this.currentProject?.relationships?.kubernetes_clusters?.data?.forEach((data) => {
      const cluster = this.$store.getters['clusters/getClusterById'](data.id)
      if(cluster) {
        myClusters.push(cluster)
      }
    })

    return myClusters
  }

  connectChannels() {
    if(this.echo) {
      this.onCustomerChange()
      this.onUserChange()
      this.onRouteProjectIdChange()
      this.onClustersChange()
    }
  }

  unmounted(): void {
    if(this.echo) {
      this.echo.leaveAllChannels()
    }
  }

  startWatchProject(projectId:string) {
    if(this.echo && !this.echo?.connector?.channels['private-projects.'+ projectId]) {

      this.echo
      .channel('private-projects.'+ projectId)
      // Deployement
      .listen('.deploymentVersions.started', (deployementVersion: APIDeploymentVersion) => {
        this.$helpers.console.log('project.deploymentVersions.started' , deployementVersion)

        if(deployementVersion) {
          API.deployment.getDeploymentPlans(deployementVersion.id)
          .then ((value: { deploymentPlans: APIDeploymentPlan[]; subjects:APIDeploymentSubject[];}) => {
            if (deployementVersion.attributes.status ===  this.$enums.DeploymentState.Running) {
              this.$store.dispatch('deployment/setModalIsOpen', true)
            }
            this.$store.dispatch('deployment/addDeploymentVersion', deployementVersion)
            this.$store.dispatch('deployment/addDeploymentPlans', value.deploymentPlans)
            value.subjects.forEach((subject:APIDeploymentSubject) => {
              this.$store.dispatch('deployment/addDeploymentSubject', subject)
            })
          })
        }
      })
      .listen('.deploymentVersions.deleted', (e:APIDeploymentVersion) => {
        this.$helpers.console.log('project.deploymentVersions.deleted' , e)
        this.$store.dispatch('deployment/deleteDeploymentVersion', e)
      })
      .listen('.deploymentVersions.created', (e:APIDeploymentVersion) => {
        this.$helpers.console.log('project.deploymentVersions.created' , e)
        this.$store.dispatch('deployment/addDeploymentVersion', e)
      })
      .listen('.deploymentVersions.fullyCompleted', (e:any) => {
        this.$helpers.console.log('project.deploymentVersions.fullyCompleted' , e)
        this.$store.dispatch('deployment/addDeploymentVersion', e)
        this.$store.dispatch('deployment/setForceDeployCompletedState', true)
      })

      .listen('.deploymentPlans.logs', (e:APIDeploymentLog[]) => {
        this.$helpers.console.log('project.deploymentPlans.logs' , e)
        this.$store.dispatch('deployment/addDeploymentLogs', {deploymentLogs: e, planId: e[0].relationships.plan.data.id})
      })
      .listen('.deploymentPlans.createdOrUpdated', (e:APIDeploymentPlan) => {
        this.$helpers.console.log('project.deploymentPlans.createdOrUpdated' , e)
        this.$store.dispatch('deployment/editDeploymentPlan', e)
        this.$store.dispatch('deployment/addDeploymentSubject', this.$store.getters['blocksAPI/getByID'](e.relationships.subject.data.id))
      })
      .listen('.deploymentPlans.deleted', (e:APIDeploymentPlan) => {
        this.$helpers.console.log('project.deploymentPlans.deleted' , e)
        this.$store.dispatch('deployment/deleteDeploymentPlan', e)
      })

      .listen('.deployments.created', (e:APIDeployment)  => {
        this.$helpers.console.log('project.deployments.created' , e, this.$helpers.ressources.isFromMe(e) ? 'FROM ME' : '')
        // if(!this.$helpers.ressources.isFromMe(e)) {
          this.$store.dispatch('blocksAPI/addDeployments', [e])
        // }
      })
      .listen('.deployments.deleted', (e:APIDeployment)  => {
        this.$helpers.console.log('project.deployments.deleted' , e)
        this.$store.dispatch('blocksAPI/deleteDeployment', e)
      })
      .listen('.deployments.updated', (e:APIDeployment)  => {
        this.$helpers.console.log('project.deployments.updated' , e)
        this.$store.dispatch('blocksAPI/addDeployments', [e])
      })
      .listen('.deployments.statusChanged', (e:APIDeployment)  => {
        this.$helpers.console.log('project.deployments.statusChanged' , e)
        this.$store.dispatch('blocksAPI/addDeployments', [e])
      })

      // ValidationErrors
      .listen('.validationErrors.calculated', (e:any) => {
        this.$helpers.console.log('project.validationErrors.calculated' , e)
        this.$store.dispatch('errorsValidation/deleteErrors', e.passing)
        this.$store.dispatch('errorsValidation/addErrors', e.failing)
      })
      // Fields
      .listen('.fields.created',  (payload:{data:APIFieldValue, included : any[]})  => {
        this.$helpers.console.log('project.fields.created' , payload, this.$helpers.ressources.isFromMe(payload.data) ? 'FROM ME' : '')

        // if(!this.$helpers.ressources.isFromMe(payload.data)) {
          this.$store.dispatch('fields/addNodeFieldDefinition', payload.included.find((item) => item.type === "fieldDefinitions"))
          this.$store.dispatch('fields/addNodeFieldTab', payload.included.find((item) => item.type === "blockTabs"))
          this.$store.dispatch('fields/addNodeFieldBox', payload.included.find((item) => item.type === "fieldBoxes"))

          this.$store.dispatch('fields/addNodeFieldValue', payload.data)
        // }
        setTimeout(() => {
          this.$emitter.emit(`fields.created.${payload.data.id}`)
        })

      })
      .listen('.fields.updated', (payload:{data:APIFieldValue, included : any[]}) => {
        this.$helpers.console.log('project.fields.updated' , payload, this.$helpers.ressources.isFromMe(payload.data) ? 'FROM ME' : '')
        const definition:APIFieldDefinition = payload.included.find((item) => item.type === "fieldDefinitions")
        const tab:APITabField = payload.included.find((item) => item.type === "blockTabs")
        const box:APIFieldBox = payload.included.find((item) => item.type === "fieldBoxes")

        if(!this.$helpers.ressources.isFromMe(payload.data) || (definition && definition.attributes.type === 'button')) {
          this.$store.dispatch('fields/addNodeFieldDefinition', definition)
          this.$store.dispatch('fields/addNodeFieldTab', tab)
          this.$store.dispatch('fields/addNodeFieldBox', box)
          this.$store.dispatch('fields/addNodeFieldValue', payload.data)

          setTimeout(() => {
            this.$emitter.emit(`fields.updated.${payload.data.id}`)
          })
        }



      })
      .listen('.fields.deleted',  (payload:{data:APIFieldValue, included : any[]})  => {
        this.$helpers.console.log('project.fields.deleted' , payload)

        this.$store.dispatch('fields/deleteFieldValue', payload.data)
        setTimeout(() => {
          this.$emitter.emit(`fields.deleted.${payload.data.id}`)
        })

      })
      .listen('.projects.created', (e:APIProject)  => {
        this.$helpers.console.log('project.projects.created' , e, this.$helpers.ressources.isFromMe(e) ? 'FROM ME' : '')
        // if(!this.$helpers.ressources.isFromMe(e)) {
          this.$store.dispatch('projects/updateProject', e)
        // }

      })
      .listen('.projects.updated', (e:APIProject)  => {
        this.$helpers.console.log('project.projects.updated' , e, this.$helpers.ressources.isFromMe(e) ? 'FROM ME' : '')
        // if(!this.$helpers.ressources.isFromMe(e)) {
          this.$store.dispatch('projects/updateProject', e)
        // }

      })
      .listen('.blocks.fieldsRecalculated', (e:APIDeployment)  => {
        this.$helpers.console.log('project.blocks.fieldsRecalculated' , e, this.$helpers.ressources.isFromMe(e) ? 'FROM ME' : '')
        this.$store.dispatch('blocksAPI/editBlock', e)
      })
    }
      
  }

  @Watch("customer", {immediate:true})
  onCustomerChange() {
    if(this.echo && this.customer && !this.echo?.connector?.channels['private-customers.'+ this.customer?.id]) {
      
      this.echo
      .channel('private-customers.'+ this.customer.id)
      // Customer
      .listen('.customers.updated', (e:any) => {
        this.$helpers.console.log('customer.customers.updated' , e)
        this.$store.dispatch('user/setCustomer', e)
        this.$store.dispatch('billing/setDraftInvoiceCredit', null)
      })
      .listen('.invoices.updated', (e:APIInvoice) => {
        this.$helpers.console.log('customer.invoices.updated' , e)
        this.$store.dispatch('billing/updateInvoice', e)
      })
      .listen('.invoices.deleted', (e:APIInvoice) => {
        this.$helpers.console.log('customer.invoices.deleted' , e)
        this.$store.dispatch('billing/deleteInvoice', e)
      })
      .listen('.paymentMethods.created', (e:any) => {
        this.$helpers.console.log('customer.paymentMethods.created' , e)
        this.$store.dispatch('billing/updatePaymentMethod', e)

      })
      .listen('.paymentMethods.updated', (e:any) => {
        this.$helpers.console.log('customer.paymentMethods.updated' , e)
        this.$store.dispatch('billing/updatePaymentMethod', e)
      })

      // ONLY ON HOME VIEW
      .listen('.projects.created', (e:APIProject)  => {
        if(!this.routeProjectId) {
          this.$helpers.console.log('customer.projects.created' , e, this.$helpers.ressources.isFromMe(e) ? 'FROM ME' : '')
          this.$store.dispatch('projects/updateProject', e)
        }
      })
      .listen('.projects.updated', (e:APIProject)  => {
        if(!this.routeProjectId) {
          this.$helpers.console.log('customer.projects.updated' , e, this.$helpers.ressources.isFromMe(e) ? 'FROM ME' : '')
          this.$store.dispatch('projects/updateProject', e)
        }
      })
      .listen('.deployments.created', (e:APIDeployment)  => {
        if(!this.routeProjectId) {
          this.$helpers.console.log('customer.deployments.created' , e, this.$helpers.ressources.isFromMe(e) ? 'FROM ME' : '')
          this.$store.dispatch('clusters/editDeployment', e)
        }
      })
      .listen('.deployments.deleted', (e:APIDeployment)  => {
        if(!this.routeProjectId) {
          this.$helpers.console.log('customer.deployments.deleted' , e)
          this.$store.dispatch('clusters/deleteDeployment', e)
        }
      })
      .listen('.deployments.updated', (e:APIDeployment)  => {
        if(!this.routeProjectId) {
          this.$helpers.console.log('customer.deployments.updated' , e)
          this.$store.dispatch('clusters/editDeployment', e)
        }
      })
      .listen('.deployments.statusChanged', (e:APIDeployment)  => {
        if(!this.routeProjectId) {
          this.$helpers.console.log('customer.deployments.statusChanged' , e)
          this.$store.dispatch('clusters/editDeployment', e)
        }
      })
    }
  }

  @Watch("user", {immediate:true})
  onUserChange() {
    if(this.echo && this.user && !this.echo?.connector?.channels['private-users.'+ this.user?.id]) {

      this.echo
      .channel('private-users.'+ this.user.id)
      // User
      .listen('.users.updated', (e:any) => {
        this.$helpers.console.log('user.user.updated' , e)
        this.$store.dispatch('user/setUser', e)
      })
      // Social accounts
      .listen('.socialAccounts.created', (e:APISocialAccount) => {
        this.$helpers.console.log('user.socialAccounts.created' , e)
        this.$store.dispatch('user/setSocialAccount', {provider : e?.attributes?.provider, socialAccount: e})
      })
      .listen('.socialAccounts.deleted', (e:APISocialAccount) => {
        this.$helpers.console.log('user.socialAccounts.deleted' , e)
        this.$store.dispatch('user/deleteSocialAccount', e?.attributes?.provider)
      })
      
    }
  }

  
  @Watch("clusters", {immediate:true, deep:true})
  onClustersChange() {
    if(this.echo && this.clusters && this.routeProjectId) {
      this.clusters.forEach((cluster) => {
        if(this.echo && !this.echo?.connector?.channels['kubernetesClusters.'+ cluster.id]) {
          this.echo.leaveChannel('private-kubernetesClusters.'+ cluster.id)
          this.echo
          .channel('private-kubernetesClusters.'+ cluster.id)
          // Deployement
          .listen('.deploymentVersions.started', (deployementVersion: APIDeploymentVersion) => {
            this.$helpers.console.log('cluster.deploymentVersions.started' , deployementVersion)

            if(deployementVersion && this.currentProject) {
                API.deployment.getDeploymentPlans(deployementVersion.id)
                .then ((value: { deploymentPlans: APIDeploymentPlan[]; subjects:APIDeploymentSubject[];}) => {
                  if (deployementVersion.attributes.status ===  this.$enums.DeploymentState.Running && this.currentProjectClusters.find((c) => c.id === cluster.id)) {
                    this.$store.dispatch('deployment/setModalIsOpen', true)
                  }
                  this.$store.dispatch('deployment/addDeploymentVersion', deployementVersion)
                  this.$store.dispatch('deployment/addDeploymentPlans', value.deploymentPlans)
                  value.subjects?.forEach((subject:APIDeploymentSubject) => {
                    this.$store.dispatch('deployment/addDeploymentSubject', subject)
                  })
                })
            }
          })
          .listen('.deploymentVersions.deleted', (e:APIDeploymentVersion) => {
            this.$helpers.console.log('cluster.deploymentVersions.deleted' , e)
            if(this.currentProject ) {//&& this.currentProjectClusters.find((c) => c.id === cluster.id)) {
              this.$store.dispatch('deployment/deleteDeploymentVersion', e)
            }
          })
          .listen('.deploymentVersions.created', (e:APIDeploymentVersion) => {
            this.$helpers.console.log('cluster.deploymentVersions.created' , e)
            if(this.currentProject) {// && this.currentProjectClusters.find((c) => c.id === cluster.id)) {
              this.$store.dispatch('deployment/addDeploymentVersion', e)
            }
          })
          .listen('.deploymentVersions.fullyCompleted', (e:any) => {
            this.$helpers.console.log('.deploymentVersions.fullyCompleted' , e)
            if(this.currentProject) {// && this.currentProjectClusters.find((c) => c.id === cluster.id)) {
              this.$store.dispatch('deployment/addDeploymentVersion', e)
              this.$store.dispatch('deployment/setForceDeployCompletedState', true)
            }
          })

          .listen('.deploymentPlans.logs', (e:APIDeploymentLog[]) => {
            this.$helpers.console.log('cluster.deploymentPlans.logs' , e)
            if(this.currentProject && this.currentProjectClusters.find((c) => c.id === cluster.id)) {
              this.$store.dispatch('deployment/addDeploymentLogs', {deploymentLogs: e, planId: e[0].relationships.plan.data.id})
            }
          })
          .listen('.deploymentPlans.createdOrUpdated', (e:APIDeploymentPlan) => {
            this.$helpers.console.log('cluster.deploymentPlans.createdOrUpdated' , e)
            if(this.currentProject) {// && this.currentProjectClusters.find((c) => c.id === cluster.id)) {
              this.$store.dispatch('deployment/editDeploymentPlan', e)
              this.$store.dispatch('deployment/addDeploymentSubject', this.$store.getters['blocksAPI/getByID'](e.relationships.subject.data.id))
            }
          })
          .listen('.deploymentPlans.deleted', (e:APIDeploymentPlan) => {
            this.$helpers.console.log('cluster.deploymentPlans.deleted' , e)
            if(this.currentProject) {// && this.currentProjectClusters.find((c) => c.id === cluster.id)) {
              this.$store.dispatch('deployment/deleteDeploymentPlan', e)
            }
          })

          .listen('.deployments.created', (e:APIDeployment)  => {
            this.$helpers.console.log('cluster.deployments.created' , e, this.$helpers.ressources.isFromMe(e) ? 'FROM ME' : '')
            // if(!this.$helpers.ressources.isFromMe(e)) {
              this.$store.dispatch('clusters/editDeployment', e)
            // }
          })
          .listen('.deployments.deleted', (e:APIDeployment)  => {
            this.$helpers.console.log('cluster.deployments.deleted' , e)
            this.$store.dispatch('clusters/deleteDeployment', e)
          })
          .listen('.deployments.updated', (e:APIDeployment)  => {
            this.$helpers.console.log('cluster.deployments.updated' , e)
            this.$store.dispatch('clusters/editDeployment', e)
          })
          .listen('.deployments.statusChanged', (e:APIDeployment)  => {
            this.$helpers.console.log('cluster.deployments.statusChanged' , e)
            this.$store.dispatch('clusters/editDeployment', e)
          })

        }
      })
    } 
  }

  @Watch("routeProjectId", {immediate:true})
  onRouteProjectIdChange() {
    if(this.routeProjectId) {
      this.startWatchProject(this.routeProjectId)
    }
  }
}
</script>