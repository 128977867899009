<template>
    <Modal :visible="visible" :closeOnExternalClick="true" @update:visible="closeModal">
      <template #content>
        <CustomButton class="ml-auto mt-2 mr-2" :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="closeModal"/>
        <v-img src="@/assets/illustrations/trash_bin.svg" height="80px" width="98px" class="mx-auto"/>

        <v-col class="pa-10 d-flex-col">
          <v-row class="ma-0 token-text-h5 mx-auto">
            Delete cluster
          </v-row>
          <Divider class="my-4"/> 
          <v-row class="ma-0">
            <div>
              <span class="token-text-regular">
                To confirm, type in the name of the cluster you will delete:
              </span>
              <span class="ml-1 token-text-medium">{{ cluster?.attributes?.name }}</span>
            </div>
          </v-row>
          <v-row class="ma-0 mt-2 mb-10">
            <CustomTextInput v-model="confirmText" class="full-width"/>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col class="pa-0 mr-2">
              <CustomButton :level="$enums.ButtonLevels.Secondary" class="ml-auto mr-2 full-width" @click="closeModal">
                Cancel
              </CustomButton>
            </v-col>
            <v-col class="pa-0 ml-2">
              <CustomButton :level="$enums.ButtonLevels.Primary" class="full-width" @click="doDelete" :loading="loading" :disabled="disabled">
                Delete cluster
              </CustomButton>
            </v-col>
          </v-row>
        </v-col>
        
      </template>
    </Modal>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import API from '@/api/wrapper';
import { Watch } from 'vue-property-decorator';
import { APIProject } from '@/typesAPI';
import Divider from '@/components/UIElements/Divider.vue';

class Props {
  visible: boolean = prop({
    required: true,
  });
  cluster: APIProject = prop({
    required: true,
  });
}


@Options({
  components: {
    Modal,
    CustomButton,
    CustomTextInput,
    Divider
  },
})
export default class ModalClusterDelete extends Vue.with(Props) {
  loading = false
  confirmText = ""

  get disabled():boolean {
    return this.confirmText !== this.cluster?.attributes?.name
  }


  closeModal() {
    this.$emit('update:visible', false)
  }

  doDelete() {
    this.loading = true

    API.clusters.deleteCluster(this.cluster.id)
    .then(() => {
      this.$store.dispatch('clusters/deleteCluster', this.cluster)
      this.loading = false
      this.$emit('update:visible', false)
    }) 

  }

  @Watch('visible', {immediate: true})
  onVisibleChange() {
    if(this.visible) {
      this.confirmText = ""
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
</style>
