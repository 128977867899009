import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/illustrations/start_trial.png'


const _hoisted_1 = { class: "subscription-start-trial-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_Popin = _resolveComponent("Popin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Popin, null, {
      header: _withCtx(() => [
        _createVNode(_component_v_img, {
          src: _imports_0,
          height: "56px",
          width: "56px"
        })
      ]),
      content: _withCtx(() => [
        _createVNode(_component_v_col, { class: "pa-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "token-text-h5 ma-0 mb-1" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("div", { class: "ma-auto" }, " Start free trial to deploy ", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "token-text-regular token-text-color-secondary ma-0" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("div", { class: "ma-auto" }, "Fransys will host your project for 12 hours for you try out the service.", -1),
                _createElementVNode("div", { class: "ma-auto" }, "Your project will be removed after the trial period.", -1),
                _createElementVNode("div", { class: "ma-auto" }, "You will not be charged after the trial period.", -1),
                _createElementVNode("div", { class: "ma-auto" }, "You don't need a credit card to start a trial.", -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_v_col, { class: "pa-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0 mb-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomButton, {
                  class: "mx-auto",
                  level: _ctx.$enums.ButtonLevels.Primary,
                  onClick: _ctx.startTrial
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Start free trial ")
                  ])),
                  _: 1
                }, 8, ["level", "onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomButton, {
                  class: "mx-auto",
                  level: _ctx.$enums.ButtonLevels.Tertiary,
                  onClick: _ctx.checkout,
                  loading: _ctx.loadingCheckout
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Upgrade plan ")
                  ])),
                  _: 1
                }, 8, ["level", "onClick", "loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}