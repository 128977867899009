<template>
  <div class="widget-billing-detail" >
    <v-col class="pa-0">
      <!-- Name -->
      <v-row class="ma-0 token-text-regular">
        Full name or company name
      </v-row>
      <v-row class="ma-0 mt-2">
        <CustomTextInput :modelValue="detailForm.name" @update:modelValue="detailForm.name = $event" class="full-width"/>
      </v-row>
      <!-- Country -->
      <v-row class="ma-0 mt-4">
        <CustomCountrySelect v-model:country="detailForm.country" />
      </v-row>
       <!-- City -->
       <v-row class="ma-0 mt-4 token-text-regular">
          City
      </v-row>
      <v-row class="ma-0 mt-2">
        <CustomTextInput :modelValue="detailForm.city" @update:modelValue="detailForm.city = $event" class="full-width"/>
      </v-row>
       <!-- Postcode -->
       <v-row class="ma-0 mt-4 token-text-regular">
          Postal Code
      </v-row>
      <v-row class="ma-0 mt-2">
        <CustomTextInput :modelValue="detailForm.postal_code" @update:modelValue="detailForm.postal_code = $event" class="full-width"/>
      </v-row>
      <!-- Address -->
      <v-row class="ma-0 mt-4 token-text-regular">
        Address
      </v-row>
      <v-row class="ma-0 mt-2">
        <CustomTextInput :modelValue="detailForm.address" @update:modelValue="detailForm.address = $event" class="full-width"/>
      </v-row>
      <!-- Tax ID -->
      <v-row class="ma-0 mt-4 token-text-regular">
        European TVA number (optional)
      </v-row>
      <v-row class="ma-0 my-2">
        <CustomTextInput :modelValue="detailForm.tax_id" @update:modelValue="detailForm.tax_id = $event" class="full-width"/>
      </v-row>

      <!-- Validation button -->
      <v-row class="ma-0 mt-4">
        <CustomButton text="Save payment billing details" :level="$enums.ButtonLevels.Primary" class="full-width"  @click="onSubmit" :disabled="disabled" :loading="loading" />
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import CustomCountrySelect from '@/components/UIElements/CustomCountrySelect.vue';
import { APICustomer } from '@/typesAPI';
import API from '@/api/wrapper';
import { Watch } from 'vue-property-decorator';

class Props {
  customer:APICustomer = prop({
    required: true,
  });
}

@Options({
  components: {
    CustomTextInput,
    CustomButton,
    CustomCountrySelect
  },
})
export default class WidgetBillingDetails extends Vue.with(Props) {

  detailForm:Partial<APICustomer['attributes']> = {}
  loading = false
  
  get disabled():boolean {
    return !this.detailForm.address || !this.detailForm.name || !this.detailForm.country || !this.detailForm.city || !this.detailForm.postal_code
  }

  @Watch('customer', {immediate:true})
  onCustomerChange() {
    if(this.customer) {
      if(this.customer.attributes.name) this.detailForm.name = this.customer.attributes.name
      if(this.customer.attributes.country) this.detailForm.country = this.customer.attributes.country
      if(this.customer.attributes.address) this.detailForm.address = this.customer.attributes.address
      if(this.customer.attributes.city) this.detailForm.city = this.customer.attributes.city
      if(this.customer.attributes.postal_code) this.detailForm.postal_code = this.customer.attributes.postal_code
      if(this.customer.attributes.tax_id) this.detailForm.tax_id = this.customer.attributes.tax_id
    }
  }

  onSubmit() {
    this.loading = true
    API.billing.editCustomer(this.customer.id, this.detailForm)
    .then(() => {
      this.loading = false
      this.$emit('success')
    })
  }



}
</script>

<style lang="scss" scoped>

</style>
