<template>
  <Teleport to="body">
    <v-col class="toastr-manager pa-0">
      <Toastr v-for="(descriptor, index) of descriptors"
        :key="'toastr-' + descriptor.id"
        :position="index"
        :severity="descriptor.severity"
        :title="descriptor.title"
        :text="descriptor.text"
        @close="close(descriptor)"
        class="mb-3"
      />
    </v-col>
  </Teleport>

</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Toastr from '@/components/UIElements/Toastr.vue';
import { ToastrDescriptor } from '@/types';

@Options({
  components: {
    Toastr,
  },
})
export default class ToastrsManager extends Vue {

  get descriptors ():ToastrDescriptor[] {
    return this.$store.getters['toastrs/getDescriptors']
  }

  close(descriptor:ToastrDescriptor) {
    this.$store.dispatch('toastrs/removeDescriptor', descriptor)
  }
}
</script>

<style lang="scss" scoped>
.toastr-manager {
  position: fixed;
  z-index: 10001 !important;
  top: 16px;
  left:0;
  right: 0;
  margin: 0 auto 0 auto;
  width: fit-content;
}
</style>