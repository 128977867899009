<template>
  <div class="block-item block-item-shadow">
    <div class="block-item-icon">
      <Icon :icon="block.icon" v-if="block.icon"/>
      <Logo :logo="block.logo" v-if="block.logo" />
    </div>
    <div class="block-item-label">
      {{block.name}}
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component';
import { Block } from '@/types'
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'

class Props {
  block: Block = prop({
    required: true,
  });
}

@Options({
  components: {
    Icon,
    Logo
  },
})
export default class BlockItemShadow extends Vue.with(Props) {
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.block-item-shadow {
  z-index: 1000;
  position:absolute;
  pointer-events: none;
  border: 2px solid $color-brand;
  box-shadow: $shadow-dragged;
}
</style>
