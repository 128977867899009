<template>
  <Teleport to="body">
    <div class="popover-container"  :class="{'opened': visible, transparent: transparent, positionerOnly: positionerOnly, nonInteractible: !closeOnExternalClick}" @click="onExternalClick">
      <div class="popover" :class="{ready:isPlaced, bar:!positionerOnly}" @click.stop="" ref="popoverDOM" :style="'top:'+ top + 'px; left:' + left + 'px; ' + (maxWidth ? 'max-width:' + maxWidth + 'px; ' : '') " @mouseenter="onStartHoverPopover" @mouseleave="onEndHoverPopover">
        <div class="arrow"
          v-if="!positionerOnly" 
          :class="{
            top: position === POSITION.BOTTOM,
            bottom: position === POSITION.TOP,
            left: position === POSITION.RIGHT,
            right: position === POSITION.LEFT
          }"
          :style="'top:'+ arrowTop + 'px; left:' + arrowLeft + 'px;'"
        >
          <div class="inner-arrow"
          :class="{
            top: position === POSITION.BOTTOM,
            bottom: position === POSITION.TOP,
            left: position === POSITION.RIGHT,
            right: position === POSITION.LEFT
          }"
          ></div>
        </div>
        <div class="popover-top pa-0">
          <div class="popover-right" >
            <template v-if="loading">
              <Spinner class="ma-auto"/>
            </template>
            <template v-else>
              <v-col class="pa-0">
                <v-row class="popover-title pa-4 pb-0" v-if="$slots.header" :class="{'pb-4': !$slots.content}">
                  <slot name="header"></slot>
                </v-row>
                <v-row class="popover-content pa-4" v-if="$slots.content">
                  <slot name="content"></slot>
                </v-row>
              </v-col>
            </template>
          </div>
        </div>
        <div class="popover-bottom pa-4 py-2" v-if="$slots.footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import Icon from '@/components/UIElements/Icon.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import { Watch } from 'vue-property-decorator'
import { nextTick } from 'vue'

export enum POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

class Props {
  visible: boolean = prop({
    required: true,
  });
  isHovered?: boolean = prop({
    required: false,
  });
  maxWidth?: string = prop({
    required: false,
  });
  target: HTMLElement = prop({
    required: true,
  });
  transparent?: boolean = prop({
    required: false,
  });
  closeOnExternalClick?: boolean = prop({
    required: false,
  });
  positionerOnly?: boolean = prop({
    required: false,
  });
  forcedPosition?: POSITION = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon,
    CustomButton,
    CustomTextInput,
    Spinner
  },
})
export default class Popover extends Vue.with(Props) {
  loading = false
  POSITION = POSITION
  top = 0;
  left = 0;
  arrowTop = 0;
  arrowLeft = 0;
  arrowWidth = 21;
  arrowHeight = 9;
  isPlaced = false
  endOverTimeout = 0
  position:POSITION = POSITION.BOTTOM

  get computedPosition():POSITION {
    return this.forcedPosition ? this.forcedPosition : this.position
  }

  onStartHoverPopover() {
    if(this.visible) {
      clearInterval(this.endOverTimeout)
      this.$emit('update:isHovered', true)
    }
  }

  onEndHoverPopover() {
    this.endOverTimeout = setTimeout(() => {
      this.$emit('update:isHovered', false)
    },300)
  }


  onExternalClick() {
    this.$emit('clickExternal')
    if(this.closeOnExternalClick) {
      this.$emit('update:visible', false)
    }
  }

  @Watch('computedPosition')
  onComputedPositionChange(){
    this.isPlaced = false
    nextTick(() => {
      this.placePopover()
    })
  }

  @Watch('visible', {immediate:true})
  onShowChange(){
    this.calculateBestPosition()

    if(this.visible) {
      this.isPlaced = false
      nextTick(() => {
        this.placePopover()
      })
    }
   
  }


  changePosition(newPosition:POSITION) {
    if(this.position !== newPosition) {
      this.position = newPosition
    }
  }

  calculateBestPosition() {
    nextTick(() => {
      setTimeout(() => {
        if(this.target && this.$refs.popoverDOM) {
          // @ts-ignore
          const targetRect:DOMRect  = this.target.$el ? this.target.$el.getBoundingClientRect() as DOMRect  : this.target?.getBoundingClientRect() as DOMRect 
          const popoverRect = (this.$refs.popoverDOM as HTMLElement).getBoundingClientRect()
          const minMargin = 8
          let bestPosition = POSITION.BOTTOM
          const availableSpace = {
            top : targetRect.top - minMargin,
            bottom : window.innerHeight - targetRect.bottom - minMargin,
            left : targetRect.left - minMargin,
            right : window.innerWidth - targetRect.right - minMargin
          }
            
          if(availableSpace.bottom >= popoverRect.height ) {
            bestPosition = POSITION.BOTTOM
          } else if(availableSpace.top >= popoverRect.height ) {
            bestPosition = POSITION.TOP
          } else if(availableSpace.left >= popoverRect.width ) {
            bestPosition = POSITION.LEFT
          } else if(availableSpace.right >= popoverRect.width ) {
            bestPosition = POSITION.RIGHT
          } else {
            if(availableSpace.bottom >= availableSpace.top && availableSpace.bottom >= availableSpace.left && availableSpace.bottom >= availableSpace.right) {
              bestPosition = POSITION.BOTTOM
            } else if(availableSpace.top >= availableSpace.bottom && availableSpace.top >= availableSpace.left && availableSpace.top >= availableSpace.right) {
              bestPosition = POSITION.TOP
            } else if(availableSpace.left >= availableSpace.top && availableSpace.left >= availableSpace.bottom && availableSpace.left >= availableSpace.right) {
              bestPosition = POSITION.LEFT
            } else if(availableSpace.right >= availableSpace.top && availableSpace.right >= availableSpace.left && availableSpace.right >= availableSpace.bottom) {
              bestPosition = POSITION.RIGHT
            }
          }
          this.changePosition(bestPosition)
        }
      })
    })
  }


  placePopover () {
      // @ts-ignore
      const targetRect:DOMRect  = this.target.$el ? this.target.$el.getBoundingClientRect() as DOMRect  : this.target?.getBoundingClientRect() as DOMRect 
      let popoverRect:DOMRect = new DOMRect()

      if(this.$refs.popoverDOM) {
        popoverRect = (this.$refs.popoverDOM as HTMLElement).getBoundingClientRect()
      }
      switch(this.computedPosition) {
        case POSITION.BOTTOM :
          // Put on Buttom 
          this.top = targetRect.top + targetRect.height
          this.left = targetRect.left + targetRect.width/2 - popoverRect.width/2
          this.arrowLeft = (popoverRect.width/2) - (this.arrowWidth/2)
          this.arrowTop = 0-this.arrowHeight
          if(!this.positionerOnly) {
            this.top += this.arrowHeight
          }
        break;
        case POSITION.TOP :
          // Put on Top 
          this.top = targetRect.top - (popoverRect.height)
          this.left = targetRect.left + targetRect.width/2 - popoverRect.width/2
          this.arrowLeft = (popoverRect.width/2) - (this.arrowWidth/2)
          this.arrowTop = popoverRect.height - 2
          if(!this.positionerOnly) {
            this.top -= this.arrowHeight
          }
        break;
        case POSITION.LEFT :
          // Put on Left 
          this.top = targetRect.top + targetRect.height/2 - popoverRect.height/2 + this.arrowHeight/2
          this.left = targetRect.left - popoverRect.width
          this.arrowLeft = popoverRect.width - 2
          this.arrowTop = (popoverRect.height/2) - (this.arrowWidth/2)
          if(!this.positionerOnly) {
            this.left -= this.arrowHeight
          }
        break;
        case POSITION.RIGHT :
          // Put on Right 
          this.top = targetRect.top + targetRect.height/2 - popoverRect.height/2
          this.left = targetRect.left + targetRect.width
          this.arrowLeft = 0-this.arrowWidth
          this.arrowTop = (popoverRect.height/2) - (this.arrowWidth/2)
          if(!this.positionerOnly) {
            this.left += this.arrowHeight
          }
        break;
      }

      this.top = Math.round(this.top)
      this.left = Math.round(this.left)
      this.arrowLeft = Math.round(this.arrowLeft)
      this.arrowTop = Math.round(this.arrowTop)

      this.isPlaced = true
    
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.popover-container {
  display: flex;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  position: fixed;
  z-index: 1000000000000 !important;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, padding 0.2s ease-in-out, background 0.2s ease-in-out;
  &:not(.opened) {
    display : none;
  }
  &.opened {
    opacity: 1;
    pointer-events: auto;
    &.transparent {
      background: transparent !important;
      .popover {
        background: rgba(255, 255, 255, 0.24) !important;
        div {
          opacity: 0;
        }
      }
    }
    &.nonInteractible {
      pointer-events: none !important;
    }
    &.positionerOnly {
      background: transparent !important;
      .popover {
        background: transparent !important;
        backdrop-filter: none !important;
        box-shadow: none !important;
      }
      .popover-content {
        margin : -16px !important;
        max-width: none !important;
        max-height: none !important;
      }
    }
  }

  .popover {
    opacity: 0;
    pointer-events: auto !important;

    &.ready {
      opacity: 1;
    }
    transition: opacity 0.2s ease-in-out, background 0.2s ease-in-out;
    div {
      transition: opacity 0.2s ease-in-out, background 0.2s ease-in-out;
    }
    z-index: 100001 !important;
    backdrop-filter: blur(1px) !important;
    width: auto;
    display: flex;
    position:absolute;
    background: white !important;
    flex-direction: column;
    display: flex;
    box-shadow: $shadow-z2 !important;

    .arrow {
      position: absolute;
      height: 9px;
      width: 21px;
      &.top {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $color-neutral-grey-12;
      }
      &.bottom {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $color-neutral-grey-12;
      }
      &.left {
        border-bottom: 10px solid transparent;
        border-top: 10px solid transparent;
        border-right: 10px solid $color-neutral-grey-12;
      }
      &.right {
        border-bottom: 10px solid transparent;
        border-top: 10px solid transparent;
        border-left: 10px solid $color-neutral-grey-12;
      }

      .inner-arrow {
        position: relative;
        &.top {
          top:0px;
          left:-9px;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid white;
        }
        &.bottom {
          top:-10px;
          left:-9px;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid white;
        }
        &.left {
          top:-10px;
          left:11px;
          border-bottom: 10px solid transparent;
          border-top: 10px solid transparent;
          border-right: 10px solid white;
        }
        &.right {
          top:-10px;
          left:-11px;
          border-bottom: 10px solid transparent;
          border-top: 10px solid transparent;
          border-left: 10px solid white;
        }
      }
    }
    .popover-top {
      width: 100%;
      height: 100%;
      flex-direction: row;
      display: flex;
      .popover-right {
        // overflow-y: auto;
        display: flex;
        .popover-header {
          margin: 0px;
          background: $color-neutral-grey-4;
          height: 56px;
          border-bottom: 1px solid $color-neutral-grey-12;
          backdrop-filter: blur(4px);
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          .popover-header-icon {
            margin-top:auto;
            margin-bottom: auto;
            margin-left:16px;
          }
          .popover-header-text {
            margin-top:auto;
            margin-bottom: auto;
            margin-left:8px;
          }
          .popover-header-x {
            margin-top:auto;
            margin-bottom: auto;
            margin-left: auto;
            margin-right:24px;
          }
        }
        .popover-title {
          margin: 0px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 32px;
        }
        .popover-content {
          margin: 0px;
          font-style: normal;
          max-height: 250px;
          overflow-y: auto;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    .popover-bottom {
      flex-direction: row;
      display: flex;
      border-top: 1px solid $color-neutral-grey-12;
    }
  }

  &:not(.positionerOnly) {
    .popover-right {
      min-width: 462px;
    }
  }
}

</style>
<style lang="scss">
.popover-container {
  .custom-text-input-container {
    width: 100%;
  }
}
</style>