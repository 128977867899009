<template>
    <Modal :visible="visible" :closeOnExternalClick="true" @update:visible="closeModal" width="400px">
      <template #header>
        <v-row class="ma-0">
          <div>
            Rename
          </div>
          <CustomButton class="ml-auto" :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="closeModal"/>
        </v-row>      
      </template>
      <template #content>
        <CustomTextInput v-model="newName" class="ma-6 mb-4" :placeholder="'New Name'" max="30"/>
        <v-row class="pa-4 ma-0">
          <v-col class="pa-0 mx-2">
            <CustomButton :level="$enums.ButtonLevels.Secondary" class="ml-auto mr-2 full-width" @click="closeModal">
              Cancel
            </CustomButton>
          </v-col>
          <v-col class="pa-0 mx-2">
            <CustomButton :level="$enums.ButtonLevels.Primary" class="full-width" @click="doRename" :loading="loadingRename">
              Save
            </CustomButton>
          </v-col>
        </v-row>
      </template>
    </Modal>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import API from '@/api/wrapper';
import { Watch } from 'vue-property-decorator';
import { APIProject } from '@/typesAPI';

class Props {
  visible?: boolean = prop({
    required: false,
  });
  target: any = prop({
    required: true,
  });
  attributeKey?: string = prop({
    required: false,
  });
}


@Options({
  components: {
    Modal,
    CustomButton,
    CustomTextInput
  },
})
export default class ModalRenameRessource extends Vue.with(Props) {
  loadingRename = false
  newName = ""

  get computedAttributeKey():string {
    return this.attributeKey ? this.attributeKey : "name"
  }

  closeModal() {
    this.newName = ""
    this.$emit('update:visible', false)
  }

  doRename() {
    this.loadingRename = true
    const body:any = {}
    body[this.computedAttributeKey] = this.newName

    switch(this.target.type) {
      case 'projects':
        API.projects.edit(this.target.id, body)
        .then((newVal) => {
          this.$store.dispatch('projects/updateProject', newVal)
          this.$emit('update:visible', false)
        }) 
        break;

      case 'kubernetesClusters':
        API.clusters.editCluster(this.target.id, body)
        .then((newVal) => {
          this.$store.dispatch('clusters/editCluster', newVal)
          this.$emit('update:visible', false)
        }) 
        break;  
      default:
        break;
    }

  }

  @Watch('visible', {immediate: true})
  onVisibleChange() {
    if(this.visible && this.target?.attributes) {
      this.loadingRename = false
      this.newName = this.target?.attributes[this.computedAttributeKey]
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
</style>
