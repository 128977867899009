<template>
  <div class="custom-number-input-container">
    <Icon v-if="icon" class="custom-number-input-icon" :icon="icon"/>
    <input 
      class="custom-number-input" 
      :class="{'has-error': error, 'has-error-fixed': false, 'has-icon': icon, disabled: disabled}"
      type="number" 
      :min="min"
      :max="max"
      :placeholder="placeholder"
      :value="modelValue"
      @input="handleInput"
      @keydown.delete="handleDelete"
    >
    <div class="custom-number-input-select-unit" v-if="units">
      <CustomButton :level="$enums.ButtonLevels.UnitSwitcher" ref="unitSwitcher" @click="openBurgerUnits">
        <div class="token-text-micro">
          {{ selectedUnit?.label }}
        </div>
        <Icon icon="caret-down" />
      </CustomButton>
      <DropdownMenu 
        :items="unitBurgerList"
        v-model:show="showBurgerUnits"
        :maxContent="true"
        @update:modelValue="onUserUnitChange"
        :target="$refs.unitSwitcher"
        classInternal="my-4"
      />
    </div>
    <div class="custom-number-input-buttons" v-if="!disabled">
      <button class="up" @click="numberUp" :disabled="disabled ? true : (modelValue && max ? parseInt(modelValue) >= max : false)"><Icon icon="caret-up-small"/></button>
      <button class="down" @click="numberDown" :disabled="disabled ? true : (modelValue && min ? parseInt(modelValue) <= min : false)"><Icon icon="caret-down-small"/></button>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'
import { nextTick } from 'vue';
import { Unit } from '@/types';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import { Watch } from 'vue-property-decorator';
import { ListItem } from '@/components/UIElements/List.vue';

class Props {
  placeholder?: string = prop({
    required: false,
  });
  nullable?: boolean = prop({
    required: false,
  });
  error?: string = prop({
    required: false,
  });
  icon?: string = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  modelValue: string | null = prop({
    required: true,
  });
  min?: number = prop({
    required: false,
  });
  max?: number = prop({
    required: false,
  });
  delay?: number = prop({
    required: false,
  });
  units?:Unit[] = prop({
    required: false,
  });  
  selectedUnit?:Unit = prop({
    required: false,
  });
}


@Options({
  components: {
    Icon,
    CustomButton,
    DropdownMenu
  },
})
export default class CustomTextInput extends Vue.with(Props) {
  timeoutDelay = 1500
  timeout?:number
  showBurgerUnits = false

  correctedValue:number | null = 0

  mounted(): void {
    if(this.delay) {
      this.timeoutDelay = this.delay
    }
  }

  onUserUnitChange(unitListItem:ListItem) {
    const newSelectedUnit = {
      label: unitListItem.text as string,
      ratio: parseInt(unitListItem.value as string)
    }
    this.showBurgerUnits = false
    this.$emit('update:selectedUnit', newSelectedUnit)

    this.$nextTick(() => {
      let value = this.modelValue !== null ? parseInt(this.modelValue)  : this.modelValue
      if(this.min !== undefined && value !== null && value / (this.selectedUnit?.ratio ? this.selectedUnit?.ratio : 1) < this.min) {
        this.correctedValue = this.min
      } else {
        this.correctedValue = value
      }

      if(this.nullable) {
        this.$emit('update:modelValue', value)
      } else {
        this.$emit('update:modelValue', this.correctedValue)
      }
      if(value !== null) {
        this.onValueChage()
      }
    })
   
  }

  openBurgerUnits() {
    this.showBurgerUnits = true
  }

  get unitBurgerList():ListItem[] {
    const ret:ListItem[] = []
    this.units?.forEach((unit, index) => {
      ret.push({
        id: 'unit-'+index,
        value: unit.ratio.toString(),
        text: unit.label,
        selected: unit.ratio === this.selectedUnit?.ratio
      })
    })
    return ret
  }

  handleDelete(event:Event) {
    const target = event.target as HTMLInputElement
    event.preventDefault()
    let troncated:number | null = target?.value.length > 1 ? parseInt(target.value.slice(0, -1)) : (this.nullable ? null : 0)

    if(this.min !== undefined && troncated !== null && troncated < this.min) {
      this.correctedValue = this.min
    } else {
      this.correctedValue = troncated
    }

    if(this.nullable) {
      this.$emit('update:modelValue', troncated)
    } else {
      this.$emit('update:modelValue', this.correctedValue)
    }
    this.onValueChage()

  }
      

  handleInput (event:Event) {
    const target = event.target as HTMLInputElement

    let value = target?.value ? parseInt(target?.value) : (this.nullable ? null : 0)
    if(value !== null) {
      if(this.min !== undefined) {
        if(value <= this.min) {
          value = this.min
        }
      }
      if(this.max !== undefined) {
        if(value >= this.max) {
          value = this.max
        }
      }
    }
    
    nextTick(() => {
      this.correctedValue = value
      this.onValueChage()
    })
  }

  numberUp () {
    const newVal = this.modelValue ? parseInt(this.modelValue) + 1 : (this.min !== undefined ? this.min : 1);
    this.correctedValue = this.max ? (newVal <= this.max ? newVal : this.max) : newVal
    this.$emit('update:modelValue', this.correctedValue)

    this.onValueChage()
  }
  numberDown () {
    const newVal = this.modelValue ? parseInt(this.modelValue) - 1 :  (this.min !== undefined ? this.min : 0);
    this.correctedValue = this.min ? (newVal >= this.min ? newVal : this.min) : newVal
    this.$emit('update:modelValue', this.correctedValue)

    this.onValueChage()
  }

  onValueChage() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.$emit('delayedUpdate', this.correctedValue)
    }, this.timeoutDelay)
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.custom-number-input-container {
  position: relative;
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .custom-number-input-icon {
    position: absolute;
    top: 21px;
    left: 22px;
  }

  .custom-number-input-select-unit {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 8px;
    right: 32px;
  }

  .custom-number-input-buttons {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 6px;
    right: 10px;
    button {
      border: 1px solid $color-neutral-grey-8;
      box-shadow: $shadow-light-btn;
      background: $color-background-button-text-secondary-normal;
      width: 16px;
      height: 12px;
      &:hover {
        box-shadow: $shadow-light-btn-hover;
        background: $color-background-button-text-secondary-hover;
      }
      &:active {
        box-shadow: $shadow-light-btn-pressed;
        background: $color-background-button-text-secondary-pressed;
      }
      &:disabled {
        box-shadow: none;
        background: $color-background-button-text-secondary-disabled !important;
      }
      &.disabled {
        box-shadow: none;
        background: $color-background-button-text-secondary-disabled !important;
      }
      >span {
        width: 12px;
      }
    }
    .up {
      border-radius: 8px 8px 0px 0px;
    }
    .down {
      border-radius: 0px 0px 8px 8px;
    }
  }
  .custom-number-input {
    width: 100%;
    height: 38px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    background: white;
    border: 1px solid $color-neutral-grey-12;
    box-shadow: 0px 1px 4px rgba(0, 0, 58, 0.08), 0px 1px 1px -1px rgba(0, 0, 58, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding-left:12px;
    padding-right:12px;
    padding-top: 8px;
    padding-bottom: 8px;
    color:#00003A;
    transition: box-shadow 0.1s ease-in-out;
    &.has-icon {
      padding-left:40px;
    }

    &:hover {
      border: 1px solid $color-neutral-grey-30;
    }
    &:focus {
      outline: none;
      border: 2px solid $color-brand;
      box-shadow: 0px 0px 0px 2px rgba(50, 25, 178, 0.24);
      caret-color: $color-brand;
      margin-left:-1px;
    }
    &::placeholder { 
      font-weight: 400;
      font-size: 14px; 
      color: $color-neutral-grey-60;
    }

    &.has-error {
      border: 1px solid $color-orange;
      &:focus {
        outline: none;
        box-shadow: inset 0px -2px 0px $color-orange ;
      }
    }

    &.has-error-fixed {
      border: 1px solid $color-green;
      &:focus {
        outline: none;
        box-shadow: inset 0px -2px 0px $color-green ;
      }
    }
    &.disabled {
      // background: $color-neutral-grey-8;
      background: none;
      color: $color-neutral-grey-60;
      border:none;
      pointer-events: none;
      box-shadow: none;
    }
  }
}

</style>
<style lang="scss">
@import '@/css/variables';
.custom-number-input-buttons {
  button {
    &:disabled {
      .icon {
        background-color: $color-neutral-grey-30 !important;
      }
    }
  }
}
</style>