<template>
    <div class="platform-node">
      <v-row>
        <v-col class="title pl-6 pt-3 mt-2">
          {{props.label}}
        </v-col>
      </v-row>

    </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';
import { NodeProps, Position, Handle } from '@vue-flow/core'

class Props {
  props: NodeProps = prop({
    required: true,
  });
}

@Options({
  components: {
    Handle,
  },
})
export default class PlatformNode extends Vue.with(Props) {
  Position:typeof Position = Position
}
</script>

<style lang="scss">
.vue-flow__node-platform {
  z-index: -1 !important;
}
.platform-node {
  height: 500px;
  width: 1000px;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  box-shadow: rgb(14 30 37 / 32%) 0px 0px 6px 0px;
  border: 2px solid #326ce5;
  background-color: #dee7fb;
  .title {
    background-color: #326ce5;
    color: white;
  }
}
</style>
