import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/illustrations/no_cluster.svg'


const _hoisted_1 = { class: "clustersView" }
const _hoisted_2 = { class: "ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalRenameRessource = _resolveComponent("ModalRenameRessource")!
  const _component_ModalClusterConfig = _resolveComponent("ModalClusterConfig")!
  const _component_ModalClusterPutOffline = _resolveComponent("ModalClusterPutOffline")!
  const _component_ModalClusterDelete = _resolveComponent("ModalClusterDelete")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_ClusterDisplay = _resolveComponent("ClusterDisplay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ModalRenameRessource, {
      target: _ctx.actionTarget,
      visible: _ctx.showModalRename,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showModalRename) = $event))
    }, null, 8, ["target", "visible"]),
    _createVNode(_component_ModalClusterConfig, {
      cluster: _ctx.actionTarget,
      creating: _ctx.creating
    }, null, 8, ["cluster", "creating"]),
    _createVNode(_component_ModalClusterPutOffline, {
      cluster: _ctx.actionTarget,
      visible: _ctx.showModalPutOffline,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModalPutOffline) = $event)),
      projects: _ctx.actionTarget ? _ctx.projects[_ctx.actionTarget.id] : []
    }, null, 8, ["cluster", "visible", "projects"]),
    _createVNode(_component_ModalClusterDelete, {
      cluster: _ctx.actionTarget,
      visible: _ctx.showModalDelete,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showModalDelete) = $event))
    }, null, 8, ["cluster", "visible"]),
    (_ctx.clusters?.length === 0)
      ? (_openBlock(), _createBlock(_component_v_col, {
          key: 0,
          class: "pa-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "pa-0 token-text-h4" }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Clusters ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "ma-0 pt-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "pa-4 empty-box" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "ma-0 d-flex-row" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { class: "pa-0 ma-auto fit-content fit-content-height d-flex-col" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, { class: "ma-0 mx-auto fit-content-height" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_img, {
                                  src: _imports_0,
                                  height: "56px",
                                  width: "72px"
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, { class: "ma-0 token-text-h5 my-4 mx-auto fit-content-height" }, {
                              default: _withCtx(() => _cache[4] || (_cache[4] = [
                                _createTextVNode(" No cluster ")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_row, { class: "ma-0 mx-auto fit-content-height" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_CustomButton, {
                                  text: "Create cluster",
                                  onClick: _ctx.createCluster
                                }, null, 8, ["onClick"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_v_col, {
          key: 1,
          class: "pa-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0 mb-4" }, {
              default: _withCtx(() => [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "token-text-h4" }, " Clusters ", -1)),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_CustomButton, {
                    icon: "add",
                    onClick: _ctx.createCluster
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Create cluster ")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "pa-0 mr-4" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clusters, (cluster, index) => {
                      return (_openBlock(), _createBlock(_component_v_row, {
                        class: "ma-0",
                        key: 'col1-'+index
                      }, {
                        default: _withCtx(() => [
                          (index%2 === 0)
                            ? (_openBlock(), _createBlock(_component_ClusterDisplay, {
                                key: 0,
                                class: "mb-8",
                                cluster: cluster,
                                projects: _ctx.projects[cluster.id],
                                clustersStatus: _ctx.clustersStatus[cluster.id],
                                onClickMenu: _ctx.onBurgerMenuItemClick,
                                onClickDisplay: _ctx.onClusterClick
                              }, null, 8, ["cluster", "projects", "clustersStatus", "onClickMenu", "onClickDisplay"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { class: "pa-0 ml-4" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clusters, (cluster, index) => {
                      return (_openBlock(), _createBlock(_component_v_row, {
                        class: "ma-0",
                        key: 'col2-'+index
                      }, {
                        default: _withCtx(() => [
                          (index%2 !== 0)
                            ? (_openBlock(), _createBlock(_component_ClusterDisplay, {
                                key: 0,
                                class: "mb-8",
                                cluster: cluster,
                                projects: _ctx.projects[cluster.id],
                                clustersStatus: _ctx.clustersStatus[cluster.id],
                                onClickMenu: _ctx.onBurgerMenuItemClick,
                                onClickDisplay: _ctx.onClusterClick
                              }, null, 8, ["cluster", "projects", "clustersStatus", "onClickMenu", "onClickDisplay"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
  ]))
}