<template>
  <div class="node-detail-logs full-width" >
    <v-col class="pa-0">
      <v-row class="ma-2">
        <div class="token-text-medium my-auto">
          Logs
        </div>
        <CustomTextInput class="ml-auto" :searchStyle="true" placeholder="Search logs" v-model="query" @update:modelValue="onQueryUpdate"/>
      </v-row>
      <v-row class="ma-0 border-bot">
      </v-row>
      <v-row class="ma-0 log-container" ref="logContainer">
        <v-col class="pa-0 ma-2" >
          <Icon class="lazy-load-up-anchor mb-2" icon="spinner" ref="lazyLoadSpinner" :class="{invisible: topLine === 1 || query}"/>
          <template v-for="log of logs" :key="log.id">
            <LogLine :log="log"/>
          </template>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component'
import { APIBlock, APILiveLog } from '@/typesAPI'
import API from '@/api/wrapper'
import LogLine from '@/components/UIElements/LogLine.vue';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import Icon from '@/components/UIElements/Icon.vue';
import { nextTick } from 'vue';
import { Watch } from 'vue-property-decorator';

class Props {
  blockAPI: APIBlock = prop({
    required: true,
  });
}

@Options({
  components: {
    LogLine,
    CustomTextInput,
    Icon
  },
})
export default class NodeDetailLogs extends Vue.with(Props) {

  logs:APILiveLog[] = []
  total = 0
  topLine = 0
  interval:any = null
  query = ""
  lazyLoading = true
  needToScroll = false
  lastHeight = 0
  lazyLoadingMinTime = 500
  waitingLazyLoadUp = false
  

  // ATENTION : Il peux y avoir des millier de lignes, donc mettre un max sur la taille 
  mounted(): void {
    if(this.blockAPI) {
      API.monitoring.liveLogs(this.blockAPI.id, null , 100, 'desc')
      .then(({logList, total}) => {
        this.logs = logList.reverse()
        this.total = total
        this.topLine = total - this.logs.length + 1

        this.interval = setInterval(this.poll,1000)
        this.scrollToBottom()
      })
    }

    setTimeout(() => {
      const observer = new IntersectionObserver(this.onLazyLoaderShow, {
        // @ts-ignore
        root: this.$refs?.logContainer?.$el as HTMLElement,   // default is the viewport
        threshold: .9 // percentage of target's visible area. Triggers "onLazyLoaderShow"
      })
      // @ts-ignore
      observer.observe(this.$refs?.lazyLoadSpinner?.$el as HTMLElement)
    }, this.lazyLoadingMinTime)


  }

  onLazyLoaderShow(entries:any) {
    if(entries[0].isIntersecting) {
      if(this.lazyLoading) {
        this.waitingLazyLoadUp = true
      } else {
        this.lazyLoad('up')
      }
    }

  }

  beforeUnmount(): void {
    clearInterval(this.interval)
  }
  
  unmounted(): void {
    clearInterval(this.interval)
  }

  scrollToBottom() {
    nextTick(() => {
      // @ts-ignore
      const elem = this.$refs?.logContainer?.$el as HTMLElement
      if(elem) {
        elem?.scrollTo({
          top: elem?.clientHeight + elem?.scrollHeight,
          left: 0,
          // behavior: "smooth",
        })
      }

      setTimeout(() => {
        this.lazyLoading = false
      },this.lazyLoadingMinTime)

    })
  }

  get logContainerEl ():HTMLElement {
    // @ts-ignore
    return this.$refs?.logContainer?.$el as HTMLElement
  }

  @Watch('lazyLoading')
  onLazyLoadingChange() {
    if(!this.lazyLoading && this.waitingLazyLoadUp) {
      this.lazyLoad('up')
    }
  }

  lazyLoad(direction?:string) {
    if(!this.lazyLoading && this.logs.length < this.total) {
      this.lazyLoading = true
      this.waitingLazyLoadUp = false

      API.monitoring.liveLogs(this.blockAPI.id, direction === "up" ? this.logs[0]?.attributes?.time : null , 50, 'desc')
      .then(({logList, total}) => {
        this.lastHeight = this.logContainerEl.scrollHeight

        if(direction === "up") {
          this.logs.unshift(...logList.reverse())
        } else {
          this.logs.push(...logList)
        }

        nextTick(() => {
          setTimeout(() => {
            this.logContainerEl?.scrollTo({
              top: this.logContainerEl.scrollHeight - this.lastHeight,
              left: 0,
            })
          },50)
          setTimeout(() => {
            this.lazyLoading = false
          }, this.lazyLoadingMinTime)
        })
        this.total = total
        this.topLine = total - this.logs.length + 1
      })
    }
   
  }

  poll(): void {
    if(!this.query) {
 
      API.monitoring.liveLogs(this.blockAPI.id, this.logs[this.logs.length - 1].attributes.time, 50, 'asc')
      .then(({logList, total}) => {
        this.logs.push(...logList)
        this.total = total
      })

      nextTick(() => {
        // @ts-ignore
        const elem = this.$refs?.logContainer?.$el as HTMLElement
        if(elem) {
          if( Math.abs(elem.scrollTop - (elem.scrollHeight - elem.clientHeight)) < 1) {
            setTimeout(() => {
              elem?.scrollTo({
                top: elem?.clientHeight + elem?.scrollHeight,
                left: 0,
              })
            },100)
          }

        }
      })
     

    }
  }

  onQueryUpdate(): void {
    API.monitoring.liveLogs(this.blockAPI.id, null, 100, this.query ? 'asc' : 'desc', this.query)
    .then(({logList, total}) => {
      if(this.query) {
        this.logs = logList
      } else {
        this.logs = logList.reverse()
        setTimeout(() => {
          this.scrollToBottom()
        },100)
      }
      this.total = total
    })
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.node-detail-logs {
  .invisible {
    display: none;
  }
  .border-bot {
    border-bottom: 1px solid $color-neutral-grey-12 !important;
  }
  .log-container {
    height: calc(90vh - 188px);
    overflow: auto;
  }
  

}

</style>