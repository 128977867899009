import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "panel-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoBar = _resolveComponent("LogoBar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ProjectBar = _resolveComponent("ProjectBar")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_BlockBar = _resolveComponent("BlockBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_col, { class: "pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-0 mb-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 pr-4 mr-0 fit-content" }, {
              default: _withCtx(() => [
                _createVNode(_component_LogoBar)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
              default: _withCtx(() => [
                _createVNode(_component_ProjectBar)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 mr-0 fit-content" }, {
              default: _withCtx(() => [
                _createVNode(_component_BlockBar)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}