<template>
  <v-card class="block-bar bar pa-0" :class="{hideLeft: hideLeft}">
    <v-row  class="pa-0 ma-0 pt-2">
      <div class="pb-2"  v-for="block in listBlocks" :key="'block-'+block.id">
        <BlockCategory :block="block" type="block"></BlockCategory>
      </div>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BlockItem from '@/components/Blocks/BlockItem.vue'
import BlockCategory from '@/components/Blocks/BlockCategory.vue'
import { Block, Platform } from '@/types'

@Options({
  components: {
    BlockItem,
    BlockCategory
  },
})
export default class BlockBarMenu extends Vue {

  // FIRST UNBOARDING
  get hideLeft () {
    return !this.$store.getters['app/getFirstUnboardingDone']
  }
  // END FIRST UNBOARDING

  get listBlocks () : Block[] {
    return this.$store.getters['blocks/getList']
  }
  get listPlatforms () : Platform[] {
    return this.$store.getters['platforms/getList']
  }
}
</script>

<style lang="scss" scoped>
.block-bar {
  max-width: 234px;
  position: relative;
  overflow: revert;
  transition: left 0.3s ease-in-out 3s;
  left: 0px;
  &.hideLeft {
    left: -300px;
  }
  .block-title {
    font-size: 12px;
  }
}
</style>
