import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/illustrations/forbidden.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Modal, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
      loading: _ctx.loading
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_v_row, { class: "pa-4 ma-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomButton, {
              level: _ctx.$enums.ButtonLevels.Tertiary,
              class: "ml-auto mr-2",
              onClick: _ctx.reject
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Refuse terms and conditions ")
              ])),
              _: 1
            }, 8, ["level", "onClick"]),
            _createVNode(_component_CustomButton, {
              level: _ctx.$enums.ButtonLevels.Primary,
              onClick: _ctx.accept,
              loading: _ctx.loading
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Accept terms and conditions ")
              ])),
              _: 1
            }, 8, ["level", "onClick", "loading"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "token-text-h4 pa-8" }, " Fransys Terms and conditions ", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "token-text-regular pa-8 pt-0" }, [
          _createElementVNode("div", { class: "token-text-medium" }, "1. Acceptance of Terms"),
          _createTextVNode(" By using the Fransys Cloud Hosting Service (\"Service\"), you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, please refrain from using the Service. "),
          _createElementVNode("br"),
          _createElementVNode("br"),
          _createElementVNode("div", { class: "token-text-medium" }, "2. Service Description"),
          _createTextVNode(" Fransys provides cloud hosting services that allow users to store, manage, and access data and applications over the internet. The Service may be subject to updates, modifications, or enhancements at any time. "),
          _createElementVNode("br"),
          _createElementVNode("br"),
          _createElementVNode("div", { class: "token-text-medium" }, "3. User Responsibilities"),
          _createTextVNode(" a. You are responsible for all activities that occur under your account, including maintaining the security and confidentiality of your login credentials. "),
          _createElementVNode("br"),
          _createTextVNode(" b. You agree not to use the Service for any unlawful, unauthorized, or unethical purposes, including but not limited to distributing malware, infringing on intellectual property rights, or engaging in fraudulent activities. "),
          _createElementVNode("br"),
          _createElementVNode("br"),
          _createElementVNode("div", { class: "token-text-medium" }, "4. Data Security and Privacy"),
          _createTextVNode(" Fransys takes data security and privacy seriously. We will take reasonable steps to protect your data, but we are not responsible for data loss, unauthorized access, or breaches that may occur. Please review our Privacy Policy for more information on how we handle your data. ")
        ], -1))
      ]),
      _: 1
    }, 8, ["visible", "loading"]),
    _createVNode(_component_Modal, {
      visible: _ctx.visibleFallback,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visibleFallback) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { class: "pa-10" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  src: _imports_0,
                  height: "64px",
                  width: "96px",
                  class: "mx-auto mb-6"
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "token-text-h4 pb-2 ma-0" }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("span", { class: "mx-auto" }, " Accept the terms and conditions to use Fransys ", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "token-text-h5 pb-6 ma-0" }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createElementVNode("span", { class: "mx-auto" }, " You can't use fransys if you don't accept the terms and conditions. ", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomButton, {
                  level: _ctx.$enums.ButtonLevels.Primary,
                  class: "mx-auto",
                  onClick: _ctx.reopen
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" Show terms and conditions ")
                  ])),
                  _: 1
                }, 8, ["level", "onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}