<template>
    <div class="divider" :class="{vertical : vertical}">
    </div>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component';

class Props {
  vertical?:boolean = prop({
    required: false,
  });
}

export default class Divider extends Vue.with(Props) {
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
.divider {
  cursor: default;
  border-top: 1px solid $color-neutral-grey-12 !important;
  padding: 0 !important;
  &.vertical {
    border-top: none !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    border-left: 1px solid $color-neutral-grey-12 !important;
  }
}
</style>
