<template>
  <v-col class="payment-method-display pa-0">
    <v-row class="ma-0 token-text-regular">

      <!-- PayPal -->
      <template v-if="paymentMethod.attributes.type === 'paypal'">
        <v-col class="pa-0 fit-content">
          <PaymentMethodLogo pmlogo="paypal" />
        </v-col>
        <v-col class="pa-0 ml-4">
          <v-row class="ma-0">
            Paypal
          </v-row>
          <v-row class="ma-0 token-text-medium">
            {{ paymentMethod.attributes.object.payer_email }}
          </v-row>
        </v-col>
      </template>

      <!-- Cards -->
      <template v-else-if="paymentMethod.attributes.type === 'card'">
        <v-col class="pa-0 fit-content">
          <PaymentMethodLogo :pmlogo="paymentMethod?.attributes?.object?.brand" />
        </v-col>
        <v-col class="pa-0 ml-4 my-auto">
          <v-row class="ma-0">
            {{ $enums.CardBrandsDisplay[paymentMethod.attributes.object.display_brand] }} 
            <span class="ml-1 token-text-medium">{{ paymentMethod.attributes.object.last4 }}</span>
          </v-row>
          <v-row class="ma-0">
            Expires
            <span class="ml-1 token-text-medium">{{ paymentMethod.attributes.object.exp_month }}/{{ paymentMethod.attributes.object.exp_year - 2000 }}</span>
          </v-row>
        </v-col>
      </template>

       <!-- SEPA -->
       <template v-else-if="paymentMethod.attributes.type === 'sepa_debit'">
        <v-col class="pa-0 fit-content">
          <PaymentMethodLogo pmlogo="sepa" />
        </v-col>
        <v-col class="pa-0 ml-4 my-auto">
          <v-row class="ma-0">
            SEPA mandate  
            <span class="ml-1 token-text-medium">{{ paymentMethod.attributes.object.fingerprint }}</span>
          </v-row>
          <v-row class="ma-0">
          </v-row>
        </v-col>
      </template>
      <template v-else>
        <v-col class="pa-0 fit-content">
          <PaymentMethodLogo pmlogo="" />
        </v-col>
        <v-col class="pa-0 ml-4 my-auto">
          <v-row class="ma-0">
            <span class="ml-1 token-text-medium">{{ paymentMethod.attributes.type }}</span>
          </v-row>
          <v-row class="ma-0">
          </v-row>
        </v-col>
      </template>


      <!-- Default badge -->
      <v-col class="pa-0 ml-auto fit-content my-auto" v-if="isDefault">
        <Badge label="Default" icon="star" :severity="$enums.Severity.INFO" :class="{'mr-2': withBurger}"/>
      </v-col>

      <!-- Burger button -->
      <v-col class="pa-0 ml-auto fit-content my-auto" v-if="withBurger">
        <CustomButton ref="menuButton" class="px-3" icon="burger" :level="$enums.ButtonLevels.Tertiary" @click="burgerOpened = !burgerOpened"/>
        <DropdownMenu 
          :items="burgerItems"
          v-model:show="burgerOpened"
          :maxContent="true"
          @update:modelValue="onBurgerMenuItemClick"
          :target="$refs.menuButton"
        />
      </v-col>
    </v-row>
  </v-col> 
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component';
import PaymentMethodLogo from '@/components/UIElements/PaymentMethodLogo.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import { APIPaymentMethod } from '@/typesAPI';
import Badge from '@/components/UIElements/Badge.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import { ListItem } from '@/components/UIElements/List.vue';
import API from '@/api/wrapper';

class Props {
  paymentMethod: APIPaymentMethod = prop({
    required: true,
  });
  withBurger?: boolean = prop({
    required: false,
  });
  isDefault?:boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    PaymentMethodLogo,
    CustomButton,
    Badge,
    DropdownMenu
  },
})
export default class PaymentMethodDisplay extends Vue.with(Props) {
  burgerOpened = false
  burgerItems: ListItem[] = [
    {
      id: 'default',
      text: 'Set to default',
      icon: 'star'
    },
    {
      id: 'separator',
      separator: true,
    },
    {
      id: 'delete',
      text: 'Delete',
      icon: 'trash',
      class: 'delete'
    },
  ]
  
  onBurgerMenuItemClick(item:ListItem) {
    if(item.id === 'default') {
      //Set to default
      API.billing.editPaymentMethod(this.paymentMethod.id, {priority: 1})
      .then((res:APIPaymentMethod) => {
        this.$store.dispatch('billing/invalidatePaymentMethods')
      })
    }

    item.selected = false
    this.burgerOpened = false
  }  
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

:deep(.delete) {
  color : $text-error;
}

</style>
