<template>
    <div class="message" :class="'severity-' + severity">
      <v-row class="ma-3">
        <v-col class="pa-0 fit-content">
          <Icon v-if="severity === $enums.Severity.DANGER" icon="cancel-square" />
          <Icon v-else-if="severity === $enums.Severity.WARNING" icon="warning" />
          <Icon v-else-if="severity === $enums.Severity.SUCCESS" icon="check-square" />
          <Icon v-else-if="severity === $enums.Severity.INFO" icon="info" />
        </v-col>
        <v-col class="pa-0 mx-3" :class="{'mt-n1' : (title || $slots['title']) && (text || $slots['text'])}">
          <v-row class="ma-0 token-text-medium mb-1" v-if="title">
            {{ $helpers.text.capitalize(title) }}
          </v-row>
          <v-row class="ma-0 token-text-medium mb-1" v-else-if="$slots['title']">
            <slot name="title"></slot>
          </v-row>
          <v-row class="ma-0 token-text-regular" v-if="text">
            {{ text }}
          </v-row>
          <v-row class="ma-0 token-text-regular" v-else-if="$slots['text']">
            <slot name="text"></slot>
          </v-row>
        </v-col>
      </v-row>
    </div>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';

class Props {
  severity: string = prop({
    required: true,
  });
  title: string = prop({
    required: true,
  });
  text: string = prop({
    required: true,
  });
}

@Options({
  components: {
    Icon,
    CustomButton
  },
})
export default class Message extends Vue.with(Props) {

}
</script>

<style lang="scss" scoped>
@import '@/css/variables'; 

.message {
  position: relative;
  border-radius: 8px;
  border: 1px solid $color-neutral-grey-12;
  opacity: 1;
  width: 100%;
  transition: margin 1s ease-in-out, box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.severity-danger {
  background-color: $bg-error;
  color:$text-error;
  border-color:$color-orange;
  :deep(.icon) {
    background-color: $color-orange !important;
  }
  :deep(.custom-button .icon) {
    background-color: $text-error !important;
  }
}
.severity-warning {
  background-color: $bg-warning;
  color:$text-warning;
  border-color:$color-yellow;
  :deep(.icon) {
    background-color: $color-yellow !important;
  }
  :deep(.custom-button .icon) {
    background-color: $text-warning !important;
  }
}
.severity-success {
  background-color: $bg-success;
  color:$text-success;
  border-color:$color-green;
  :deep(.icon) {
    background-color: $color-green !important;
  }
  :deep(.custom-button .icon) {
    background-color: $text-success !important;
  }
}

.severity-info {
  background-color: $bg-info;
  color:$text-info;
  border-color:$color-blue;
  :deep(.icon) {
    background-color: $color-blue !important;
  }
  :deep(.custom-button .icon) {
    background-color: $text-info !important;
  }
}

.severity-neutral {
  background-color: $color-neutral-grey-8;
  color:$color-neutral-grey-60;
  border-color:$color-neutral-grey-20;
  :deep(.icon) {
    background-color: $color-neutral-grey-20 !important;
  }
  :deep(.custom-button .icon) {
    background-color: $color-neutral-grey-60 !important;
  }
}


</style>