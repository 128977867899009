<template>
  <div class="shell-detail-field"
    :class="{disabled : disabled}"
  >
    <template v-if="horizontal">
      <v-row class="ma-0 d-flex">
        <v-col class="pa-0" v-if="!solo">
          <template v-if="title">
            {{ title }}
          </template>
          <template v-else>
            <slot name="title"></slot>
          </template>
        </v-col>
        <v-col class="pa-0" align="right" style="max-width: fit-content;">
          <slot name="content"></slot>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row class="ma-0 mb-2" v-if="!solo">
        <template v-if="title">
          {{ title }}
        </template>
        <template v-else>
          <slot name="title"></slot>
        </template>
      </v-row>
      <v-row class="ma-0">
        <slot name="content"></slot>
      </v-row>
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import Icon from '@/components/UIElements/Icon.vue'

class Props {
  title?: string = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  horizontal?: boolean = prop({
    required: false,
  });
  solo?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon
  },
})
export default class ShellDetailField extends Vue.with(Props) {

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
.shell-detail-field {
  
}
</style>
