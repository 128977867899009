<template>
  <div class="tokenLoginView full-view">
    <template v-if="loading">
      <Spinner class="ma-auto"/>
    </template>
    <template v-else-if="error.title">
      <BackgroundGradiant/>
      <v-col class="pa-0 my-auto">
        <v-row class="ma-0 pb-12 mx-auto fit-content">
          <FransysSignature />
        </v-row>
        <v-row class="ma-0">
          <div class="ma-auto error bar">
            <!-- Display error -->
            <template v-if="error.title === ERROR_TYPES.TOKEN_EXPIRED">
              <v-img src="@/assets/illustrations/link_expired.png" height="56px" width="56px" class="mx-auto mb-6"/>
              <div class="ma-auto token-text-h4 mb-4">
                This link expired
              </div>
              <div class="ma-auto token-text-regular token-text-color-secondary">
                Every link is only usable for {{ meta[ERROR_TYPES.TOKEN_EXPIRED].expiration }}
              </div>
              <div class="ma-auto token-text-regular token-text-color-secondary">
                To log in, generate a new log in link
              </div>
            </template>
            <template v-else-if="error.title === ERROR_TYPES.RESOURCE_NOT_FOUND">
              <v-img src="@/assets/illustrations/link_not_exist.png" height="56px" width="56px" class="mx-auto mb-6"/>
              <div class="ma-auto token-text-h4 mb-4">
                This link does not exist
              </div>
              <div class="ma-auto token-text-regular token-text-color-secondary">
                It seems the link you used does not exist
              </div>
              <div class="ma-auto token-text-regular token-text-color-secondary">
                To log in, generate a new log in link
              </div>
            </template>
            <template v-else-if="error.title === ERROR_TYPES.TOKEN_MAX_USED">
              <v-img src="@/assets/illustrations/link_already_used.png" height="56px" width="56px" class="mx-auto mb-6"/>
              <div class="ma-auto token-text-h4 mb-4">
                This link has already been used
              </div>
              <div class="ma-auto token-text-regular token-text-color-secondary">
                Every link is only usable {{ meta[ERROR_TYPES.TOKEN_MAX_USED].max_uses }}
              </div>
              <div class="ma-auto token-text-regular token-text-color-secondary">
                To log in, generate a new log in link
              </div>
            </template>
            <template v-else>
              <div class="ma-auto token-text-h4 color-error">
                {{ error.details }}
              </div>
            </template>
            <!-- Action callback -->
            <div class="full-width mb-2 mt-6">
              <CustomTextInput v-model="email" placeholder="hello@email.com"/>
            </div>
            <div class="full-width">
              <CustomButton :level="$enums.ButtonLevels.Primary" class="full-width" @click="generateNewLink">
                Generate Link
              </CustomButton>
            </div>
          </div>
        </v-row>
      </v-col>
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import API from '@/api/wrapper';
import Spinner from '@/components/UIElements/Spinner.vue';
import BackgroundGradiant from '@/components/UIElements/BackgroundGradiant.vue';
import FransysSignature from '@/components/UIElements/FransysSignature.vue';
import { APIError } from '@/typesAPI';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';


enum ERROR_TYPES {
  RESOURCE_NOT_FOUND = 'resource-not-found',
  TOKEN_MAX_USED = 'token-overused',
  TOKEN_EXPIRED = 'token-expired',
}


@Options({
  components: {
    Spinner,
    BackgroundGradiant,
    FransysSignature,
    CustomButton,
    CustomTextInput
  },
})
export default class TokenLoginView extends Vue {

  email = ""
  loading = true
  error: APIError = {
    title: '',
    details: ''
  }
  meta:any = {}
  ERROR_TYPES: typeof ERROR_TYPES = ERROR_TYPES

  mounted(): void {
    this.loading = true
    API.session.getCSRF()
    .then(() => {
        API.session.tokenLogin(this.$route.query.token as string)
        .then(() => {
          this.$router.replace({
            name: 'projects'
          });
      })
      .catch((err) => {
        if(err?.response?.data?.errors) {
          const errors = Object.values(err.response.data.errors) as any[]
          if(errors.length) {
            this.meta = err.response.data.meta
            this.error = errors[0]
          }
        }
        this.loading = false
      })
    })
    .catch(() => {
      this.loading = false
    })
  }

  generateNewLink(): void {
    this.$router.replace({name:'register', query : {email: this.email}})
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
.tokenLoginView {
  .error {
    min-width: 480px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    box-shadow: -4px 0px 16px 0px rgba(1, 19, 194, 0.08), 4px 0px 16px 0px rgba(127, 61, 244, 0.08), 0px -4px 16px 0px rgba(243, 83, 0, 0.08), 0px 4px 16px 0px rgba(255, 114, 202, 0.08) !important;
  }
}

</style>