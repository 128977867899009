<template>
  <div class="environments-bar bar">
    <list 
      :items="listItems"
      title="ENVIRONMENTS"
      :deployable="true"
    >
      <template #footer>
        <v-col class="add-new-environment pa-0">
          + New environment
        </v-col>
      </template>
    </list>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Environment } from '@/types'
import { Ref } from 'vue-property-decorator';
import Icon from '@/components/UIElements/Icon.vue'
import List, { ListItem } from '@/components/UIElements/List.vue'

@Options({
  components: {
    Icon,
    List
  },
})
export default class EnvironmentsBar extends Vue {
  @Ref('envList') readonly envList!: HTMLElement

  deployed = false
  selectedEnv:Environment | null = null

  deploy() {
    if(this.deployed) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  collapse() {
    let sectionHeight = this.envList.scrollHeight;
    let elementTransition = this.envList.style.transition;
    this.envList.style.transition = '';
    requestAnimationFrame(() => {
      this.envList.style.height = sectionHeight + 'px';
      this.envList.style.transition = elementTransition;
      requestAnimationFrame(() => {
        this.envList.style.height = 0 + 'px';
      });
    });
    this.deployed = false
  }

  expand() {
    let sectionHeight = this.envList.scrollHeight;
    this.envList.style.height = sectionHeight + 'px';
    const handleEventEnd = () => {
      this.envList.removeEventListener('transitionend', handleEventEnd);
      this.envList.style.height = 'auto';
    };
    this.envList.addEventListener('transitionend', handleEventEnd)
    
    this.deployed = true
  }

  selectEnv(env: Environment) {
    this.selectedEnv = env
  }

  get listEnvironments () : Environment[] {
    return this.$store.getters['environments/getList']
  }

  get listItems (): ListItem[] {
    return this.listEnvironments.map((env) => {
      return {
        text: env.label,
        selected: env.id === '0' ? true : false
      }
    })
  }

  mounted(): void {
    this.selectEnv(this.listEnvironments[0])
  }
}
</script>

<style lang="scss" scoped>
.environments-bar {
  min-height: 40px;
  .environments-btn {
    width: 100%;
    overflow: hidden;
    padding: 0;
    justify-content: flex-start;
    display: flex;
    height: 40px;
    cursor: pointer;
    font-weight: 450;
    font-size: 12px;
    line-height: 20px;
    .environments-btn-text {
      margin-top:auto;
      margin-bottom:auto;
      margin-left: 16px;
    }
    .environments-btn-icon {
      margin-top:auto;
      margin-bottom:auto;
      margin-left: auto;
      margin-right:16px;
      transition: transform 0.2s ease-in-out;
      &.deployed {
        transform: rotate(-180deg);
      }
    }
  }

  .env-list {
    height: 0px;
    width: 100%;
    transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
    opacity: 0;
    &.deployed {
      opacity: 1;
    }
  }

  .environment {
    cursor: pointer;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-left:8px !important;
    margin-right:8px !important;
    padding: 8px !important;
    margin-top:8px !important;
  }
  .env-selected {
    background-color: #331ab3;
    color:white;
  }
  .add-new-environment {
    cursor: pointer;
    border-top: 1px solid lightgrey;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 8px !important;
    padding-left:16px !important;
    padding-right:16px !important;
    margin-left:0px !important;
    margin-right:0px !important;
    margin-top:8px !important;
  }
}
</style>