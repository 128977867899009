import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeploymentModal = _resolveComponent("DeploymentModal")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["save-bar d-flex", {hideLeft: _ctx.hideLeft, firstUnboardingOngoing: _ctx.firstUnboardingOngoing, deployementModalOpen: _ctx.isDeployementModalOpen, deployementModalReduced: _ctx.isDeployementModalReduced, deployed:_ctx.deployed, deploying:_ctx.deploying}])
  }, [
    _createVNode(_component_v_col, { class: "pa-0 ma-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "pa-0 ma-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_DeploymentModal)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}