import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_PriceDisplay = _resolveComponent("PriceDisplay")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["project-bar bar px-2 d-flex", {collapsed:_ctx.collapsed}])
  }, [
    _createVNode(_component_v_col, { class: "ma-auto pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "pa-0 ma-0 d-flex" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 my-auto title-container" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "ma-0 full-width" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CustomTextInput, {
                      modelValue: _ctx.newName,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newName) = $event)),
                      class: "mr-0",
                      token: 'token-text-medium',
                      plain: true,
                      onBlur: _ctx.doNameChange,
                      onKeypress: _withKeys(_ctx.doNameChange, ["enter"]),
                      max: 30
                    }, null, 8, ["modelValue", "onBlur", "onKeypress"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.currentClusterId)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_Divider, {
                    vertical: true,
                    class: "mx-2"
                  }),
                  _createVNode(_component_v_col, { class: "pa-0 fit-content d-flex-col" }, {
                    default: _withCtx(() => [
                      (_ctx.monthlyPrice)
                        ? (_openBlock(), _createBlock(_component_v_row, {
                            key: 0,
                            class: "ma-0 token-text-medium price token-text-color-primary ml-2"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_PriceDisplay, {
                                price: _ctx.displayPriceMin,
                                price2: _ctx.displayPriceMax,
                                currency: _ctx.customer?.attributes.currency
                              }, null, 8, ["price", "price2", "currency"])
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_v_row, {
                            key: 1,
                            class: "ma-0 token-text-medium price token-text-color-primary ma-auto"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Spinner, { class: "ma-auto" })
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}