<template>
    <v-row class="full-width ma-0">
      <v-col class="pa-0 d-flex">
        <div class="separator my-auto"></div>
      </v-col>
      <v-col class="pa-0 d-flex text-col mx-2">
        <div class="ma-auto d-flex">
          <slot></slot>
        </div>
      </v-col>
      <v-col class="pa-0 d-flex">
        <div class="separator my-auto"></div>
      </v-col>
    </v-row>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class TextBetweenLines extends Vue {
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
.separator {
    cursor: default;
    width: 100%;
    border-top: 1px solid $color-neutral-grey-12 !important;
}
.text-col {
  width: fit-content;
  max-width: fit-content;
}
</style>
