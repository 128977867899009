import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ma-auto d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, { class: "full-width ma-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { class: "pa-0 d-flex" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "separator my-auto" }, null, -1)
        ])),
        _: 1
      }),
      _createVNode(_component_v_col, { class: "pa-0 d-flex text-col mx-2" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ]),
        _: 3
      }),
      _createVNode(_component_v_col, { class: "pa-0 d-flex" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "separator my-auto" }, null, -1)
        ])),
        _: 1
      })
    ]),
    _: 3
  }))
}