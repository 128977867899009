<template>
  <div class="height-deployer" :class="{deployed : internalDeployed}" ref="heightDeployer">
    <slot></slot>
  </div>
</template>


<script lang="ts">
import { Vue, prop } from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';

class Props {
  modelValue: boolean = prop({
    required: true,
  });
  forceDeployed?: boolean = prop({
    required: false,
  });
}

export default class List extends Vue.with(Props) {
  @Ref('heightDeployer') readonly heightDeployer!: HTMLElement
  internalDeployed = this.modelValue ? true : false

  collapse() {
    let sectionHeight = this.heightDeployer.scrollHeight;
    let elementTransition = this.heightDeployer.style.transition;
    this.heightDeployer.style.transition = '';
    requestAnimationFrame(() => {
      this.heightDeployer.style.height = sectionHeight + 'px';
      this.heightDeployer.style.transition = elementTransition;
      requestAnimationFrame(() => {
        this.heightDeployer.style.height = 0 + 'px';
      });
    });
    this.internalDeployed = false
    this.$emit('collapse')

  }

  expand() {
    const handleEventEnd = () => {
      this.heightDeployer.removeEventListener('transitionend', handleEventEnd);
      this.heightDeployer.style.height = 'auto';
    };
    this.heightDeployer.addEventListener('transitionend', handleEventEnd)

    let sectionHeight = this.heightDeployer.scrollHeight;
    this.heightDeployer.style.height = sectionHeight + 'px';
    
    this.internalDeployed = true
    this.$emit('expand')
  }

  @Watch('modelValue')
  onModelValueChange(newValue:boolean) {
    if(!newValue) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  @Watch('forceDeployed')
  onForceDeployedChange() {
    if(this.forceDeployed !== undefined ) {
      this.$emit('update:modelValue', this.forceDeployed)
    }
  }

}



</script>

<style lang="scss" scoped>
@import '@/css/variables';
.height-deployer {
    height: 0px;
    overflow: hidden;
    transition: height 0.2s ease-in-out, opacity 0.4s ease-in-out;
    opacity: 0;
    &:not(.deployed) {
      transition: height 0.2s ease-in-out, opacity 0.4s ease-in-out, margin 0.1s ease-in-out 0.2s;
      margin:0px !important;
    }
    &.deployed {
      height: auto;
      opacity: 1;
    }
  }
</style>
