import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loginSocialButton" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CustomButton, {
      class: "full-width",
      level: _ctx.$enums.ButtonLevels.Secondary,
      icon: _ctx.provider,
      onClick: _ctx.onClick
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Log in with " + _toDisplayString(_ctx.providerName), 1)
      ]),
      _: 1
    }, 8, ["level", "icon", "onClick"])
  ]))
}