<template>
  <div class="panel-top">
    <v-col class="pa-0">
      <v-row class="ma-0 no-flex-wrap">
        <!-- <v-col class="pa-0 pr-4 fit-content">
          <AccountBar/>
        </v-col> -->
        <v-col class="pa-0 pr-4 fit-content ml-auto" align="right">
          <ContactUsBar/>
        </v-col>
        <v-col class="pa-0  fit-content" align="right">
          <ValidationErrorsBar/>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ValidationErrorsBar from '@/components/ValidationErrors/ValidationErrorsBar.vue';
import ContactUsBar from '@/components/Actions/ContactUsBar.vue';
import AccountBar from '@/components/Account/AccountBar.vue';

@Options({
  components: {
    ValidationErrorsBar,
    ContactUsBar,
    AccountBar,
  },
})
export default class PanelTop extends Vue {

}
</script>

<style lang="scss" scoped>
.panel-top {
  pointer-events: all;
  width: 100%;
}
</style>