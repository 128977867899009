<template>
  <div class="error-load-field" >
     NO DETAIL FIELD FOR TYPE : {{ definitionAttributes.type }} ( {{ definitionAttributes.name }} )
  </div>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component'
import { APIFieldDefinition } from '@/typesAPI'

class Props {
  definitionAttributes: APIFieldDefinition['attributes'] = prop({
    required: true,
  });
}

export default class ErrorLoad extends Vue.with(Props) {

}
</script>

<style lang="scss" scoped>
.error-load-field {
  color: red;
  font-weight: 700;
}

</style>